import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assets/images/logo.png';
import { Route, Link, BrowserRouter as Router, Redirect } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import * as myConstClass from './constant.js';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import Header from './Header.js';
import Footer from './Footer.js';
import { Editor } from 'react-draft-wysiwyg';
import $ from 'jquery';
import { Base64 } from 'js-base64';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import SimpleReactValidator from 'simple-react-validator';
import ReactTooltip from 'react-tooltip';
import thumbImage from './assets/images/thumb.png';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import 'react-image-lightbox/style.css';


// Translation Higher Order Component
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    ListGroup,
    ListGroupItem
} from 'reactstrap';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
import YouTubePlayer from 'react-player/lib/players/YouTube';
import ReactPlayer from 'react-player';


function DesktopDesign(props) {
    return (
        <Row className="d-xl-flex d-lg-flex d-none">
            <Col xl="5" lg="5">
                <h4 style={{ color: '#212529' }}>
                    {props.guestInfo.title}
                </h4>
                <Col xl="12" lg="12">
                    {props.eventTopic.map((info, ii) => {

                        if (ii == 0) {
                            return (
                                <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                    <Label className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                        {info.name}
                                    </Label>
                                </Link>
                            )
                        } else {
                            return (
                                <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                    <Label style={{ marginLeft: '5px' }} className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                        {info.name}
                                    </Label>
                                </Link>
                            )
                        }
                    }
                    )}
                </Col>
            </Col>
            <Col xl="7" lg="7">
                <Row>
                    <Col xl="3" lg="3">
                        <p className="mb-2">{props.t('home.Language')}</p>

                        {props.guestInfo.arr_event_languages.map((info, ii) => {

                            return (<p className="mb-0">{info.label}</p>
                            )
                        }
                        )}
                    </Col>

                    <Col xl="3" lg="3" id="durationDiv" className="durationDiv">
                        <Label className="d-xl-block d-lg-block d-inline">
                            {props.t('home.Duration')}&nbsp;
                        </Label>
                        <Label className="d-xl-block d-lg-block d-inline">
                            {props.guestInfo.duration} {props.t('home.Days')}
                        </Label>
                    </Col>
                    <Col xl="3" lg="3" id="ageGroupDiv" className="ageGroupDiv">
                        <Label className="d-xl-block d-lg-block d-inline">

                            {props.t('home.Age Group')}
                            &nbsp;
                        </Label>
                        <Label className="d-xl-block d-lg-block d-inline">
                            {props.guestInfo.age_group}
                        </Label>
                    </Col>

                    <Col xl="6" lg="6">
                        <Label>{props.t('home.donation_contribution')}</Label><br />
                        <Label className="mb-0 font-weight-bolder d-inline-block">
                            {props.t('home.INR')} {props.guestInfo.first_price}/- {props.t('home.per')} {props.guestInfo.first_price_for}
                        </Label>
                        {
                            props.guestInfo.second_price_for &&
                            <Label className="mb-0 font-weight-bolder d-inline-block" id="secondPriceDiv">
                                {props.t('home.INR')} {props.guestInfo.second_price}/- {props.t('home.per')} {props.guestInfo.second_price_for}
                            </Label>
                        }
                    </Col>
                </Row>
            </Col >
        </Row >
    )

}

function MobileDesign(props) {
    return (
        <Row className="d-xl-none d-lg-none d-flex">
            <input type="hidden" name="MachineTestModalObjectm" id="MachineTestModalObjectm" value="" />
            <Col md="12" sm="12" xs="12">
                <h5>
                    {props.guestInfo.title}
                </h5>
            </Col>
            <Col md="12" sm="12" xs="12">
                <Row>

                    <Col md="12" sm="12" xs="12" id="durationDiv" className="durationDiv">
                        <Label>
                            {props.t('home.Duration')}&nbsp;
                        </Label>
                        <Label className="font-weight-normal">
                            {props.guestInfo.duration} {props.t('home.Days')}
                        </Label>
                    </Col>
                    <Col md="12" sm="12" xs="12" id="ageGroupDiv" className="ageGroupDiv">
                        <Label>
                            {props.t('home.Age Group')}&nbsp;
                        </Label>
                        <Label className="font-weight-normal">
                            {props.guestInfo.age_group}
                        </Label>
                    </Col>

                    <Col md="6" sm="6" xs="6" className="align-self-center">
                        <span className="font-weight-bold">
                            {props.t('home.INR')}
                            {props.guestInfo.first_price}/-
                        </span>
                        <small className="text-lowercase ">
                            {props.t('home.per')} {props.guestInfo.first_price_for}
                        </small>
                        <br />
                        {
                            props.guestInfo.second_price_for &&
                            <>
                                <span className="font-weight-bold">
                                    {props.t('home.INR')}
                                    {props.guestInfo.second_price}/-
                                </span>
                                <small className="text-lowercase ">
                                    {props.t('home.per')} {props.guestInfo.second_price_for}
                                </small>
                            </>
                        }
                    </Col>
                    <Col md={{ offset: 2, size: 4 }}>
                        <Label>{props.guestInfo.first_price_for}</Label>
                        <Input type="select" className="firstPriceDropdownDiv" name={"parentm" + props.guestInfo.event_id} id={"parentm" + props.guestInfo.event_id} bsSize="sm">
                            <option value="0" selected="selected">00</option>
                            <option value="1" >01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                        </Input>
                    </Col>
                    {
                        props.guestInfo.second_price_for &&
                        <Col id="secondPriceDropdownDiv">
                            <Label>{props.guestInfo.second_price_for}</Label>
                            <Input type="select" className="form-control form-control-sm" name={"childm" + props.guestInfo.event_id} id={"childm" + props.guestInfo.event_id} bsSize="sm">
                                <option value="0" selected="selected">00</option>
                                <option value="1" >01</option>
                                <option value="2">02</option>
                                <option value="3">03</option>
                                <option value="4">04</option>
                                <option value="5">05</option>
                                <option value="6">06</option>
                                <option value="7">07</option>
                                <option value="8">08</option>
                                <option value="9">09</option>
                                <option value="10">10</option>
                            </Input>
                        </Col>
                    }
                </Row>

            </Col>
            <Col md="12" sm="12" xs="12">
                <Row className="mt-2">
                    <Col md="6" sm="6" xs="6">

                        <i className="far fa-comment-dots orangeColor mr-2"></i>
                        <span className="small mr-2">{props.guestInfo.total_review}</span>

                        <i className="fas fa-thumbs-up orangeColor mr-2"></i>
                        <span className="small">{props.guestInfo.total_like}</span>

                    </Col>
                    <Col md={{ size: 3, offset: 3 }} sm="6" xs="6" className="align-self-start text-right">
                        <button className="btn btn-sm btn-outline-brown color-red mt-4 scheduleButton font-weight-bold" id="showScheduleBtn" onClick={props.showSchedule}>
                            {props.t('home.SHOW SCHEDULE')}
                        </button>

                        <div className="machineTestAddToCartDiv" >
                            <span style={{ marginTop: '10px' }} className={props.guestInfo.event_id + ' btn btn-sm btn-outline-brown color-brown machineTestAddToCart machineTestAddToCartButton'} name={props.guestInfo.event_id} id={props.guestInfo.event_id} onClick={props.showScheduleMachine}>{props.t('home.ADD TO CART')}</span>
                        </div>
                    </Col>

                </Row>
            </Col>
        </Row>
    )

}


// import 'react-awesome-slider/dist/styles.css';
// import AwesomeSlider from 'react-awesome-slider';
// //import AwsSliderStyles from 'react-awesome-slider/src/styles.scss';

class Event_details extends Component {
    constructor(props) {
        super(props);
        this.event_id = 0;
        this.state = {
            photoIndex: 0,
            startIndex: 0,
            lightboxOpen: false,
            images: [],
            isOpen: false,
            share_current_image_url: '',
            social_description: '',
            share_image_url: '',
            notifyinfo: {
                first_name: '',
                last_name: '',
                message: '',
                status: '',
                email: '',
            },

            posts: [],
            types: [],
            scheduleEvent: [],
            eventtopic: [],
            eventtopicmain: [],
            flag: 0,
            review: [],
            feedback: [],
            arrVideo: [],
            cartEvent: [],
            machineCartEvent: [],
            guestinfo: {
                type_id: '',
                first_topic_id: '',
                no_of_pages: '',
                total_like: '',
                total_review: '',
                title: '',
                author: '',
                quantity: '',
                price: '',
                description: '',
                images_array: [],
                arr_event_languages: [],
                current_event_type_id: '',
            },
            editorState: EditorState.createEmpty(),
        }
        this.validator = new SimpleReactValidator();
        this.setTopicId = this.setTopicId.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeIncrement = this.handleChangeIncrement.bind(this);
        this.handleChangeDecrement = this.handleChangeDecrement.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.addEventToCart = this.addEventToCart.bind(this);
        this.decreaseQuantity = this.decreaseQuantity.bind(this);
        this.increaseQuantity = this.increaseQuantity.bind(this);
        this.deleteFromCart = this.deleteFromCart.bind(this);
        this.deleteFromCartMachineTest = this.deleteFromCartMachineTest.bind(this);
        this.submitReview = this.submitReview.bind(this);
        this.submitLike = this.submitLike.bind(this);

        this.addMachineTestEventToCart = this.addMachineTestEventToCart.bind(this);
        this.addMachineTestEventToCartPopUp = this.addMachineTestEventToCartPopUp.bind(this);

        this.readMore = this.readMore.bind(this);
        this.readLess = this.readLess.bind(this);

        this.showMore = this.showMore.bind(this);
        this.showLess = this.showLess.bind(this);
        this.showallReview = this.showallReview.bind(this);

        this.showMoreFeedback = this.showMoreFeedback.bind(this);
        this.showLessFeedback = this.showLessFeedback.bind(this);
        this.notifyMe = this.notifyMe.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.addToCartContinue = this.addToCartContinue.bind(this);
        this.openGallery = this.openGallery.bind(this);

        this.openGalleryVideo = this.openGalleryVideo.bind(this);
        this.closeGallery = this.closeGallery.bind(this);
    }

    openGalleryVideo(e) {
        $('.jss1').attr("style", "display: none !important");
        $("#openGallery").css({ 'display': 'flex' });
        this.setState({ lightboxOpen: true });
        // var id = e.target.id;
        $('html,body').animate({
            scrollTop: 0
        }, 700);
        $('.slide-ico').css('display', 'none');
        this.state.photoIndex = e.target.id;
        $('#videoCloseIcon').css('display', 'inline');
        $('body').css('overflow', 'hidden');
        // $("#openGallery > div").css("z-index","99999"); 
        // $("#openGallery > div").css("z-index","99999 !important");
        // $( "#openGallery div" ).first().css("z-index","99999 !important"); 
        $('.fixed-top').css('z-index', '-9999999');
        $('.jss1').css('display', 'none !important');
        //$('.ytp-button').trigger();
        //alert('hi');
    }
    closeGallery() {
        this.setState({ lightboxOpen: false });
        $('#videoCloseIcon').css('display', 'none');
        $('.slide-ico').css('display', 'block');
        $('body').css('overflow', 'scroll');
        // $('.fixed-top').css('z-index','9999999');
        $('.fixed-top').css('z-index', '9999999');
        $('#openGallery').css('display', 'none');
    }

    addMachineTestEventToCartPopUp(e) {
        var event_schedule_id = e.target.id;
        //alert(event_schedule_id);
        $('#MachineTestModalObject').val(event_schedule_id);
        $('#MachineTestModalObjectm').val(event_schedule_id);
        var arr_event_schedule = event_schedule_id.split('_');
        var event_id = arr_event_schedule[0];

        var previous_event_id = localStorage.getItem('current_schedule_id');
        localStorage.removeItem('current_schedule_id');
        localStorage.setItem('current_schedule_id', event_id);

        $('#MachineTestModal').modal('show');
    }


    openGallery(e) {
        this.setState({ isOpen: true });
        // var id = e.target.id;
        this.state.photoIndex = e.target.id;
    }

    addToCartContinue() {
        $('#MachineTestModal').modal('hide');
        var event_id = $('#MachineTestModalObject').val();
        if (event_id > 0) {

        } else {
            var event_id = $('#MachineTestModalObjectm').val();
        }

        var parent = $('#parent' + event_id).val();
        // alert(event_id);
        if (parent > 0) {

        } else {
            var parent = $('#parentm' + event_id).val();
        }

        var child = $('#child' + event_id).val();
        if (child > 0) {

        } else {
            var child = $('#childm' + event_id).val();
        }
        var user_id = localStorage.getItem('user_id');

        if (child == undefined) {
            child = 0;
        }
        if (child == '') {
            child = 0;
        }

        if ((parent > 0 || child > 0) && user_id != '' && user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/addMachineTestEventToCart/' + event_id + '/' + user_id + '/' + parent + '/' + child)
                .then(response => response.json())
                .then(response => {
                    this.setState({ cart: response })
                    var message = this.state.cart.message;
                    var status = this.state.cart.status;
                    console.log('status' + status);
                    if (status == 'true') {

                        //$('#'+event_id).css('display','none');
                        $('.' + event_id).removeClass('btn-outline-brown');
                        //  $('.' + event_id).addClass('btn-sm btn-light');
                        $('.machineTestAddToCartButton').addClass('btn-outline-brown');
                        $('.machineTestAddToCartButton').addClass('btn-sm');
                        if (getLanguage() == 'ma') {
                            $('.machineTestAddToCartButton').html('अधिक घ�?या');
                        } else {
                            $('.machineTestAddToCartButton').html('ADD MORE');
                        }
                        //$('#added_in_cart_'+event_id).css('display','block');
                        // $('#delete_'+event_schedule_id).css('display','contents');
                        $('.machineTestAddToCart').css('display', 'inline');
                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        if (user_id > 0 && user_id != null) {
                            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartcount: response })
                                    var totalcartcount = this.state.cartcount.totalcartcount;
                                    $('.lblCartCount').html(totalcartcount);
                                })


                            fetch(myConstClass.BASE_URL + 'cart/getMachineTestEventCartDataWithoutGroupWise/' + user_id + '/' + getLanguage())
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ machineCartEvent: response })
                                    const numRows = this.state.machineCartEvent.length;
                                    if (numRows > 0) {
                                        $('.noItemInCart').css('display', 'none');
                                    } else {
                                        $('.noItemInCart').css('display', 'block');
                                    }
                                })

                        }

                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err))


        } else {
            //toastr.warning('Product quantity must be greater than 0')
            if (user_id > 0) {
                if (getLanguage() == 'ma') {
                    var message = "कृपया सहभागींची संख�?या निवडा.";
                } else {
                    var message = "Please select the number of participants.";
                }
                toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                var message = "Please login to our website.";
                localStorage.removeItem('error');
                localStorage.setItem('error', message);
                this.props.history.push('/signin')
            }

        }
    }
    handleSubmit(e) {
        e.preventDefault();
        var user_id = localStorage.getItem('user_id');
        var notify_schedule_id = $('#notify_schedule_id').val();
        console.log('this.refs', $('#pform').serializeArray());
        if (this.validator.allValid()) {
            const formData = {};
            for (const field in this.refs) {
                formData[field] = this.refs[field].value;
            }
            console.log('-->', formData);
            fetch(myConstClass.BASE_URL + `users/addnotify/` + user_id + '/' + notify_schedule_id, {
                method: 'POST',
                body: JSON.stringify($('#pform').serializeArray()),

            })
                .then(response => response.json())
                .then(response => {
                    this.setState({ notifyinfo: response })
                    var message = this.state.notifyinfo.message;
                    var status = this.state.notifyinfo.status;
                    console.log('hii' + status);
                    if (status == 'true') {
                        // $('#address').modal('hide');
                        // $('#pform')[0].reset();

                        //const numRows = this.state.notifyinfo.length;

                        this.state.notifyinfo.first_name = this.state.notifyinfo.first_name;
                        //this.state.notifyinfo.middle_name = this.state.notifyinfo[0].middle_name;
                        this.state.notifyinfo.last_name = this.state.notifyinfo.last_name;

                        //this.state.notifyinfo.phone = this.state.notifyinfo[0].phone;
                        this.state.notifyinfo.email = this.state.notifyinfo.email;
                        //this.state.notifyinfo.event_type = this.state.notifyinfo[0].event_type;
                        //this.state.notifyinfo.comment = this.state.notifyinfo[0].comment;

                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    $('#notifyModel').modal('hide');
                    //console.log(status);
                })
                .catch(err => console.log(err))
        }
        else {
            //alert('here');
            this.validator.showMessages();
            console.log('validations' + this.validator.showMessages());
            e.preventDefault();
            toast('Please fill all required fields.', { containerId: 'B', type: toast.TYPE.ERROR })
            // toast('Please fill all required fields.', {containerId: 'B',type: toast.TYPE.ERROR})

        }
    }

    notifyMe(e) {
        var event_schedule_id = e.target.id;
        var arr_event_schedule = event_schedule_id.split('_');
        var schedule_id = arr_event_schedule[1];
        var title = $('.notifyScheduletitle_' + schedule_id).val();
        var eventTitle = $('#notifyEventtitle_' + schedule_id).val();
        $('#eventTitle').html(eventTitle);
        // alert(schedule_id);

        $('#notifyTitle').html(title);
        $('#notify_schedule_id').val(schedule_id);
        $('#notifyModel').modal('show');
    }

    setTopicId() {
        var first_topic_id = localStorage.getItem('topic_id_duplicate');
        localStorage.removeItem('event_topic_id');
        localStorage.setItem('event_topic_id', first_topic_id);
        setTimeout(this.props.history.push('/products'), 10);
    }

    showallReview() {
        $('#ShowAllReviewDiv').css('display', 'none');
        const answer_array = this.props.location.pathname.split('/');
        fetch(myConstClass.BASE_URL + 'review/getEventReviewFrontEnd/' + answer_array['2'] + '/200')
            .then(response => response.json())
            .then(response => {
                this.setState({ review: response })

            })
            .catch(err => console.log(err))

    }


    showMore(e) {
        var review_id_all = e.target.id;
        var arr_review_id = review_id_all.split('_');
        var review_id = arr_review_id[1];
        $('#showMoreDiv_' + review_id).css('display', 'none');
        $('#showLessDiv_' + review_id).css('display', 'block');
    }

    showLess(e) {
        var review_id_all = e.target.id;
        var arr_review_id = review_id_all.split('_');
        var review_id = arr_review_id[1];
        $('#showMoreDiv_' + review_id).css('display', 'block');
        $('#showLessDiv_' + review_id).css('display', 'none');
    }

    showMoreFeedback(e) {
        var review_id_all = e.target.id;
        var arr_review_id = review_id_all.split('_');
        var review_id = arr_review_id[1];
        $('#showMoreFeedbackDiv_' + review_id).css('display', 'none');
        $('#showLessFeedbackDiv_' + review_id).css('display', 'block');
    }

    showLessFeedback(e) {
        var review_id_all = e.target.id;
        var arr_review_id = review_id_all.split('_');
        var review_id = arr_review_id[1];
        $('#showMoreFeedbackDiv_' + review_id).css('display', 'block');
        $('#showLessFeedbackDiv_' + review_id).css('display', 'none');
    }

    readMore(e) {
        // var product_id = e.target.id;
        $('#ReadMoreDiv').css('display', 'none');
        $('#ReadLessDiv').css('display', 'block');
    }

    readLess(e) {
        // var product_id = e.target.id;
        $('#ReadMoreDiv').css('display', 'block');
        $('#ReadLessDiv').css('display', 'none');
    }

    addMachineTestEventToCart(e) {
        var event_schedule_id = e.target.id;
        var arr_event_schedule = event_schedule_id.split('_');
        var event_id = arr_event_schedule[0];
        //var schedule_id = arr_event_schedule[1];
        // console.log(e.target.id);

        var parent = $('#parent' + event_id).val();

        if (parent > 0) {

        } else {
            var parent = $('#parentm' + event_id).val();
        }
        //    var child =  $('#child'+event_id).val();
        var child = 0;
        var user_id = localStorage.getItem('user_id');
        //console.log(product_quantity);

        if ((parent > 0 || child > 0) && user_id != '' && user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/addMachineTestEventToCart/' + event_id + '/' + user_id + '/' + parent + '/' + child)
                .then(response => response.json())
                .then(response => {
                    this.setState({ cart: response })
                    var message = this.state.cart.message;
                    var status = this.state.cart.status;
                    if (status == 'true') {

                        //$('#'+event_id).css('display','none');
                        $('.machineTestAddToDefault').removeClass('btn-outline-brown');
                        $('.machineTestAddToDefault').addClass('btn-sm btn-light');

                        if (getLanguage() == 'ma') {
                            $('.machineTestAddToCartButton').html('अधिक घ�?या');
                        } else {
                            $('.machineTestAddToCartButton').html('ADD MORE');
                        }
                        $('.machineTestAddToCartButton').css('display', 'block');

                        $('.added_in_cart_' + event_id).css('display', 'block');
                        $('.machineTestAddedInCart').css('display', 'block');
                        ///$('.machineTestAddToDefault').css('display','none');
                        $('.delete_' + event_schedule_id).css('display', 'contents');
                        $('.machineTestAddToCartButton').css('display', 'block');
                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        if (user_id > 0 && user_id != null) {
                            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartcount: response })
                                    var totalcartcount = this.state.cartcount.totalcartcount;
                                    $('.lblCartCount').html(totalcartcount);
                                })


                            fetch(myConstClass.BASE_URL + 'cart/getMachineTestEventCartDataWithoutGroupWise/' + user_id + '/' + getLanguage())
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ machineCartEvent: response })
                                    const numRows = this.state.machineCartEvent.length;
                                    if (numRows > 0) {
                                        $('.noItemInCart').css('display', 'none');
                                    } else {
                                        $('.noItemInCart').css('display', 'block');
                                    }
                                })

                        }
                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err))


        } else {
            //toastr.warning('Product quantity must be greater than 0')
            if (user_id > 0) {
                if (getLanguage() == 'ma') {
                    var message = "Please select the number of participants.";
                } else {
                    var message = "कृपया सहभागींची संख�?या निवडा.";
                }
                toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                var message = "Please login to our website.";
                localStorage.removeItem('error');
                localStorage.setItem('error', message);
                this.props.history.push('/signin')
                //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

            }

        }
        //toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!')
        //this.props.offline();    
    }


    submitLike() {
        const answer_array = this.props.location.pathname.split('/');
        var user_id = localStorage.getItem('user_id');
        if (user_id != '' && user_id != null && user_id > 0) {

            fetch(myConstClass.BASE_URL + 'review/saveEventLikeFrontEnd/' + user_id + '/' + answer_array['2'])
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventinfo: response })
                    var status = this.state.eventinfo.status;
                    var message = this.state.eventinfo.message;
                    if (status == 'true') {
                        var productLikeCount = $('#productLikeCount').html();
                        var productLikeCountNew = parseInt(productLikeCount) + 1;
                        $('#productLikeCount').html(productLikeCountNew);
                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        //setTimeout(this.props.history.push('/products'),1000);
                    } else {
                        toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
                        //toast("Wow so easy !");
                    }
                })
                .catch(err => console.log(err))
            // toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});


        } else {
            //var error =  'You must have login for submitting review.';
            localStorage.removeItem('error');
            localStorage.setItem('error', 'You must have login for like product.');
            // toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
            // this.props.history.push('/signin');
            setTimeout(this.props.history.push('/signin'), 1000);
        }

        //alert(user_review);
    }

    submitReview(e) {
        e.preventDefault();
        console.log('this.refs', $('#pform').serializeArray());
        const formData = {};
        for (const field in this.refs) {
            formData[field] = this.refs[field].value;
        }
        const answer_array = this.props.location.pathname.split('/');
        var user_review_current = $('#user_review').val();
        var user_review = $.trim(user_review_current);
        //alert(user_review);
        var user_id = localStorage.getItem('user_id');
        if (user_id != '' && user_id != null && user_id > 0) {
            if (user_review != '') {
                var message = 'Your review added successfully,once admin approval will display it.';

                // fetch(myConstClass.BASE_URL+'review/saveEventReviewFrontEnd/'+user_id+'/'+answer_array['2']+'/'+user_review)
                fetch(myConstClass.BASE_URL + `review/saveEventReviewFrontEnd/` + user_id + '/' + answer_array['2'], {
                    method: 'POST',
                    body: JSON.stringify($('#pform').serializeArray()),

                })
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ eventinfo: response })
                        var status = this.state.eventinfo.status;
                        var message = this.state.eventinfo.message;
                        if (status == 'true') {
                            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                            //setTimeout(this.props.history.push('/products'),1000);
                        } else {
                            toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
                            //toast("Wow so easy !");
                        }
                    })
                    .catch(err => console.log(err))
                // toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});

                $('#user_review').val('');
            } else {
                var message = "Please enter review.";
                toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
            }


        } else {
            //var error =  'You must have login for submitting review.';
            localStorage.removeItem('error');
            localStorage.setItem('error', 'You must have login for submitting review.');
            // toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
            // this.props.history.push('/signin');
            setTimeout(this.props.history.push('/signin'), 1000);
        }

        //alert(user_review);
    }


    deleteFromCartMachineTest(e) {
        var delete_event_schedule_id = e.target.id;
        var arr_event_schedule = delete_event_schedule_id.split('_');
        var event_id = arr_event_schedule[1];
        // var event_schedule_id = event_id+'_'+schedule_id;
        // console.log(e.target.id);
        //alert(event_id);

        var user_id = localStorage.getItem('user_id');
        //console.log(product_quantity);

        if ((event_id > 0) && user_id != '' && user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/removeProductFromCart/' + event_id + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ removeFromCart: response })
                    var message = this.state.removeFromCart.message;
                    var status = this.state.removeFromCart.status;
                    if (status == 'true') {
                        //$('#added_in_cart_'+event_id).css('display','none');
                        //$('#remove'+event_id).css('display','none');
                        //$('#'+event_id).css('display','block'); //for delete
                        $('.' + event_id).css('display', 'block');
                        $('.machineTestAddToCart').css('display', 'inline');
                        //     $('.' + event_id).removeClass('btn-sm btn-light');
                        //   $('.machineTestAddToCart').css('display', 'block');
                        $('.' + event_id).removeClass('btn-sm btn-light');

                        $('.machineTestAddToCartButton').addClass('btn-outline-brown');
                        $('.machineTestAddToCartButton' + event_id).addClass('btn-outline-brown');

                        $('.machineTestAddToCartButton').addClass('btn-sm');

                        if (getLanguage() == 'ma') {
                            $('.machineTestAddToCartButton').html('कार�?टमध�?ये टाका');
                        } else {
                            $('.machineTestAddToCartButton').html('ADD TO CART');
                        }
                        //$('.machineTestAddedInCart').css('display', 'none');
                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });

                        if (user_id > 0 && user_id != null) {
                            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartcount: response })
                                    var totalcartcount = this.state.cartcount.totalcartcount;
                                    $('.lblCartCount').html(totalcartcount);
                                })

                            fetch(myConstClass.BASE_URL + 'cart/getMachineTestEventCartDataWithoutGroupWise/' + user_id + '/' + getLanguage())
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ machineCartEvent: response })
                                    const numRows = this.state.machineCartEvent.length;
                                    if (numRows > 0) {
                                        $('.noItemInCart').css('display', 'none');
                                    } else {
                                        $('.noItemInCart').css('display', 'block');
                                    }
                                })
                        }


                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err))


        } else {
            //toastr.warning('Product quantity must be greater than 0')
            if (user_id > 0) {
                toast('Error occure.', { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                var message = "Please login to our website.";
                localStorage.removeItem('error');
                localStorage.setItem('error', message);
                this.props.history.push('/signin')
                //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

            }

        }
        //toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!')
        //this.props.offline();    
    }


    deleteFromCart(e) {
        var delete_event_schedule_id = e.target.id;
        var arr_event_schedule = delete_event_schedule_id.split('_');
        var event_id = arr_event_schedule[1];
        var schedule_id = arr_event_schedule[2];
        var event_schedule_id = event_id + '_' + schedule_id;
        // console.log(e.target.id);
        //alert(event_id);

        var user_id = localStorage.getItem('user_id');
        //console.log(product_quantity);

        if ((schedule_id > 0) && user_id != '' && user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/removeProductFromCart/' + schedule_id + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ removeFromCart: response })
                    var message = this.state.removeFromCart.message;
                    var status = this.state.removeFromCart.status;
                    if (status == 'true') {
                        $('.' + event_schedule_id).css('display', 'inline');
                        $('.remove' + schedule_id).css('display', 'none');
                        $('#' + delete_event_schedule_id).css('display', 'none'); //for delete

                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        if (user_id > 0 && user_id != null) {
                            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartcount: response })
                                    var totalcartcount = this.state.cartcount.totalcartcount;
                                    $('.lblCartCount').html(totalcartcount);
                                })

                            var current_event_type_id = this.state.guestinfo.current_event_type_id;
                            fetch(myConstClass.BASE_URL + 'cart/getEventCartDataWithoutGroupWise/' + user_id + '/' + getLanguage() + '/' + current_event_type_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartEvent: response })

                                    const numRows = this.state.cartEvent.length;
                                    localStorage.setItem("cartCount", numRows)
                                    if (numRows > 0) {
                                        $('.noItemInCart').css('display', 'none');
                                    } else {
                                        $('.noItemInCart').css('display', 'block');
                                    }

                                })

                        }
                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err))


        } else {
            //toastr.warning('Product quantity must be greater than 0')
            if (user_id > 0) {
                toast('Error occure.', { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                var message = "Please login to our website.";
                localStorage.removeItem('error');
                localStorage.setItem('error', message);
                this.props.history.push('/signin')
                //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

            }

        }
        //toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!')
        //this.props.offline();    
    }

    decreaseQuantity(event_id) {
        var qty = parseInt($('#qty' + event_id).val()) - 1;
        if (qty < 0) {
            qty = 0;
        }
        console.log(qty);
        $('#qty' + event_id).val(qty);
    }
    increaseQuantity(event_id) {
        var qty = parseInt($('#qty' + event_id).val()) + 1;
        console.log(qty);
        $('#qty' + event_id).val(qty);
    }




    addToCart(e) {

        var product_id = e.target.id;
        // console.log(e.target.id);
        var product_quantity = $('#qty' + product_id).val();
        var user_id = localStorage.getItem('user_id');
        console.log(product_quantity);

        if (product_quantity > 0 && product_id != '' && user_id != '' && user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/addProductToCart/' + product_id + '/' + product_quantity + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ cart: response })
                    var message = this.state.cart.message;
                    var status = this.state.cart.status;
                    if (status == 'true') {
                        $('#' + product_id).css('display', 'none');
                        $('#addedInCart_' + product_id).css('display', 'inherit');
                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        if (user_id > 0 && user_id != null) {
                            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartcount: response })
                                    var totalcartcount = this.state.cartcount.totalcartcount;
                                    $('.lblCartCount').html(totalcartcount);
                                })
                        }



                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err))


        } else {
            //toastr.warning('Product quantity must be greater than 0')
            if (user_id > 0) {
                toast('Product quantity must be greater than 0.', { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                var message = "Please login to our website.";
                localStorage.removeItem('error');
                localStorage.setItem('error', message);
                this.props.history.push('/signin')
                //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

            }

        }
        //toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!')
        //this.props.offline();    
    }




    addEventToCart(e) {

        var event_schedule_id = e.target.id;
        var arr_event_schedule = event_schedule_id.split('_');
        var event_id = arr_event_schedule[0];
        var schedule_id = arr_event_schedule[1];
        // console.log(e.target.id);


        var parent = $('#parent' + event_id).val();

        if (parent > 0) {

        } else {
            var parent = $('#parentm' + event_id).val();
        }

        var child = $('#child' + event_id).val();
        if (child > 0) {

        } else {
            var child = $('#childm' + event_id).val();
        }
        var user_id = localStorage.getItem('user_id');
        //console.log(product_quantity);

        var second_price = this.state.guestinfo.second_price;
        if (second_price == '' || second_price == 0 || second_price == undefined) {
            var child = 0;
        }
        //alert(second_price);
        if ((parent > 0 || child > 0) && user_id != '' && user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/addEventToCart/' + schedule_id + '/' + user_id + '/' + parent + '/' + child)
                .then(response => response.json())
                .then(response => {
                    this.setState({ cart: response })
                    var message = this.state.cart.message;
                    var status = this.state.cart.status;
                    if (status == 'true') {
                        $('.' + event_schedule_id).css('display', 'none');
                        $('.remove' + schedule_id).css('display', 'contents');
                        $('.delete_' + event_schedule_id).css('display', 'contents');

                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        if (user_id > 0 && user_id != null) {
                            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartcount: response })
                                    var totalcartcount = this.state.cartcount.totalcartcount;
                                    $('.lblCartCount').html(totalcartcount);
                                })
                            var current_event_type_id = this.state.guestinfo.current_event_type_id;
                            fetch(myConstClass.BASE_URL + 'cart/getEventCartDataWithoutGroupWise/' + user_id + '/' + getLanguage() + '/' + current_event_type_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartEvent: response })

                                    const numRows = this.state.cartEvent.length;
                                    localStorage.setItem("cartCount", numRows)
                                    if (numRows > 0) {
                                        $('.noItemInCart').css('display', 'none');
                                    } else {
                                        $('.noItemInCart').css('display', 'block');
                                    }

                                    if ((current_event_type_id == 4 || current_event_type_id == 2) && getLanguage() == 'ma') {
                                        $('.bookNowInCartMrPoojs').css('display', 'inline');
                                        $('.bookNowInCartMr').css('display', 'none');
                                        $('.bookNowInCartEn').css('display', 'none');
                                    } else if ((current_event_type_id == 1 || current_event_type_id == 5) && getLanguage() == 'ma') {
                                        //alert('#bookNowInCartMrPoojs');
                                        $('.bookNowInCartMrPoojs').css('display', 'none');
                                        $('.bookNowInCartMr').css('display', 'inline');
                                        $('.bookNowInCartEn').css('display', 'none');
                                    }

                                })

                        }
                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err))


        } else {
            //toastr.warning('Product quantity must be greater than 0')
            if (user_id > 0) {
                if (getLanguage() == 'ma') {
                    var message = "कृपया सहभागींची संख�?या निवडा.";
                } else {
                    var message = "Please select the number of participants.";
                }
                toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                var message = "Please login to our website.";
                localStorage.removeItem('error');
                localStorage.setItem('error', message);
                this.props.history.push('/signin')
                //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

            }

        }
        //toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!')
        //this.props.offline();    
    }


    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    handleChangeIncrement(event) {
        var qty = parseInt($('#qty').val()) + 1;

        this.setState({ value: qty });
    }

    handleChangeDecrement(event) {
        var qty = parseInt($('#qty').val()) - 1;
        if (qty < 0) {
            qty = 0;
        }
        this.setState({ value: qty });
    }
    componentDidMount() {


        //   fetch(myConstClass.BASE_URL+'product/getAvailProdTypes')
        //   .then(response => response.json())
        //   .then(response =>this.setState({types: response}))
        //   .catch(err => console.log(err))
        if (getLanguage() == 'ma') {
            $('.relatedImageMarathi').css('display', 'block');
            $('.relatedImageEnglish').css('display', 'none');
        } else {
            $('.relatedImageMarathi').css('display', 'none');
            $('.relatedImageEnglish').css('display', 'block');
        }
        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            localStorage.removeItem('success');
        }
        var user_id = localStorage.getItem('user_id');
        if (user_id == '' || user_id == undefined) {
            $('.goToCart').css('display', 'none');
            $('.goToLogin').css('display', 'inline');
        }
        const answer_array = this.props.location.pathname.split('/');
        // alert(answer_array);
        if (answer_array['2']) {

            fetch(myConstClass.BASE_URL + 'event/getEventInfoFrontEnd/' + answer_array['2'] + '/' + getLanguage() + '/0/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ guestinfo: response })
                    var current_event_type_id = this.state.guestinfo.event_type_id;
                    if (current_event_type_id == 1) {
                        $('.for_res_event').css('display', 'contents');
                        $('.for_other_event').css('display', 'none');
                    } else {
                        $('.for_res_event').css('display', 'none');
                        $('.for_other_event').css('display', 'contents');
                    }
                    if ((current_event_type_id == 4 || current_event_type_id == 2) && getLanguage() == 'ma') {
                        $('.bookNowInCartMrPoojs').css('display', 'inline');
                        $('.bookNowInCartMr').css('display', 'none');
                        $('.bookNowInCartEn').css('display', 'none');
                    } else if ((current_event_type_id == 1 || current_event_type_id == 5) && getLanguage() == 'ma') {
                        //alert('#bookNowInCartMrPoojs');
                        $('.bookNowInCartMrPoojs').css('display', 'none');
                        $('.bookNowInCartMr').css('display', 'inline');
                        $('.bookNowInCartEn').css('display', 'none');
                    } else {
                        $('.bookNowInCartMrPoojs').css('display', 'none');
                        $('.bookNowInCartMr').css('display', 'none');
                        $('.bookNowInCartEn').css('display', 'inline');
                    }
                    this.state.guestinfo.current_event_type_id = this.state.guestinfo.event_type_id;
                    //alert(current_event_type_id);
                    if (current_event_type_id == 3) {
                        $('.appointmentDiv').css('display', 'block');
                        $('#eventLink').css('display', 'none');
                        $('#machineTestLink').css('display', 'contents');
                        $('.machineTestAddToCartDiv').css('display', 'block');
                        $('.scheduleButton').css('display', 'none');
                        if (answer_array['2'] == 83) {
                            $('.appointmentDivPsyco').css('display', 'block');
                            $('.appointmentDiv1').css('display', 'block');
                        } else {
                            $('.appointmentDivPsyco').css('display', 'none');
                        }
                    } else {
                        $('.appointmentDivPsyco').css('display', 'none');
                        $('.appointmentDiv').css('display', 'none');
                        $('#eventLink').css('display', 'contents');
                        $('#machineTestLink').css('display', 'none');
                        $('.scheduleButton').css('display', 'block');
                        $('.machineTestAddToCartDiv').css('display', 'none');
                    }
                    var length = this.state.guestinfo.images_array.length;
                    // alert(length);
                    if (length > 1) {
                        $('.imageSliderIcon').css('display', 'inline');

                    } else {
                        $('.imageSliderIcon').css('display', 'none');

                    }

                    this.state.guestinfo.images_array.map((info, ii) => {
                        if (ii == 0) {
                            this.setState({ share_current_image_url: window.location.href });
                            this.setState({ share_image_url: info.image_name });
                        }
                    })

                    fetch(myConstClass.BASE_URL + 'event/getImages' + '/' + answer_array['2'])
                        .then(response => response.json())
                        .then(response => this.setState({ images: response }))
                        .catch(err => console.log(err))


                    var second_price = this.state.guestinfo.second_price;
                    var event_id = this.state.guestinfo.event_id;

                    //alert(second_price);
                    if (second_price == '' || second_price == 0 || second_price == undefined) {
                        $('#secondPriceDropdownDiv').css('display', 'none');
                        $('#secondPriceDiv').css('display', 'none');
                        // $('.firstPriceDropdownDiv').css('width', '69%');
                    }
                    if (current_event_type_id == 3) {
                        var is_added_in_cart = this.state.guestinfo.is_added_in_cart;

                        if (is_added_in_cart == 1) {
                            // $('.machineTestAddedInCart').css('display','block');
                            // $('.'+event_id).css('display','none');
                            // $('.machineTestAddToDefault').css('display','none');
                            // $('.machineTestAddToDefault').removeClass('btn-outline-brown');
                            // $('.machineTestAddToDefault').addClass('btn-sm btn-light');

                            // if (getLanguage() == 'ma') {
                            //     $('.machineTestAddToCartButton').html( 'अधिक घ�?या');
                            // } else {
                            //     $('.machineTestAddToCartButton').html('ADDED TO CART');
                            // }
                            $('.' + event_id).removeClass('btn-outline-brown');
                            $('.' + event_id).addClass('btn-sm ');
                            $('.machineTestAddToCart').css('display', 'inline');
                            $('.machineTestAddToCartButton').addClass('btn-outline-brown');
                            $('.machineTestAddToCartButton' + event_id).addClass('btn-outline-brown');
                            if (getLanguage() == 'ma') {
                                $('.machineTestAddToCartButton').html('अधिक घ�?या');
                            } else {
                                $('.machineTestAddToCartButton').html('ADD MORE');
                            }
                            $('.machineTestAddToCartButton').css('display', 'block');
                        } else {
                            $('.machineTestAddedInCart').css('display', 'none');
                            $('.machineTestAddToCart').css('display', 'block');
                            $('.' + event_id).css('display', 'block');
                            $('.machineTestAddToDefault').css('display', 'block');
                        }
                        $('#secondPriceDiv').css('display', 'none');
                        $('.durationDiv').css('display', 'none');
                        $('.ageGroupDiv').css('display', 'block');
                        $('.upcommingCoursesFor').css('display', 'none');
                        $('.StudyCourcesInCartDiv').css('display', 'none');
                        $('.allScheduleDiv').css('display', 'none');
                        $('.machineTestStep3Div').css('display', 'block');
                        $('.machineCartEvent').css('display', 'block');
                        $('.machineTestAddToCartDiv').css('display', 'block');
                        $('.machineTestAddToCart').css('display', 'inline');

                        if (answer_array['2'] == 83) {
                            $('.machineTestStep3Div').css('display', 'none');
                            $('.machineTestStep3DivPsyco').css('display', 'block');
                            $('.appointmentDivPsyco').css('display', 'block');

                        }

                    } else {
                        $('.durationDiv').css('display', 'block');
                        $('.upcommingCoursesFor').css('display', 'block');
                        $('.ageGroupDiv').css('display', 'none');
                        $('.machineTestStep3Div').css('display', 'none');
                        $('.machineTestStep3DivPsyco').css('display', 'none');
                        $('.StudyCourcesInCartDiv').css('display', 'block');
                        $('.allScheduleDiv').css('display', 'block');
                        $('.machineCartEvent').css('display', 'none');
                        $('#machineTestAddToCartDiv').css('display', 'none');
                        $('.machineTestAddToCart').css('display', 'none');
                    }

                    if (current_event_type_id != 3) {
                        fetch(myConstClass.BASE_URL + 'cart/getEventCartDataWithoutGroupWise/' + user_id + '/' + getLanguage() + '/' + current_event_type_id)
                            .then(response => response.json())
                            .then(response => {
                                this.setState({ cartEvent: response })

                                const numRows = this.state.cartEvent.length;
                                localStorage.setItem("cartCount", numRows)
                                if (numRows > 0) {
                                    $('.noItemInCart').css('display', 'none');
                                } else {
                                    $('.noItemInCart').css('display', 'block');
                                }

                            })
                    } else {
                        fetch(myConstClass.BASE_URL + 'cart/getMachineTestEventCartDataWithoutGroupWise/' + user_id + '/' + getLanguage())
                            .then(response => response.json())
                            .then(response => {
                                this.setState({ machineCartEvent: response })

                                const numRows = this.state.machineCartEvent.length;
                                if (numRows > 0) {
                                    $('.noItemInCart').css('display', 'none');
                                } else {
                                    $('.noItemInCart').css('display', 'block');
                                }

                            })
                    }

                    var desc = (this.state.guestinfo.description);
                    this.state.guestinfo.description = desc;

                    var short_description = (this.state.guestinfo.short_description);
                    this.state.guestinfo.short_description = short_description;

                    //   if(this.state.guestinfo.short_description == ''){
                    //     this.state.guestinfo.short_description = 'Tm90IGFkZGVkIHlldC4=';


                    //alert(short_description);
                    var descripotion_length = desc.length;
                    if (descripotion_length < 450 || descripotion_length == undefined) {
                        $('#readMoreButton').css('display', 'none');
                    }
                })
                .then(response => {
                    this.setState({
                        editorState: EditorState.createWithContent(
                            convertFromRaw(JSON.parse(this.state.guestinfo.description))
                        )
                    })
                }).then(console.log('' + this.state.guestinfo.title))
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'product/getRelatedProductsForEvent/' + answer_array['2'] + '/' + getLanguage() + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ posts: response })
                    var total_product = this.state.posts.length;
                    if (total_product >= 4) {
                        $('#showAllProducts').css('display', 'block');
                    } else {
                        $('#showAllProducts').css('display', 'none');
                    }
                })

            fetch(myConstClass.BASE_URL + 'event/getEventTopicsFrontEnd/' + answer_array['2'] + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventtopicmain: response })
                    // var total_topic = this.state.eventtopic.length;

                })

            fetch(myConstClass.BASE_URL + 'event/getEventSubTopicsFrontEnd/' + answer_array['2'] + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventtopic: response })
                    var total_topic = this.state.eventtopic.length;
                    if (total_topic > 0) {
                        var first_topic_id = this.state.eventtopic[0].topic_id;
                        localStorage.removeItem('topic_id_duplicate');
                        localStorage.setItem('topic_id_duplicate', first_topic_id);
                    }
                })
            var current_event_type_id = this.state.guestinfo.current_event_type_id;


            if (current_event_type_id != 3) {
                var event_id = answer_array['2'];
                fetch(myConstClass.BASE_URL + 'event/getAllScheduleEventFrontEnd' + '/' + getLanguage() + '/' + event_id + '/' + user_id)
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ scheduleEvent: response })
                        const numRows = this.state.scheduleEvent.length;
                        if (numRows < 1) {
                            $('.noScheduleAvailable').css('display', 'block');
                        } else {
                            this.state.guestinfo.schedule_event_name = this.state.scheduleEvent[0].title;
                            $('.noScheduleAvailable').css('display', 'none');

                        }
                    })
                    .catch(err => console.log(err))
            }


        }

        fetch(myConstClass.BASE_URL + 'review/getEventReviewFrontEnd/' + answer_array['2'] + '/2')
            .then(response => response.json())
            .then(response => {
                this.setState({ review: response })
                const total = this.state.review.length;
                if (total > 1) {
                    $('#ShowAllReviewDiv').css('display', 'inline');
                } else {
                    $('#ShowAllReviewDiv').css('display', 'none');
                }
            })
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'review/getEventReviewFrontEnd/' + answer_array['2'] + '/1')
            .then(response => response.json())
            .then(response => {
                this.setState({ review: response })
                const total = this.state.review.length;
                if (total < 1) {
                    $('#ShowAllReviewDiv').css('display', 'none');
                }
            })
            .catch(err => console.log(err))



        fetch(myConstClass.BASE_URL + 'review/getEventFeedbackFrontEnd/' + answer_array['2'] + '/1')
            .then(response => response.json())
            .then(response => this.setState({ feedback: response }))
            .catch(err => console.log(err))


        fetch(myConstClass.BASE_URL + 'event/getEventVideoFrontEnd/' + answer_array['2'])
            .then(response => response.json())
            .then(response => {
                this.setState({ arrVideo: response })
                var numRow = this.state.arrVideo.length;
                if (numRow > 0) {
                    $('#carouselExampleControls1').css('display', 'inline');
                } else {
                    $('#carouselExampleControls1').css('display', 'none');
                }
            })
            .catch(err => console.log(err))

        // var current_event_type_id =  this.state.guestinfo.current_event_type_id ;


        if (user_id > 0) {
            var btoa_user_id = btoa(user_id);
            // fetch(myConstClass.BASE_URL + 'users/getUserProfile/' + user_id)
            fetch(myConstClass.BASE_URL + 'users/getUserProfile/' + btoa_user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ notifyinfo: response })
                    const numRows = this.state.notifyinfo.length;
                    if (numRows > 0) {
                        this.state.notifyinfo.first_name = this.state.notifyinfo[0].first_name;
                        //this.state.notifyinfo.middle_name = this.state.notifyinfo[0].middle_name;
                        this.state.notifyinfo.last_name = this.state.notifyinfo[0].last_name;
                        this.state.notifyinfo.phone = this.state.notifyinfo[0].phone;
                        this.state.notifyinfo.email = this.state.notifyinfo[0].email;

                    }
                })
        }

        this.showSchedule = this.showSchedule.bind(this);
        this.showScheduleMachine = this.showScheduleMachine.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (getLanguage() == 'ma') {
            $('.relatedImageMarathi').css('display', 'block');
            $('.relatedImageEnglish').css('display', 'none');
        } else {
            $('.relatedImageMarathi').css('display', 'none');
            $('.relatedImageEnglish').css('display', 'block');
        }
        var user_id = localStorage.getItem('user_id');
        const answer_array = this.props.location.pathname.split('/');
        const { flag } = this.state
        var current_event_type_id = this.state.guestinfo.event_type_id;
        if (current_event_type_id == 1) {
            $('.for_res_event').css('display', 'contents');
            $('.for_other_event').css('display', 'none');
        } else {
            $('.for_res_event').css('display', 'none');
            $('.for_other_event').css('display', 'contents');
        }
        if ((current_event_type_id == 4 || current_event_type_id == 2) && getLanguage() == 'ma') {
            $('.bookNowInCartMrPoojs').css('display', 'inline');
            $('.bookNowInCartMr').css('display', 'none');
            $('.bookNowInCartEn').css('display', 'none');
        } else if ((current_event_type_id == 1 || current_event_type_id == 5) && getLanguage() == 'ma') {
            //alert('#bookNowInCartMrPoojs');
            $('.bookNowInCartMrPoojs').css('display', 'none');
            $('.bookNowInCartMr').css('display', 'inline');
            $('.bookNowInCartEn').css('display', 'none');
        } else {
            $('.bookNowInCartMrPoojs').css('display', 'none');
            $('.bookNowInCartMr').css('display', 'none');
            $('.bookNowInCartEn').css('display', 'inline');
        }
        this.state.guestinfo.current_event_type_id = this.state.guestinfo.event_type_id;
        if (current_event_type_id == 3) {
            $('.appointmentDiv').css('display', 'block');
            $('#eventLink').css('display', 'none');
            $('#machineTestLink').css('display', 'contents');
            $('.machineTestAddToCartDiv').css('display', 'block');
            $('.scheduleButton').css('display', 'none');
            if (answer_array['2'] == 83) {
                $('.appointmentDiv').css('display', 'none');
                $('.appointmentDiv1').css('display', 'block');
            }
        } else {
            $('.appointmentDiv').css('display', 'none');
            $('.appointmentDiv1').css('display', 'none');
            $('#eventLink').css('display', 'contents');
            $('#machineTestLink').css('display', 'none');
            $('.scheduleButton').css('display', 'block');
            $('.machineTestAddToCartDiv').css('display', 'none');
        }
        // alert(answer_array);
        if (answer_array['2'] && getLanguage() == 'ma' && prevProps.history.location.pathname !== prevProps.location.pathname) {
            this.setState({ flag: 1 })
            fetch(myConstClass.BASE_URL + 'event/getEventInfoFrontEnd/' + answer_array['2'] + '/' + getLanguage() + '/0/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ guestinfo: response })
                    var current_event_type_id = this.state.guestinfo.event_type_id;
                    if (current_event_type_id == 1) {
                        $('.for_res_event').css('display', 'contents');
                        $('.for_other_event').css('display', 'none');
                    } else {
                        $('.for_res_event').css('display', 'none');
                        $('.for_other_event').css('display', 'contents');
                    }
                    if ((current_event_type_id == 4 || current_event_type_id == 2) && getLanguage() == 'ma') {
                        $('.bookNowInCartMrPoojs').css('display', 'inline');
                        $('.bookNowInCartMr').css('display', 'none');
                        $('.bookNowInCartEn').css('display', 'none');
                    } else if ((current_event_type_id == 1 || current_event_type_id == 5) && getLanguage() == 'ma') {
                        //alert('#bookNowInCartMrPoojs');
                        $('.bookNowInCartMrPoojs').css('display', 'none');
                        $('.bookNowInCartMr').css('display', 'inline');
                        $('.bookNowInCartEn').css('display', 'none');
                    }
                    this.state.guestinfo.current_event_type_id = this.state.guestinfo.event_type_id;
                    //alert(current_event_type_id);
                    if (current_event_type_id == 3) {
                        $('.appointmentDiv').css('display', 'block');
                        $('#eventLink').css('display', 'none');
                        $('#machineTestLink').css('display', 'contents');
                        $('.machineTestAddToCartDiv').css('display', 'block');
                        $('.scheduleButton').css('display', 'none');
                        if (answer_array['2'] == 83) {
                            $('.appointmentDivPsyco').css('display', 'block');
                            $('.appointmentDiv1').css('display', 'block');
                        } else {
                            $('.appointmentDivPsyco').css('display', 'none');
                        }
                    } else {
                        $('.appointmentDivPsyco').css('display', 'none');
                        $('.appointmentDiv').css('display', 'none');
                        $('#eventLink').css('display', 'contents');
                        $('#machineTestLink').css('display', 'none');
                        $('.scheduleButton').css('display', 'block');
                        $('.machineTestAddToCartDiv').css('display', 'none');
                    }
                    var event_id = this.state.guestinfo.event_id;
                    if (current_event_type_id == 3) {
                        var is_added_in_cart = this.state.guestinfo.is_added_in_cart;

                        if (is_added_in_cart == 1) {
                            // $('.machineTestAddedInCart').css('display','block');
                            // $('.'+event_id).css('display','none');
                            // $('.machineTestAddToDefault').css('display','none');
                            // $('.machineTestAddToDefault').removeClass('btn-outline-brown');
                            // $('.machineTestAddToDefault').addClass('btn-sm btn-light');

                            // if (getLanguage() == 'ma') {
                            //     $('.machineTestAddToCartButton').html('अधिक घ�?या');
                            // } else {
                            //     $('.machineTestAddToCartButton').html('ADDED TO CART');
                            // }
                            $('.' + event_id).removeClass('btn-outline-brown');
                            $('.' + event_id).addClass('btn-sm btn-light');
                            if (getLanguage() == 'ma') {
                                $('.machineTestAddToCartButton').html('अधिक घ�?या');
                            } else {
                                $('.machineTestAddToCartButton').html('ADD MORE');
                            }
                            $('.machineTestAddToCartButton').css('display', 'block');
                        } else {
                            $('.machineTestAddedInCart').css('display', 'none');
                            $('.machineTestAddToCart').css('display', 'block');
                            $('.' + event_id).css('display', 'block');
                            $('.machineTestAddToDefault').css('display', 'block');
                        }
                        $('#secondPriceDiv').css('display', 'none');
                        $('.durationDiv').css('display', 'none');
                        $('.ageGroupDiv').css('display', 'block');
                        $('.upcommingCoursesFor').css('display', 'none');
                        $('.StudyCourcesInCartDiv').css('display', 'none');
                        $('.allScheduleDiv').css('display', 'none');
                        $('.machineTestStep3Div').css('display', 'block');
                        $('.machineCartEvent').css('display', 'block');
                        $('.machineTestAddToCartDiv').css('display', 'block');
                        $('.machineTestAddToCart').css('display', 'inline');

                        if (answer_array['2'] == 83) {
                            $('.machineTestStep3Div').css('display', 'none');
                            $('.machineTestStep3DivPsyco').css('display', 'block');
                            $('.appointmentDivPsyco').css('display', 'block');

                        }

                    } else {
                        $('.durationDiv').css('display', 'block');
                        $('.upcommingCoursesFor').css('display', 'block');
                        $('.ageGroupDiv').css('display', 'none');
                        $('.machineTestStep3Div').css('display', 'none');
                        $('.machineTestStep3DivPsyco').css('display', 'none');
                        $('.StudyCourcesInCartDiv').css('display', 'block');
                        $('.allScheduleDiv').css('display', 'block');
                        $('.machineCartEvent').css('display', 'none');
                        $('#machineTestAddToCartDiv').css('display', 'none');
                        $('.machineTestAddToCart').css('display', 'none');
                    }
                    var desc = (this.state.guestinfo.description);
                    var descripotion_length = desc.length;
                    if (descripotion_length < 450 || descripotion_length == undefined) {
                        $('#readMoreButton').css('display', 'none');
                    }
                    var length = this.state.guestinfo.images_array.length;

                    if (length > 1) {
                        $('.imageSliderIcon').css('display', 'inline');

                    } else {
                        $('.imageSliderIcon').css('display', 'none');

                    }
                    this.state.guestinfo.images_array.map((info, ii) => {
                        if (ii == 0) {
                            this.setState({ share_current_image_url: window.location.href });
                            this.setState({ share_image_url: info.image_name });
                        }
                    })
                })
                .then(response => {
                    this.setState({
                        editorState: EditorState.createWithContent(
                            convertFromRaw(JSON.parse(this.state.guestinfo.description))
                        )
                    })
                })
                .catch(err => console.log(err))


            fetch(myConstClass.BASE_URL + 'event/getEventTopicsFrontEnd/' + answer_array['2'] + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventtopicmain: response })
                    // var total_topic = this.state.eventtopic.length;

                })
            // START = get data detail
            var event_id = answer_array['2'];
            fetch(myConstClass.BASE_URL + 'event/getAllScheduleEventFrontEnd' + '/' + getLanguage() + '/' + event_id + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ scheduleEvent: response })
                    const numRows = this.state.scheduleEvent.length;
                    if (numRows < 1) {
                        $('.noScheduleAvailable').css('display', 'block');
                    } else {
                        this.state.guestinfo.schedule_event_name = this.state.scheduleEvent[0].title;
                        $('.noScheduleAvailable').css('display', 'none');

                    }
                })
                .catch(err => console.log(err))
            // end = get data detail
            fetch(myConstClass.BASE_URL + 'event/getEventSubTopicsFrontEnd/' + answer_array['2'] + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventtopic: response })
                    var total_topic = this.state.eventtopic.length;
                    if (total_topic > 0) {
                        var first_topic_id = this.state.eventtopic[0].topic_id;
                        localStorage.removeItem('topic_id_duplicate');
                        localStorage.setItem('topic_id_duplicate', first_topic_id);
                    }
                })

            fetch(myConstClass.BASE_URL + 'product/getRelatedProductsForEvent/' + answer_array['2'] + '/' + getLanguage() + '/' + user_id)
                .then(response => response.json())
                .then(response => this.setState({ posts: response }))
                //   .then(response =>{this.setState({editorState: EditorState.createWithContent(
                //     convertFromRaw(JSON.parse(this.state.guestinfo.description))
                //   )})
                // })
                .catch(err => console.log(err))

        }

        if (answer_array['2'] && getLanguage() == 'en' && prevProps.history.location.pathname !== prevProps.location.pathname) {
            this.setState({ flag: 0 })
            fetch(myConstClass.BASE_URL + 'event/getEventInfoFrontEnd/' + answer_array['2'] + '/' + getLanguage() + '/0/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ guestinfo: response })
                    var current_event_type_id = this.state.guestinfo.event_type_id;
                    if (current_event_type_id == 1) {
                        $('.for_res_event').css('display', 'contents');
                        $('.for_other_event').css('display', 'none');
                    } else {
                        $('.for_res_event').css('display', 'none');
                        $('.for_other_event').css('display', 'contents');
                    }
                    if ((current_event_type_id == 4 || current_event_type_id == 2) && getLanguage() == 'ma') {
                        $('.bookNowInCartMrPoojs').css('display', 'inline');
                        $('.bookNowInCartMr').css('display', 'none');
                        $('.bookNowInCartEn').css('display', 'none');
                    } else if ((current_event_type_id == 1 || current_event_type_id == 5) && getLanguage() == 'ma') {
                        //alert('#bookNowInCartMrPoojs');
                        $('.bookNowInCartMrPoojs').css('display', 'none');
                        $('.bookNowInCartMr').css('display', 'inline');
                        $('.bookNowInCartEn').css('display', 'none');
                    } else {
                        $('.bookNowInCartMrPoojs').css('display', 'none');
                        $('.bookNowInCartMr').css('display', 'none');
                        $('.bookNowInCartEn').css('display', 'inline');
                    }
                    this.state.guestinfo.current_event_type_id = this.state.guestinfo.event_type_id;
                    //alert(current_event_type_id);
                    if (current_event_type_id == 3) {
                        $('.appointmentDiv').css('display', 'block');
                        $('#eventLink').css('display', 'none');
                        $('#machineTestLink').css('display', 'contents');
                        $('.machineTestAddToCartDiv').css('display', 'block');
                        $('.scheduleButton').css('display', 'none');
                        if (answer_array['2'] == 83) {
                            $('.appointmentDivPsyco').css('display', 'block');
                            $('.appointmentDiv1').css('display', 'block');
                        } else {
                            $('.appointmentDivPsyco').css('display', 'none');
                        }
                    } else {
                        $('.appointmentDivPsyco').css('display', 'none');
                        $('.appointmentDiv').css('display', 'none');
                        $('#eventLink').css('display', 'contents');
                        $('#machineTestLink').css('display', 'none');
                        $('.scheduleButton').css('display', 'block');
                        $('.machineTestAddToCartDiv').css('display', 'none');
                    }
                    var event_id = this.state.guestinfo.event_id;
                    if (current_event_type_id == 3) {
                        var is_added_in_cart = this.state.guestinfo.is_added_in_cart;
                        //alert(is_added_in_cart);
                        if (is_added_in_cart == 1) {
                            // $('.machineTestAddedInCart').css('display','block');
                            // $('.'+event_id).css('display','none');
                            // $('.machineTestAddToDefault').css('display','none');
                            // $('.machineTestAddToDefault').removeClass('btn-outline-brown');
                            // $('.machineTestAddToDefault').addClass('btn-sm btn-light');

                            // if (getLanguage() == 'ma') {
                            //     $('.machineTestAddToCartButton').html( 'अधिक घ�?या');
                            // } else {
                            //     $('.machineTestAddToCartButton').html('ADDED TO CART');
                            // }
                            $('.' + event_id).removeClass('btn-outline-brown');
                            $('.' + event_id).addClass('btn-sm btn-light');
                            if (getLanguage() == 'ma') {
                                $('.machineTestAddToCartButton').html('अधिक घ�?या');
                            } else {
                                $('.machineTestAddToCartButton').html('ADD MORE');
                            }
                            $('.machineTestAddToCartButton').css('display', 'block');
                        } else {
                            $('.machineTestAddedInCart').css('display', 'none');
                            $('.machineTestAddToCart').css('display', 'block');
                            $('.' + event_id).css('display', 'block');
                            $('.machineTestAddToDefault').css('display', 'block');
                        }
                        $('#secondPriceDiv').css('display', 'none');
                        $('.durationDiv').css('display', 'none');
                        $('.ageGroupDiv').css('display', 'block');
                        $('.upcommingCoursesFor').css('display', 'none');
                        $('.StudyCourcesInCartDiv').css('display', 'none');
                        $('.allScheduleDiv').css('display', 'none');
                        $('.machineTestStep3Div').css('display', 'block');
                        $('.machineCartEvent').css('display', 'block');
                        $('.machineTestAddToCartDiv').css('display', 'block');
                        $('.machineTestAddToCart').css('display', 'inline');

                        if (answer_array['2'] == 83) {
                            $('.machineTestStep3Div').css('display', 'none');
                            $('.machineTestStep3DivPsyco').css('display', 'block');
                            $('.appointmentDivPsyco').css('display', 'block');

                        }

                    } else {
                        $('.durationDiv').css('display', 'block');
                        $('.upcommingCoursesFor').css('display', 'block');
                        $('.ageGroupDiv').css('display', 'none');
                        $('.machineTestStep3Div').css('display', 'none');
                        $('.machineTestStep3DivPsyco').css('display', 'none');
                        $('.StudyCourcesInCartDiv').css('display', 'block');
                        $('.allScheduleDiv').css('display', 'block');
                        $('.machineCartEvent').css('display', 'none');
                        $('#machineTestAddToCartDiv').css('display', 'none');
                        $('.machineTestAddToCart').css('display', 'none');
                    }
                    var desc = (this.state.guestinfo.description);

                    var descripotion_length = desc.length;
                    if (descripotion_length < 450 || descripotion_length == undefined) {
                        $('#readMoreButton').css('display', 'none');
                    }
                    var length = this.state.guestinfo.images_array.length;

                    if (length > 1) {
                        $('.imageSliderIcon').css('display', 'inline');

                    } else {
                        $('.imageSliderIcon').css('display', 'none');

                    }
                    this.state.guestinfo.images_array.map((info, ii) => {
                        if (ii == 0) {
                            this.setState({ share_current_image_url: window.location.href });
                            this.setState({ share_image_url: info.image_name });
                        }
                    })
                })
                .then(response => {
                    this.setState({
                        editorState: EditorState.createWithContent(
                            convertFromRaw(JSON.parse(this.state.guestinfo.description))
                        )
                    })
                })
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'product/getRelatedProductsForEvent/' + answer_array['2'] + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ posts: response }))
                //   .then(response =>{this.setState({editorState: EditorState.createWithContent(
                //     convertFromRaw(JSON.parse(this.state.guestinfo.description))
                //   )})
                // })
                .catch(err => console.log(err))

            fetch(myConstClass.BASE_URL + 'event/getEventTopicsFrontEnd/' + answer_array['2'] + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventtopicmain: response })
                    // var total_topic = this.state.eventtopic.length;

                })

            ///START = //////////////////
            var event_id = answer_array['2'];
            fetch(myConstClass.BASE_URL + 'event/getAllScheduleEventFrontEnd' + '/' + getLanguage() + '/' + event_id + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ scheduleEvent: response })
                    const numRows = this.state.scheduleEvent.length;
                    if (numRows < 1) {
                        $('.noScheduleAvailable').css('display', 'block');
                    } else {
                        this.state.guestinfo.schedule_event_name = this.state.scheduleEvent[0].title;
                        $('.noScheduleAvailable').css('display', 'none');

                    }
                })
                .catch(err => console.log(err))
            ///END = ////////////////////

            fetch(myConstClass.BASE_URL + 'event/getEventSubTopicsFrontEnd/' + answer_array['2'] + '/' + getLanguage())
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventtopic: response })
                    var total_topic = this.state.eventtopic.length;
                    if (total_topic > 0) {
                        var first_topic_id = this.state.eventtopic[0].topic_id;
                        localStorage.removeItem('topic_id_duplicate');
                        localStorage.setItem('topic_id_duplicate', first_topic_id);
                    }
                })

        }





    }

    showSchedule() {
        //alert('hi');
        // let id = document.getElementById('showScheduleBtn')

        // if (id) {
        //     if (id.innerHTML === 'SHOW SCHEDULE') {
        //         id.innerHTML = 'HIDE SCHEDULE';
        //         document.getElementById('showScheduleEvent').style = 'display:block'
        //     } else {
        //         document.getElementById('showScheduleEvent').style = 'display:none'
        //         id.innerHTML = 'SHOW SCHEDULE';
        //     }
        // }
        document.getElementById('showScheduleEvent').style = 'display:block';

    }

    showScheduleMachine(e) {
        var event_schedule_id = e.target.id;
        //alert(event_schedule_id);
        $('#MachineTestModalObject').val(event_schedule_id);
        $('#MachineTestModalObjectm').val(event_schedule_id);
        var arr_event_schedule = event_schedule_id.split('_');
        var event_id = arr_event_schedule[0];

        var previous_event_id = localStorage.getItem('current_schedule_id');
        localStorage.removeItem('current_schedule_id');
        localStorage.setItem('current_schedule_id', event_id);
        $('#MachineTestModal').modal('show');
    }

    goToCart() {
        var current_event_type_id = this.state.guestinfo.event_type_id;

        var user_id = localStorage.getItem('user_id');
        var message = "";
        if (user_id > 0 && user_id != undefined) {
            if (parseInt(localStorage.getItem("cartCount")) > 0 || current_event_type_id == 3) {
                this.props.history.push('/mycart');
            } else {
                if (this.state.scheduleEvent.length) {
                    if (getLanguage() == 'ma') {
                        var message = "कृपया कार�?टवर जोडा वर क�?लिक करा.";
                    } else {
                        var message = "Please click on add to cart.";
                    }
                } else {
                    if (getLanguage() == 'ma') {
                        message = "कार�?यक�?रमाचे वेळापत�?रक उपलब�?ध नाही.";
                    } else {
                        message = "Event schedule not available.";
                    }
                }

                toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
            }

        } else {
            var message = "Please login to our website.";
            localStorage.removeItem('error');
            localStorage.setItem('error', message);
            this.props.history.push('/signin')
            //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 
        }
    }

    render() {
        const { notifyinfo } = this.state
        const { photoIndex, isOpen, lightboxOpen, cartEvent, machineCartEvent, arrVideo, feedback, review, scheduleEvent, posts, guestinfo, eventtopic, eventtopicmain } = this.state
        const { t } = this.props;
        return (

            <div className="App">
                <i style={{ cursor: 'pointer', display: 'none' }} onClick={this.closeGallery} className="fa fa-times" aria-hidden="true" id="videoCloseIcon2"></i>
                <div>
                    {/* <button onClick={this.notify}>Notify !</button> */}
                    <ToastContainer />
                </div>
                <Header />
                <div className="container-fluid">

                    <div className="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                        <div className="col-lg-9">
                            <div className="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb bg-white mb-0 ml-n2">
                                        <li className="breadcrumb-item">
                                            <Link to="/">{t('home.home')}</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0)">{t('home.events')}
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link id="eventLink" to={'/events/' + guestinfo.event_type + '/' + guestinfo.event_type_id}>{guestinfo.event_type}</Link>
                                            <Link style={{ display: 'none' }} id="machineTestLink" to={'/machine-test/' + guestinfo.event_type + '/' + guestinfo.event_type_id}>{guestinfo.event_type}</Link>

                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {guestinfo.title}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <Row>
                                <Col xl="12" lg="12" md="12">
                                    <p className="small mb-1">{guestinfo.event_type}</p>
                                    <h4>{guestinfo.title}</h4>
                                    <p className="small mb-1">

                                        {eventtopicmain.map((info, ii) => {
                                            if (ii == 0) {
                                                return (
                                                    <Link to={'/topics/' + info.name + '/' + info.topic_id}>
                                                        <Label className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                                            {info.name}
                                                        </Label>
                                                    </Link>
                                                )
                                            } else {
                                                return (
                                                    <Link to={'/topics/' + info.name + '/' + info.topic_id} className="mx-2">
                                                        <Label className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                                            {info.name}
                                                        </Label>
                                                    </Link>

                                                )
                                            }
                                        }
                                        )}
                                    </p>
                                </Col>
                                <Col xl="4" lg="4" md="4" className="d-xl-flex d-lg-flex d-md-flex d-none pl-3">
                                    <span className="mr-2">
                                        <i className="far fa-comment-dots orangeColor"></i> <span className="small">{guestinfo.total_review}</span>
                                    </span>
                                    <span>
                                        <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">{guestinfo.total_like}</span>
                                    </span>
                                    <span className="pr-2 text-center" onClick={this.submitLike} style={{ cursor: 'pointer' }}>
                                        <i className="fas fa-thumbs-up color-brown"></i> <br /> <span className="small d-block mt-n1" >{t('home.LIKE THIS')}</span>
                                    </span>
                                </Col>
                            </Row>
                            <div className="row"  >
                                <div className="col-9 appointmentDiv" style={{ display: 'none' }}>
                                    <h6 className="color-red my-2">
                                        {t('home.Step 1')}: &nbsp;
                                        {t('home.Please_call_and_take_and_appointment_before_booking_the_Test.')}
                                    </h6>
                                    <p>
                                        {t('home.Contact on Machine')} : {t('home.Monday_to_Friday')}
                                        <br /> {t('home.Phone')}: {t('home.Phone_all')}
                                    </p>
                                </div>

                                <div className="col-9 appointmentDiv1" style={{ display: 'none' }}>
                                    <h6 className="color-red my-2">
                                        {t('home.Step 1')}: &nbsp;
                                        {t('home.Please_call_and_take_and_appointment_before_booking_the_Test_psyco')}
                                    </h6>
                                    <p>
                                        {t('home.Contact on Machine')} : {t('home.Monday_to_Friday_psyco')}
                                        <br /> {t('home.Phone')}: {t('home.Phone_all_psyco')}
                                    </p>
                                </div>

                            </div>
                        </div>

                        <div className="col-lg-3">
                            <div className="row eventappointmentDiv">&nbsp;</div>

                            <div className="row">
                                <div className="col-12 appointmentDiv" >
                                    <br />
                                    <h6 className="color-red my-2">
                                        {t('home.Step 2')}: &nbsp;
                                        {t('home.Kindly choose the quantity and Add to Cart')}


                                    </h6>
                                </div>

                                <div className="col-12 appointmentDivPsyco" >
                                    <br />
                                    <h6 className="color-red my-2">
                                        {t('home.Step 2')}: &nbsp;
                                        {t('home.Kindly choose the quantity and Add to Cart')}


                                    </h6>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-9 mt-2">
                            <div className="col py-2 border border-warning bg-white box-shadow" style={{ minHeight: '80px' }}>
                                <DesktopDesign
                                    guestInfo={guestinfo}
                                    eventTopic={eventtopic}
                                    t={(name) => t(name)}
                                />
                                <MobileDesign
                                    guestInfo={guestinfo}
                                    eventTopic={eventtopic}
                                    t={(name) => t(name)}
                                    showSchedule={this.showSchedule}
                                    showScheduleMachine={this.showScheduleMachine}
                                />
                            </div>
                            <Col className="mt-2" xs="12" sm="12" id="showScheduleEvent" style={{ display: 'none' }}>
                                <ListGroup>
                                    <ListGroupItem className="upcommingCoursesFor" id="upcommingCoursesFor">
                                        <span className="font-weight-bolder">
                                            {t('home.Upcoming Courses for')}&nbsp;&nbsp;{guestinfo.title}&nbsp; <span class="for_res_event" style={{ display: 'none' }}>{t('home.Upcoming Courses for marathi')}</span> <span class="for_other_event" style={{ display: 'none' }}>{t('home.Upcoming Courses for marathi other')}</span>
                                        </span>
                                    </ListGroupItem>
                                    <ListGroupItem class="machineTestStep3Div" id="machineTestStep3Div" style={{ display: 'none' }}>
                                        <h6 className="color-red my-2">
                                            {t('home.Step 3')}: &nbsp;
                                            <label class="bookNowInCartMrPoojs">{t('home.in Cart poojs')}&nbsp; </label>
                                            <label class="bookNowInCartMr">{t('home.in Cart')}&nbsp; </label>
                                            {guestinfo.event_type}&nbsp;

                                            <label class="bookNowInCartEn">  {t('home.in Cart')} &nbsp; </label>
                                        </h6>
                                        <p style={{ color: 'red' }}>
                                            {t('home.NOTE')}:&nbsp;{guestinfo.event_type}{t('home.To change quantity remove from cart and add again')}
                                        </p>
                                    </ListGroupItem>
                                    <ListGroupItem class="machineTestStep3Div" id="machineTestStep3Div" style={{ display: 'none' }}>
                                    </ListGroupItem>

                                    <ListGroupItem class="machineTestStep3DivPsyco" id="machineTestStep3DivPsyco" style={{ display: 'none' }}>
                                        <h6 className="color-red my-2">
                                            {t('home.Step 3')}: &nbsp;
                                            {t('home.Study Cources in Cart details psyco')}
                                        </h6>
                                        <p style={{ color: 'red' }}>
                                            {t('home.NOTE')}:&nbsp;{t('home.Psycho-Feedback Therapy change quantity')}
                                        </p>
                                    </ListGroupItem>
                                    <ListGroupItem class="machineTestStep3DivPsyco" id="machineTestStep3DivPsyco" style={{ display: 'none' }}>
                                    </ListGroupItem>

                                </ListGroup>



                                <ListGroup className="mb-3 px-2 machineCartEvent" id="machineCartEvent">
                                    <Col className="py-2 border border-dark bg-white">
                                        {machineCartEvent.map((info, i) => {
                                            return (
                                                <ListGroupItem>
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-6 mt-2">
                                                            <p>{i + 1}. {info.title}
                                                            </p>
                                                        </div>
                                                        <div className="col-3 pl-0 align-content-center">
                                                            <p className="text-right">
                                                                {t('home.Qty')}:{info.parent}
                                                            </p>
                                                        </div>
                                                        <div className="col-3 pl-0 align-content-center">
                                                            <p className="text-right">
                                                                <span className="mr-3">
                                                                    <i style={{ cursor: 'pointer' }} className="fas fa-trash-alt" name={'delete_cart_' + info.event_id} id={'delete_' + info.event_id} onClick={this.deleteFromCartMachineTest}></i>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>

                                                </ListGroupItem>
                                            )
                                        })
                                        }

                                        <ListGroupItem style={{ marginBottom: '10px', display: 'none' }} name="noItemInCart" id="noItemInCart" className="text-muted noItemInCart">
                                            {t('home.No items in the cart')}
                                        </ListGroupItem>

                                        <ListGroupItem>
                                            <Link id="" className="btn btn-brown btn-sm mb-2 goToCart btn-block" onClick={() => this.goToCart()}>{t('home.GO TO CART')}</Link>
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <Link onClick={() => this.goToCart()} style={{ display: 'none' }} className="goToLogin">
                                                <button className="btn btn-sm btn-brown px-4 btn-block">{t('home.GO TO CART')}</button>
                                            </Link>
                                        </ListGroupItem>



                                    </Col>
                                </ListGroup>


                                <ListGroup className="px-2 pb-2 allScheduleDiv 123" id="allScheduleDiv" >

                                    {sortDates(scheduleEvent, 1).map((info, i) => {
                                        if (i == 0) {
                                            return (

                                                <>
                                                    <ListGroupItem>
                                                        {splitByFirstMatch(info?.date)[1]}
                                                        {/* <span className="orangeColor mb-0">{(info.date.substring(2, 1) == "t" || info.date.substring(2, 1) == "n" || info.date.substring(2, 1) == "s") ? info.date.substring(4) : info.date.substring(5)}</span> */}
                                                    </ListGroupItem>

                                                    {sortDates(info.arr_schedule, 2).map((cell, ii) => {
                                                        // console.log("cell", cell)
                                                        if (cell.is_full == 1) {
                                                            return (
                                                                <ListGroupItem>

                                                                    <Row>
                                                                        <Col sm="6" xs="6">
                                                                            <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }}>
                                                                                    {cell.city_name}
                                                                                </a>
                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                    <p>{cell.start_date_format} - {cell.end_date_format}, {cell.venue_address}</p>
                                                                                    <p>{t('home.Contact No')}<br />
                                                                                        {cell.contact_details}</p>
                                                                                </ReactTooltip>
                                                                            </p>
                                                                        </Col>
                                                                        <Col className="text-right">
                                                                            <p className="text-right">
                                                                                <p style={{ marginRight: '10px' }}>
                                                                                    {t('home.FULL')}
                                                                                </p>
                                                                            </p>
                                                                        </Col>
                                                                    </Row>
                                                                </ListGroupItem>
                                                            )

                                                        } else if (cell.notify == 1) {
                                                            return (
                                                                <ListGroupItem>

                                                                    <Row>
                                                                        <Col sm="6" xs="66">
                                                                            <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }}>
                                                                                    {cell.city_name}
                                                                                </a>
                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                    <p>{cell.start_date_format} - {cell.end_date_format}, {cell.venue_address}</p>
                                                                                    <p>{t('home.Contact No')}<br />
                                                                                        {cell.contact_details}</p>
                                                                                </ReactTooltip>
                                                                            </p>
                                                                        </Col>
                                                                        <Col sm="6" xs="6" className="text-right">


                                                                            <button id={'notifyScheduleid_' + cell.schedule_id} className="btn btn-sm btn-outline-dark" style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                            <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + '-' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                            <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />


                                                                        </Col>
                                                                    </Row>
                                                                </ListGroupItem>
                                                            )

                                                        } else {
                                                            if (cell.available_in_cart == 1) {
                                                                return (
                                                                    <ListGroupItem>
                                                                        <Row>
                                                                            <Col sm="6" xs="6">
                                                                                <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }}>
                                                                                        {cell.city_name}
                                                                                    </a>
                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                        <p>{cell.start_date_format} - {cell.end_date_format}, {cell.venue_address}</p>
                                                                                        <p>{t('home.Contact No')}<br />
                                                                                            {cell.contact_details}</p>
                                                                                    </ReactTooltip>

                                                                                </p>
                                                                            </Col>
                                                                            <Col sm="6" xs="6" className="text-right">
                                                                                <p className="text-right">
                                                                                    <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                    <br />

                                                                                    <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id} >{t('home.Added in cart')}</span>
                                                                                </p>

                                                                            </Col>
                                                                        </Row>
                                                                    </ListGroupItem>
                                                                )
                                                            } else {
                                                                return (
                                                                    <ListGroupItem>

                                                                        <Row>
                                                                            <Col sm="6" xs="6">
                                                                                <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} id='global' target='_blank' href={cell.url} style={{ textDecoration: 'underline' }}>
                                                                                        {cell.city_name}
                                                                                    </a>

                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                        <p>{cell.start_date_format} - {cell.end_date_format}, {cell.venue_address}</p>
                                                                                        <p>{t('home.Contact No')}<br />
                                                                                            {cell.contact_details}</p>
                                                                                    </ReactTooltip>
                                                                                </p>
                                                                            </Col>
                                                                            <Col sm="6" xs="6" className="text-right">

                                                                                <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                <br />
                                                                                <button className={cell.event_id + '_' + cell.schedule_id + ' btn btn-sm btn-outline-brown color-red'} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addEventToCart}>{t('home.ADD TO CART')}</button>

                                                                                <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>

                                                                            </Col>
                                                                        </Row>
                                                                    </ListGroupItem>
                                                                )
                                                            }
                                                        }//Else complete


                                                    })
                                                    }
                                                </>
                                            )
                                        } else {
                                            return (

                                                <>
                                                    <ListGroupItem className="orangeColor">
                                                        {(info.date.substring(2, 1) == "t" || info.date.substring(2, 1) == "n" || info.date.substring(2, 1) == "s") ? info.date.substring(4) : info.date.substring(5)}
                                                    </ListGroupItem>
                                                    {sortDates(info.arr_schedule, 2).map((cell, ii) => {
                                                        if (cell.is_full == 1) {
                                                            return (
                                                                <ListGroupItem>

                                                                    <Row>
                                                                        <Col sm="6" xs="6">
                                                                            <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }}>
                                                                                    {cell.city_name}
                                                                                </a>
                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                    <p>{cell.start_date_format} - {cell.end_date_format}, {cell.venue_address}</p>
                                                                                    <p>{t('home.Contact No')}<br />
                                                                                        {cell.contact_details}</p>
                                                                                </ReactTooltip>
                                                                            </p>
                                                                        </Col>
                                                                        <Col sm="6" xs="6" className="text-right">

                                                                            {t('home.FULL')}

                                                                        </Col>
                                                                    </Row>
                                                                </ListGroupItem>
                                                            )

                                                        } else if (cell.notify == 1) {
                                                            return (
                                                                <ListGroupItem>
                                                                    <Row>
                                                                        <Col sm="6" xs="6">
                                                                            <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }}>
                                                                                    {cell.city_name}
                                                                                </a>
                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                    <p>{cell.start_date_format} - {cell.end_date_format}, {cell.venue_address}</p>
                                                                                    <p>{t('home.Contact No')}<br />
                                                                                        {cell.contact_details}</p>
                                                                                </ReactTooltip>

                                                                            </p>
                                                                        </Col>

                                                                        <Col className="text-right">
                                                                            <button id={'notifyScheduleid_' + cell.schedule_id} className="btn btn-sm btn-outline-dark" style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                            <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + '-' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                            <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                        </Col>
                                                                    </Row>
                                                                </ListGroupItem>
                                                            )

                                                        } else {
                                                            if (cell.available_in_cart == 1) {
                                                                return (
                                                                    <ListGroupItem>

                                                                        <Row>
                                                                            <Col sm="6" xs="6">
                                                                                <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }}>
                                                                                        {cell.city_name}
                                                                                    </a>
                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                        <p>{cell.start_date_format} - {cell.end_date_format}, {cell.venue_address}</p>
                                                                                        <p>{t('home.Contact No')}<br />
                                                                                            {cell.contact_details}</p>
                                                                                    </ReactTooltip>
                                                                                </p>
                                                                            </Col>
                                                                            <Col sm="6" xs="6" className="text-right">

                                                                                <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                <br />
                                                                                <button style={{ display: 'none' }} className={cell.event_id + '_' + cell.schedule_id + ' btn btn-sm btn-outline-brown'} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addEventToCart}>{t('home.ADD TO CART')}</button>
                                                                                <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id}>{t('home.Added in cart')}</span>

                                                                            </Col>
                                                                        </Row>
                                                                    </ListGroupItem>
                                                                )
                                                            } else {
                                                                return (
                                                                    <ListGroupItem>

                                                                        <Row>
                                                                            <Col sm="6" xs="6">
                                                                                <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }}>
                                                                                        {cell.city_name}
                                                                                    </a>
                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                        <p>{cell.start_date_format} - {cell.end_date_format}, {cell.venue_address}</p>
                                                                                        <p>{t('home.Contact No')}<br />
                                                                                            {cell.contact_details}</p>
                                                                                    </ReactTooltip>
                                                                                </p>
                                                                            </Col>
                                                                            <Col sm="6" xs="6" className="text-right">

                                                                                <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                <br />
                                                                                <button className={cell.event_id + '_' + cell.schedule_id + ' btn btn-sm btn-outline-brown color-red'} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addEventToCart}>{t('home.ADD TO CART')}</button>

                                                                                <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>

                                                                            </Col>
                                                                        </Row>
                                                                    </ListGroupItem>
                                                                )
                                                            }
                                                        }//Else complete


                                                    })
                                                    }
                                                </>
                                            )
                                        }



                                    })
                                    }








                                    <ListGroupItem style={{ marginBottom: '10px', display: 'none' }} name="noScheduleAvailable" id="noScheduleAvailable" className="text-muted noScheduleAvailable">
                                        {t('home.No schedule available')}
                                    </ListGroupItem>

                                    <ListGroupItem>
                                        <Link className="btn btn-brown btn-sm mb-2 goToCart btn-block" onClick={() => this.goToCart()}>{t('home.GO TO CART')}</Link>
                                    </ListGroupItem>
                                    <ListGroupItem style={{ display: 'none' }} className="goToLogin">
                                        <Link onClick={() => this.goToCart()}>
                                            <button className="btn btn-sm btn-brown px-4 btn-block">{t('home.GO TO CART')}</button>
                                        </Link>
                                    </ListGroupItem>

                                </ListGroup>

                                <ListGroup className="StudyCourcesInCartDiv" id="StudyCourcesInCartDiv">
                                    <ListGroupItem className="color-red">


                                        <h6 class="color-red">
                                            <label class="bookNowInCartMrPoojs">{t('home.in Cart poojs')}&nbsp; </label>
                                            <label class="bookNowInCartMr">{t('home.in Cart')}&nbsp; </label>
                                            {guestinfo.event_type}&nbsp;

                                            <label class="bookNowInCartEn">  {t('home.in Cart')} &nbsp; </label>
                                        </h6>
                                    </ListGroupItem>

                                    {cartEvent.map((info, i) => {
                                        return (
                                            <ListGroupItem>
                                                <div className="row d-flex">
                                                    <div className="col-6">
                                                        <p className="font-weight-bold">
                                                            {info.title}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 pl-0 text-right">
                                                        <div className="row">
                                                            <div className="text-muted text-left col-10">{info.date}<br /> {info.start_date_format} - {info.end_date_format}<br />

                                                                <a data-tip='' data-for={'test123' + info.schedule_id} target='_blank' href={info.url} style={{ textDecoration: 'underline' }}>
                                                                    {info.city_name}
                                                                </a>
                                                                <ReactTooltip id={'test123' + info.schedule_id} data-tip={info.venue_address} aria-haspopup='true' role='example'>
                                                                    <p>{info.date}, {info.venue_address}</p>
                                                                    <p>{t('home.Contact No')}<br />
                                                                        {info.contact_details}</p>
                                                                </ReactTooltip>
                                                            </div>
                                                            <div className="col-2">
                                                                {/* <i className="fas fa-trash-alt"></i> */}
                                                                <i style={{ cursor: 'pointer' }} className="fas fa-trash-alt" name={'delete_cart_' + info.event_id + '_' + info.schedule_id} id={'delete_' + info.event_id + '_' + info.schedule_id} onClick={this.deleteFromCart}></i>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </ListGroupItem>
                                        )
                                    })
                                    }
                                    <ListGroupItem style={{ display: 'none' }} name="noItemInCart" id="noItemInCart" className="text-muted noItemInCart">
                                        {t('home.No items in the cart')}
                                    </ListGroupItem>

                                    <ListGroupItem>
                                        <Link className="btn btn-brown btn-sm mb-2 goToCart btn-block" onClick={() => this.goToCart()}>{t('home.GO TO CART')}</Link>
                                    </ListGroupItem>

                                    <ListGroupItem style={{ display: 'none' }} className="goToLogin">
                                        <Link onClick={() => this.goToCart()}>
                                            <button className="btn btn-sm btn-brown px-4 btn-block">{t('home.GO TO CART')}</button>
                                        </Link>
                                    </ListGroupItem>

                                </ListGroup>
                            </Col>
                            <Row className="mt-3">
                                <Col xl="6" lg="6" md="6" sm="12" xs="12">
                                    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                                        <div className="carousel-inner">

                                            {guestinfo.images_array.map((info, ii) => {
                                                if (ii == 0) {

                                                    return (
                                                        <div className="carousel-item active">
                                                            <div className="event-image-container px-2 py-2">
                                                                <img style={{ cursor: 'pointer' }} id={ii} onClick={this.openGallery} className="d-block w-100  eventDetailsImageNew mb-2" src={info.image_name} />
                                                            </div>
                                                        </div>
                                                    )
                                                } else {

                                                    return (
                                                        <div className="carousel-item ">
                                                            <div className="event-image-container px-2 py-2">
                                                                <img style={{ cursor: 'pointer' }} id={ii} onClick={this.openGallery} className="d-block w-100 eventDetailsImageNew mb-2" src={info.image_name} />
                                                            </div>
                                                        </div>



                                                    )
                                                }
                                            }

                                            )}

                                        </div>
                                        <a className="carousel-control-prev " style={{ opacity: 1 }} href="#carouselExampleControls" role="button" data-slide="prev">
                                            <i className="fas fa-chevron-left slide-ico imageSliderIcon"></i>
                                            <span className="sr-only">{t('home.Previous')}</span>
                                        </a>
                                        <a className="carousel-control-next " style={{ opacity: 1 }} href="#carouselExampleControls" role="button" data-slide="next">
                                            <i className="fas fa-chevron-right slide-ico imageSliderIcon"></i>
                                            <span className="sr-only">{t('home.Next')}</span>
                                        </a>
                                    </div>

                                    <div id="carouselExampleControls1" className="carousel slide" data-ride="carousel">
                                        <div className="carousel-inner">

                                            {arrVideo.map((info, ii) => {
                                                if (ii == 0) {
                                                    // return( <div className="carousel-item active"> <img className="d-block w-100 img-fluid" src={myConstClass.IMAGE_URL+'event/'+info.image_name}  /></div>  
                                                    return (
                                                        //   <div className="carousel-item active wrapper">
                                                        //   <ReactPlayer
                                                        //   className='d-block w-100 img-fluid cmsVideoNew'
                                                        //   width='100%'
                                                        //   height='100%'
                                                        //   type="video"  url={info.src}  /> 
                                                        //   </div>
                                                        <div className="carousel-item active wrapper">
                                                            {/* <ReactPlayer
                                        className='d-block w-100 img-fluid '
                                        width='100%'
                                        height='100%'
                                        type="video" id={ii}   src={info.you_tube_url} />  */}
                                                            <div className="col-lg-12 col-md-12 col-sm-12 mb-3 columnPaddingZero">
                                                                <div className="event-image-container px-2 py-2">
                                                                    <img style={{ cursor: 'pointer' }} id={ii} onClick={this.openGalleryVideo} src={info.you_tube_url} className="d-block w-100  eventDetailsImageNew mb-2" alt="..." />
                                                                    <div className="overlay">
                                                                        <a href="#" className="icon" title="User Profile">
                                                                            <i className="fab fa-youtube color-red" onClick={this.openGalleryVideo}></i>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )
                                                } else {
                                                    // return( <div className="carousel-item "> <img className="d-block w-100 img-fluid" src={myConstClass.IMAGE_URL+'event/'+info.image_name}  /></div>  
                                                    return (
                                                        //  <div className="carousel-item wrapper"> 
                                                        // <ReactPlayer
                                                        // className='d-block w-100 img-fluid cmsVideoNew'
                                                        // width='100%'
                                                        // height='100%'
                                                        // type="video"  url={info.src}  /> 
                                                        //  </div>  
                                                        <div className="carousel-item wrapper">
                                                            {/* <ReactPlayer
                                        className='d-block  w-100  img-fluid '
                                        width='100%'
                                        height='100%'
                                        type="video"  id={ii}   src={info.you_tube_url}  />  */}
                                                            <div className="col-lg-12 col-md-12 col-sm-12 mb-3 columnPaddingZero">
                                                                <div className="div1">
                                                                    <div className="event-image-container px-2 py-2">
                                                                        <img style={{ cursor: 'pointer' }} onClick={this.openGalleryVideo} src={info.you_tube_url} className="d-block w-100  eventDetailsImageNew mb-2" alt="..." />
                                                                        <div className="overlay">
                                                                            <a href="#" className="icon" title="User Profile">
                                                                                <i className="fab fa-youtube color-red" onClick={this.openGalleryVideo}></i>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }

                                            )}
                                            <a className="carousel-control-prev videoSliderIcon" style={{ opacity: 1 }} href="#carouselExampleControls1" role="button" data-slide="prev">
                                                <i className="fas fa-chevron-left slide-ico"></i>
                                                <span className="sr-only">{t('home.Previous')}</span>
                                            </a>
                                            <a className="carousel-control-next videoSliderIcon" style={{ opacity: 1 }} href="#carouselExampleControls1" role="button" data-slide="next">
                                                <i className="fas fa-chevron-right slide-ico"></i>
                                                <span className="sr-only">{t('home.Next')}</span>
                                            </a>
                                        </div>

                                    </div>

                                </Col>

                                <div className="col col-md-6 eventDescriptionDiv">
                                    <p>
                                        {/* {guestinfo.description} */}
                                        <span id="ReadMoreDiv" class="ReadMoreDivEvent">
                                            {/* {guestinfo.short_description} */}
                                            {ReactHtmlParser(guestinfo.short_description)}

                                            <button style={{ float: 'right' }} id="readMoreButton" onClick={this.readMore} className="btn orangeColor btn-link">{t('home.Read more')}</button>
                                        </span>

                                        <span id="ReadLessDiv" style={{ display: 'none' }}>
                                            {/* {guestinfo.description} */}
                                            {ReactHtmlParser(guestinfo.description)}
                                            <button style={{ float: 'right' }} onClick={this.readLess} className="btn orangeColor btn-link">{t('home.Read less')}</button>
                                        </span>

                                    </p>

                                    <div className="pt-3">
                                        <hr />
                                        <h5>{t('home.TESTIMONIALS')}</h5>
                                        {feedback.map((info, ii) => {
                                            if (info.description.length < 210) {
                                                return (
                                                    <div>
                                                        <p>{info.description}
                                                        </p>
                                                        {/* <p><a className="orangeColor text-right">
                            {t('home.Show more')}...
                                        </a></p> */}
                                                        <p className="font-weight-bolder">
                                                            {info.name} , {info.address}
                                                        </p>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div>
                                                        <p id={'showMoreFeedbackDiv_' + info.review_id} style={{ minHeight: '93px' }}>{info.short_description}
                                                            <span className="orangeColor" id={'showMoreFeedbackSpan_' + info.review_id} onClick={this.showMoreFeedback} style={{ cursor: 'pointer', float: 'right' }}><br />{t('home.Show more')}</span>
                                                        </p>

                                                        <p id={'showLessFeedbackDiv_' + info.review_id} style={{ minHeight: '93px', display: 'none' }}>{info.description}
                                                            <span className="orangeColor" id={'showLesFeedbackSpan_' + info.review_id} onClick={this.showLessFeedback} style={{ cursor: 'pointer', float: 'right' }}><br />{t('home.Show less')}</span>
                                                        </p>

                                                        <p className="font-weight-bolder">{info.name}, {info.address} </p>

                                                    </div>
                                                )
                                            }
                                        }

                                        )}

                                        <hr />
                                        <h5>{t('home.REVIEWS')}</h5>
                                        {review.map((info, ii) => {
                                            if (info.description.length < 210) {
                                                return (
                                                    <div>
                                                        <p>{info.description}
                                                        </p>
                                                        {/* <p><a className="orangeColor text-right">
                            {t('home.Show more')}...
                                        </a></p> */}
                                                        <p className="font-weight-bolder">
                                                            {/* {info.first_name}   {info.last_name} */}
                                                            {info.name}
                                                            , {info.address} </p>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div>
                                                        <p id={'showMoreDiv_' + info.review_id} style={{ minHeight: '93px' }}>{info.short_description}
                                                            <span className="orangeColor" id={'showMoreSpan_' + info.review_id} onClick={this.showMore} style={{ cursor: 'pointer', float: 'right' }}><br />{t('home.Show more')}</span>
                                                        </p>

                                                        <p id={'showLessDiv_' + info.review_id} style={{ minHeight: '93px', display: 'none' }}>{info.description}
                                                            <span className="orangeColor" id={'showLesSpan_' + info.review_id} onClick={this.showLess} style={{ cursor: 'pointer', float: 'right' }}><br />{t('home.Show less')}</span>
                                                        </p>

                                                        <p className="font-weight-bolder">{info.name}, {info.address} </p>

                                                    </div>

                                                )
                                            }
                                        }

                                        )}
                                        <div className="row">
                                            <div className="col-md-5 col-12 mb-2">
                                                <p id="ShowAllReviewDiv"><button className="btn btn-outline-brown btn-sm btn-block" onClick={this.showallReview}>

                                                    {t('home.SHOW ALL')}
                                                </button>
                                                </p>
                                            </div>
                                        </div>
                                        <h5 className="">{t('home.Please add your review')}</h5>
                                        <Form action="" id="pform" onSubmit={this.submitReview} method="post" encType="multipart/form-data" className="form-horizontal">
                                            <textarea id="user_review" name="user_review" className="form-control" placeholder={t('home.Type here')} rows="4"></textarea>
                                            {/* <button className="btn btn-outline-brown mt-3">SUBMIT</button> */}
                                            <div className="row">
                                                <div className="col-md-4 col-12">
                                                    <button className="btn btn-outline-brown mt-3 btn-block" >{t('home.SUBMIT')}</button>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </Row>
                        </div>
                        <Col className="mt-2 d-xl-block d-lg-block d-none" xl="3" lg="3">
                            <Row className="mb-3 px-2">
                                <Col className="border border-warning bg-white box-shadow py-2">
                                    <Row className="upcommingCoursesFor" id="upcommingCoursesFor">
                                        <Col className="font-weight-bolder">
                                            {t('home.Upcoming Courses for')}&nbsp;&nbsp;{guestinfo.title}&nbsp;<span class="for_res_event" style={{ display: 'none' }}>{t('home.Upcoming Courses for marathi')}</span> <span class="for_other_event" style={{ display: 'none' }}>{t('home.Upcoming Courses for marathi other')}</span>
                                            <hr className="border-dark mx-n2" />
                                        </Col>
                                    </Row>

                                    <Row className="d-xl-flex d-lg-flex d-md-flex d-none">
                                        <Col xl="3" lg="3" md="3" className="align-self-end">
                                            <Label className="qty_lbl">
                                                {t('home.Quantity')}:
                                            </Label>
                                        </Col>
                                        <Col xl="9">
                                            <form>
                                                <div className="row">
                                                    <div className="col">
                                                        <Label>{guestinfo.first_price_for}</Label>
                                                        <Input type="select" className="firstPriceDropdownDiv" name={"parent" + guestinfo.event_id} id={"parent" + guestinfo.event_id} bsSize="sm">
                                                            <option value="0" selected="selected">00</option>
                                                            <option value="1" >01</option>
                                                            <option value="2">02</option>
                                                            <option value="3">03</option>
                                                            <option value="4">04</option>
                                                            <option value="5">05</option>
                                                            <option value="6">06</option>
                                                            <option value="7">07</option>
                                                            <option value="8">08</option>
                                                            <option value="9">09</option>
                                                            <option value="10">10</option>
                                                        </Input>
                                                    </div>
                                                    {guestinfo.second_price_for &&
                                                        <div className="col" id="secondPriceDropdownDiv">
                                                            <Label>{guestinfo.second_price_for}</Label>
                                                            <Input type="select" name={"child" + guestinfo.event_id} id={"child" + guestinfo.event_id} bsSize="sm">
                                                                <option value="0" selected="selected">00</option>
                                                                <option value="1">01</option>
                                                                <option value="2">02</option>
                                                                <option value="3">03</option>
                                                                <option value="4">04</option>
                                                                <option value="5">05</option>
                                                                <option value="6">06</option>
                                                                <option value="7">07</option>
                                                                <option value="8">08</option>
                                                                <option value="9">09</option>
                                                                <option value="10">10</option>
                                                            </Input>
                                                        </div>}


                                                    <div className="col machineTestAddToCartDiv" id="machineTestAddToCartDiv">
                                                        {/* <span style={{ marginLeft: '-24px' }} id="machineTestAddedInCart" className="btn btn-sm btn-outline-brown machineTestAddToDefault" onClick={this.addMachineTestEventToCartPopUp}>{t('home.ADDED TO CART')}</span> */}
                                                        {/* <button type="button" className="btn btn-sm btn-outline-brown color-brown machineTestAddToDefault" onClick={this.addMachineTestEventToCartPopUp}>{t('home.ADD TO CART')}</button> */}
                                                        <span style={{ marginLeft: '-24px', backgroundColor: 'transparent', border: 'none !important' }} className={guestinfo.event_id + ' machineTestAddToCart    '}  >
                                                            <button type="button" className={guestinfo.event_id + ' btn btn-sm btn-outline-brown color-brown machineTestAddToCartButton'} name={guestinfo.event_id} data-btn="addcart" id={guestinfo.event_id} onClick={this.addMachineTestEventToCartPopUp} >{t('home.ADD TO CART')}</button>

                                                        </span>
                                                    </div>

                                                </div>
                                            </form>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>

                            <Row className="mb-3 px-2 machineTestStep3Div" id="machineTestStep3Div" style={{ display: 'none' }}>
                                <Col className="">
                                    <h6 className="color-red my-2">
                                        {t('home.Step 3')}: &nbsp;
                                        <label class="bookNowInCartMrPoojs">{t('home.in Cart poojs')}&nbsp; </label>
                                        <label class="bookNowInCartMr">{t('home.in Cart')}&nbsp; </label>
                                        {guestinfo.event_type}&nbsp;

                                        <label class="bookNowInCartEn">  {t('home.in Cart')} &nbsp; </label>
                                    </h6>
                                    <p style={{ color: 'red' }}>
                                        {t('home.NOTE')}:&nbsp;{guestinfo.event_type}{t('home.To change quantity remove from cart and add again')}

                                    </p>
                                </Col>
                            </Row>
                            <Row className="px-2 mb-3 machineTestStep3Div" id="machineTestStep3Div" style={{ display: 'none' }}>
                                <Col>
                                </Col>
                            </Row>

                            <Row className="mb-3 px-2 machineTestStep3DivPsyco" id="machineTestStep3DivPsyco" style={{ display: 'none' }}>
                                <Col className="">
                                    <h6 className="color-red my-2">
                                        {t('home.Step 3')}: &nbsp;
                                        {t('home.Study Cources in Cart details psyco')}
                                    </h6>
                                    <p style={{ color: 'red' }}>
                                        {t('home.NOTE')}:&nbsp;{t('home.Psycho-Feedback Therapy change quantity')}

                                    </p>
                                </Col>
                            </Row>
                            <Row className="px-2 mb-3 machineTestStep3DivPsyco" id="machineTestStep3DivPsyco" style={{ display: 'none' }}>
                                <Col>
                                </Col>
                            </Row>


                            <Row className="mb-3 px-2 machineCartEvent" id="machineCartEvent">
                                <Col className="py-2 border border-dark bg-white">
                                    {machineCartEvent.map((info, i) => {
                                        return (
                                            <div>
                                                <div className="row d-flex align-items-center">
                                                    <div className="col-6 mt-2">
                                                        <p>{i + 1}. {info.title}
                                                        </p>
                                                    </div>
                                                    <div className="col-3 pl-0 align-content-center">
                                                        <p className="text-right">
                                                            {t('home.Qty')}:{info.parent}
                                                        </p>
                                                    </div>
                                                    <div className="col-3 pl-0 align-content-center">
                                                        <p className="text-right">
                                                            <span className="mr-3">
                                                                <i style={{ cursor: 'pointer' }} className="fas fa-trash-alt" name={'delete_cart_' + info.event_id} id={'delete_' + info.event_id} onClick={this.deleteFromCartMachineTest}></i>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr className="border-dark mx-n2 mt-1" />
                                            </div>
                                        )
                                    })
                                    }

                                    <span style={{ marginBottom: '10px', display: 'none' }} name="noItemInCart" id="noItemInCart" className="text-muted noItemInCart">{t('home.No items in the cart')}</span>

                                    <Link id="" className="btn btn-brown btn-sm mb-2 goToCart btn-block" onClick={() => this.goToCart()}>{t('home.GO TO CART')}</Link>
                                    <Link onClick={() => this.goToCart()} style={{ display: 'none' }} className="goToLogin">
                                        <button className="btn btn-sm btn-brown px-4  btn-block">{t('home.GO TO CART')}</button>
                                    </Link>


                                </Col>
                            </Row>


                            <Row className="px-2 pb-2 allScheduleDiv" id="allScheduleDiv" >
                                <Col className="col py-2 border border-warning bg-white box-shadow" style={{ maxHeight: '620px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                    {sortDates(scheduleEvent).map((info, i) => {
                                        if (i == 0) {
                                            return (

                                                <span>
                                                    {/* <hr className="border-dark mx-n2" /> */}
                                                    <p className="orangeColor mb-0">
                                                        {splitByFirstMatch(info?.date)[1]}
                                                        {/* {(info.date.substring(2, 1) == "t" || info.date.substring(2, 1) == "n" || info.date.substring(2, 1) == "s") ? info.date.substring(4) : info.date.substring(5)} */}
                                                    </p>
                                                    {info.arr_schedule.map((cell, ii) => {
                                                        if (cell.is_full == 1) {
                                                            return (
                                                                <span>
                                                                    <hr className="border-dark mx-n2 mt-2" />
                                                                    <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                        <div className="col-6">
                                                                            <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                {/* <a target='_blank' href={'https://www.google.com/maps/search/?api=1&query='+cell.latitude+','+cell.longitude} title={cell.venue_address}>{cell.city_name}</a> */}
                                                                                {/* <a target='_blank' href={cell.url} title={cell.venue_address}>{cell.city_name}</a> */}
                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }}>
                                                                                    {cell.city_name}
                                                                                </a>
                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                    <p>{cell.start_date_format} - {cell.end_date_format}, {cell.venue_address}</p>
                                                                                    <p>{t('home.Contact No')}<br />
                                                                                        {cell.contact_details}</p>
                                                                                </ReactTooltip>
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-6 pl-0 align-content-center">
                                                                            <p className="text-right">
                                                                                <p style={{ marginRight: '10px' }}>
                                                                                    {t('home.FULL')}
                                                                                </p>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            )

                                                        } else if (cell.notify == 1) {
                                                            return (
                                                                <span>
                                                                    <hr className="border-dark mx-n2 mt-2" />
                                                                    <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                        <div className="col-6">
                                                                            <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                {/* <a target='_blank' href={'https://www.google.com/maps/search/?api=1&query='+cell.latitude+','+cell.longitude} title={cell.venue_address}>{cell.city_name}</a> */}
                                                                                {/* <a target='_blank' href={cell.url} title={cell.venue_address}>{cell.city_name}</a> */}
                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }}>
                                                                                    {cell.city_name}
                                                                                </a>
                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                    <p>{cell.start_date_format} - {cell.end_date_format}, {cell.venue_address}</p>
                                                                                    <p>{t('home.Contact No')}<br />
                                                                                        {cell.contact_details}</p>
                                                                                </ReactTooltip>


                                                                            </p>
                                                                        </div>
                                                                        <div className="col-6 pl-0 align-content-center">
                                                                            <p className="text-right">
                                                                                <p style={{ marginRight: '10px' }}>
                                                                                    <button id={'notifyScheduleid_' + cell.schedule_id} className="btn btn-sm btn-outline-dark" style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                    <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + '-' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                    <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                                </p>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            )

                                                        } else {
                                                            if (cell.available_in_cart == 1) {
                                                                return (
                                                                    <span>
                                                                        <hr className="border-dark mx-n2 mt-2" />
                                                                        <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                            <div className="col-6">
                                                                                <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                    {/* <a  traget="_blank" href={'https://www.google.com/maps/search/?api=1&query='+cell.latitude+','+cell.longitude}>
                   
                   {cell.city_name}
                   </a> */}
                                                                                    {/* <a target='_blank' href={'https://www.google.com/maps/search/?api=1&query='+cell.latitude+','+cell.longitude} title={cell.venue_address}>{cell.city_name}</a> */}
                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }}>
                                                                                        {cell.city_name}
                                                                                    </a>
                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                        <p>{cell.start_date_format} - {cell.end_date_format}, {cell.venue_address}</p>
                                                                                        <p>{t('home.Contact No')}<br />
                                                                                            {cell.contact_details}</p>
                                                                                    </ReactTooltip>

                                                                                </p>
                                                                            </div>
                                                                            <div className="col-6 pl-0 align-content-center">
                                                                                <p className="text-right">

                                                                                    <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                    <button style={{ display: 'none' }} className={cell.event_id + '_' + cell.schedule_id + ' btn btn-sm btn-outline-brown'} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addEventToCart}>{t('home.ADD TO CART')}</button>
                                                                                    <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id}>{t('home.Added in cart')}</span>
                                                                                </p>

                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                )
                                                            } else {
                                                                return (
                                                                    <span>
                                                                        <hr className="border-dark mx-n2 mt-2" />
                                                                        <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                            <div className="col-6">
                                                                                <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                    {/* <a traget="_blank" href={'https://www.google.com/maps/search/?api=1&query='+cell.latitude+','+cell.longitude}>
                               
                               
                               </a> */}
                                                                                    {/* <Link  target="_blank" to={'https://www.google.com/maps/search/?api=1&query='+cell.latitude+','+cell.longitude} ><button>{cell.city_name}</button></Link> */}
                                                                                    {/* <a target='_blank' href={'https://www.google.com/maps/search/?api=1&query='+cell.latitude+','+cell.longitude} title={cell.venue_address}>{cell.city_name}</a> */}
                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} id='global' target='_blank' href={cell.url} style={{ textDecoration: 'underline' }}>
                                                                                        {cell.city_name}
                                                                                    </a>

                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                        <p>{cell.start_date_format} - {cell.end_date_format}, {cell.venue_address}</p>
                                                                                        <p>{t('home.Contact No')}<br />
                                                                                            {cell.contact_details}</p>
                                                                                    </ReactTooltip>
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-6 pl-0 align-content-center">
                                                                                <p className="text-right">
                                                                                    <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                    {/* <button className="btn btn-sm btn-outline-brown">{t('home.ADD TO CART')} </button> */}
                                                                                    <button className={cell.event_id + '_' + cell.schedule_id + ' btn btn-sm btn-outline-brown color-red'} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addEventToCart}>{t('home.ADD TO CART')}</button>


                                                                                    <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                </p>

                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                )
                                                            }
                                                        }//Else complete


                                                    })
                                                    }
                                                </span>
                                            )
                                        } else {
                                            return (

                                                <span>
                                                    <hr className="border-dark mx-n2" />
                                                    <p className="orangeColor mb-0">
                                                        {splitByFirstMatch(info?.date)[1]}
                                                    </p>
                                                    {/* {console.log("11", info.date)} */}
                                                    {/* {console.log("12", info.date.charAt(2))} */}
                                                    {/* {console.log("13", info.date.substring(4))} */}
                                                    {/* {console.log("14", info.date.substring(5))} */}
                                                    {info.arr_schedule.map((cell, ii) => {
                                                        if (cell.is_full == 1) {
                                                            return (
                                                                <span>
                                                                    <hr className="border-dark mx-n2 mt-2" />
                                                                    <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                        <div className="col-6">
                                                                            <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                {/* <a target='_blank' href={'https://www.google.com/maps/search/?api=1&query='+cell.latitude+','+cell.longitude} title={cell.venue_address}>{cell.city_name}</a> */}
                                                                                {/* <a target='_blank' href={cell.url} title={cell.venue_address}>{cell.city_name}</a> */}
                                                                                {/* <a target='_blank' href={cell.url} title={cell.venue_address} style={{textDecoration:'underline'}}>{cell.city_name}</a> */}
                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }}>
                                                                                    {cell.city_name}
                                                                                </a>
                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                    <p>{cell.start_date_format} - {cell.end_date_format}, {cell.venue_address}</p>
                                                                                    <p>{t('home.Contact No')}<br />
                                                                                        {cell.contact_details}</p>
                                                                                </ReactTooltip>
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-6 pl-0 align-content-center">
                                                                            <p className="text-right">
                                                                                <p style={{ marginRight: '10px' }}>
                                                                                    {t('home.FULL')}
                                                                                </p>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            )

                                                        } else if (cell.notify == 1) {
                                                            return (
                                                                <span>
                                                                    <hr className="border-dark mx-n2 mt-2" />
                                                                    <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                        <div className="col-6">
                                                                            <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                {/* <a target='_blank' href={'https://www.google.com/maps/search/?api=1&query='+cell.latitude+','+cell.longitude} title={cell.venue_address}>{cell.city_name}</a> */}
                                                                                {/* <a target='_blank' href={cell.url} title={cell.venue_address}>{cell.city_name}</a> */}
                                                                                {/* <a target='_blank' href={cell.url} title={cell.venue_address} style={{textDecoration:'underline'}}>{cell.city_name}</a> */}
                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }}>
                                                                                    {cell.city_name}
                                                                                </a>
                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                    <p>{cell.start_date_format} - {cell.end_date_format}, {cell.venue_address}</p>
                                                                                    <p>{t('home.Contact No')}<br />
                                                                                        {cell.contact_details}</p>
                                                                                </ReactTooltip>

                                                                            </p>
                                                                        </div>
                                                                        <div className="col-6 pl-0 align-content-center">
                                                                            <p className="text-right">
                                                                                <p style={{ marginRight: '10px' }}>
                                                                                    <button id={'notifyScheduleid_' + cell.schedule_id} className="btn btn-sm btn-outline-dark" style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                    <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + '-' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                    <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                                </p>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            )

                                                        } else {
                                                            if (cell.available_in_cart == 1) {
                                                                return (
                                                                    <span>
                                                                        <hr className="border-dark mx-n2 mt-2" />
                                                                        <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                            <div className="col-6">
                                                                                <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                    {/* <a  traget="_blank" href={'https://www.google.com/maps/search/?api=1&query='+cell.latitude+','+cell.longitude}>
                   
                   {cell.city_name}
                   </a> */}
                                                                                    {/* <a target='_blank' href={'https://www.google.com/maps/search/?api=1&query='+cell.latitude+','+cell.longitude} title={cell.venue_address}>{cell.city_name}</a> */}
                                                                                    {/* <a target='_blank' href={cell.url} title={cell.venue_address} style={{textDecoration:'underline'}}>{cell.city_name}</a> */}
                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }}>
                                                                                        {cell.city_name}
                                                                                    </a>
                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                        <p>{cell.start_date_format} - {cell.end_date_format}, {cell.venue_address}</p>
                                                                                        <p>{t('home.Contact No')}<br />
                                                                                            {cell.contact_details}</p>
                                                                                    </ReactTooltip>
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-6 pl-0 align-content-center">
                                                                                <p className="text-right">

                                                                                    <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                    <button style={{ display: 'none' }} className={cell.event_id + '_' + cell.schedule_id + ' btn btn-sm btn-outline-brown'} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addEventToCart}>{t('home.ADD TO CART')}</button>
                                                                                    <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id}>{t('home.Added in cart')}</span>
                                                                                </p>

                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                )
                                                            } else {
                                                                return (
                                                                    <span>
                                                                        <hr className="border-dark mx-n2 mt-2" />
                                                                        <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                            <div className="col-6">
                                                                                <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                    {/* <a traget="_blank" href={'https://www.google.com/maps/search/?api=1&query='+cell.latitude+','+cell.longitude}>
                               
                               
                               </a> */}
                                                                                    {/* <Link  target="_blank" to={'https://www.google.com/maps/search/?api=1&query='+cell.latitude+','+cell.longitude} ><button>{cell.city_name}</button></Link> */}
                                                                                    {/* <a target='_blank' href={'https://www.google.com/maps/search/?api=1&query='+cell.latitude+','+cell.longitude} title={cell.venue_address}>{cell.city_name}</a> */}
                                                                                    {/* <a target='_blank' href={cell.url} title={cell.venue_address} style={{textDecoration:'underline'}}>{cell.city_name}</a> */}
                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }}>
                                                                                        {cell.city_name}
                                                                                    </a>
                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                        <p>{cell.start_date_format} - {cell.end_date_format}, {cell.venue_address}</p>
                                                                                        <p>{t('home.Contact No')}<br />
                                                                                            {cell.contact_details}</p>
                                                                                    </ReactTooltip>
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-6 pl-0 align-content-center">
                                                                                <p className="text-right">
                                                                                    <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                    {/* <button className="btn btn-sm btn-outline-brown">{t('home.ADD TO CART')} </button> */}
                                                                                    <button className={cell.event_id + '_' + cell.schedule_id + ' btn btn-sm btn-outline-brown color-red'} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addEventToCart}>{t('home.ADD TO CART')}</button>


                                                                                    <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                </p>

                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                )
                                                            }
                                                        }//Else complete


                                                    })
                                                    }
                                                </span>
                                            )
                                        }



                                    })
                                    }







                                    <hr className="border-dark mx-n2 mt-2" />

                                    <span style={{ marginBottom: '10px', display: 'none' }} name="noScheduleAvailable" id="noScheduleAvailable" className="text-muted noScheduleAvailable">{t('home.No schedule available')}</span>
                                    <br />
                                    {/* <button className="btn btn-brown btn-sm mb-2">{t('home.GO TO CART')}</button> */}
                                    <Link className="btn btn-brown btn-sm mb-2 goToCart btn-block" onClick={() => this.goToCart()}>{t('home.GO TO CART')}</Link>
                                    <Link onClick={() => this.goToCart()} style={{ display: 'none' }} className="goToLogin">
                                        <button className="btn btn-sm btn-brown px-4 btn-block">{t('home.GO TO CART')}</button>
                                    </Link>
                                </Col>
                            </Row>

                            <Row id="col StudyCourcesInCartDiv" className="StudyCourcesInCartDiv">
                                <Col>
                                    <h6 class="color-red">
                                        <label class="bookNowInCartMrPoojs">{t('home.in Cart poojs')}&nbsp; </label>
                                        <label class="bookNowInCartMr">{t('home.in Cart')}&nbsp; </label>
                                        {guestinfo.event_type}&nbsp;

                                        <label class="bookNowInCartEn">  {t('home.in Cart')} &nbsp; </label>
                                    </h6>
                                    <div className="col py-2 border border-warning bg-white box-shadow mb-3  mt-2">
                                        {/* <p className="orangeColor mb-0">MAY 2019</p> */}
                                        <hr className="border-dark mx-n2 mt-2" />
                                        {cartEvent.map((info, i) => {
                                            return (
                                                <div>
                                                    <div className="row d-flex">
                                                        <div className="col-6">
                                                            <p className="font-weight-bold">
                                                                {info.title}
                                                            </p>
                                                        </div>
                                                        <div className="col-6 pl-0 text-right">
                                                            <div className="row">
                                                                <div className="text-muted text-left col-10">{info.date}<br /> {info.start_date_format} - {info.end_date_format}<br />
                                                                    {/* <a href="#">{info.city_name}</a> */}
                                                                    <a data-tip='' data-for={'test123' + info.schedule_id} target='_blank' href={info.url} style={{ textDecoration: 'underline' }}>
                                                                        {info.city_name}
                                                                    </a>
                                                                    <ReactTooltip id={'test123' + info.schedule_id} data-tip={info.venue_address} aria-haspopup='true' role='example'>
                                                                        <p>{info.date}, {info.venue_address}</p>
                                                                        <p>{t('home.Contact No')}<br />
                                                                            {info.contact_details}</p>
                                                                    </ReactTooltip>
                                                                </div>
                                                                <div className="col-2">
                                                                    {/* <i className="fas fa-trash-alt"></i> */}
                                                                    <i style={{ cursor: 'pointer' }} className="fas fa-trash-alt" name={'delete_cart_' + info.event_id + '_' + info.schedule_id} id={'delete_' + info.event_id + '_' + info.schedule_id} onClick={this.deleteFromCart}></i>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <hr className="border-dark mx-n2 mt-2" />
                                                </div>
                                            )
                                        })
                                        }

                                        <span style={{ marginBottom: '10px', display: 'none' }} name="noItemInCart" id="noItemInCart" className="text-muted noItemInCart">{t('home.No items in the cart')}</span>

                                        <Link className="btn btn-brown btn-sm mb-2 goToCart btn-block" onClick={() => this.goToCart()}>{t('home.GO TO CART')}</Link>

                                        <Link onClick={() => this.goToCart()} style={{ display: 'none' }} className="goToLogin">
                                            <button className="btn btn-sm btn-brown px-4 btn-block">{t('home.GO TO CART')}</button>
                                        </Link>
                                    </div>

                                </Col>
                            </Row>
                        </Col>
                    </div>


                    <div className="row">
                        <div className="col-lg-9 mt-3">
                            <div className="row">
                                <div className="col">
                                    {/* <div className="col p-3 text-white mb-4" style={{background: url(assets/images/title-bg.png); background-size:cover}}> */}
                                    <div className="col p-3 text-white mb-4 relatedProductsImage relatedImageEnglish" style={{ backgroundImage: "url(" + myConstClass.IMAGE_URL + "title-bg.png)", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', display: 'none' }}>

                                    </div>
                                    <div className="col p-3 text-white mb-4 relatedProductsImage relatedImageMarathi" style={{ backgroundImage: "url(" + myConstClass.IMAGE_URL + "Related_Products_mr.jpg)", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', display: 'none' }}>

                                    </div>
                                </div>

                            </div>
                            <div className="row">

                                {posts.map((info, ii) => {
                                    if (ii < 4) {
                                        return (
                                            <Col xl="3" lg="3" md="6" sm="6" xs="6" className="mb-3">
                                                <Card className="text-center">
                                                    <Link to={"/products_details/" + info.title_url + '/' + info.product_id}>
                                                        <div className="image-container px-2 py-2">
                                                            <img className="image_css_product" src={info.image_name} />
                                                        </div>
                                                    </Link>

                                                    <CardBody className="py-3">
                                                        <Row>
                                                            <Col xs="12">
                                                                <span className="mr-2">
                                                                    <i className="far fa-comment-dots orangeColor"></i> <span className="small">{info.total_review}</span>
                                                                </span>
                                                                <span>
                                                                    <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">{info.total_like}</span>
                                                                </span>
                                                            </Col>
                                                            <Col xs="12" className="px-1 text-truncate mh--100-px">
                                                                <Link style={{ color: 'black' }} to={"/products_details/" + info.title_url + '/' + info.product_id}>
                                                                    {info.short_title}
                                                                </Link>
                                                            </Col>
                                                            <Col xs="12" className="px-1">
                                                                {t('home.donation_contribution')}
                                                            </Col>
                                                            <Col xs="12" className="font-weight-bolder">
                                                                {t('home.INR')} {info.price}/-
                                                            </Col>
                                                            <Col xs="12" className="qty mb-3 px-0">
                                                                <span>{t('home.Qty')} &nbsp;</span>
                                                                <span className="minus border" onClick={this.decreaseQuantity.bind(this, info.product_id)}>
                                                                    <span className="d-flex align-items-center ico-hld">
                                                                        <i className="fas fa-minus"></i>
                                                                    </span>
                                                                </span>
                                                                <input type="number" className="count" value="1" name={"qty" + info.product_id} id={"qty" + info.product_id} onChange={this.handleChange} />
                                                                <span className="plus border" name={"qty_inc" + info.product_id} id={"qty_inc" + info.product_id} onClick={this.increaseQuantity.bind(this, info.product_id)}>
                                                                    <span className="d-flex align-items-center ico-hld"  >
                                                                        <i className="fas fa-plus"></i>
                                                                    </span>
                                                                </span>
                                                            </Col>
                                                            <Col xs="12" className="px-0">
                                                                {info.added_in_cart.map((carts, j) => {
                                                                    if (carts.cart_id > 0) {
                                                                        return (
                                                                            <span>
                                                                                <button className="btn btn-sm btn-light" name={info.product_id} id={info.product_id} onClick={this.addToCart}>{t('home.ADD MORE')}</button>
                                                                            </span>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <span>
                                                                                <button style={{ display: 'none' }} id={'addedInCart_' + info.product_id} className="btn btn-sm btn-light">{t('home.ADD MORE')}</button>
                                                                                <button className="btn btn-brown btn-sm" name={info.product_id} id={info.product_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                            </span>
                                                                        )
                                                                    }
                                                                })
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>

                                        )
                                    }
                                })
                                }

                                {/* 
                                <div className="col-md-3 col-6 mb-3">
                                    <div className="col border text-center">
                                        <div className=" py-3">
                                            <img src="assets/images/product1.png" className="img-fluid" />
                                            <div className="my-2">
                                                <span className="mr-2">
                                                            <i className="fas fa-comment-dots orangeColor"></i> <span className="small">234</span>
                                                </span>
                                                <span>
                                                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">15</span>
                                                </span>
                                            </div>
                                            <p className="font-weight-bold mb-1">Prenatal Education Kit</p>
                                            <p className="mb-1">Donation Contribution</p>
                                            <p className="font-weight-bolder">Rs. 990/-</p>
                                            <div className="qty mb-3">
                                                <span>Qty-</span>
                                                <span className="minus border">
                                                            <span className="d-flex align-items-center ico-hld">
                                                                <i className="fas fa-minus"></i>
                                                            </span>
                                                </span>
                                                <input type="number" className="count" name="qty" value="1" />
                                                <span className="plus border">
                                                                <span className="d-flex align-items-center ico-hld">
                                                                    <i className="fas fa-plus"></i>
                                                                </span>
                                                </span>
                                            </div>
                                            <button className="btn btn-brown btn-sm">ADD TO CART</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-6 mb-3">
                                    <div className="col border text-center">
                                        <div className=" py-3">
                                            <img src="assets/images/product1.png" className="img-fluid" />
                                            <div className="my-2">
                                                <span className="mr-2">
                                                                <i className="fas fa-comment-dots orangeColor"></i> <span className="small">234</span>
                                                </span>
                                                <span>
                                                                <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">15</span>
                                                </span>
                                            </div>
                                            <p className="font-weight-bold mb-1">Prenatal Education Kit</p>
                                            <p className="mb-1">Donation Contribution</p>
                                            <p className="font-weight-bolder">Rs. 990/-</p>
                                            <div className="qty mb-3">
                                                <span>Qty-</span>
                                                <span className="minus border">
                                                                <span className="d-flex align-items-center ico-hld">
                                                                    <i className="fas fa-minus"></i>
                                                                </span>
                                                </span>
                                                <input type="number" className="count" name="qty" value="1" />
                                                <span className="plus border">
                                                                    <span className="d-flex align-items-center ico-hld">
                                                                        <i className="fas fa-plus"></i>
                                                                    </span>
                                                </span>
                                            </div>
                                            <button className="btn btn-brown btn-sm">ADD TO CART</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-6 mb-3">
                                    <div className="col border text-center">
                                        <div className=" py-3">
                                            <img src="assets/images/product1.png" className="img-fluid" />
                                            <div className="my-2">
                                                <span className="mr-2">
                                                                    <i className="fas fa-comment-dots orangeColor"></i> <span className="small">234</span>
                                                </span>
                                                <span>
                                                                    <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">15</span>
                                                </span>
                                            </div>
                                            <p className="font-weight-bold mb-1">Prenatal Education Kit</p>
                                            <p className="mb-1">Donation Contribution</p>
                                            <p className="font-weight-bolder">Rs. 990/-</p>
                                            <div className="qty mb-3">
                                                <span>Qty-</span>
                                                <span className="minus border">
                                                                    <span className="d-flex align-items-center ico-hld">
                                                                        <i className="fas fa-minus"></i>
                                                                    </span>
                                                </span>
                                                <input type="number" className="count" name="qty" value="1" />
                                                <span className="plus border">
                                                                        <span className="d-flex align-items-center ico-hld">
                                                                            <i className="fas fa-plus"></i>
                                                                        </span>
                                                </span>
                                            </div>
                                            <button className="btn btn-brown btn-sm">ADD TO CART</button>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="row" id="showAllProducts">
                                <div className="col text-center">
                                    {/* <Link className="btn btn-outline-brown"  to={"/products"}>{t('home.SHOW MORE')}</Link> */}
                                    <button className="btn btn-outline-brown" onClick={this.setTopicId}>{t('home.SHOW MORE')}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div className="modal fade z-index--99999" id="notifyModel" tabindex="-1" role="dialog" aria-labelledby="notifyModel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content bg-light" style={{ overflow: 'auto' }}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{t('home.Event')}-&nbsp;<span id="eventTitle"></span></h5><br />
                                <h5 className="modal-title" id="exampleModalLabel">
                                    - {/* {t('home.Notify for')} */}
                                    <span id="notifyTitle"></span></h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <i className="far fa-times-circle"></i>
                                </button>
                            </div>
                            <Form action="" id="pform" onSubmit={this.handleSubmit} method="post" encType="multipart/form-data" className="form-horizontal">
                                <div className="modal-body">

                                    {/* <div className="row">
                        <div className="col">
                            <p className="text-right color-red">* {t('home.Mandatory Fields')}</p>
                        </div>
                    </div> */}
                                    <div className="row">
                                        <div className="form-group col col-md-4">
                                            <label className="color-black font-weight-bolder">{t('home.Name')}<span className="color-red">*</span></label>
                                            <input value={notifyinfo.first_name} onChange={e => { this.setState({ notifyinfo: { ...notifyinfo, first_name: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('first_name')} type="text" id="first_name" name="first_name" className="form-control" placeholder={t('home.Enter First Name')} />


                                            {this.validator.message('first_name', this.state.notifyinfo.first_name, 'required')}

                                        </div>
                                        <div className="form-group col col-md-4">
                                            <label className="color-black font-weight-bolder">{t('home.Last Name')}<span className="color-red">*</span></label>
                                            {/* <input type="text" className="form-control" id="last_name" name="last_name" placeholder={t('home.Enter Last Name')} /> */}
                                            <input value={notifyinfo.last_name} onBlur={() => this.validator.showMessageFor('last_name')} onChange={e => { this.setState({ notifyinfo: { ...notifyinfo, last_name: e.target.value } }) }} type="text" id="last_name" name="last_name" className="form-control" placeholder={t('home.Enter Last Name')} />
                                            {this.validator.message('last_name', this.state.notifyinfo.last_name, 'required')}
                                        </div>


                                    </div>
                                    <div className="row">

                                        <div className="form-group col col-md-4">
                                            <label className="color-black font-weight-bolder">{t('home.Email')} <span className="color-red">*</span></label>
                                            <input value={notifyinfo.email} onChange={e => { this.setState({ notifyinfo: { ...notifyinfo, email: e.target.value } }) }} type="text" className="form-control" id="email" name="email" placeholder={t('home.Email')} />
                                            {this.validator.message('email', this.state.notifyinfo.email, 'required')}
                                        </div>
                                        <input type="hidden" value="" id="notify_schedule_id" name="notify_schedule_id" />
                                    </div>

                                </div>
                                <div className="modal-footer">

                                    <span data-dismiss="modal" aria-label="Close" style={{ cursor: 'pointer' }}>{t('home.Cancel')}</span>
                                    <button type="submit" className="btn btn-outline-brown bg-white btn-sm pl-4 pr-4" style={{ color: 'black' }}>{t('home.Submit')}</button>
                                </div>
                            </Form>
                        </div>

                    </div>
                </div>

                <div className="modal fade z-index--99999" id="MachineTestModal" style={{ marginTop: '-20px' }}>
                    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" role="document">
                        <div className="modal-content bg-light">
                            <div className="modal-header" style={{ background: '#9d0b0e', height: '12%' }}>
                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '-5px', color: 'white', fontSize: '15px' }}>

                                    {t('home.APPOINTMENT ALERT')}
                                </h5>
                                {/* <button type="button" className="close">
                                    <i className="far fa-times-circle"></i>
                                    
                          </button> */}
                                <span style={{ color: 'white', cursor: 'pointer' }} data-dismiss="modal" aria-label="Close">X</span>
                            </div>
                            <div className="modal-body" style={{ marginLeft: '25px' }}>
                                <div className="row">
                                    <input type="hidden" name="MachineTestModalObject" id="MachineTestModalObject" value="" />
                                    <div className="col">
                                        {/* <i className="fas fa-thumbs-up" style={{fontSize:'30px'}}></i> */}
                                        &nbsp;
                                        <img style={{ height: '36px', width: '36px' }} className="image_main_carosal" src={thumbImage} />
                                        &nbsp;  {t('home.thank you for choosing Machine Tests')}

                                        <br />
                                        <p style={{ marginTop: '13px' }}>
                                            {t('home.research_center')}
                                            <br />
                                            {t('home.avoid inconvenience to you')}


                                        </p>
                                        <p>
                                            {t('home.If you have taken the appointment please')}

                                        </p>


                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <button type="button" className="btn btn-brown btn-sm mb-2" onClick={this.addToCartContinue}>
                                            {t('home.CONTINUE Machine Test')}

                                        </button>
                                        <br />
                                        <br />
                                        <p>
                                            {t('home.call_on_machine')}

                                        </p>
                                        <span style={{ color: 'white', cursor: 'pointer' }} className="btn btn-brown btn-sm mb-2" data-dismiss="modal" aria-label="Close">
                                            {t('home.CLOSE Machine Test')}

                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="modal-footer">
                    <span data-dismiss="modal" aria-label="Close">Cancel</span>
                    <button type="button" className="btn btn-outline-brown bg-white btn-sm pl-4 pr-4">SAVE</button>
                </div> */}
                        </div>
                    </div>
                </div>


                <Footer share_image_url={this.state.share_image_url} description={guestinfo.social_description} share_current_image_url={this.state.share_current_image_url} />



                <div id="openGallery" className="openGallerydiv" >
                    <span ><i style={{ cursor: 'pointer' }} onClick={this.closeGallery} class="fa fa-times" aria-hidden="true" id="videoCloseIcon"></i></span>;

                    {lightboxOpen && (
                        <ReactImageVideoLightbox

                            data={this.state.arrVideo}
                            startIndex={this.state.startIndex}
                            showResourceCount={true}
                            onCloseCallback={this.callbackFunction} />
                    )}

                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={this.state.images[photoIndex]['image_url']}
                        nextSrc={this.state.images[(photoIndex + 1) % this.state.images.length]['image_url']}
                        prevSrc={this.state.images[(photoIndex + this.state.images.length - 1) % this.state.images.length]['image_url']}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + this.state.images.length - 1) % this.state.images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % this.state.images.length,
                            })
                        }
                    />
                )}
            </div>

        );
    }
}
Event_details.propTypes = {
    t: PropTypes.func.isRequired,
};

function sortDates(arr, type) {
    const monthMap = {
        January: 0, February: 1, March: 2, April: 3, May: 4, June: 5,
        July: 6, August: 7, September: 8, October: 9, November: 10, December: 11
    };
    // console.log("start", type, arr)
    const parseDate = (dateStr) => {
        // Extract day, month, and year using regex
        const match = dateStr.match(/^(\d+)(?:st|nd|rd|th) (\w+) (\d{4})$/);
        if (!match) return null;

        const day = parseInt(match[1], 10);
        const month = monthMap[match[2]];
        const year = parseInt(match[3], 10);

        return new Date(year, month, day);
    };
    if (type === 1)
        arr.sort((a, b) => parseDate(a?.date) - parseDate(b?.date));
        //arr.sort((a, b) => parseDate(b?.date) - parseDate(a?.date));
    if (type === 2)
        arr.sort((a, b) => parseDate(a?.start_date) - parseDate(b?.start_date));
        //arr.sort((a, b) => new Date(b?.start_date) - new Date(a?.start_date));
    
    return arr;
}

function splitByFirstMatch(str) {
    // Define possible delimiters
    const delimiters = ["t ", "h ", "d "];

    // Find the first occurrence of any delimiter
    let index = -1, match = "";

    for (let delimiter of delimiters) {
        let pos = str.indexOf(delimiter);
        if (pos !== -1 && (index === -1 || pos < index)) {
            index = pos;
            match = delimiter;
        }
    }

    // If no match is found, return the original string in an array
    if (index === -1) {
        return [str];
    }

    // Split the string at the found index
    return [str.substring(0, index), str.substring(index + match.length)];
}

export default translate(Event_details);

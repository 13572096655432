import React, { Component } from "react";

import logo from "./assets/images/logo.png";
import live from "./assets/images/live.png";
import logomr from "./assets/images/logomr.png";
import { ToastContainer, toast } from "react-toastify";
// import { Route, Link, BrowserRouter as Router, Redirect } from "react-router-dom";
import {
  Route,
  Link,
  BrowserRouter as Router,
  Redirect,
  withRouter
} from "react-router-dom";

import "./assets/dist/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/rfs.css";
import * as myConstClass from "./constant.js";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";
import $ from "jquery";
import Appnew from "./Appnew.js";
import ReactGA from "react-ga";
// import './assets/css/font-awesome.min.css';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
//import { hashHistory } from 'react-router;'

// Translation Higher Order Component
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from "react-switch-lang";
import en from "./en.json";
import ma from "./ma.json";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";
import { routes } from "./routes.constants";

// Do this two lines only when setting up the application
setTranslations({ en, ma });
setDefaultLanguage("en");

// If you want to remember selected language
setLanguageCookie();

class Header extends Component {
  constructor(props) {
    super(props);
    this.someTrackingHandler = this.someTrackingHandler.bind(this);
    ReactGA.initialize("UA-21334372-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    this.state = {
      subscribeinfo: {
        first_name: "",
        last_name: "",
        message: "",
        status: "",
        email: "",
      },
      user_id: "",
      redirect: false,
      userinfo: {
        first_name: "",
        middle_name: "",
        last_name: "",
        profile_picture: "profile.jpg",
      },
      settinginfo: {
        url: "",
        on_off: "",
      },
      menuinfo: "",
      //            menuinfo: {
      //                menu: '',
      //                sub_menu: '',
      //            },
      diwaliissueinfo: {
        status: "",
        message: "",
        magazine_id: "",
        issue_id: "",
      },
      cartcount: {
        totalcartcount: "0",
      },
      subMenuLevel1: false,
      subMenuLevel1Heading: "",
      subMenuLevel1List: [],
      subMenuLevel2Heading: "",
      subMenuLevel2: false,
      subMenuLevel2List: [],
      eventList: [],
    };
    this.key = "en";

    this.logout = this.logout.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validatorNewsletter = new SimpleReactValidator();
    this.handleSetLanguageNew = this.handleSetLanguageNew.bind(this);
    this.getEvent = this.getEvent.bind(this);
    this.getEvent();
    this.handleCartClick = this.handleCartClick.bind(this);
  }

  getEvent() {
    fetch(myConstClass.BASE_URL + "event/getcourses")
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          eventList: response,
        });
      });
  }

  someTrackingHandler() {
    ReactGA.event({
      category: "some category",
      action: "some action",
    });
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "90%";
  }

  closeNav() {
    this.setState({
      subMenuLevel1: false,
      subMenuLevel1Heading: "",
      subMenuLevel1List: [],
      subMenuLevel2Heading: "",
      subMenuLevel2: false,
      subMenuLevel2List: [],
    });
    document.getElementById("mySidenav").style.width = "0";
  }

  onMouseEnterTopicDiv() {
    $(".topicDesktop2").slideDown("fast");
  }
  closeTopicDiv() {
    // $('.topicDesktop2').slideToggle();
    $(".topicDesktop2").slideUp("fast");
  }
  onMouseEnterAboutusDiv() {
    $(".aboutusMenuLinks").slideDown("fast");
  }
  closeAboutusDiv() {
    $(".aboutusMenuLinks").slideUp("fast");
  }
  onMouseEnterEventsDiv() {
    $(".eventsMenuLinks").slideDown("fast");
  }
  closeEventsDiv() {
    $(".eventsMenuLinks").slideUp("fast");
  }
  onMouseProductsDiv() {
    $(".productsMenuLinks").slideDown("fast");
  }
  closeProductsDiv() {
    $(".productsMenuLinks").slideUp("fast");
  }
  onMouseMoreDiv() {
    $(".moreMenuLinks").slideDown("fast");
  }
  closeMoreDiv() {
    $(".moreMenuLinks").slideUp("fast");
  }

  showSubMenuLevel2(subMenu) {
    var docs = [];

    if (this.state.subMenuLevel1Heading == this.props.t("home.TOPICS")) {
      if (subMenu === this.props.t("home.Newly Wed Couples")) {
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Newly Wed Couples/1"
            custom="custom"
            onClick={() => this.closeNav()}
          >
            All Subtopics
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Newly Wed Couples/1/1"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Happy Marriage")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Newly Wed Couples/1/2"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Super Procreation (Suprajanan)")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Newly Wed Couples/1/3"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Regarding Infertility")}
          </Link>
        );
        this.setState({
          subMenuLevel2: true,
          subMenuLevel2List: docs,
          subMenuLevel2Heading: this.props.t("home.Newly Wed Couples"),
        });
      } else if (subMenu === "Garbha Sanskar") {
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Garbha Sanskar (Prenatal Education)/2"
            onClick={() => this.closeNav()}
          >
            All Subtopics
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Garbha Sanskar (Prenatal Education)/2/4"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Garbha Sanskar")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Garbha Sanskar (Prenatal Education)/2/5"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Music for Unborn")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Garbha Sanskar (Prenatal Education)/2/6"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Yoga during Pregnancy")}
          </Link>
        );
        this.setState({
          subMenuLevel2: true,
          subMenuLevel2List: docs,
          subMenuLevel2Heading:
            this.props.t("home.Garbha Sanskar") +
            " " +
            this.props.t("home.(Prenatal Education)"),
        });
      } else if (subMenu === "Nurturing Kids Birth up to 7 years") {
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Nurturing Kids-Birth up to 7 years/3"
            onClick={() => this.closeNav()}
          >
            All Subtopics
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Nurturing Kids-Birth up to 7 years/3/7"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.For Kids")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Nurturing Kids-Birth up to 7 years/3/8"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.For Parents")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Nurturing Kids-Birth up to 7 years/3/9"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.For Mentally Challenged")}
          </Link>
        );
        this.setState({
          subMenuLevel2: true,
          subMenuLevel2List: docs,
          subMenuLevel2Heading:
            this.props.t("home.Nurturing Kids") +
            " " +
            this.props.t("home.Birth up to 7 years"),
        });
      } else if (subMenu === "Child Development 8 to 14 years") {
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Child Development-8 to 14 years/4"
            onClick={() => this.closeNav()}
          >
            All Subtopics
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Child Development-8 to 14 years/4/10"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.For Children")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Child Development-8 to 14 years/4/11"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.For Parents")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Child Development-8 to 14 years/4/12"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.For Teachers")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Child Development-8 to 14 years/4/12"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.For Mentally Challenged")}
          </Link>
        );

        this.setState({
          subMenuLevel2: true,
          subMenuLevel2List: docs,
          subMenuLevel2Heading:
            this.props.t("home.Child Development") +
            " " +
            this.props.t("home.8 to 14 years"),
        });
      } else if (subMenu === "Youth Power 15 to 28 years") {
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Youth Power-15 to 28 years/5"
            onClick={() => this.closeNav()}
          >
            All Subtopics
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Youth Power-15 to 28 years/5/14"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.For Teenagers: 15 to 21 years")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Youth Power-15 to 28 years/5/15"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.For Parents of Teenagers")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Youth Power-15 to 28 years/5/16"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.For Youths: 22 to 28 years")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Youth Power-15 to 28 years/5/17"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Pre-Marriage Adjustment")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Youth Power-15 to 28 years/5/40"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Post-Marriage Adjustment")}
          </Link>
        );
        this.setState({
          subMenuLevel2: true,
          subMenuLevel2List: docs,
          subMenuLevel2Heading:
            this.props.t("home.Youth Power:") +
            " " +
            this.props.t("home.15 to 28 years"),
        });
      } else if (subMenu === "Stress Management") {
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Managing Stress/6"
            onClick={() => this.closeNav()}
          >
            All Subtopics
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Managing Stress/6/18"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Managing Stress")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Managing Stress/6/19"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Relief from Jealousy")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Managing Stress/6/20"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.De-Addiction,")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Managing Stress/6/39"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Success in Occupation")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Managing Stress/6/21"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Music Therapy")}
          </Link>
        );
        this.setState({
          subMenuLevel2: true,
          subMenuLevel2List: docs,
          subMenuLevel2Heading: this.props.t("home.Stress Management"),
        });
      } else if (subMenu === "Health Care") {
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Health Care/7"
            onClick={() => this.closeNav()}
          >
            All Subtopics
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Health Care/7/22"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Cure without Medicine")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Health Care/7/23"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.For Senior Citizens")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Health Care/7/24"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Plant Therapy")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Health Care/7/25"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Ayurvedic Products Header")}
          </Link>
        );

        this.setState({
          subMenuLevel2: true,
          subMenuLevel2List: docs,
          subMenuLevel2Heading: this.props.t("home.Health Care"),
        });
      } else if (subMenu === "Meditation & Yoga") {
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Meditation & Yoga/8"
            onClick={() => this.closeNav()}
          >
            All Subtopics
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Meditation & Yoga/8/26"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Meditation")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Meditation & Yoga/8/27"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Yoga")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Meditation & Yoga/8/28"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Scientific Pranayam")}
          </Link>
        );

        this.setState({
          subMenuLevel2: true,
          subMenuLevel2List: docs,
          subMenuLevel2Heading: this.props.t("home.Meditation & Yoga"),
        });
      } else if (subMenu === "Science of Spirituality") {
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Science of Spirituality/9"
            onClick={() => this.closeNav()}
          >
            All Subtopics
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Science of Spirituality/9/29"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Mind")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Science of Spirituality/9/30"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.God")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Science of Spirituality/9/31"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Mantra")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Science of Spirituality/9/32"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Religion")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Science of Spirituality/9/33"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Fortune")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Science of Spirituality/9/34"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Prayer")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Science of Spirituality/9/35"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Yadnya")}
          </Link>
        );

        this.setState({
          subMenuLevel2: true,
          subMenuLevel2List: docs,
          subMenuLevel2Heading: this.props.t("home.Science of Spirituality"),
        });
      } else if (subMenu === "Life after Death") {
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Life after Death/10"
            onClick={() => this.closeNav()}
          >
            All Subtopics
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Life after Death/10/36"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Rebirth/Reincarnation")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/topics/Life after Death/10/37"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Shraddha")}
          </Link>
        );

        this.setState({
          subMenuLevel2: true,
          subMenuLevel2List: docs,
          subMenuLevel2Heading: this.props.t("home.Life after Death"),
        });
      }
    } else if (
      this.state.subMenuLevel1Heading === this.props.t("home.PRODUCTS")
    ) {
      if (subMenu === "Books") {
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/books/1"
            onClick={() => this.closeNav()}
          >
            All Topics
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/books/1/1"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Newly Wed Couples")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/books/1/2"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Pre Natal(Garbha Sanskar)")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/books/1/3"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Nurturing Kids")}
            <br />
            {this.props.t("home.Birth up to 7 years")}
          </Link>
        );

        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/books/1/4"
            onClick={() => this.closeNav()}
          >
            {this.props.t(
              "home.Child Development & Parenting - Birth 8 to 14 years"
            )}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/books/1/5"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Youth Power")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/books/1/6"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Stress Management")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/books/1/7"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Health Care")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/books/1/8"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Meditation & Yoga")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/books/1/9"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Science of Spirituality")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/books/1/10"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Life after Death")}{" "}
          </Link>
        );
        this.setState({
          subMenuLevel2: true,
          subMenuLevel2List: docs,
          subMenuLevel2Heading: this.props.t("home.Books"),
        });
      } else if (subMenu === "Kits") {
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/kits/2"
            onClick={() => this.closeNav()}
          >
            All Topics
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/kits/2/1"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Newly Wed Couples")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/kits/2/2"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Pre Natal(Garbha Sanskar)")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/kits/2/3"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Nurturing Kids")}
            <br />
            {this.props.t("home.Birth up to 7 years")}
          </Link>
        );

        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/kits/2/4"
            onClick={() => this.closeNav()}
          >
            {this.props.t(
              "home.Child Development & Parenting - Birth 8 to 14 years"
            )}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/kits/2/5"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Youth Power")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/kits/2/6"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Stress Management")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/kits/2/7"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Health Care")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/kits/2/8"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Meditation & Yoga")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/kits/2/9"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Science of Spirituality")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/kits/2/10"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Life after Death")}
          </Link>
        );
        this.setState({
          subMenuLevel2: true,
          subMenuLevel2List: docs,
          subMenuLevel2Heading: this.props.t("home.Kits"),
        });
      } else if (subMenu === "CDs") {
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/CDs/3"
            onClick={() => this.closeNav()}
          >
            All Topics
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/CDs/3/1"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Newly Wed Couples")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/CDs/3/2"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Pre Natal(Garbha Sanskar)")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/CDs/3/3"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Nurturing Kids")}
            <br />
            {this.props.t("home.Birth up to 7 years")}
          </Link>
        );

        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/CDs/3/4"
            onClick={() => this.closeNav()}
          >
            {this.props.t(
              "home.Child Development & Parenting - Birth 8 to 14 years"
            )}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/CDs/3/5"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Youth Power")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/CDs/3/6"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Stress Management")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/CDs/3/7"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Health Care")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/CDs/3/8"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Meditation & Yoga")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/CDs/3/9"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Science of Spirituality")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/CDs/3/10"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Life after Death")}
          </Link>
        );
        this.setState({
          subMenuLevel2: true,
          subMenuLevel2List: docs,
          subMenuLevel2Heading: this.props.t("home.CDs"),
        });
      } else if (subMenu === "Experimentals Products") {
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/experimental/4"
            onClick={() => this.closeNav()}
          >
            All Topics
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/experimental/4/1"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Newly Wed Couples")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/experimental/4/2"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Pre Natal(Garbha Sanskar)")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/experimental/4/3"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Nurturing Kids")}
            <br />
            {this.props.t("home.Birth up to 7 years")}
          </Link>
        );

        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/experimental/4/4"
            onClick={() => this.closeNav()}
          >
            {this.props.t(
              "home.Child Development & Parenting - Birth 8 to 14 years"
            )}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/experimental/4/5"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Youth Power")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/experimental/4/6"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Stress Management")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/experimental/4/7"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Health Care")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/experimental/4/8"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Meditation & Yoga")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/experimental/4/9"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Science of Spirituality")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/experimental/4/10"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Life after Death")}
          </Link>
        );
        this.setState({
          subMenuLevel2: true,
          subMenuLevel2List: docs,
          subMenuLevel2Heading: this.props.t("home.Experimentals Products"),
        });
      } else if (subMenu === "Ayurvedic") {
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/ayurvedic/5"
            onClick={() => this.closeNav()}
          >
            All Topics
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/ayurvedic/5/1"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Newly Wed Couples")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/ayurvedic/5/2"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Pre Natal(Garbha Sanskar)")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/ayurvedic/5/3"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Nurturing Kids")}
            <br />
            {this.props.t("home.Birth up to 7 years")}
          </Link>
        );

        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/ayurvedic/5/4"
            onClick={() => this.closeNav()}
          >
            {this.props.t(
              "home.Child Development & Parenting - Birth 8 to 14 years"
            )}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/ayurvedic/5/5"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Youth Power")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/ayurvedic/5/6"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Stress Management")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/ayurvedic/5/7"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Health Care")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/ayurvedic/5/8"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Meditation & Yoga")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/ayurvedic/5/9"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Science of Spirituality")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/product_type/ayurvedic/5/10"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Life after Death")}
          </Link>
        );
        this.setState({
          subMenuLevel2: true,
          subMenuLevel2List: docs,
          subMenuLevel2Heading: this.props.t("home.Ayurvedic"),
        });
      }
    } else if (this.state.subMenuLevel1Heading == this.props.t("home.MORE")) {
      if (subMenu === "Gallery") {
        docs.push(
          <Link
            className="dropdown-item"
            to="/photo-gallery"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Photo Gallery")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/video-gallery"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Video Gallery")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/audio-gallery"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Audio Gallery")}
          </Link>
        );
        docs.push(
          <Link
            className="dropdown-item"
            to="/wallpaper-gallery"
            onClick={() => this.closeNav()}
          >
            {this.props.t("home.Wallpaper Gallery")}
          </Link>
        );

        this.setState({
          subMenuLevel2: true,
          subMenuLevel2List: docs,
          subMenuLevel2Heading: this.props.t("home.Gallery"),
        });
      }
    }
  }

  showSubMenuLevel1(subMenu) {
    let docs = [];

    if (subMenu === "events") {
      localStorage.setItem("eventAllTopic", "show");
      docs.push(
        <a
          className="dropdown-item"
          href="https://onlinecourses.manashakti.org/"
          target="_blank"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Online Courses")}
        </a>
      );
      docs.push(
        <a
          className="dropdown-item"
          href="https://onlineselftests.manashakti.org/"
          target="_blank"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Online Tests")}
        </a>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/events/Residential Study Courses/1"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Residential Study Courses")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/events/1-Day Workshops/2"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.1-Day Workshops")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/machine-test/Machine Tests/3"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Machine Tests")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/event_details/83"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Psycho-Feedback Therapy")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/events/Rational Poojas/4"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Rational Poojs")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/events/Free Events/5"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Free Events")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/organize-a-trip/7"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Organize a Trip")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/cycle-of-good-deeds"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Cycle of")}
          {this.props.t("home.Good Deeds")}
        </Link>
      );

      this.setState({
        subMenuLevel1: true,
        subMenuLevel1List: docs,
        subMenuLevel1Heading: this.props.t("home.EVENTS"),
      });
    } else if (subMenu === "topics") {
      docs.push(
        <Link
          className="dropdown-item"
          onClick={() =>
            this.showSubMenuLevel2("Suprajanan (Newly Wed Couples)")
          }
        >
          {this.props.t("home.Newly Wed Couples")}
          <i className="fa fa-angle-right float-right mx-3"></i>
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          onClick={() => this.showSubMenuLevel2("Garbha Sanskar")}
        >
          {this.props.t("home.Garbha Sanskar")}
          <i className="fa fa-angle-right float-right mx-3"></i>
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          onClick={() =>
            this.showSubMenuLevel2("Nurturing Kids Birth up to 7 years")
          }
        >
          {this.props.t("home.Nurturing Kids") +
            " " +
            this.props.t("home.Birth up to 7 years")}
          <i className="fa fa-angle-right float-right mx-3"></i>
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          onClick={() =>
            this.showSubMenuLevel2("Child Development 8 to 14 years")
          }
        >
          {this.props.t("home.Child Development") +
            " " +
            this.props.t("home.8 to 14 years")}
          <i className="fa fa-angle-right float-right mx-3"></i>
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          onClick={() => this.showSubMenuLevel2("Youth Power 15 to 28 years")}
        >
          {this.props.t("home.Youth Power:") +
            " " +
            this.props.t("home.15 to 28 years")}
          <i className="fa fa-angle-right float-right mx-3"></i>
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          onClick={() => this.showSubMenuLevel2("Stress Management")}
        >
          {this.props.t("home.Stress Management")}
          <i className="fa fa-angle-right float-right mx-3"></i>
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          onClick={() => this.showSubMenuLevel2("Health Care")}
        >
          {this.props.t("home.Health Care")}
          <i className="fa fa-angle-right float-right mx-3"></i>
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          onClick={() => this.showSubMenuLevel2("Meditation & Yoga")}
        >
          {this.props.t("home.Meditation & Yoga")}
          <i className="fa fa-angle-right float-right mx-3"></i>
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          onClick={() => this.showSubMenuLevel2("Science of Spirituality")}
        >
          {this.props.t("home.Science of Spirituality")}
          <i className="fa fa-angle-right float-right mx-3"></i>
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          onClick={() => this.showSubMenuLevel2("Life after Death")}
        >
          {this.props.t("home.Life after Death")}
          <i className="fa fa-angle-right float-right mx-3"></i>
        </Link>
      );
      this.setState({
        subMenuLevel1: true,
        subMenuLevel1List: docs,
        subMenuLevel1Heading: this.props.t("home.TOPICS"),
      });
    } else if (subMenu === "products") {
      docs.push(
        <Link
          className="dropdown-item"
          onClick={() => this.showSubMenuLevel2("Books")}
        >
          {this.props.t("home.Books")}
          <i className="fa fa-angle-right float-right mx-3"></i>
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          onClick={() => this.showSubMenuLevel2("Kits")}
        >
          {this.props.t("home.Kits")}
          <i className="fa fa-angle-right float-right mx-3"></i>
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          onClick={() => this.showSubMenuLevel2("CDs")}
        >
          {this.props.t("home.CDs")}
          <i className="fa fa-angle-right float-right mx-3"></i>
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          onClick={() => this.showSubMenuLevel2("Experimentals Products")}
        >
          {this.props.t("home.Experimentals Products")}
          <i className="fa fa-angle-right float-right mx-3"></i>
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          onClick={() => this.showSubMenuLevel2("Ayurvedic")}
        >
          {this.props.t("home.Ayurvedic")}
          <i className="fa fa-angle-right float-right mx-3"></i>
        </Link>
      );
      docs.push(
        <Link className="dropdown-item" to="/magazine">
          {this.props.t("home.Magazine")}
        </Link>
      );
      docs.push(
        <Link className="dropdown-item" to="/product_type/diwali/6">
          {this.props.t("home.Diwali Issue")}
        </Link>
      );
      docs.push(
        <Link className="dropdown-item" to="/product_type/bal-diwali/7">
          {this.props.t("home.Bal Diwali Issue")}
        </Link>
      );
      this.setState({
        subMenuLevel1: true,
        subMenuLevel1List: docs,
        subMenuLevel1Heading: this.props.t("home.PRODUCTS"),
      });
    } else if (subMenu === "myAccount") {
      docs.push(
        <Link
          className="dropdown-item"
          to="/order-history"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Order History")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/participants"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Master List of Participants")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/my-interactions"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.My Interactions")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/change-password"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Change Password")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/profile"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Profile")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/donation-contribution"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.donation_contribution")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/logout"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Sign Out")}
        </Link>
      );
      this.setState({
        subMenuLevel1: true,
        subMenuLevel1List: docs,
        subMenuLevel1Heading: this.props.t("home.My Account"),
      });
    } else if (subMenu === "more") {
      docs.push(
        <Link
          className="dropdown-item"
          onClick={() => this.showSubMenuLevel2("Gallery")}
        >
          {this.props.t("home.Gallery")}
          <i className="fa fa-angle-right float-right mx-3"></i>
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/news/1"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.News")}
        </Link>
      );

      docs.push(
        <Link
          className="dropdown-item"
          to="/download"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Downloads")}
        </Link>
      );
      {
        /*docs.push(
        <a
          style={{ cursor: "pointer" }}
          className="dropdown-item"
          data-toggle="modal"
          data-target="#subscribeModel"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Newsletter")}
        </a>
      );
      docs.push(
        <a
          target="_blank"
          href="https://res.cloudinary.com/https-www-manashakti-org/image/upload/v1609224827/Other/Under_Construction_gndfqt.png"
          style={{ cursor: "default" }}
          className="dropdown-item"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Blog")}
        </a>
      );*/
      }
      docs.push(
        <Link
          className="dropdown-item"
          to="/faqs"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.FAQs")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/e-shopping-help"
          onClick={() => this.closeNav()}
        >
          {this.props.t("e_shopping_help")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/site-map"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Site Map")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/feedback"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Feedback")}
        </Link>
      );
      this.setState({
        subMenuLevel1: true,
        subMenuLevel1List: docs,
        subMenuLevel1Heading: this.props.t("home.MORE"),
      });
    } else if (subMenu === "aboutUs") {
      docs.push(
        <Link
          className="dropdown-item"
          to="/about-us/6"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.ABOUT US")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/about-us/1"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Vission & Mission")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/about-us/2"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.First Thinker")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/about-us/8"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.New Way Philosopy")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/about-us/3"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Values")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/about-us/4"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Work Areas")}
        </Link>
      );
      docs.push(
        <Link
          className="dropdown-item"
          to="/about-us/5"
          onClick={() => this.closeNav()}
        >
          {this.props.t("home.Comments Of World Thinkers")}
        </Link>
      );

      this.setState({
        subMenuLevel1: true,
        subMenuLevel1List: docs,
        subMenuLevel1Heading: this.props.t("home.ABOUT US"),
      });
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    var user_id = localStorage.getItem("user_id");
    // console.log("this.refs", $("#pform_newsletter").serializeArray());
    if (this.validatorNewsletter.allValid()) {
      const formData = {};
      for (const field in this.refs) {
        formData[field] = this.refs[field].value;
      }
      // console.log("-->", formData);
      fetch(myConstClass.BASE_URL + `users/addSubscriber/` + user_id, {
        method: "POST",
        body: JSON.stringify($("#pform_newsletter").serializeArray()),
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({ subscribeinfo: response });
          var message = this.state.subscribeinfo.message;
          var status = this.state.subscribeinfo.status;
          // console.log("hii" + status);
          if (status == "true") {
            // $('#address').modal('hide');
            // $('#pform')[0].reset();

            //const numRows = this.state.subscribeinfo.length;

            this.state.subscribeinfo.first_name =
              this.state.subscribeinfo.first_name;
            //this.state.subscribeinfo.middle_name = this.state.subscribeinfo[0].middle_name;
            this.state.subscribeinfo.last_name =
              this.state.subscribeinfo.last_name;

            //this.state.subscribeinfo.phone = this.state.subscribeinfo[0].phone;
            this.state.subscribeinfo.email = this.state.subscribeinfo.email;
            //this.state.subscribeinfo.event_type = this.state.subscribeinfo[0].event_type;
            //this.state.subscribeinfo.comment = this.state.subscribeinfo[0].comment;

            toast("" + message, { containerId: "B", type: toast.TYPE.SUCCESS });
          } else {
            toast("" + message, { containerId: "B", type: toast.TYPE.ERROR });
          }
          $("#subscribeModel").modal("hide");
          //console.log(status);
        })
        .catch((err) => console.log(err));
    } else {
      this.validatorNewsletter.showMessages();
      // console.log("validations" + this.validatorNewsletter.showMessages());
      e.preventDefault();
      toast("Please fill all required fields.", {
        containerId: "B",
        type: toast.TYPE.ERROR,
      });
    }
  }

  componentDidMount() {
    var key = localStorage.getItem("key");
    if (key == "en") {
      this.key = "ma";
      $("#logoEnglish").css("display", "none");
      $("#logoMarathi").css("display", "inline");
      $("#logoEnglish_m").css("display", "none");
      $("#logoMarathi_m").css("display", "inline");
    } else {
      $("#logoEnglish").css("display", "inline");
      $("#logoMarathi").css("display", "none");
      $("#logoEnglish_m").css("display", "inline");
      $("#logoMarathi_m").css("display", "none");
      this.key = "en";
    }
    setLanguage(this.key);
    setLanguageCookie("lang_key");

    window.scrollTo(0, 0);
    var user_id = localStorage.getItem("user_id");
    var profile_picture = localStorage.getItem("profile_picture");
    if (profile_picture != "" && profile_picture != undefined) {
      this.state.userinfo.profile_picture = profile_picture;
    }
    setTimeout(
      function () {
        //    $('.sc-bdVaJa').css('display','none');
      }.bind(this),
      5000
    );

    fetch(myConstClass.BASE_URL + "sitesetting/getSiteSettingFront")
      .then((response) => response.json())
      .then((response) => {
        this.setState({ settinginfo: response });
        var on_off = this.state.settinginfo.on_off;
        if (on_off == 1) {
          this.state.settinginfo.url = this.state.settinginfo.url;
          $("#liveLi").css("display", "block");
        } else {
          $("#liveLi").css("display", "none");
        }
      });
    // fetch(myConstClass.BASE_URL + 'sitesetting/getMenues')
    //     .then(response => response.json())
    //     .then(response => {
    //         this.setState({ menuinfo: response })
    //     })

    var user_id = localStorage.getItem("user_id");
    this.setState({ user_id });
    if (user_id > 0) {
      var btoa_user_id = btoa(user_id);
      // fetch(myConstClass.BASE_URL + 'users/getUserProfile/' + user_id)
      fetch(myConstClass.BASE_URL + "users/getUserProfile/" + btoa_user_id)
        .then((response) => response.json())
        .then((response) => {
          this.setState({ subscribeinfo: response });
          const numRows = this.state.subscribeinfo.length;
          if (numRows > 0) {
            this.state.subscribeinfo.first_name =
              this.state.subscribeinfo[0].first_name;

            this.state.subscribeinfo.last_name =
              this.state.subscribeinfo[0].last_name;
            this.state.subscribeinfo.phone = this.state.subscribeinfo[0].phone;
            this.state.subscribeinfo.email = this.state.subscribeinfo[0].email;
          }
        });
    }

    if (user_id > 0 && user_id != null) {
      fetch(myConstClass.BASE_URL + "cart/getTotalCartCount/" + user_id)
        .then((response) => response.json())
        .then((response) => {
          this.setState({ cartcount: response });
          var totalcartcount = this.state.cartcount.totalcartcount;
          $(".lblCartCount").html(totalcartcount);
          if (totalcartcount > 0) {
            $("#ContinueToCheckoutDiv").css("display", "none");
            // $('#itemsInTheCart').css('display','block');
            // $('#productItemThead').css('display','block');
            // $('#productItemShippingThead').css('display','block');
          } else {
            // $('#noRecordsFoundTR').css('display','inline');
            $("#instructionsDiv").css("display", "none");
            $("#ContinueToCheckoutDiv").css("display", "block");
            $("#ContinueToCheckoutMainDiv").css("display", "none");

            $("#itemsInTheCart").css("display", "none");
            $("#productItemThead").css("display", "none");
            $("#productItemShippingThead").css("display", "none");
          }
        });

      $("#signUpButton").css("display", "none");
      $("#signInButton").css("display", "none");
      $("#logoutButton").css("display", "block");
      $("#myCartButton").css("display", "block");

      var first_name = localStorage.getItem("first_name");
      var middle_name = localStorage.getItem("middle_name");
      var last_name = localStorage.getItem("last_name");
      var is_verify_sadhak_no = localStorage.getItem("is_verify_sadhak_no");
      if (is_verify_sadhak_no == 1) {
        $(".donationContribution").css("display", "block");
      } else {
        $(".donationContribution").css("display", "none");
      }
      // this.state.userinfo.first_name = first_name;
      // this.state.userinfo.middle_name = middle_name;
      // this.state.userinfo.last_name = last_name;
      // $('#user_first_name').html(first_name);
      // $('#user_last_name').html(last_name ? last_name[0] : last_name);
      // var name_str =first_name +" "+last_name;
      // if(name_str.length > 20) {
      //     name_str = name_str.substring(0,20);
      // } else {
      //     name_str = name_str;
      // }
      // $('#user_first_name').html(name_str);

      var user_full_name_str = first_name + " " + last_name;
      if (user_full_name_str.length > 18) {
        user_full_name_str = user_full_name_str.substring(0, 18);
      } else {
        user_full_name_str = user_full_name_str;
      }

      var name_str = (first_name ? first_name[0] : "") + " " + (last_name ? last_name[0] : "");
      $("#user_first_name").html(name_str.toUpperCase());
      $("#myAccountSpan").html(user_full_name_str);

      $("#user_last_name").html();
      // console.log(last_name);
    } else {
      $(".lblCartCount").html("0");
      $("#logoutButton").css("display", "none");
      $(".userProfile").css("display", "none");
      $("#myCartButton").css("display", "none");
    }
  }
  // diwaliIssues(){
  //     fetch(myConstClass.BASE_URL+'magazine/getMagazineDiwaliInfoFrontEnd')
  //     .then(response => response.json())
  //     .then(response =>{this.setState({diwaliissueinfo: response})
  //     var status = this.state.diwaliissueinfo.status;
  //     var message = this.state.diwaliissueinfo.message;
  //     var magazine_id = this.state.diwaliissueinfo.magazine_id;
  //     var issue_id = this.state.diwaliissueinfo.issue_id;
  //     if(status == 'true'){

  //         // this.props.history.push('/signin');
  //         //toast(''+message, {containerId: 'B',type: toast.TYPE.SUCCESS});
  //         this.props.history.push('/magazine_details/'+magazine_id+'/'+issue_id);
  //         // return <Redirect to={'/magazine_details/'+magazine_id+'/'+issue_id} />
  //         //setTimeout(this.props.history.push('/products'),1000);
  //     }else{
  //         toast(message, {containerId: 'B',type: toast.TYPE.ERROR});
  //         //toast("Wow so easy !");
  //     }
  // })
  //     .catch(err => console.log(err))

  // }

  logout() {
    localStorage.clear();
    var message = "You have logged out successfully.";
    localStorage.setItem("success", message);
  }
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/signin" />;
    }
    this.props.history.push(`/signin`);
  };

  handleSetLanguageNew = (key) => () => {
    if (key == "en") {
      this.key = "ma";
      $("#logoEnglish").css("display", "none");
      $("#logoMarathi").css("display", "inline");
      $("#logoEnglish_m").css("display", "none");
      $("#logoMarathi_m").css("display", "inline");
    } else {
      $("#logoEnglish").css("display", "inline");
      $("#logoMarathi").css("display", "none");
      $("#logoEnglish_m").css("display", "inline");
      $("#logoMarathi_m").css("display", "none");
      this.key = "en";
    }
    setLanguage(this.key);
    setLanguageCookie("lang_key");
    localStorage.removeItem("key");
    localStorage.setItem("key", key);
    this.handleSetLanguage(key);
  };

  handleSetLanguage = (key) => () => {
    if (key == "en") {
      this.key = "ma";
    } else {
      this.key = "en";
    }
    setLanguage(this.key);
    setLanguageCookie("lang_key");
  };

  gotToProducts(type_id) {
    alert("hi");
    this.setState({ flag: 1 });
    //this.props.history.push('/product_type/'+type_id)
    // browserHistory.push('/product_type/'+type_id);
    //alert(type_id);
  }

  onMouseEnter(divId) {
    // alert(divId);
    $("#" + divId).css("display", "block");
  }

  onMouseLeave(divId) {
    //alert(divId);
    $("#" + divId).css("display", "none");
  }

  handleCartClick(e) {
    const user_id = localStorage.getItem("user_id");
    const { t, history } = this.props;

    if (!user_id) {
      e.preventDefault();
      // toast.error("Please login first");
      history.replace("/signin");
      localStorage.setItem('error', "please login first");
    }
  }

  render() {
    const { settinginfo, userinfo, subscribeinfo } = this.state;
    const { t, signin } = this.props;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    // let header_menu_name;
    //     header_menu_name = this.state.menuinfo ?  this.state.menuinfo.map((cell, i) => {
    //          return    <Link> cell.menu_name </Link>
    //                ;
    //          }) : '';
    // console.log(header_menu_name);

    // let header_submenu_name;
    //     header_submenu_name = this.state.menuinfo ?  this.state.menuinfo.map((cell, i) => {
    //           return   cell.sub_menu.map(( m)=>{
    //                    return m;
    //                })
    //          }) : '';

    let balance_div;
    let bal;
    let i_value;
    // balance_div =   this.state.menuinfo ?  this.state.menuinfo.map((cell, i) => {

    //    return   <Link className="dropdown-item"  to="/photo-gallery"  onClick={this.onMouseLeave.bind(this,  cell.menu_html_id )} onMouseLeave={this.onMouseLeave.bind(this,   cell.menu_html_id )} onMouseEnter={this.onMouseEnter.bind(this,  cell.menu_html_id )}>{cell.menu_name}
    //           <div onMouseLeave={this.onMouseLeave.bind(this, cell.menu_html_id )} onClick={this.onMouseLeave.bind(this,  cell.menu_html_id )} onMouseEnter={this.onMouseEnter.bind(this,   cell.menu_html_id )} id={cell.menu_html_id}  className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
    //           <ul style={{ listStyle: "none" }}>

    //        {cell.sub_menu.map((  i2) => {
    //               return  <li><Link className="dropdown-item" to="/photo-gallery">{i2}   </Link></li>;
    //           })}
    //           </ul>
    //         </div>
    //         </Link>;
    //    }) : '';

    return (
      <div>
        <Appnew />
        <div className="fixed-top">
          <div className="container-fluid top-menu pt-1 pb-1">
            <ul className="nav justify-content-end">
              <li style={{ marginRight: "60.9%" }} id="liveLi">
                <a target="_blank" href={settinginfo.url}>
                  <img src={live} id="liveEnglish" />
                </a>
              </li>
              <li style={{ marginRight: "10px" }}>
                <a
                  target="_blank"
                  href="https://www.facebook.com/manashaktiresearchcentrelonavla"
                >
                  <i class="fab fa-facebook-f text-white m-2"></i>
                </a>
                <a
                  target="_blank"
                  href="https://twitter.com/manashakti?lang=en"
                >
                  <i class="fab fa-twitter text-white m-2"></i>
                </a>
                <a
                  target="_blank"
                  href="https://in.linkedin.com/in/manashakti-research-centre-a4230290"
                >
                  <i class="fab fa-linkedin-in text-white m-2"></i>
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UC2LM6zmd2jA1Mw-E18P0coA"
                >
                  <i class="fab fa-youtube text-white m-2"></i>
                </a>
              </li>

              <li
                style={{ cursor: "pointer" }}
                className="nav-item mr-2 nav-link btn btn--outline-light btn-sm py-05 pl-2 pr-2"
                onClick={this.handleSetLanguageNew(this.key)}
              >
                {t("home.language")}
              </li>
              <li className="nav-item">
                {/* <a className=" btn text-light py-05 pl-2 pr-2" href="#"><small>{t('home.contact_us')}</small></a> */}
                <Link
                  className="btn text-light py-05 pl-2 pr-2"
                  to="/contact-us"
                >
                  <small style={{ fontSize: "100%" }}>
                    {t("home.contact_us")}
                  </small>
                </Link>
              </li>
            </ul>
          </div>

          <nav className="navbar navbar-expand-lg navbar-light border-bottom  main-nav d-xl-flex d-lg-flex  d-none">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <Link className="navbar-brand" to="/">
              <img src={logo} id="logoEnglish" />
              <img src={logomr} id="logoMarathi" />
            </Link>
            <div className="mobile-search-cart d-lg-none d-md-block">
              <li
                className="nav-item search"
                style={{ margin: "9px 13px 0px 18px" }}
              >
                <Link
                  to="/site-search"
                  style={{ cursor: "pointer", color: "black" }}
                >
                  <i className="fa fa-search"></i>
                </Link>
              </li>

              <li
                id="myCartButton"
                className="nav-item"
                style={{
                  margin: "9px 13px 0px 18px",
                  color: "#9d0b0e !important",
                  cursor: "pointer",
                  display: "inline-block !important",
                }}
              >
                <span
                  id="lblCartCount"
                  className="lblCartCount"
                  style={{
                    fontSize: "12px",
                    background: "rgb(255, 0, 0)",
                    border: "1px rgb(255, 0, 0) solid",
                    color: "rgb(255, 255, 255)",
                    padding: "0 6px",
                    verticleAlign: "top",
                    position: "absolute",
                  }}
                >
                  0
                </span>

                <Link to="/mycart">
                  <i
                    style={{ color: "red" }}
                    className="fas fa-shopping-cart"
                  ></i>
                </Link>
              </li>
            </div>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav " style={{ paddingInlineStart: "0px" }}>
                <div id="scroll_view">
                  {/* this is only mobile scroll  */}
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link  color--dark-brown"
                      to="/about-us/6"
                      onMouseEnter={() => this.onMouseEnterAboutusDiv()}
                    >
                      {t("home.ABOUT US")}{" "}
                      <i
                        className="fa fa-angle-down float-right mt-2 pr-3 mr-4"
                        id="mobile_dropdown"
                      ></i>
                      <span className="dropdown-toggle"></span>
                    </Link>
                    <div
                      className="dropdown-menu headerDropDownMenu aboutusMenuLinks"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <Link
                        className="dropdown-item"
                        to="/about-us/1"
                        onClick={() => this.closeAboutusDiv()}
                      >
                        {t("home.Vission & Mission")}
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/about-us/2"
                        onClick={() => this.closeAboutusDiv()}
                      >
                        {t("home.First Thinker")}
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/about-us/8"
                        onClick={() => this.closeAboutusDiv()}
                      >
                        {t("home.New Way Philosopy")}
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/about-us/3"
                        onClick={() => this.closeAboutusDiv()}
                      >
                        {t("home.Values")}
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/about-us/4"
                        onClick={() => this.closeAboutusDiv()}
                      >
                        {t("home.Work Areas")}
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/about-us/5"
                        onClick={() => this.closeAboutusDiv()}
                      >
                        {t("home.Comments Of World Thinkers")}
                      </Link>
                      {/* <a className="dropdown-item" href="#">{t('home.Vission - Mission')}</a> */}
                      {/* <a className="dropdown-item" href="#">{t('home.First Thinker')}</a>
                        <a className="dropdown-item" href="#">{t('home.Research Centre')}</a>
                        <a className="dropdown-item" href="#">{t('home.World Thinkers')}</a> */}
                    </div>
                  </li>

                  <hr className="horizontal_line"></hr>

                  <li className="nav-item dropdown mobile">
                    <a
                      className="nav-link dropdown-toggle color--dark-brown cursorDefault"
                      href="#"
                      id="navbarDropdownMenuLink"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="false"
                      aria-expanded="false"
                    >
                      {t("home.TOPICS")}{" "}
                      <i
                        className="fa fa-angle-down float-right mt-2 pr-3 mr-4"
                        id="mobile_dropdown"
                      ></i>
                    </a>

                    <div
                      className="dropdown-menu headerDropDownMenus "
                      id="accordionExample"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <div>
                        <Link
                          className="dropdown-item    orangeColor"
                          to="/topics/Newly Wed Couples/1"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          {t("home.Newly Wed Couples")}
                          <i
                            className="fa fa-angle-down float-right mt-2 pr-3 mr-4"
                            id="mobile_dropdown"
                          ></i>
                        </Link>
                        <div
                          id="collapseOne"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <p style={{ fontSize: "13px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Newly Wed Couples/1/1"
                            >
                              {" "}
                              {t("home.Happy Marriage")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Newly Wed Couples/1/2"
                            >
                              {t("home.Super Procreation (Suprajanan)")}
                            </Link>
                          </p>

                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Newly Wed Couples/1/3"
                            >
                              {" "}
                              {t("home.Regarding Infertility")}
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div>
                        <Link
                          className="dropdown-item  orangeColor "
                          to="/topics/Garbha Sanskar (Prenatal Education)/2"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          {t("home.Garbha Sanskar")}
                          <br />
                          {t("home.(Prenatal Education)")}
                          <i
                            className="fa fa-angle-down float-right mt-2 pr-3 mr-4"
                            id="mobile_dropdown"
                          ></i>
                        </Link>
                        <div
                          id="collapseTwo"
                          className="collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <p style={{ fontSize: "13px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Garbha Sanskar (Prenatal Education)/2/4"
                            >
                              {" "}
                              {t("home.Garbha Sanskar")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Garbha Sanskar (Prenatal Education)/2/5"
                            >
                              {" "}
                              {t("home.Music for Unborn")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Garbha Sanskar (Prenatal Education)/2/6"
                            >
                              {" "}
                              {t("home.Yoga during Pregnancy")}
                            </Link>
                          </p>
                        </div>
                      </div>
                      <div>
                        <Link
                          className="dropdown-item  orangeColor "
                          to="/topics/Nurturing Kids-Birth up to 7 years/3"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          {t("home.Nurturing Kids")}
                          <br />
                          {t("home.Birth up to 7 years")}
                          <i
                            className="fa fa-angle-down float-right mt-2 pr-3 mr-4"
                            id="mobile_dropdown"
                          ></i>
                        </Link>
                        <div
                          id="collapseThree"
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <p style={{ fontSize: "13px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Nurturing Kids-Birth up to 7 years/3/7"
                            >
                              {" "}
                              {t("home.For Kids")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Nurturing Kids-Birth up to 7 years/3/8"
                            >
                              {" "}
                              {t("home.For Parents")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Nurturing Kids-Birth up to 7 years/3/9"
                            >
                              {" "}
                              {t("home.For Mentally Challenged")}
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div>
                        <Link
                          className="dropdown-item  orangeColor"
                          to="/topics/Child Development-8 to 14 years/4"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          {t("home.Child Development")}
                          <br />
                          {t("home.8 to 14 years")}
                          <i
                            className="fa fa-angle-down float-right mt-2 pr-3 mr-4"
                            id="mobile_dropdown"
                          ></i>
                        </Link>
                        <div
                          id="collapseFour"
                          aria-labelledby="headingFour"
                          data-parent="#accordionExample"
                        >
                          <p style={{ fontSize: "13px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Child Development-8 to 14 years/4/10"
                            >
                              {" "}
                              {t("home.For Children")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Child Development-8 to 14 years/4/11"
                            >
                              {" "}
                              {t("home.For Parents")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Child Development-8 to 14 years/4/12"
                            >
                              {" "}
                              {t("home.For Teachers")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Child Development-8 to 14 years/4/12"
                            >
                              {" "}
                              {t("home.For Mentally Challenged")}
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div>
                        <Link
                          className="dropdown-item  orangeColor"
                          to="/topics/Youth Power-15 to 28 years/5"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          {t("home.Youth Power:")}
                          <br />
                          {t("home.15 to 28 years")}
                          <i
                            className="fa fa-angle-down float-right mt-2 pr-3 mr-4"
                            id="mobile_dropdown"
                          ></i>
                        </Link>
                        <div
                          id="collapseFive"
                          aria-labelledby="headingFive"
                          data-parent="#accordionExample"
                        >
                          <p style={{ fontSize: "13px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Youth Power-15 to 28 years/5/14"
                            >
                              {" "}
                              {t("home.For Teenagers: 15 to 21 years")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Youth Power-15 to 28 years/5/15"
                            >
                              {" "}
                              {t("home.For Parents of Teenagers")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Youth Power-15 to 28 years/5/16"
                            >
                              {" "}
                              {t("home.For Youths: 22 to 28 years")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Youth Power-15 to 28 years/5/17"
                            >
                              {" "}
                              {t("home.Pre-Marriage Adjustment")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                            className="mb-4"
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Youth Power-15 to 28 years/5/40"
                            >
                              {" "}
                              {t("home.Post-Marriage Adjustment")}
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div>
                        <Link
                          className="dropdown-item  orangeColor"
                          to="/topics/Stress Management/6"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          {t("home.Stress Management")}
                          <i
                            className="fa fa-angle-down float-right mt-2 pr-3 mr-4"
                            id="mobile_dropdown"
                          ></i>
                        </Link>
                        <div
                          id="collapseSix"
                          aria-labelledby="headingSix"
                          data-parent="#accordionExample"
                        >
                          <p style={{ fontSize: "13px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Managing Stress/6/18"
                            >
                              {" "}
                              {t("home.Managing Stress")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Managing Stress/6/19"
                            >
                              {" "}
                              {t("home.Relief from Jealousy")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Managing Stress/6/20"
                            >
                              {t("home.De-Addiction,")}
                              {/* {t('home.Success in Occupation')} */}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Managing Stress/6/39"
                            >
                              {t("home.Success in Occupation")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Managing Stress/6/21"
                            >
                              {" "}
                              {t("home.Music Therapy")}
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div>
                        <Link
                          className="dropdown-item  orangeColor"
                          to="/topics/Health Care/7"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          {t("home.Health Care")}{" "}
                          <i
                            className="fa fa-angle-down float-right mt-2 pr-3 mr-4"
                            id="mobile_dropdown"
                          ></i>
                        </Link>
                        <div
                          id="collapseSeven"
                          aria-labelledby="headingSeven"
                          data-parent="#accordionExample"
                        >
                          <p style={{ fontSize: "13px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Health Care/7/22"
                            >
                              {" "}
                              {t("home.Cure without Medicine")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Health Care/7/23"
                            >
                              {" "}
                              {t("home.For Senior Citizens")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Health Care/7/24"
                            >
                              {t("home.Plant Therapy")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Health Care/7/25"
                            >
                              {" "}
                              {t("home.Ayurvedic Products Header")}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div>
                        <Link
                          className="dropdown-item  orangeColor"
                          to="/topics/Meditation & Yoga/8"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseEight"
                          aria-expanded="false"
                          aria-controls="collapseEight"
                        >
                          {t("home.Meditation & Yoga")}{" "}
                          <i
                            className="fa fa-angle-down float-right mt-2 pr-3 mr-4"
                            id="mobile_dropdown"
                          ></i>
                        </Link>
                        <div
                          id="collapseEight"
                          aria-labelledby="headingEight"
                          data-parent="#accordionExample"
                        >
                          <p style={{ fontSize: "13px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Meditation & Yoga/8/26"
                            >
                              {" "}
                              {t("home.Meditation")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Meditation & Yoga/8/27"
                            >
                              {" "}
                              {t("home.Yoga")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Meditation & Yoga/8/28"
                            >
                              {t("home.Scientific Pranayam")}
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div>
                        <Link
                          className="dropdown-item  orangeColor"
                          to="/topics/Science of Spirituality/9"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseNine"
                          aria-expanded="false"
                          aria-controls="collapseNine"
                        >
                          {t("home.Science of Spirituality")}{" "}
                          <i
                            className="fa fa-angle-down float-right mt-2 pr-3 mr-4"
                            id="mobile_dropdown"
                          ></i>
                        </Link>
                        <div
                          id="collapseNine"
                          aria-labelledby="headingNine"
                          data-parent="#accordionExample"
                        >
                          <p style={{ fontSize: "13px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Science of Spirituality/9/29"
                            >
                              {" "}
                              {t("home.Mind")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Science of Spirituality/9/30"
                            >
                              {" "}
                              {t("home.God")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Science of Spirituality/9/31"
                            >
                              {t("home.Mantra")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Science of Spirituality/9/32"
                            >
                              {" "}
                              {t("home.Religion")}
                            </Link>
                          </p>

                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Science of Spirituality/9/33"
                            >
                              {" "}
                              {t("home.Fortune")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Science of Spirituality/9/34"
                            >
                              {" "}
                              {t("home.Prayer")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Science of Spirituality/9/35"
                            >
                              {" "}
                              {t("home.Yadnya")}
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div>
                        <Link
                          className="dropdown-item  orangeColor"
                          to="/topics/Life after Death/10"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTen"
                          aria-expanded="false"
                          aria-controls="collapseTen"
                        >
                          {t("home.Life after Death")}{" "}
                          <i
                            className="fa fa-angle-down float-right mt-2 pr-3 mr-4"
                            id="mobile_dropdown"
                          ></i>
                        </Link>
                        <div
                          id="collapseTen"
                          aria-labelledby="headingTen"
                          data-parent="#accordionExample"
                        >
                          <p style={{ fontSize: "13px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Life after Death/10/36"
                            >
                              {" "}
                              {t("home.Rebirth/Reincarnation")}
                            </Link>
                          </p>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "-20px",
                              lineHeight: 2.5,
                            }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Life after Death/10/37"
                            >
                              {" "}
                              {t("home.Shraddha")}
                            </Link>
                          </p>
                          {/* <p style={{ fontSize: '13px', marginTop: '-20px', lineHeight: 2.5 }}>
                                                        <Link className="dropdown-item orangeColor" to="/topics/Life after Death/10/38">
                                                            {t('home.Skin Donation')}
                                                        </Link>
                                                    </p> */}
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item dropdown desktop ">
                    <a
                      className="nav-link dropdown-toggle color--dark-brown cursorDefault"
                      href="#"
                      id="navbarDropdownMenuLink"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      lnk="1"
                      onMouseEnter={() => this.onMouseEnterTopicDiv()}
                    >
                      {t("home.TOPICS")}
                    </a>
                    {/*                                    
                                        <Link className="nav-link  orangeColor" id="navbarDropdownMenuLink"      to="/topics">
                                            {t('home.TOPICS')}
                                        </Link> */}

                    <div
                      className="dropdown-menu headerDropDownMenu topicDesktop2"
                      aria-labelledby="navbarDropdownMenuLink"
                      style={{
                        width: "1000px",
                        height: "405px",
                        marginLeft: "-100px",
                      }}
                    >
                      <div style={{ position: "absolute" }}>
                        <Link
                          className="dropdown-item  orangeColor dropdown-item-topic-item"
                          to="/topics/Newly Wed Couples/1"
                          // onClick={() => }
                          onClick={() => {
                            this.closeNav();
                            this.closeTopicDiv();
                          }}
                        >
                          {t("home.Suprajanan")} <br />
                          {t("home.(Newly Wed Couples)")}
                        </Link>
                        <div
                          className="topicDesktop123"
                          style={{ display: "absolute" }}
                        >
                          <p style={{ fontSize: "13px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Newly Wed Couples/1/1"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Happy Marriage")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Newly Wed Couples/1/2"
                              style={{ paddingRight: "5px" }}
                              onClick={() => this.closeTopicDiv()}
                            >
                              {t("home.Super Procreation (Suprajanan)")}
                            </Link>
                          </p>

                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Newly Wed Couples/1/3"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Regarding Infertility")}
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div
                        style={{ position: "absolute", marginLeft: "200px" }}
                      >
                        <Link
                          className="dropdown-item  orangeColor dropdown-item-topic-item"
                          to="/topics/Garbha Sanskar (Prenatal Education)/2"
                          onClick={() => this.closeTopicDiv()}
                        >
                          {t("home.Garbha Sanskar")}
                          <br />
                          {t("home.(Prenatal Education)")}
                        </Link>
                        <div
                          className="topicDesktop123"
                          style={{ display: "absolute" }}
                        >
                          <p style={{ fontSize: "13px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Garbha Sanskar (Prenatal Education)/2/4"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Garbha Sanskar")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Garbha Sanskar (Prenatal Education)/2/5"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Music for Unborn")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Garbha Sanskar (Prenatal Education)/2/6"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Yoga during Pregnancy")}
                            </Link>
                          </p>
                        </div>
                      </div>
                      <div
                        style={{ position: "absolute", marginLeft: "390px" }}
                      >
                        <Link
                          className="dropdown-item  orangeColor dropdown-item-topic-item"
                          to="/topics/Nurturing Kids-Birth up to 7 years/3"
                          onClick={() => this.closeTopicDiv()}
                        >
                          {t("home.Nurturing Kids")}
                          <br />
                          {t("home.Birth up to 7 years")}
                        </Link>
                        <div
                          className="topicDesktop123"
                          style={{ display: "absolute" }}
                        >
                          <p style={{ fontSize: "13px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Nurturing Kids-Birth up to 7 years/3/7"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.For Kids")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Nurturing Kids-Birth up to 7 years/3/8"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.For Parents")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Nurturing Kids-Birth up to 7 years/3/9"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.For Mentally Challenged")}
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div
                        style={{ position: "absolute", marginLeft: "590px" }}
                      >
                        <Link
                          className="dropdown-item  orangeColor dropdown-item-topic-item"
                          to="/topics/Child Development-8 to 14 years/4"
                          onClick={() => this.closeTopicDiv()}
                        >
                          {t("home.Child Development")}
                          <br />
                          {t("home.8 to 14 years")}
                        </Link>
                        <div
                          className="topicDesktop123"
                          style={{ display: "absolute" }}
                        >
                          <p style={{ fontSize: "13px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Child Development-8 to 14 years/4/10"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.For Children")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Child Development-8 to 14 years/4/11"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.For Parents")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Child Development-8 to 14 years/4/12"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.For Teachers")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Child Development-8 to 14 years/4/12"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.For Mentally Challenged")}
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div
                        style={{ position: "absolute", marginLeft: "790px" }}
                      >
                        <Link
                          className="dropdown-item  orangeColor dropdown-item-topic-item"
                          to="/topics/Youth Power-15 to 28 years/5"
                          onClick={() => this.closeTopicDiv()}
                        >
                          {t("home.Youth Power:")}
                          <br />
                          {t("home.15 to 28 years")}
                        </Link>
                        <div
                          className="topicDesktop123"
                          style={{ display: "absolute" }}
                        >
                          <p style={{ fontSize: "13px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Youth Power-15 to 28 years/5/14"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.For Teenagers: 15 to 21 years")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Youth Power-15 to 28 years/5/15"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.For Parents of Teenagers")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Youth Power-15 to 28 years/5/16"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.For Youths: 22 to 28 years")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Youth Power-15 to 28 years/5/17"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Pre-Marriage Adjustment")}
                            </Link>
                          </p>
                          <p
                            className="mb-4"
                            style={{ fontSize: "13px", marginTop: "-20px" }}
                          >
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Youth Power-15 to 28 years/5/40"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Post-Marriage Adjustment")}
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div style={{ position: "absolute", marginTop: "185px" }}>
                        <Link
                          className="dropdown-item  orangeColor dropdown-item-topic-item"
                          to="/topics/Stress Management/6"
                          onClick={() => this.closeTopicDiv()}
                        >
                          {t("home.Stress Management")}
                        </Link>
                        <div
                          className="topicDesktop123"
                          style={{ display: "absolute" }}
                        >
                          <p style={{ fontSize: "13px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Managing Stress/6/18"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Managing Stress")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Managing Stress/6/19"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Relief from Jealousy")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Managing Stress/6/20"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {t("home.De-Addiction,")}
                              {/* {t('home.Success in Occupation')} */}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Managing Stress/6/39"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {t("home.Success in Occupation")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Managing Stress/6/21"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Music Therapy")}
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div
                        style={{
                          position: "absolute",
                          marginTop: "185px",
                          marginLeft: "200px",
                        }}
                      >
                        <Link
                          className="dropdown-item  orangeColor dropdown-item-topic-item"
                          to="/topics/Health Care/7"
                          onClick={() => this.closeTopicDiv()}
                        >
                          {t("home.Health Care")}
                        </Link>
                        <div
                          className="topicDesktop123"
                          style={{ display: "absolute" }}
                        >
                          <p style={{ fontSize: "13px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Health Care/7/22"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Cure without Medicine")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Health Care/7/23"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.For Senior Citizens")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Health Care/7/24"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {t("home.Plant Therapy")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Health Care/7/25"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Ayurvedic Products Header")}
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div
                        style={{
                          position: "absolute",
                          marginTop: "185px",
                          marginLeft: "390px",
                        }}
                      >
                        <Link
                          className="dropdown-item  orangeColor dropdown-item-topic-item"
                          to="/topics/Meditation & Yoga/8"
                          onClick={() => this.closeTopicDiv()}
                        >
                          {t("home.Meditation & Yoga")}
                        </Link>
                        <div
                          className="topicDesktop123"
                          style={{ display: "absolute" }}
                        >
                          <p style={{ fontSize: "13px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Meditation & Yoga/8/26"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Meditation")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Meditation & Yoga/8/27"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Yoga")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Meditation & Yoga/8/28"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {t("home.Scientific Pranayam")}
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div
                        style={{
                          position: "absolute",
                          marginTop: "185px",
                          marginLeft: "590px",
                        }}
                      >
                        <Link
                          className="dropdown-item  orangeColor dropdown-item-topic-item"
                          to="/topics/Science of Spirituality/9"
                          onClick={() => this.closeTopicDiv()}
                        >
                          {t("home.Science of Spirituality")}
                        </Link>
                        <div
                          className="topicDesktop123"
                          style={{ display: "absolute" }}
                        >
                          <p style={{ fontSize: "13px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Science of Spirituality/9/29"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Mind")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Science of Spirituality/9/30"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.God")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Science of Spirituality/9/31"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {t("home.Mantra")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Science of Spirituality/9/32"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Religion")}
                            </Link>
                          </p>

                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Science of Spirituality/9/33"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Fortune")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Science of Spirituality/9/34"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Prayer")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Science of Spirituality/9/35"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Yadnya")}
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div
                        style={{
                          position: "absolute",
                          marginTop: "185px",
                          marginLeft: "790px",
                        }}
                      >
                        <Link
                          className="dropdown-item  orangeColor dropdown-item-topic-item"
                          to="/topics/Life after Death/10"
                          onClick={() => this.closeTopicDiv()}
                        >
                          {t("home.Life after Death")}
                        </Link>
                        <div
                          className="topicDesktop123"
                          style={{ display: "absolute" }}
                        >
                          <p style={{ fontSize: "13px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Life after Death/10/36"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Rebirth/Reincarnation")}
                            </Link>
                          </p>
                          <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                            <Link
                              className="dropdown-item orangeColor"
                              to="/topics/Life after Death/10/37"
                              onClick={() => this.closeTopicDiv()}
                            >
                              {" "}
                              {t("home.Shraddha")}
                            </Link>
                          </p>
                          {/* <p style={{ fontSize: '13px', marginTop: '-20px' }}>
                                                        <Link className="dropdown-item orangeColor" to="/topics/Life after Death/10/38">
                                                            {t('home.Skin Donation')}
                                                        </Link>
                                                    </p> */}
                        </div>
                      </div>
                    </div>
                  </li>

                  {/* Main topic li close */}

                  <hr className="horizontal_line"></hr>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link  color--dark-brown"
                      id="navbarDropdownMenuLink"
                      to="/booknow/Residential%20Study%20Courses/1"
                      onMouseEnter={() => this.onMouseEnterEventsDiv()}
                    >
                      {t("home.EVENTS")}{" "}
                      <i
                        className="fa fa-angle-down float-right mt-2 pr-3 mr-4"
                        id="mobile_dropdown"
                      ></i>
                      <span className="dropdown-toggle"></span>
                    </Link>

                    {/*<a
                      className="nav-link dropdown-toggle color--dark-brown cursorDefault"
                      // href="#"
                      href={routes.events}
                      id="navbarDropdownMenuLink"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onMouseEnter={() => this.onMouseEnterEventsDiv()}
                    >
                      {t("home.EVENTS")}{" "}
                      <i
                        className="fa fa-angle-down float-right mt-2 pr-3 mr-4"
                        id="mobile_dropdown"
                      ></i>
                    </a>*/}
                    <div
                      className="dropdown-menu headerDropDownMenu eventsMenuLinks"
                      aria-labelledby="navbarDropdownMenuLink"
                      style={{ minWidth: "259px" }}
                    >
                      <a
                        className="dropdown-item"
                        href="https://onlineselftests.manashakti.org/"
                        target="_blank"
                        onClick={() => this.closeEventsDiv()}
                      >
                        {t("home.Online Tests")}
                      </a>
                      <a
                        className="dropdown-item"
                        href="https://onlinecourses.manashakti.org/"
                        target="_blank"
                        onClick={() => this.closeEventsDiv()}
                      >
                        {t("home.Online Courses")}
                      </a>
                      {this.state.eventList.map((event) => (
                        <a
                          className="dropdown-item"
                          href={event.event_url}
                          onClick={this.onMouseLeave.bind(
                            this,
                            event.event_type_id
                          )}
                          onMouseLeave={this.onMouseLeave.bind(
                            this,
                            event.event_type_id
                          )}
                          onMouseEnter={this.onMouseEnter.bind(
                            this,
                            event.event_type_id
                          )}
                        >
                          {!localStorage.getItem("key") ||
                          localStorage.getItem("key") !== "en"
                            ? event.event_type_en
                            : event.event_type_mr}
                          <div
                            onMouseLeave={this.onMouseLeave.bind(
                              this,
                              event.event_type_id
                            )}
                            onClick={this.onMouseLeave.bind(
                              this,
                              event.event_type_id
                            )}
                            onMouseEnter={this.onMouseEnter.bind(
                              this,
                              event.event_type_id
                            )}
                            id={event.event_type_id}
                            className="dropdown-menu-second-lv"
                            aria-labelledby="navbarDropdownMenuLink"
                            style={{ minWidth: "300px" }}
                          >
                            {event.arr_topics.map((topic, index) => (
                              <a
                                className="dropdown-item"
                                href={topic.topic_url}
                                onClick={this.onMouseLeave.bind(
                                  this,
                                  event.event_type_id + "" + topic.topic_id
                                )}
                                onMouseLeave={this.onMouseLeave.bind(
                                  this,
                                  event.event_type_id + "" + topic.topic_id
                                )}
                                onMouseEnter={this.onMouseEnter.bind(
                                  this,
                                  event.event_type_id + "" + topic.topic_id
                                )}
                              >
                                {!localStorage.getItem("key") ||
                                localStorage.getItem("key") !== "en"
                                  ? topic.topic_title_en
                                  : topic.topic_title_mr}
                                {topic.arr_courses.length ? (
                                  <div
                                    onMouseLeave={this.onMouseLeave.bind(
                                      this,
                                      event.event_type_id + "" + topic.topic_id
                                    )}
                                    onClick={this.onMouseLeave.bind(
                                      this,
                                      event.event_type_id + "" + topic.topic_id
                                    )}
                                    onMouseEnter={this.onMouseEnter.bind(
                                      this,
                                      event.event_type_id + "" + topic.topic_id
                                    )}
                                    id={
                                      event.event_type_id + "" + topic.topic_id
                                    }
                                    className="dropdown-menu-third-lv"
                                    aria-labelledby="navbarDropdownMenuLink"
                                    style={{
                                      minWidth: "250px",
                                      top:
                                        topic.arr_courses.length < 8
                                          ? index * 2 + "rem"
                                          : 0 + "rem",
                                    }}
                                  >
                                    <ul style={{ listStyle: "none" }}>
                                      <li>
                                        {topic.arr_courses.map((courses) => (
                                          <Link
                                            className="dropdown-item"
                                            to={
                                              "/event_details/" +
                                              courses.event_id
                                            }
                                          >
                                            {!localStorage.getItem("key") ||
                                            localStorage.getItem("key") !== "en"
                                              ? courses.title_en
                                              : courses.title_mr}
                                          </Link>
                                        ))}
                                      </li>
                                    </ul>
                                  </div>
                                ) : null}
                              </a>
                            ))}
                          </div>
                        </a>
                      ))}
                      <Link
                        className="dropdown-item"
                        to="/event_details/83"
                        onClick={() => this.closeEventsDiv()}
                      >
                        {t("home.Psycho-Feedback Therapy")}
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/organize-a-trip/7"
                        // onClick={}
                        onMouseLeave={this.onMouseLeave.bind(
                          this,
                          "organizeDiv"
                        )}
                        onMouseEnter={this.onMouseEnter.bind(
                          this,
                          "organizeDiv"
                        )}
                        onClick={() => {
                          this.onMouseLeave.bind(this, "organizeDiv");
                          this.closeEventsDiv();
                        }}
                      >
                        {t("home.Organize a Trip")}
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/cycle-of-good-deeds"
                        // onClick={}
                        onMouseLeave={this.onMouseLeave.bind(
                          this,
                          "organizeDiv"
                        )}
                        onMouseEnter={this.onMouseEnter.bind(
                          this,
                          "organizeDiv"
                        )}
                        onClick={() => {
                          this.onMouseLeave.bind(this, "organizeDiv");
                          this.closeEventsDiv();
                        }}
                      >
                        {t("home.Cycle of")}
                        {t("home.Good Deeds")}
                      </Link>
                      {/* <Link className="dropdown-item" to="/events/Residential Study Courses/1" onClick={this.onMouseLeave.bind(this, 'residentialsDiv')} onMouseLeave={this.onMouseLeave.bind(this, 'residentialsDiv')} onMouseEnter={this.onMouseEnter.bind(this, 'residentialsDiv')}>
                                                {t('home.Residential Study Courses')}
                                                <div onMouseLeave={this.onMouseLeave.bind(this, 'residentialsDiv')} onClick={this.onMouseLeave.bind(this, 'residentialsDiv')} onMouseEnter={this.onMouseEnter.bind(this, 'residentialsDiv')} id="residentialsDiv" className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    <ul style={{ listStyle: "none" }}>
                                                        <li >
                                                            <Link className="dropdown-item" to="/events/Residential Study Courses/1/1" onClick={this.onMouseLeave.bind(this, 'thirdLevel')} onMouseLeave={this.onMouseLeave.bind(this, 'thirdLevel')} onMouseEnter={this.onMouseEnter.bind(this, 'thirdLevel')}>
                                                                {t('home.Newly Wed Couples')}

                                                                <div onMouseLeave={this.onMouseLeave.bind(this, 'thirdLevel')} onClick={this.onMouseLeave.bind(this, 'thirdLevel')} onMouseEnter={this.onMouseEnter.bind(this, 'thirdLevel')} id="thirdLevel" className="dropdown-menu-third-lv" aria-labelledby="navbarDropdownMenuLink">
                                                                    <ul style={{ listStyle: "none" }}>
                                                                        <li >
                                                                            <Link className="dropdown-item" to="/events/Residential Study Courses/1/1" onClick={this.onMouseLeave.bind(this, 'thirdLevel')} onMouseLeave={this.onMouseLeave.bind(this, 'thirdLevel')} onMouseEnter={this.onMouseEnter.bind(this, 'thirdLevel')}>
                                                                                Ansh
                                                                            </Link>

                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Residential Study Courses/1/2">{t('home.Pre Natal(Garbha Sanskar)')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Residential Study Courses/1/3">{t('home.Nurturing Kids')}<br />
                                                                {t('home.Birth up to 7 years')} </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Residential Study Courses/1/4">{t('home.Child Development & Parenting - Birth 8 to 14 years')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Residential Study Courses/1/5">{t('home.Youth Power')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Residential Study Courses/1/6">{t('home.Stress Management')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Residential Study Courses/1/7">{t('home.Health Care')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Residential Study Courses/1/8">{t('home.Meditation & Yoga')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Residential Study Courses/1/9">{t('home.Science of Spirituality')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Residential Study Courses/1/10">{t('home.Life after Death')}  </Link>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </Link>

                                            <Link className="dropdown-item" to="/events/1-Day Workshops/2" onClick={this.onMouseLeave.bind(this, 'workshopsDiv')} onMouseLeave={this.onMouseLeave.bind(this, 'workshopsDiv')} onMouseEnter={this.onMouseEnter.bind(this, 'workshopsDiv')} >{t('home.1-Day Workshops')}

                                                <div onMouseLeave={this.onMouseLeave.bind(this, 'workshopsDiv')} onClick={this.onMouseLeave.bind(this, 'workshopsDiv')} onMouseEnter={this.onMouseEnter.bind(this, 'workshopsDiv')} id="workshopsDiv" className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    <ul style={{ listStyle: "none" }}>
                                                        <li >
                                                            <Link className="dropdown-item" to="/events/1-Day Workshops/2/1">{t('home.Newly Wed Couples')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/1-Day Workshops/2/2">{t('home.Pre Natal(Garbha Sanskar)')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/1-Day Workshops/2/3">{t('home.Nurturing Kids')}<br />
                                                                {t('home.Birth up to 7 years')} </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/1-Day Workshops/2/4">{t('home.Child Development & Parenting - Birth 8 to 14 years')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/1-Day Workshops/2/5">{t('home.Youth Power')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/1-Day Workshops/2/6">{t('home.Stress Management')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/1-Day Workshops/2/7">{t('home.Health Care')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/1-Day Workshops/2/8">{t('home.Meditation & Yoga')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/1-Day Workshops/2/9">{t('home.Science of Spirituality')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/1-Day Workshops/2/10">{t('home.Life after Death')}  </Link>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </Link>

                                            <Link className="dropdown-item" to="/machine-test/Machine Tests/3" onClick={this.onMouseLeave.bind(this, 'machineDiv')} onMouseLeave={this.onMouseLeave.bind(this, 'machineDiv')} onMouseEnter={this.onMouseEnter.bind(this, 'machineDiv')}>{t('home.Machine Tests')}

                                                <div onMouseLeave={this.onMouseLeave.bind(this, 'machineDiv')} onClick={this.onMouseLeave.bind(this, 'machineDiv')} onMouseEnter={this.onMouseEnter.bind(this, 'machineDiv')} id="machineDiv" className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    <ul style={{ listStyle: "none" }}>
                                                        <li >
                                                            <Link className="dropdown-item" to="/machine-test/Machine Tests/3/1">{t('home.Newly Wed Couples')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/machine-test/Machine Tests/3/2">{t('home.Pre Natal(Garbha Sanskar)')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/machine-test/Machine Tests/3/3">{t('home.Nurturing Kids')}<br />
                                                                {t('home.Birth up to 7 years')} </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/machine-test/Machine Tests/3/4">{t('home.Child Development & Parenting - Birth 8 to 14 years')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/machine-test/Machine Tests/3/5">{t('home.Youth Power')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/machine-test/Machine Tests/3/6">{t('home.Stress Management')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/machine-test/Machine Tests/3/7">{t('home.Health Care')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/machine-test/Machine Tests/3/8">{t('home.Meditation & Yoga')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/machine-test/Machine Tests/3/9">{t('home.Science of Spirituality')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/machine-test/Machine Tests/3/10">{t('home.Life after Death')}  </Link>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </Link>

                                            <Link className="dropdown-item" to="/event_details/83" >{t('home.Psycho-Feedback Therapy')}</Link>

                                            <Link className="dropdown-item" to="/events/Rational Poojas/4" onClick={this.onMouseLeave.bind(this, 'poojsDiv')} onMouseLeave={this.onMouseLeave.bind(this, 'poojsDiv')} onMouseEnter={this.onMouseEnter.bind(this, 'poojsDiv')}>{t('home.Rational Poojs')}

                                                <div onMouseLeave={this.onMouseLeave.bind(this, 'poojsDiv')} onClick={this.onMouseLeave.bind(this, 'poojsDiv')} onMouseEnter={this.onMouseEnter.bind(this, 'poojsDiv')} id="poojsDiv" className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    <ul style={{ listStyle: "none" }}>
                                                        <li >
                                                            <Link className="dropdown-item" to="/events/Rational Poojas/4/1">{t('home.Newly Wed Couples')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Rational Poojas/4/2">{t('home.Pre Natal(Garbha Sanskar)')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Rational Poojas/4/3">{t('home.Nurturing Kids')}<br />
                                                                {t('home.Birth up to 7 years')} </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Rational Poojas/4/4">{t('home.Child Development & Parenting - Birth 8 to 14 years')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Rational Poojas/4/5">{t('home.Youth Power')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Rational Poojas/4/6">{t('home.Stress Management')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Rational Poojas/4/7">{t('home.Health Care')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Rational Poojas/4/8">{t('home.Meditation & Yoga')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Rational Poojas/4/9">{t('home.Science of Spirituality')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Rational Poojas/4/10">{t('home.Life after Death')}  </Link>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </Link>

                                            <Link className="dropdown-item" to="/events/Free Events/5" onClick={this.onMouseLeave.bind(this, 'freeeventDiv')} onMouseLeave={this.onMouseLeave.bind(this, 'freeeventDiv')} onMouseEnter={this.onMouseEnter.bind(this, 'freeeventDiv')}>{t('home.Free Events')}

                                                <div onMouseLeave={this.onMouseLeave.bind(this, 'freeeventDiv')} onClick={this.onMouseLeave.bind(this, 'freeeventDiv')} onMouseEnter={this.onMouseEnter.bind(this, 'freeeventDiv')} id="freeeventDiv" className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    <ul style={{ listStyle: "none" }}>
                                                        <li >
                                                            <Link className="dropdown-item" to="/events/Free Events/5/1">{t('home.Newly Wed Couples')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Free Events/5/2">{t('home.Pre Natal(Garbha Sanskar)')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Free Events/5/3">{t('home.Nurturing Kids')}<br />
                                                                {t('home.Birth up to 7 years')} </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Free Events/5/4">{t('home.Child Development & Parenting - Birth 8 to 14 years')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Free Events/5/5">{t('home.Youth Power')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Free Events/5/6">{t('home.Stress Management')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Free Events/5/7">{t('home.Health Care')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Free Events/5/8">{t('home.Meditation & Yoga')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Free Events/5/9">{t('home.Science of Spirituality')}  </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/events/Free Events/5/10">{t('home.Life after Death')}  </Link>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </Link>

                                            <Link className="dropdown-item" to="/organize-a-trip/7" onClick={this.onMouseLeave.bind(this, 'organizeDiv')} onMouseLeave={this.onMouseLeave.bind(this, 'organizeDiv')} onMouseEnter={this.onMouseEnter.bind(this, 'organizeDiv')}>{t('home.Organize a Trip')}
                                            </Link> */}
                    </div>
                  </li>

                  <hr className="horizontal_line"></hr>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link  color--dark-brown"
                      id="navbarDropdownMenuLink"
                      to="/products"
                      onMouseEnter={() => this.onMouseProductsDiv()}
                    >
                      {t("home.PRODUCTS")}{" "}
                      <i
                        className="fa fa-angle-down float-right mt-2 pr-3 mr-4"
                        id="mobile_dropdown"
                      ></i>
                      <span className="dropdown-toggle"></span>
                    </Link>
                    <div
                      className="dropdown-menu headerDropDownMenu headerDropDownMenusForProduct productsMenuLinks"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <Link
                        className="dropdown-item"
                        to="/product_type/books/1"
                        // onClick={}
                        onMouseLeave={this.onMouseLeave.bind(this, "booksDiv")}
                        onMouseEnter={this.onMouseEnter.bind(this, "booksDiv")}
                        onClick={() => {
                          this.onMouseLeave.bind(this, "booksDiv");
                          this.closeProductsDiv();
                        }}
                      >
                        {t("home.Books")}

                        <div
                          onMouseLeave={this.onMouseLeave.bind(
                            this,
                            "booksDiv"
                          )}
                          onClick={this.onMouseLeave.bind(this, "booksDiv")}
                          onMouseEnter={this.onMouseEnter.bind(
                            this,
                            "booksDiv"
                          )}
                          id="booksDiv"
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdownMenuLink"
                        >
                          <ul style={{ listStyle: "none" }}>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/books/1/1"
                              >
                                {t("home.Newly Wed Couples")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/books/1/2"
                              >
                                {t("home.Pre Natal(Garbha Sanskar)")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/books/1/3"
                              >
                                {t("home.Nurturing Kids")}
                                {t("home.Birth up to 7 years")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/books/1/4"
                              >
                                {t(
                                  "home.Child Development & Parenting - Birth 8 to 14 years"
                                )}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/books/1/5"
                              >
                                {t("home.Youth Power")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/books/1/6"
                              >
                                {t("home.Stress Management")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/books/1/7"
                              >
                                {t("home.Health Care")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/books/1/8"
                              >
                                {t("home.Meditation & Yoga")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/books/1/9"
                              >
                                {t("home.Science of Spirituality")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/books/1/10"
                              >
                                {t("home.Life after Death")}{" "}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/product_type/kits/2"
                        // onClick={}
                        onMouseLeave={this.onMouseLeave.bind(this, "kitsDiv")}
                        onMouseEnter={this.onMouseEnter.bind(this, "kitsDiv")}
                        onClick={() => {
                          this.onMouseLeave.bind(this, "kitsDiv");
                          this.closeProductsDiv();
                        }}
                      >
                        {t("home.Kits")}
                        <div
                          onMouseLeave={this.onMouseLeave.bind(this, "kitsDiv")}
                          onClick={this.onMouseLeave.bind(this, "kitsDiv")}
                          onMouseEnter={this.onMouseEnter.bind(this, "kitsDiv")}
                          id="kitsDiv"
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdownMenuLink"
                        >
                          <ul style={{ listStyle: "none" }}>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/kits/2/1"
                              >
                                {t("home.Newly Wed Couples")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/kits/2/2"
                              >
                                {t("home.Pre Natal(Garbha Sanskar)")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/kits/2/3"
                              >
                                {t("home.Nurturing Kids")}
                                {t("home.Birth up to 7 years")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/kits/2/4"
                              >
                                {t(
                                  "home.Child Development & Parenting - Birth 8 to 14 years"
                                )}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/kits/2/5"
                              >
                                {t("home.Youth Power")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/kits/2/6"
                              >
                                {t("home.Stress Management")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/kits/2/7"
                              >
                                {t("home.Health Care")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/kits/2/8"
                              >
                                {t("home.Meditation & Yoga")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/kits/2/9"
                              >
                                {t("home.Science of Spirituality")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/kits/2/10"
                              >
                                {t("home.Life after Death")}{" "}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/product_type/CDs/3"
                        // onClick={}
                        onMouseLeave={this.onMouseLeave.bind(this, "cdsDiv")}
                        onMouseEnter={this.onMouseEnter.bind(this, "cdsDiv")}
                        onClick={() => {
                          this.onMouseLeave.bind(this, "cdsDiv");
                          this.closeProductsDiv();
                        }}
                      >
                        {t("home.CDs")}

                        <div
                          onMouseLeave={this.onMouseLeave.bind(this, "cdsDiv")}
                          onClick={this.onMouseLeave.bind(this, "cdsDiv")}
                          onMouseEnter={this.onMouseEnter.bind(this, "cdsDiv")}
                          id="cdsDiv"
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdownMenuLink"
                        >
                          <ul style={{ listStyle: "none" }}>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/CDs/3/1"
                              >
                                {t("home.Newly Wed Couples")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/CDs/3/2"
                              >
                                {t("home.Pre Natal(Garbha Sanskar)")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/CDs/3/3"
                              >
                                {t("home.Nurturing Kids")}
                                {t("home.Birth up to 7 years")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/CDs/3/4"
                              >
                                {t(
                                  "home.Child Development & Parenting - Birth 8 to 14 years"
                                )}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/CDs/3/5"
                              >
                                {t("home.Youth Power")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/CDs/3/6"
                              >
                                {t("home.Stress Management")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/CDs/3/7"
                              >
                                {t("home.Health Care")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/CDs/3/8"
                              >
                                {t("home.Meditation & Yoga")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/CDs/3/9"
                              >
                                {t("home.Science of Spirituality")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/CDs/3/10"
                              >
                                {t("home.Life after Death")}{" "}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/product_type/experimental/4"
                        // onClick={}
                        onMouseLeave={this.onMouseLeave.bind(
                          this,
                          "experimentalDiv"
                        )}
                        onMouseEnter={this.onMouseEnter.bind(
                          this,
                          "experimentalDiv"
                        )}
                        onClick={() => {
                          this.onMouseLeave.bind(this, "experimentalDiv");
                          this.closeProductsDiv();
                        }}
                      >
                        {t("home.Experimentals Products")}

                        <div
                          onMouseLeave={this.onMouseLeave.bind(
                            this,
                            "experimentalDiv"
                          )}
                          onClick={this.onMouseLeave.bind(
                            this,
                            "experimentalDiv"
                          )}
                          onMouseEnter={this.onMouseEnter.bind(
                            this,
                            "experimentalDiv"
                          )}
                          id="experimentalDiv"
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdownMenuLink"
                        >
                          <ul style={{ listStyle: "none" }}>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/experimental/4/1"
                              >
                                {t("home.Newly Wed Couples")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/experimental/4/2"
                              >
                                {t("home.Pre Natal(Garbha Sanskar)")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/experimental/4/3"
                              >
                                {t("home.Nurturing Kids")}
                                {t("home.Birth up to 7 years")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/experimental/4/4"
                              >
                                {t(
                                  "home.Child Development & Parenting - Birth 8 to 14 years"
                                )}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/experimental/4/5"
                              >
                                {t("home.Youth Power")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/experimental/4/6"
                              >
                                {t("home.Stress Management")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/experimental/4/7"
                              >
                                {t("home.Health Care")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/experimental/4/8"
                              >
                                {t("home.Meditation & Yoga")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/experimental/4/9"
                              >
                                {t("home.Science of Spirituality")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/experimental/4/10"
                              >
                                {t("home.Life after Death")}{" "}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Link>

                      <Link
                        className="dropdown-item"
                        to="/product_type/ayurvedic/5"
                        // onClick={}
                        onMouseLeave={this.onMouseLeave.bind(
                          this,
                          "ayurvedicDiv"
                        )}
                        onMouseEnter={this.onMouseEnter.bind(
                          this,
                          "ayurvedicDiv"
                        )}
                        onClick={() => {
                          this.onMouseLeave.bind(this, "ayurvedicDiv");
                          this.closeProductsDiv();
                        }}
                      >
                        {t("home.Ayurvedic")}

                        <div
                          onMouseLeave={this.onMouseLeave.bind(
                            this,
                            "ayurvedicDiv"
                          )}
                          onClick={this.onMouseLeave.bind(this, "ayurvedicDiv")}
                          onMouseEnter={this.onMouseEnter.bind(
                            this,
                            "ayurvedicDiv"
                          )}
                          id="ayurvedicDiv"
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdownMenuLink"
                        >
                          <ul style={{ listStyle: "none" }}>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/ayurvedic/5/1"
                              >
                                {t("home.Newly Wed Couples")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/ayurvedic/5/2"
                              >
                                {t("home.Pre Natal(Garbha Sanskar)")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/ayurvedic/5/3"
                              >
                                {t("home.Nurturing Kids")}
                                {t("home.Birth up to 7 years")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/ayurvedic/5/4"
                              >
                                {t(
                                  "home.Child Development & Parenting - Birth 8 to 14 years"
                                )}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/ayurvedic/5/5"
                              >
                                {t("home.Youth Power")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/ayurvedic/5/6"
                              >
                                {t("home.Stress Management")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/ayurvedic/5/7"
                              >
                                {t("home.Health Care")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/ayurvedic/5/8"
                              >
                                {t("home.Meditation & Yoga")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/ayurvedic/5/9"
                              >
                                {t("home.Science of Spirituality")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/product_type/ayurvedic/5/10"
                              >
                                {t("home.Life after Death")}{" "}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Link>

                      <Link
                        className="dropdown-item"
                        to="/magazine"
                        onClick={() => this.closeProductsDiv()}
                      >
                        {t("home.Magazine")}
                      </Link>

                      <Link
                        className="dropdown-item"
                        to="/product_type/diwali/6"
                        // onClick={}
                        onMouseLeave={this.onMouseLeave.bind(this, "diwaliDiv")}
                        onMouseEnter={this.onMouseEnter.bind(this, "diwaliDiv")}
                        onClick={() => {
                          this.onMouseLeave.bind(this, "diwaliDiv");
                          this.closeProductsDiv();
                        }}
                      >
                        {t("home.Diwali Issue")}
                        {/* <div onMouseLeave={this.onMouseLeave.bind(this, 'diwaliDiv')} onClick={this.onMouseLeave.bind(this, 'diwaliDiv')} onMouseEnter={this.onMouseEnter.bind(this, 'diwaliDiv')} id="diwaliDiv"  className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                            <ul style={{ listStyle: "none" }}>
                                                <li >
                                                    <Link className="dropdown-item" to="/product_type/diwali/6/1">{t('home.Newly Wed Couples')}  </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/product_type/diwali/6/2">{t('home.Pre Natal(Garbha Sanskar)')}  </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/product_type/diwali/6/3">{t('home.Nurturing Kids')}<br/>
                            {t('home.Birth up to 7 years')} </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/product_type/diwali/6/4">{t('home.Child Development & Parenting - Birth 8 to 14 years')}  </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/product_type/diwali/6/5">{t('home.Youth Power')}  </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/product_type/diwali/6/6">{t('home.Stress Management')}  </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/product_type/diwali/6/7">{t('home.Health Care')}  </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/product_type/diwali/6/8">{t('home.Meditation & Yoga')}  </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/product_type/diwali/6/9">{t('home.Science of Spirituality')}  </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/product_type/diwali/6/10">{t('home.Life after Death')}  </Link>
                                                </li>
                                            </ul>
                                        </div> */}
                      </Link>

                      <Link
                        className="dropdown-item"
                        to="/product_type/bal-diwali/7"
                        // onClick={}
                        onMouseLeave={this.onMouseLeave.bind(
                          this,
                          "baldiwaliDiv"
                        )}
                        onMouseEnter={this.onMouseEnter.bind(
                          this,
                          "baldiwaliDiv"
                        )}
                        onClick={() => {
                          this.onMouseLeave.bind(this, "baldiwaliDiv");
                          this.closeProductsDiv();
                        }}
                      >
                        {t("home.Bal Diwali Issue")}
                        {/* <div onMouseLeave={this.onMouseLeave.bind(this, 'baldiwaliDiv')} onClick={this.onMouseLeave.bind(this, 'baldiwaliDiv')} onMouseEnter={this.onMouseEnter.bind(this, 'baldiwaliDiv')} id="baldiwaliDiv"  className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                            <ul style={{  listStyle: "none" }}>
                                                <li >
                                                    <Link className="dropdown-item" to="/product_type/bal-diwali/7/1">{t('home.Newly Wed Couples')}  </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/product_type/bal-diwali/7/2">{t('home.Pre Natal(Garbha Sanskar)')}  </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/product_type/bal-diwali/7/3">{t('home.Nurturing Kids')}<br/>
                            {t('home.Birth up to 7 years')} </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/product_type/bal-diwali/7/4">{t('home.Child Development & Parenting - Birth 8 to 14 years')}  </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/product_type/bal-diwali/7/5">{t('home.Youth Power')}  </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/product_type/bal-diwali/7/6">{t('home.Stress Management')}  </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/product_type/bal-diwali/7/7">{t('home.Health Care')}  </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/product_type/bal-diwali/7/8">{t('home.Meditation & Yoga')}  </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/product_type/bal-diwali/7/9">{t('home.Science of Spirituality')}  </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/product_type/bal-diwali/7/10">{t('home.Life after Death')}  </Link>
                                                </li>
                                            </ul>
                                        </div> */}
                      </Link>

                      {/* <a onClick={this.gotToProducts.bind(this, 3)}>go to kits</a> */}
                      {/* <Route path='/product_type/4' component={Product_type}/>hi */}
                    </div>
                  </li>

                  <hr className="horizontal_line"></hr>

                  <li className="nav-item dropdown">
                    {/* <a className="nav-link dropdown-toggle orangeColor" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    
                                        </a> */}
                    <Link
                      className="nav-link  color--dark-brown"
                      to="/research"
                    >
                      {t("home.RESEARCH")}
                    </Link>
                  </li>

                  <hr className="horizontal_line"></hr>

                  <li className="nav-item dropdown">
                    {/* <a className="nav-link dropdown-toggle orangeColor" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {t('home.EVENT CALENDAR')}
                                            </a> */}
                    <Link
                      className="nav-link color--dark-brown"
                      to="/event-calendar"
                    >
                      <i
                        className="fa fa-calendar"
                        style={{ color: "#A6ACAF" }}
                      ></i>
                      &nbsp;&nbsp;{t("home.EVENT CALENDAR")}
                    </Link>
                    {/* <div className="dropdown-menu headerDropDownMenu" aria-labelledby="navbarDropdownMenuLink">
                        <a className="dropdown-item" href="#">Action</a>
                        <a className="dropdown-item" href="#">Another action</a>
                        <a className="dropdown-item" href="#">Something else here</a>
                    </div> */}
                  </li>

                  <hr className="horizontal_line"></hr>

                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle color--dark-brown cursorDefault"
                      href="#"
                      id="navbarDropdownMenuLink"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onMouseEnter={() => this.onMouseMoreDiv()}
                    >
                      {t("home.MORE")}{" "}
                      <i
                        className="fa fa-angle-down float-right mt-2 pr-3 mr-4"
                        id="mobile_dropdown"
                      ></i>
                    </a>
                    <div
                      className="dropdown-menu headerDropDownMenu moreHeaderDropDown moreMenuLinks"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      {/* <Link className="dropdown-item"  to="/order-history">{t('home.Order History')}</Link> */}

                      <Link
                        className="dropdown-item"
                        to="/photo-gallery"
                        // onClick={}
                        onMouseLeave={this.onMouseLeave.bind(
                          this,
                          "galleryDiv"
                        )}
                        onMouseEnter={this.onMouseEnter.bind(
                          this,
                          "galleryDiv"
                        )}
                        onClick={() => {
                          this.onMouseLeave.bind(this, "galleryDiv");
                          this.closeMoreDiv();
                        }}
                      >
                        {t("home.Gallery")}
                        <div
                          onMouseLeave={this.onMouseLeave.bind(
                            this,
                            "galleryDiv"
                          )}
                          onClick={this.onMouseLeave.bind(this, "galleryDiv")}
                          onMouseEnter={this.onMouseEnter.bind(
                            this,
                            "galleryDiv"
                          )}
                          id="galleryDiv"
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdownMenuLink"
                        >
                          <ul style={{ listStyle: "none" }}>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/photo-gallery"
                              >
                                {t("home.Photo Gallery")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/video-gallery"
                              >
                                {t("home.Video Gallery")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/audio-gallery"
                              >
                                {t("home.Audio Gallery")}{" "}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/wallpaper-gallery"
                              >
                                {t("home.Wallpaper Gallery")}{" "}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/news/1"
                        onClick={() => this.closeMoreDiv()}
                      >
                        {t("home.News")}
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/download"
                        onClick={() => this.closeMoreDiv()}
                      >
                        {t("home.Downloads")}
                      </Link>
                      {/* <a style={{ cursor: 'pointer' }} className="dropdown-item" data-toggle="modal" data-target="#subscribeModel">{t('home.Newsletter')}</a> */}
                      {/*<a
                        style={{ cursor: "pointer" }}
                        className="dropdown-item"
                        target="_blank"
                        href="https://res.cloudinary.com/https-www-manashakti-org/image/upload/v1609224827/Other/Under_Construction_gndfqt.png"
                      >
                        {t("home.Newsletter")}
                      </a>
                      <a
                        className="dropdown-item"
                        target="_blank"
                        href="https://res.cloudinary.com/https-www-manashakti-org/image/upload/v1609224827/Other/Under_Construction_gndfqt.png"
                      >
                        {t("home.Blog")}
                      </a>*/}
                      <Link
                        className="dropdown-item"
                        to="/faqs"
                        onClick={() => this.closeMoreDiv()}
                      >
                        {t("home.FAQs")}
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/e-shopping-help"
                        onClick={() => this.closeMoreDiv()}
                      >
                        {t("e_shopping_help")}
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/site-map"
                        onClick={() => this.closeMoreDiv()}
                      >
                        {t("home.Site Map")}
                      </Link>
                      {/* <a className="dropdown-item" href="#">{t('home.News')}</a> */}
                      {/* <a className="dropdown-item" href="#">Another action</a>
                                    <a className="dropdown-item" href="#">Something else here</a> */}
                      <Link
                        className="dropdown-item"
                        to="/feedback"
                        onClick={() => this.closeMoreDiv()}
                      >
                        {t("home.Feedback")}
                      </Link>
                      {balance_div}
                    </div>
                  </li>

                  <hr className="horizontal_line"></hr>

                  <li
                    className="nav-item search"
                    style={{ margin: "9px 13px 0px 18px" }}
                  >
                    <Link
                      to="/site-search"
                      style={{ cursor: "pointer", color: "black" }}
                    >
                      <i className="fa fa-search"></i>
                    </Link>
                  </li>

                  <li
                    id="myCartButton"
                    className="nav-item"
                    style={{
                      margin: "9px 13px 0px 18px",
                      color: "red !important",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      id="lblCartCount"
                      className="lblCartCount"
                      style={{
                        fontSize: "12px",
                        background: "#ff0000",
                        color: "#fff",
                        padding: "0 6px",
                        verticleAlign: "top",
                        position: "absolute",
                      }}
                    >
                      0
                    </span>
                    <Link
                     style={{ color: "red" }}
                     to={"/mycart"}
                    >
                      <i className="fas fa-shopping-cart"></i>
                    </Link>
                  </li>

                  <li
                    className="nav-item"
                    id="signUpButton"
                    style={{
                      margin: "9px 13px 0px 18px",
                      color: "red !important",
                      cursor: "pointer",
                    }}
                  >
                    <Link className="color--dark-brown" to="/signin">
                      {t("home.Login")}
                    </Link>
                    &nbsp;|&nbsp;
                    <Link className="color--dark-brown" to="/signup">
                      {t("home.Register")}
                    </Link>
                  </li>
                  <li
                    className="nav-item userProfile userNameForMobile"
                    id="logoutButton"
                    style={{
                      fontSize: "0.8rem",
                      alignSelf: "center",
                      maxWidth: "8rem",
                      borderLeftStyle: "solid",
                      paddingLeft: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    <span id="user_first_name"></span>
                    &nbsp;
                    <span id="user_last_name"></span>
                  </li>

                  <li className="nav-item dropdown userProfile">
                    <a
                      className="nav-link dropdown-toggle orangeColor"
                      href="#"
                      id="navbarDropdownMenuLink"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {/* {t('home.My Account')} */}
                      <img
                        class=""
                        style={{
                          borderRadius: "50%",
                          height: "2rem",
                          width: "2rem",
                        }}
                        src={
                          myConstClass.IMAGE_URL +
                          "user/" +
                          userinfo.profile_picture
                        }
                      />
                    </a>
                    <div
                      className="dropdown-menu headerDropDownMenu"
                      aria-labelledby="navbarDropdownMenuLink"
                      style={{ marginLeft: "-119px" }}
                    >
                      {/* <a className="dropdown-item profileMenuDiv myAccountDiv" href="javascript:void(0);" style={{ cursor: 'default', color: 'white' }}>{t('home.My Account')}</a> */}
                      <span
                        className="dropdown-item profileMenuDiv myAccountDiv"
                        id="myAccountSpan"
                        style={{ cursor: "default", color: "white" }}
                      >
                        <b>{t("home.My Account")}</b>
                      </span>
                      <Link
                        className="dropdown-item profileMenuDiv"
                        to="/profile"
                      >
                        {t("home.Profile")}
                      </Link>
                      <Link
                        className="dropdown-item profileMenuDiv"
                        to="/change-password"
                      >
                        {t("home.Change Password")}
                      </Link>
                      <Link
                        className="dropdown-item profileMenuDiv"
                        to="/my-interactions"
                      >
                        {t("home.My Interactions")}
                      </Link>
                      <Link
                        className="dropdown-item profileMenuDiv"
                        to="/participants"
                      >
                        {t("home.Master List of Participants")}
                      </Link>

                      <Link
                        className="dropdown-item profileMenuDiv"
                        to="/order-history"
                      >
                        {t("home.Order History")}
                      </Link>
                      {/* <a className="dropdown-item profileMenuDiv"  to="javascript:void(0)">{t('home.My Interactions')}</a> */}
                      {/* <a className="dropdown-item"  href="javascript:void(0)">{t('home.')}</a> */}

                      {/* <Link  to="/order-history"className="dropdown-item"  >{t('home.Order History')}</Link> */}
                      <Link
                        to="/donation-contribution"
                        className="dropdown-item donationContribution"
                      >
                        {t("home.donation_contribution")}
                      </Link>
                      <Link
                        to="/logout"
                        className="dropdown-item profileMenuDiv"
                      >
                        {t("home.Sign Out")}
                      </Link>
                    </div>
                  </li>

                  {/* <li className="nav-item dropdown userProfile  pb-5" >
                                <label id="logoutButton" >
                                    <span id="user_first_name"></span>&nbsp;<span id="user_last_name"></span>
                                </label>
                                <span className="text_img">
                                    <a className="nav-link dropdown-toggle orangeColor pb-5" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img style={{width:'35px',height:'35px',borderRadius:'50%'}} className=""  src={myConstClass.IMAGE_URL + 'user/' + userinfo.profile_picture} />
                                    </a>
                                    <div className="dropdown-menu headerDropDownMenu  " aria-labelledby="navbarDropdownMenuLink" style={{ marginLeft: '-119px' }}>
                                        <Link className="dropdown-item profileMenuDiv" to="/order-history" >{t('home.Order History')}</Link>
                                        <Link className="dropdown-item profileMenuDiv" to="/participants">{t('home.Master List of Participants')}</Link>
                                       <Link className="dropdown-item profileMenuDiv" to="/my-interactions">{t('home.My Interactions')}</Link>
                                        <Link className="dropdown-item profileMenuDiv" to="/change-password">{t('home.Change Password')}</Link>
                                        <Link className="dropdown-item profileMenuDiv" to="/profile">{t('home.Profile')}</Link>
                                        <Link to="/logout" className="dropdown-item profileMenuDiv"  >{t('home.Sign Out')}</Link>
                                    </div>
                                </span>
                            </li> */}

                  {/* <li className="nav-item dropdown userProfile" >

                               
                                
                            </li> */}

                  {/* </div> */}
                </div>{" "}
                {/* this is only mobile scroll  */}
              </ul>
            </div>
          </nav>

          {/* Mobile Design */}
          <nav className="navbar navbar-expand-lg navbar-light border-bottom  main-nav d-xl-none d-lg-none d-flex">
            <button
              className="navbar-toggler"
              type="button"
              onClick={() => this.openNav()}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <Link className="navbar-brand" to="/">
              <img src={logo} id="logoEnglish_m" style={{ width: 150 }} />
              <img src={logomr} id="logoMarathi_m" style={{ width: 150 }} />
            </Link>
            <div className="mobile-search-cart d-xl-none d-lg-none d-inline">
              <li
                className="nav-item search"
                style={{ margin: "9px 13px 0px 18px" }}
              >
                <Link
                  to="/site-search"
                  style={{ cursor: "pointer", color: "black" }}
                >
                  <i className="fa fa-search"></i>
                </Link>
              </li>

              <li
                id="myCartButton"
                className="nav-item"
                style={{
                  margin: "9px 13px 0px 18px",
                  color: "#9d0b0e !important",
                  cursor: "pointer",
                  display: "inline-block !important",
                }}
              >
                <span
                  id="lblCartCount"
                  className="lblCartCount"
                  style={{
                    fontSize: "12px",
                    background: "rgb(255, 0, 0)",
                    border: "1px rgb(255, 0, 0) solid",
                    color: "rgb(255, 255, 255)",
                    padding: "0 6px",
                    verticleAlign: "top",
                    position: "absolute",
                  }}
                >
                  0
                </span>
                <Link to="/mycart">
                  <i
                    style={{ color: "red" }}
                    className="fas fa-shopping-cart"
                  ></i>
                </Link>
              </li>
            </div>

            <div id="mySidenav" className="sidenav">
              <a
                href="javascript:void(0)"
                class="closebtn"
                onClick={() => this.closeNav()}
              >
                &times;
              </a>

              <a>
                Welcome &nbsp;&nbsp;
                <span>
                  {localStorage.getItem("first_name")}&nbsp;&nbsp;
                  {localStorage.getItem("last_name")}
                </span>
                &nbsp;&nbsp;
                <img
                  className="rounded-circle"
                  height={30}
                  width={30}
                  src={
                    myConstClass.IMAGE_URL + "user/" + userinfo.profile_picture
                  }
                  alt="Profile Image"
                />
              </a>
              <div
                style={{
                  borderWidth: 0.1,
                  borderColor: "white",
                  borderStyle: "solid",
                }}
              ></div>

              {this.state.subMenuLevel1 ? (
                <div>
                  {this.state.subMenuLevel2 ? (
                    <>
                      <a
                        className="d-inline"
                        onClick={() =>
                          this.setState({
                            subMenuLevel1: false,
                            subMenuLevel2: false,
                            subMenuLevel1List: [],
                            subMenuLevel2List: [],
                            subMenuLevel1Heading: "",
                            subMenuLevel2Heading: "",
                          })
                        }
                      >
                        <i className="fa fa-angle-left ml-n4"></i>&nbsp;&nbsp;
                        {t("home.HOME")}
                      </a>
                      <a
                        className="d-inline"
                        onClick={() =>
                          this.setState({
                            subMenuLevel1: true,
                            subMenuLevel2: false,
                            subMenuLevel2List: [],
                            subMenuLevel2Heading: "",
                          })
                        }
                      >
                        <i className="fa fa-angle-left ml-n4"></i>&nbsp;&nbsp;
                        {this.state.subMenuLevel1Heading}
                      </a>
                      <a
                        className="d-inline ml-n4"
                        onClick={() =>
                          this.setState({
                            subMenuLevel1: true,
                            subMenuLevel2: false,
                            subMenuLevel2List: [],
                            subMenuLevel2Heading: "",
                          })
                        }
                      >
                        /&nbsp;&nbsp;{this.state.subMenuLevel2Heading}
                      </a>
                      {this.state.subMenuLevel2List}
                    </>
                  ) : (
                    <>
                      <a
                        className="d-inline"
                        onClick={() =>
                          this.setState({
                            subMenuLevel1: false,
                            subMenuLevel2: false,
                            subMenuLevel1List: [],
                            subMenuLevel2List: [],
                            subMenuLevel1Heading: "",
                            subMenuLevel2Heading: "",
                          })
                        }
                      >
                        <i className="fa fa-angle-left ml-n4"></i>&nbsp;&nbsp;
                        {t("home.HOME")}
                      </a>
                      <a className="d-inline ml-n4">
                        /&nbsp;&nbsp;{this.state.subMenuLevel1Heading}
                      </a>
                      {this.state.subMenuLevel1List}
                    </>
                  )}
                </div>
              ) : (
                <div>
                  <a
                    className="dropdown-item"
                    onClick={() => this.showSubMenuLevel1("aboutUs")}
                  >
                    {t("home.ABOUT US")}{" "}
                    <i className="fa fa-angle-right float-right mx-3"></i>
                  </a>
                  <a
                    className="dropdown-item"
                    onClick={() => this.showSubMenuLevel1("topics")}
                  >
                    {t("home.TOPICS")}{" "}
                    <i className="fa fa-angle-right float-right mx-3"></i>
                  </a>
                  <a
                    className="dropdown-item"
                    onClick={() => this.showSubMenuLevel1("events")}
                  >
                    {t("home.EVENTS")}{" "}
                    <i className="fa fa-angle-right float-right mx-3"></i>
                  </a>
                  <a
                    className="dropdown-item"
                    onClick={() => this.showSubMenuLevel1("products")}
                  >
                    {t("home.PRODUCTS")}{" "}
                    <i className="fa fa-angle-right float-right mx-3"></i>
                  </a>
                  <Link className="dropdown-item" to="/research">
                    {t("home.RESEARCH")}
                  </Link>
                  <Link className="dropdown-item" to="/event-calendar">
                    {t("home.EVENT CALENDAR")}
                  </Link>
                  {/* <Link
                    className="dropdown-item"
                    to="/booknow/Residential Study Courses/1"
                  >
                    {t("home.BOOK NOW")}
                  </Link> */}
                  <a
                    className="dropdown-item"
                    onClick={() => this.showSubMenuLevel1("more")}
                  >
                    {t("home.MORE")}{" "}
                    <i className="fa fa-angle-right float-right mx-3"></i>
                  </a>
                  <div
                    style={{
                      borderWidth: 0.1,
                      borderColor: "white",
                      borderStyle: "solid",
                    }}
                  ></div>
                  {localStorage.getItem("user_id") ? (
                    <>
                      <a
                        className="dropdown-item"
                        onClick={() => this.showSubMenuLevel1("myAccount")}
                      >
                        {t("home.My Account")}{" "}
                        <i className="fa fa-angle-right float-right mx-3"></i>
                      </a>
                      <div
                        style={{
                          borderWidth: 0.1,
                          borderColor: "white",
                          borderStyle: "solid",
                        }}
                      ></div>
                    </>
                  ) : null}
                  {localStorage.getItem("user_id") ? null : (
                    <Link className="dropdown-item" to="/signin">
                      {t("home.Login")}
                    </Link>
                  )}
                  {localStorage.getItem("user_id") ? null : (
                    <Link className="dropdown-item" to="/signup">
                      {t("home.Register")}
                    </Link>
                  )}
                </div>
              )}
            </div>
          </nav>

          <div
            className="modal fade z-index--99999"
            id="subscribeModel"
            tabindex="-1"
            role="dialog"
            aria-labelledby="subscribeModel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg"
              role="document"
            >
              <div
                className="modal-content bg-light"
                style={{ overflow: "auto" }}
              >
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {t("home.Newsletter")}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="far fa-times-circle"></i>
                  </button>
                </div>
                <Form
                  action=""
                  id="pform_newsletter"
                  name="pform_newsletter"
                  onSubmit={this.handleSubmit}
                  method="post"
                  encType="multipart/form-data"
                  className="form-horizontal"
                >
                  <div className="modal-body">
                    {/* <div className="row">
                        <div className="col">
                            <p className="text-right color-red">* {t('home.Mandatory Fields')}</p>
                        </div>
                    </div> */}
                    <div className="row">
                      <div className="form-group col col-md-4">
                        <label className="color-black font-weight-bolder">
                          {t("home.Name")}
                          <span className="color-red">*</span>
                        </label>
                        <input
                          value={subscribeinfo.first_name}
                          onChange={(e) => {
                            this.setState({
                              subscribeinfo: {
                                ...subscribeinfo,
                                first_name: e.target.value,
                              },
                            });
                          }}
                          onBlur={() =>
                            this.validatorNewsletter.showMessageFor(
                              "first_name"
                            )
                          }
                          type="text"
                          id="first_name"
                          name="first_name"
                          className="form-control"
                          placeholder={t("home.Enter First Name")}
                        />

                        {this.validatorNewsletter.message(
                          "first_name",
                          this.state.subscribeinfo.first_name,
                          "required"
                        )}
                      </div>
                      <div className="form-group col col-md-4">
                        <label className="color-black font-weight-bolder">
                          {t("home.Last Name")}
                          <span className="color-red">*</span>
                        </label>
                        {/* <input type="text" className="form-control" id="last_name" name="last_name" placeholder={t('home.Enter Last Name')} /> */}
                        <input
                          value={subscribeinfo.last_name}
                          onBlur={() =>
                            this.validator.showMessageFor("last_name")
                          }
                          onChange={(e) => {
                            this.setState({
                              subscribeinfo: {
                                ...subscribeinfo,
                                last_name: e.target.value,
                              },
                            });
                          }}
                          type="text"
                          id="last_name"
                          name="last_name"
                          className="form-control"
                          placeholder={t("home.Enter Last Name")}
                        />
                        {this.validatorNewsletter.message(
                          "last_name",
                          this.state.subscribeinfo.last_name,
                          "required"
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col col-md-4">
                        <label className="color-black font-weight-bolder">
                          {t("home.Email")} <span className="color-red">*</span>
                        </label>
                        <input
                          value={subscribeinfo.email}
                          onChange={(e) => {
                            this.setState({
                              subscribeinfo: {
                                ...subscribeinfo,
                                email: e.target.value,
                              },
                            });
                          }}
                          type="text"
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder={t("home.Email")}
                        />
                        {this.validatorNewsletter.message(
                          "email",
                          this.state.subscribeinfo.email,
                          "required"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <span
                      data-dismiss="modal"
                      aria-label="Close"
                      style={{ cursor: "pointer" }}
                    >
                      {t("home.Cancel")}
                    </span>
                    <button
                      type="submit"
                      className="btn btn-outline-brown bg-white btn-sm pl-4 pr-4"
                      style={{ color: "black" }}
                    >
                      {t("home.Save")}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  t: PropTypes.func.isRequired,
};
export default withRouter(translate(Header));

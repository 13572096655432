import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import backgroundEvent1 from './assets/images/background_1.jpg';
import backgroundEvent2 from './assets/images/background_2.jpg';
import backgroundEvent3 from './assets/images/background_3.jpg';
import backgroundEvent4 from './assets/images/background_4.jpg';
import backgroundEvent5 from './assets/images/background_5.jpg';
import backgroundEvent6 from './assets/images/background_6.jpg';

import backgroundEvent1_mr from './assets/images/Residential_Study_Courses_mr.jpg';
import backgroundEvent2_mr from './assets/images/One_Day_Workshop_mr.jpg';

import backgroundEvent3_mr from './assets/images/Machine_test _mr.jpg';
import backgroundEvent4_mr from './assets/images/Rational_Poojas_mr.jpg';
import backgroundEvent5_mr from './assets/images/Free_Events_mr.jpg';
import backgroundEvent6_mr from './assets/images/Organize_A_Trip_mr.jpg';

import ResidentialStudyCourses from './assets/images/ResidentialStudyCourses.jpg';
import ResidentialStudyCourses_mr from './assets/images/Residential_Study_Courses_m_mr.jpg';
import OneDayWorkshop from './assets/images/OneDayWorkshop.jpg';
import OneDayWorkshop_mr from './assets/images/One_Day_Workshop_m_mr.jpg';
import RationalPoojas from './assets/images/RationalPoojas.jpg';
import RationalPoojas_mr from './assets/images/Rational_Poojas_m_mr.jpg';
import FreeEvents from './assets/images/FreeEvents.jpg';
import FreeEvents_mr from './assets/images/Free_Events_m_mr.jpg';


import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import './assets/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/rfs.css';
import './assets/fontawesome/css/all.min.css';
import Header from './Header.js';
import Footer from './Footer.js';
import * as myConstClass from './constant.js';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage, getLanguage,
    translate,
} from 'react-switch-lang';
import en from './en.json';
import ma from './ma.json';
import PropTypes from 'prop-types';
//import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    ListGroup,
    ListGroupItem
} from 'reactstrap';
import { roundToNearestMinutesWithOptions } from 'date-fns/fp';


function DesktopDesignParentChild(props) {
    return (
        <Row className="d-xl-flex d-lg-flex d-none">
            <Col xl="5" lg="5" md="5">
                <h4>
                    <Link style={{ color: '#212529' }} to={"/event_details/" + props.cell.event_id}>
                        {props.cell.title}
                    </Link>
                </h4>
                <Col xl="12" lg="12" md="12">
                    {props.cell.topic.map((info, ii) => {

                        if (ii == 0) {
                            return (
                                <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                    <Label className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                        {info.name}
                                    </Label>
                                </Link>
                            )
                        } else {
                            return (
                                <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                    <Label style={{ marginLeft: '5px' }} className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                        {info.name}
                                    </Label>
                                </Link>
                            )
                        }
                    }
                    )}
                </Col>
            </Col>
            <Col xl="7" lg="7" md="7">
                <Row>
                    <Col xl="2" lg="2" md="2">
                        <span className="mr-2">
                            <i className="far fa-comment-dots orangeColor"></i> <span className="small">{props.cell.total_review}</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">{props.cell.total_like}</span>
                        </span>
                    </Col>

                    <Col xl="2" lg="2" md="2">
                        <Label className="d-xl-block d-lg-block d-inline">
                            {props.t('home.Duration')}&nbsp;
                        </Label>
                        <Label className="d-xl-block d-lg-block d-md-block d-inline">
                            {props.cell.duration} {props.t('home.Days')}
                        </Label>
                        <p className="mb-0 d-xl-block d-lg-block d-md-block d-block">
                            <Link style={{ textDecoration: 'underline' }} to={"/event_details/" + props.cell.event_id}>{props.t('home.Know More')}</Link>
                        </p>
                    </Col>
                    <Col xl="2" lg="2" md="2">
                        <Label>{props.cell.first_price_for}</Label>
                        <select className="form-control form-control-sm" name={"parent" + props.cell.event_id} id={"parent" + props.cell.event_id}>
                            <option value="0" selected="selected">00</option>
                            <option value="1" >01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                        </select>
                    </Col>
                    <Col xl="2" lg="2" md="2">
                        <Label>{props.cell.second_price_for}</Label>
                        <select className="form-control form-control-sm" name={"child" + props.cell.event_id} id={"child" + props.cell.event_id}>
                            <option value="0" selected="selected">00</option>
                            <option value="1">01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                        </select>
                    </Col>
                    <Col xl="4" lg="4" md="4" className="text-right">
                        <h5>
                            {props.t('home.INR')} {props.cell.first_price}/- <small style={{ fontWeight: '700', textTransform: 'lowercase' }}>{props.t('home.per')} {props.cell.first_price_for}</small>
                            <br />
                            {props.t('home.INR')} {props.cell.second_price}/- <small style={{ fontWeight: '700', textTransform: 'lowercase' }}>{props.t('home.per')} {props.cell.second_price_for}</small>
                        </h5>


                        <button style={{ fontWeight: '700' }} className={'schedule_' + props.cell.event_id + ' btn btn-sm btn-outline-brown color-red mt-4 scheduleButton'} id={'schedule_' + 'd_' + props.cell.event_id + '_' + props.topicId} onClick={props.showSchedule}>
                            {props.t('home.SHOW SCHEDULE')}
                        </button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )

}

function MobileDesignParentChild(props) {
    return (
        <Row className="d-xl-none d-lg-none d-flex">
            <Col md="12" sm="12" xs="12" >
                <Link to={"/event_details/" + props.cell.event_id}>
                    <h5>
                        {props.cell.title}
                    </h5>
                </Link>
            </Col>
            <Col md="12" sm="12" xs="12">
                <Row>
                    <Col md="12" sm="12" xs="12">
                        {props.cell.topic.map((info, ii) => {

                            if (ii == 0) {
                                return (
                                    <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                        <Label className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                            {info.name}
                                        </Label>
                                    </Link>
                                )
                            } else {
                                return (
                                    <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                        <Label style={{ marginLeft: '5px' }} className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                            {info.name}
                                        </Label>
                                    </Link>
                                )
                            }
                        }
                        )}
                    </Col>
                    <Col md="6" sm="12" xs="12" >
                        <Label>
                            {props.t('home.Duration')}&nbsp;
                        </Label>
                        <Label className="font-weight-normal">
                            {props.cell.duration} {props.t('home.Days')}
                        </Label>
                    </Col>
                    <Col md="6" className="d-md-flex d-none">
                        <Link style={{ textDecoration: 'underline' }} to={"/event_details/" + props.cell.event_id}>{props.t('home.Know More')}</Link>
                    </Col>
                    <Col sm="6" xs="6" md="6" className="align-self-center">
                        <span className="font-weight-bold">
                            {props.t('home.INR')}
                            {props.cell.first_price}/-
                        </span>
                        <small className="text-lowercase ">
                            {props.t('home.per')} {props.cell.first_price_for}
                        </small>
                        <br />
                        <span className="font-weight-bold">
                            {props.t('home.INR')}
                            {props.cell.second_price}/-
                        </span>
                        <small className="text-lowercase ">
                            {props.t('home.per')} {props.cell.second_price_for}
                        </small>
                    </Col>
                    <Col sm="3" xs="3" md="3">
                        <Label>{props.cell.first_price_for}</Label>
                        <select className="form-control form-control-sm" name={"parentm" + props.cell.event_id} id={"parentm" + props.cell.event_id}>
                            <option value="0" selected="selected">00</option>
                            <option value="1" >01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                        </select>
                    </Col>
                    <Col sm="3" xs="3" md="3">
                        <Label>{props.cell.second_price_for}</Label>
                        <select className="form-control form-control-sm" name={"childm" + props.cell.event_id} id={"childm" + props.cell.event_id}>
                            <option value="0" selected="selected">00</option>
                            <option value="1">01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                        </select>
                    </Col>
                </Row>

            </Col>
            <Col md="12" sm="12" xs="12">
                <Row>
                    <Col md="5" sm="5" xs="5" className="align-self-start">
                        <span className="mr-2">
                            <i className="far fa-comment-dots orangeColor"></i>
                            <span className="small">{props.cell.total_review}</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">{props.cell.total_like}</span>
                        </span>
                    </Col>
                    <Col md="7" sm="7" xs="7" className="align-self-start text-right">
                        <button className={'schedule_' + props.cell.event_id + '' + props.topicId + ' btn btn-sm btn-outline-brown color-red mt-4 scheduleButton font-weight-bold'} id={'schedule_' + 'm_' + props.cell.event_id + '_' + props.topicId} onClick={props.showSchedule}>
                            {props.t('home.SHOW SCHEDULE')}
                        </button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )



}

function DesktopDesignAdult(props) {
    return (
        <Row className="d-xl-flex d-lg-flex d-none">
            <Col xl="5" lg="5" md="5">
                <h4>
                    <Link style={{ color: '#212529' }} to={"/event_details/" + props.cell.event_id}>
                        {props.cell.title}
                    </Link>
                </h4>
                <Col xl="12" lg="12" md="12">
                    {props.cell.topic.map((info, ii) => {

                        if (ii == 0) {
                            return (
                                <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                    <Label className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                        {info.name}
                                    </Label>
                                </Link>
                            )
                        } else {
                            return (
                                <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                    <Label style={{ marginLeft: '5px' }} className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                        {info.name}
                                    </Label>
                                </Link>
                            )
                        }
                    }
                    )}
                </Col>
            </Col>
            <Col xl="7" lg="7" md="7">
                <Row>
                    <Col xl="2" lg="2" md="2">
                        <span className="mr-2">
                            <i className="far fa-comment-dots orangeColor"></i> <span className="small">{props.cell.total_review}</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">{props.cell.total_like}</span>
                        </span>
                    </Col>

                    <Col xl="2" lg="2" md="2">
                        <Label className="d-xl-block d-lg-block d-inline">
                            {props.t('home.Duration')}&nbsp;
                        </Label>
                        <Label className="d-xl-block d-lg-block d-md-block d-inline">
                            {props.cell.duration} {props.t('home.Days')}
                        </Label>
                        <p className="mb-0 d-xl-block d-lg-block d-md-inline d-block">
                            <Link style={{ textDecoration: 'underline' }} to={"/event_details/" + props.cell.event_id}>{props.t('home.Know More')}</Link>
                        </p>
                    </Col>
                    <Col xl="2" lg="2" md="2">
                        <Label>{props.cell.first_price_for}</Label>
                        <select className="form-control form-control-sm" name={"parent" + props.cell.event_id} id={"parent" + props.cell.event_id}>
                            <option value="0" selected="selected">00</option>
                            <option value="1">01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                        </select>
                    </Col>

                    <Col xl="6" lg="6" md="6" className="text-right">
                        <h5>
                            {props.t('home.INR')} {props.cell.first_price}/- <small style={{ fontWeight: '700', textTransform: 'lowercase' }}>{props.t('home.per')} {props.cell.first_price_for}</small>
                        </h5>


                        <button style={{ fontWeight: '700' }} className={'schedule_' + props.cell.event_id + ' btn btn-sm btn-outline-brown color-red mt-4 scheduleButton'} id={'schedule_' + 'd_' + props.cell.event_id + '_' + props.topicId} onClick={props.showSchedule}>
                            {props.t('home.SHOW SCHEDULE')}
                        </button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )

}

function MobileDesignAdult(props) {
    return (
        <Row className="d-xl-none d-lg-none d-flex">
            <Col sm="12" xs="12">
                <Link to={"/event_details/" + props.cell.event_id}>
                    <h5>
                        {props.cell.title}
                    </h5>
                </Link>
            </Col>
            <Col md="12" sm="12" xs="12">
                <Row>
                    <Col md="12" sm="12" xs="12">
                        {props.cell.topic.map((info, ii) => {

                            if (ii == 0) {
                                return (
                                    <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                        <Label className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                            {info.name}
                                        </Label>
                                    </Link>
                                )
                            } else {
                                return (
                                    <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                        <Label style={{ marginLeft: '5px' }} className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">
                                            {info.name}
                                        </Label>
                                    </Link>
                                )
                            }
                        }
                        )}
                    </Col>
                    <Col md="6" sm="12" xs="12">
                        <Label>
                            {props.t('home.Duration')}&nbsp;
                        </Label>
                        <Label className="font-weight-normal">
                            {props.cell.duration} {props.t('home.Days')}
                        </Label>
                    </Col>
                    <Col md="6" className="d-md-flex d-none">
                        <Link style={{ textDecoration: 'underline' }} to={"/event_details/" + props.cell.event_id}>{props.t('home.Know More')}</Link>
                    </Col>
                    <Col md="6" sm="6" xs="6" className="align-self-center">
                        <span className="font-weight-bold">
                            {props.t('home.INR')}
                            {props.cell.first_price}/-
                        </span>
                        <small className="text-lowercase ">
                            {props.t('home.per')} {props.cell.first_price_for}
                        </small>
                    </Col>
                    <Col md={{ size: 4, offset: 2 }} sm="3" xs="3">
                        <Label>{props.cell.first_price_for}</Label>
                        <select className="form-control form-control-sm" name={"parentm" + props.cell.event_id} id={"parentm" + props.cell.event_id}>
                            <option value="0" selected="selected">00</option>
                            <option value="1">01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                        </select>
                    </Col>
                </Row>

            </Col>
            <Col md="12" sm="12" xs="12">
                <Row>
                    <Col md="5" sm="5" xs="5" className="align-self-start">
                        <span className="mr-2">
                            <i className="far fa-comment-dots orangeColor"></i>
                            <span className="small">{props.cell.total_review}</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">{props.cell.total_like}</span>
                        </span>
                    </Col>
                    <Col md="7" sm="7" xs="7" className="align-self-start text-right">
                        <button className={'schedule_' + props.cell.event_id + '' + props.topicId + ' btn btn-sm btn-outline-brown color-red mt-4 scheduleButton font-weight-bold'} id={'schedule_' + 'm_' + props.cell.event_id + '_' + props.topicId} onClick={props.showSchedule}>
                            {props.t('home.SHOW SCHEDULE')}
                        </button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )

}



class Events extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notifyinfo: {
                first_name: '',
                last_name: '',
                message: '',
                status: '',
                email: '',
            },
            ResidentialStudyCourses: '',
            OneDayWorkshop: '',
            RationalPoojas: '',
            FreeEvents: '',
            share_current_image_url: '',
            share_image_url: '',
            social_description: '',
            eventdetails: {
                event_type: '',
                event_description: '',

            },
            guestinfo: {
                event_type: '',
                event_type_id: '',
                topic_id: '',
                event_type_name: '',
                event_id: '0',
                schedule_event_name: '',
                language_id: '',

            },
            flag: 0,
            quantityValue: '1',
            posts: [],
            eventinfo: [],
            languages: [],
            topic: [],
            eventTypes: [],
            scheduleEvent: [],
            cart: [],
            cartEvent: [],
            removeFromCart: [],
            eventdetails: [],

            types: [],
            showScheduleFlag: -1
        }
        this.handleChange = this.handleChange.bind(this);
        this.increaseQuantity = this.increaseQuantity.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.deleteFromCart = this.deleteFromCart.bind(this);
        this.showSchedule = this.showSchedule.bind(this);
        this.showMoreEvents = this.showMoreEvents.bind(this);
        this.goToCart = this.goToCart.bind(this);
        // this.notify = this.notify.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validator = new SimpleReactValidator();
        this.notifyMe = this.notifyMe.bind(this);
    }

    goToCart() {
        var user_id = localStorage.getItem('user_id');
        if (user_id > 0 && user_id != undefined) {
            if (parseInt(localStorage.getItem("cartCount")) > 0) {
                this.props.history.push('/mycart');
            } else {
                if (this.state.showScheduleFlag > -1) {
                    if (this.state.showScheduleFlag) {
                        if (getLanguage() == 'ma') {
                            var message = "कृपया कार्टवर जोडा वर क्लिक करा.";
                        } else {
                            var message = "Please click on add to cart.";
                        }
                    } else {
                        if (getLanguage() == 'ma') {
                            message = "कार्यक्रमाचे वेळापत्रक उपलब्ध नाही.";
                        } else {
                            message = "Event schedule not available.";
                        }
                    }
                } else {
                    if (getLanguage() == 'ma') {
                        var message = "कृपया शो वेळापत्रक वर क्लिक करा एक तारीख निवडा आणि कार्टमध्ये जोडा.";
                    } else {
                        var message = "Please click on show schedule select a date and add to cart.";
                    }
                }

                toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
            }

        } else {
            var message = "Please login to our website.";
            localStorage.removeItem('error');
            localStorage.setItem('error', message);
            this.props.history.push('/signin')
            //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

        }
    }

    handleSubmit(e) {
        e.preventDefault();
        var user_id = localStorage.getItem('user_id');
        var notify_schedule_id = $('#notify_schedule_id').val();
        console.log('this.refs', $('#pform').serializeArray());
        if (this.validator.allValid()) {
            const formData = {};
            for (const field in this.refs) {
                formData[field] = this.refs[field].value;
            }
            console.log('-->', formData);
            fetch(myConstClass.BASE_URL + `users/addnotify/` + user_id + '/' + notify_schedule_id, {
                method: 'POST',
                body: JSON.stringify($('#pform').serializeArray()),

            })
                .then(response => response.json())
                .then(response => {
                    this.setState({ notifyinfo: response })
                    var message = this.state.notifyinfo.message;
                    var status = this.state.notifyinfo.status;
                    console.log('hii' + status);
                    if (status == 'true') {
                        // $('#address').modal('hide');
                        // $('#pform')[0].reset();

                        //const numRows = this.state.notifyinfo.length;

                        this.state.notifyinfo.first_name = this.state.notifyinfo.first_name;
                        //this.state.notifyinfo.middle_name = this.state.notifyinfo[0].middle_name;
                        this.state.notifyinfo.last_name = this.state.notifyinfo.last_name;

                        //this.state.notifyinfo.phone = this.state.notifyinfo[0].phone;
                        this.state.notifyinfo.email = this.state.notifyinfo.email;
                        //this.state.notifyinfo.event_type = this.state.notifyinfo[0].event_type;
                        //this.state.notifyinfo.comment = this.state.notifyinfo[0].comment;

                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    $('#notifyModel').modal('hide');
                    //console.log(status);
                })
                .catch(err => console.log(err))
        }
        else {
            //alert('here');
            this.validator.showMessages();
            console.log('validations' + this.validator.showMessages());
            e.preventDefault();
            toast('Please fill all required fields.', { containerId: 'B', type: toast.TYPE.ERROR })
            // toast('Please fill all required fields.', {containerId: 'B',type: toast.TYPE.ERROR})

        }
    }

    handleChange(evt) {
        // check it out: we get the evt.target.name (which will be either "email" or "password")
        // and use it to target the key on our `state` object with the same name, using bracket syntax
        this.setState({ [evt.target.name]: evt.target.value });
    }
    increaseQuantity(evt) { //console.log(evt);
        var qty = evt.target.value;
        var qty = parseInt(qty) + 1; //alert(evt.target.value);
        // check it out: we get the evt.target.name (which will be either "email" or "password")
        // and use it to target the key on our `state` object with the same name, using bracket syntax
        this.setState({ [evt.target.name]: qty });
    }
    componentDidMount() {
        const answer_array = this.props.location.pathname.split('/');
        var current_language = getLanguage();
        localStorage.setItem('current_language', current_language);
        var message = localStorage.getItem('success');
        if (message != '' && message != null) {
            toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
            localStorage.removeItem('success');
        }
        if (getLanguage() == 'ma') {
            this.state.ResidentialStudyCourses = ResidentialStudyCourses_mr;
            this.state.OneDayWorkshop = OneDayWorkshop_mr;
            this.state.RationalPoojas = RationalPoojas_mr;
            this.state.FreeEvents = FreeEvents_mr;
        } else {
            this.state.ResidentialStudyCourses = ResidentialStudyCourses;
            this.state.OneDayWorkshop = OneDayWorkshop;
            this.state.RationalPoojas = RationalPoojas;
            this.state.FreeEvents = FreeEvents;
        }
        if ((answer_array['3'] == 1 || answer_array['3'] == 5)) {
            $('.marathiStep1').css('display', 'inline');
            $('.marathiStep2').css('display', 'none');
        } else {
            $('.marathiStep1').css('display', 'none');
            $('.marathiStep2').css('display', 'inline');
        }
        if ((answer_array['3'] == 4 || answer_array['3'] == 2) && current_language == 'ma') {
            $('.bookNowInCartMrPoojs').css('display', 'inline');
            $('.bookNowInCartMr').css('display', 'none');
            $('.bookNowInCartEn').css('display', 'none');
        } else if ((answer_array['3'] == 1 || answer_array['3'] == 5) && current_language == 'ma') {
            //alert('#bookNowInCartMrPoojs');
            $('.bookNowInCartMrPoojs').css('display', 'none');
            $('.bookNowInCartMr').css('display', 'inline');
            $('.bookNowInCartEn').css('display', 'none');
        }

        var user_id = localStorage.getItem('user_id');

        var event_type = answer_array['3'];
        if (event_type == 1) {
            if (getLanguage() == 'ma') {
                $('#background_1_mr').css('display', 'block');
                $('#background_2_mr').css('display', 'none');
                $('#background_3_mr').css('display', 'none');
                $('#background_4_mr').css('display', 'none');
                $('#background_5_mr').css('display', 'none');
                $('#background_6_mr').css('display', 'none');
            } else {
                $('#background_1').css('display', 'block');
                $('#background_2').css('display', 'none');
                $('#background_3').css('display', 'none');
                $('#background_4').css('display', 'none');
                $('#background_5').css('display', 'none');
                $('#background_6').css('display', 'none');
            }


            $('#background_1_m').css('display', 'block');

            $('#background_2_m').css('display', 'none');
            $('#background_3_m').css('display', 'none');
            $('#background_4_m').css('display', 'none');
            $('#background_5_m').css('display', 'none');
            $('#background_6_m').css('display', 'none');

            $('#background_1_m_1').css('display', 'block');

            $('#background_2_m_2').css('display', 'none');
            $('#background_3_m_3').css('display', 'none');
            $('#background_4_m_4').css('display', 'none');
            $('#background_5_m_5').css('display', 'none');
            $('#background_6_m_6').css('display', 'none');

            $('.for_res_event').css('display', 'contents');
            $('.for_other_event').css('display', 'none');
        } else if (event_type == 2) {
            if (getLanguage() == 'ma') {
                $('#background_2_mr').css('display', 'block');
                $('#background_1_mr').css('display', 'none');
                $('#background_3_mr').css('display', 'none');
                $('#background_4_mr').css('display', 'none');
                $('#background_5_mr').css('display', 'none');
                $('#background_6_mr').css('display', 'none');
            } else {
                $('#background_2').css('display', 'block');
                $('#background_1').css('display', 'none');
                $('#background_3').css('display', 'none');
                $('#background_4').css('display', 'none');
                $('#background_5').css('display', 'none');
                $('#background_6').css('display', 'none');
            }

            $('#background_1_m').css('display', 'none');

            $('#background_2_m').css('display', 'block');
            $('#background_3_m').css('display', 'none');
            $('#background_4_m').css('display', 'none');
            $('#background_5_m').css('display', 'none');
            $('#background_6_m').css('display', 'none');

            $('#background_1_m_1').css('display', 'none');

            $('#background_2_m_2').css('display', 'block');
            $('#background_3_m_3').css('display', 'none');
            $('#background_4_m_4').css('display', 'none');
            $('#background_5_m_5').css('display', 'none');
            $('#background_6_m_6').css('display', 'none');

            $('.for_res_event').css('display', 'none');
            $('.for_other_event').css('display', 'contents');
        } else if (event_type == 3) {
            if (getLanguage() == 'ma') {
                $('#background_3_mr').css('display', 'block');
                $('#background_1_mr').css('display', 'none');
                $('#background_2_mr').css('display', 'none');
                $('#background_4_mr').css('display', 'none');
                $('#background_5_mr').css('display', 'none');
                $('#background_6_mr').css('display', 'none');
            } else {
                $('#background_3').css('display', 'block');
                $('#background_1').css('display', 'none');
                $('#background_2').css('display', 'none');
                $('#background_4').css('display', 'none');
                $('#background_5').css('display', 'none');
                $('#background_6').css('display', 'none');
            }

            $('#background_1_m').css('display', 'none');

            $('#background_2_m').css('display', 'none');
            $('#background_3_m').css('display', 'none');
            $('#background_4_m').css('display', 'block');
            $('#background_5_m').css('display', 'none');
            $('#background_6_m').css('display', 'none');

            $('#background_1_m_1').css('display', 'none');

            $('#background_2_m_2').css('display', 'none');
            $('#background_3_m_3').css('display', 'block');
            $('#background_4_m_4').css('display', 'none');
            $('#background_5_m_5').css('display', 'none');
            $('#background_6_m_6').css('display', 'none');

            $('.for_res_event').css('display', 'none');
            $('.for_other_event').css('display', 'contents');
        } else if (event_type == 4) {
            if (getLanguage() == 'ma') {
                $('#background_4_mr').css('display', 'block');
                $('#background_1_mr').css('display', 'none');
                $('#background_2_mr').css('display', 'none');
                $('#background_3_mr').css('display', 'none');
                $('#background_5_mr').css('display', 'none');
                $('#background_6_mr').css('display', 'none');
            } else {
                $('#background_4').css('display', 'block');
                $('#background_1').css('display', 'none');
                $('#background_2').css('display', 'none');
                $('#background_3').css('display', 'none');
                $('#background_5').css('display', 'none');
                $('#background_6').css('display', 'none');
            }

            $('#background_1_m').css('display', 'none');

            $('#background_2_m').css('display', 'none');
            $('#background_3_m').css('display', 'none');
            $('#background_4_m').css('display', 'block');
            $('#background_5_m').css('display', 'none');
            $('#background_6_m').css('display', 'none');

            $('#background_1_m_1').css('display', 'none');

            $('#background_2_m_2').css('display', 'none');
            $('#background_3_m_3').css('display', 'none');
            $('#background_4_m_4').css('display', 'block');
            $('#background_5_m_5').css('display', 'none');
            $('#background_6_m_6').css('display', 'none');

            $('.for_res_event').css('display', 'none');
            $('.for_other_event').css('display', 'contents');
        } else if (event_type == 5) {
            if (getLanguage() == 'ma') {
                $('#background_5_mr').css('display', 'block');
                $('#background_1_mr').css('display', 'none');
                $('#background_2_mr').css('display', 'none');
                $('#background_3_mr').css('display', 'none');
                $('#background_4_mr').css('display', 'none');
                $('#background_6_mr').css('display', 'none');
            } else {
                $('#background_5').css('display', 'block');
                $('#background_1').css('display', 'none');
                $('#background_2').css('display', 'none');
                $('#background_3').css('display', 'none');
                $('#background_4').css('display', 'none');
                $('#background_6').css('display', 'none');
            }

            $('#background_1_m').css('display', 'none');

            $('#background_2_m').css('display', 'none');
            $('#background_3_m').css('display', 'none');
            $('#background_4_m').css('display', 'none');
            $('#background_5_m').css('display', 'block');
            $('#background_6_m').css('display', 'none');


            $('#background_1_m_1').css('display', 'none');

            $('#background_2_m_2').css('display', 'none');
            $('#background_3_m_3').css('display', 'none');
            $('#background_4_m_4').css('display', 'none');
            $('#background_5_m_5').css('display', 'block');
            $('#background_6_m_6').css('display', 'none');

            $('.for_res_event').css('display', 'none');
            $('.for_other_event').css('display', 'contents');
        } else if (event_type == 6) {
            if (getLanguage() == 'ma') {
                $('#background_6_mr').css('display', 'block');
                $('#background_1_mr').css('display', 'none');
                $('#background_2_mr').css('display', 'none');
                $('#background_3_mr').css('display', 'none');
                $('#background_4_mr').css('display', 'none');
                $('#background_5_mr').css('display', 'none');
            } else {
                $('#background_6').css('display', 'block');
                $('#background_1').css('display', 'none');
                $('#background_2').css('display', 'none');
                $('#background_3').css('display', 'none');
                $('#background_4').css('display', 'none');
                $('#background_5').css('display', 'none');
            }

            $('#background_1_m').css('display', 'none');

            $('#background_2_m').css('display', 'none');
            $('#background_3_m').css('display', 'none');
            $('#background_4_m').css('display', 'none');
            $('#background_5_m').css('display', 'none');
            $('#background_6_m').css('display', 'block');


            $('#background_1_m_1').css('display', 'none');

            $('#background_2_m_2').css('display', 'none');
            $('#background_3_m_3').css('display', 'none');
            $('#background_4_m_4').css('display', 'none');
            $('#background_5_m_5').css('display', 'none');
            $('#background_6_m_6').css('display', 'block');

            $('.for_res_event').css('display', 'none');
            $('.for_other_event').css('display', 'contents');
        }
        var topic_id = answer_array['4'];

        var language_id = answer_array['5'];

        if (topic_id > 0) {
            this.state.guestinfo.topic_id = topic_id;
        } else {
            var topic_id = 0;
        }

        if (language_id > 0) {
            this.state.guestinfo.language_id = language_id;
        } else {
            var language_id = 0;
        }

        // alert(topic_id);
        if (event_type > 0) {
            this.state.guestinfo.event_type_id = event_type;
        } else {
            var event_type = 0;
        }

        fetch(myConstClass.BASE_URL + 'event/getEventsFrontEnd' + '/' + getLanguage() + '/' + event_type + '/' + topic_id + '/' + language_id)
            .then(response => response.json())
            .then(response => {
                this.setState({ posts: response })
                //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                this.state.guestinfo.event_type_name = answer_array['2'];
                this.state.guestinfo.event_type = answer_array['2'];
                this.state.guestinfo.event_id = this.state.posts[0].event_id;

                if (this.state.posts.length > 0) {
                    var numRows = this.state.posts[0].arr_events.length;
                    if (numRows > 0) {
                        $('.productNotAvailable').css('display', 'none');
                    } else {
                        $('.productNotAvailable').css('display', 'block');
                    }
                } else {
                    $('.productNotAvailable').css('display', 'inline');
                }
                // var numRows = this.state.posts[0].arr_events.length;
                // if(numRows > 0){
                //     $('.productNotAvailable').css('display','none');
                // }else{
                //     $('.productNotAvailable').css('display','block');
                // }

                //console.log('Rahul'+this.state.guestinfo.event_id);


                // var event_id = this.state.guestinfo.event_id;
                // fetch(myConstClass.BASE_URL+'event/getAllScheduleEventFrontEnd'+'/'+getLanguage()+'/'+event_id+'/'+user_id)
                // .then(response => response.json())
                // .then(response =>{this.setState({scheduleEvent: response})
                // const numRows = this.state.scheduleEvent.length;
                // if(numRows < 1){
                //     $('.noScheduleAvailable').css('display','block');
                // }else{
                //     this.state.guestinfo.schedule_event_name = this.state.scheduleEvent[0].title;
                //     $('.noScheduleAvailable').css('display','none');

                // }
                // })
                // .catch(err => console.log(err))




            })
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'event/getEventTypeDetails' + '/' + getLanguage() + '/' + answer_array['3'] + '/' + user_id)
            .then(response => response.json())
            .then(response => {
                this.setState({ eventdetails: response })
                //const numRows = this.state.scheduleEvent.length;
                var event_type_id = this.state.eventdetails[0].event_type_id;
                //alert(event_type_id);
                if (event_type_id == 7) {
                    $('.CurrentEventType').css('display', 'none');

                } else {
                    $('.CurrentEventType').css('display', 'inline');
                }
                this.state.eventdetails.map((infos, ii) => {
                    if (ii == '0') {
                        this.setState({ share_current_image_url: window.location.href });
                        this.setState({ social_description: infos.event_description })

                    }
                })

            })


        fetch(myConstClass.BASE_URL + 'cart/getEventCartDataWithoutGroupWise/' + user_id + '/' + getLanguage() + '/' + event_type)
            .then(response => response.json())
            .then(response => {
                this.setState({ cartEvent: response })

                const numRows = this.state.cartEvent.length;
                localStorage.setItem("cartCount", numRows)
                if (numRows > 0) {
                    $('.noItemInCart').css('display', 'none');
                } else {
                    $('.noItemInCart').css('display', 'block');
                    // $('.goToCartBtn').css('display', 'none');

                }

            })


        fetch(myConstClass.BASE_URL + 'event/getEventTypes')
            .then(response => response.json())
            .then(response => this.setState({ eventTypes: response }))
            .catch(err => console.log(err))


        fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
            .then(response => response.json())
            .then(response => this.setState({ topic: response }))
            .catch(err => console.log(err))

        fetch(myConstClass.BASE_URL + 'product/getProductLanguages')
            .then(response => response.json())
            .then(response => this.setState({ languages: response }))
            .catch(err => console.log(err))



        if (user_id > 0) {
            var btoa_user_id = btoa(user_id);
            // fetch(myConstClass.BASE_URL + 'users/getUserProfile/' + user_id)
            fetch(myConstClass.BASE_URL + 'users/getUserProfile/' + btoa_user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ notifyinfo: response })
                    const numRows = this.state.notifyinfo.length;
                    if (numRows > 0) {
                        this.state.notifyinfo.first_name = this.state.notifyinfo[0].first_name;
                        //this.state.notifyinfo.middle_name = this.state.notifyinfo[0].middle_name;
                        this.state.notifyinfo.last_name = this.state.notifyinfo[0].last_name;
                        this.state.notifyinfo.phone = this.state.notifyinfo[0].phone;
                        this.state.notifyinfo.email = this.state.notifyinfo[0].email;

                    }
                })
        }

    }

    componentDidUpdate(prevProps) {
        console.log("ComponentDidUpdate")
        const answer_array = this.props.location.pathname.split('/');
        //alert(answer_array['2']);
        const { flag } = this.state
        var current_product_type = answer_array['3'];
        //alert(answer_array['3']);
        var old_event_type = localStorage.getItem('old_event_type');
        var user_id = localStorage.getItem('user_id');
        localStorage.setItem('old_event_type', current_product_type);
        if (answer_array['3'] == 1) {
            $('.for_res_event').css('display', 'contents');
            $('.for_other_event').css('display', 'none');
        } else {
            $('.for_res_event').css('display', 'none');
            $('.for_other_event').css('display', 'contents');
        }
        if ((answer_array['3'] == 1 || answer_array['3'] == 5)) {
            $('.marathiStep1').css('display', 'inline');
            $('.marathiStep2').css('display', 'none');
        } else {
            $('.marathiStep1').css('display', 'none');
            $('.marathiStep2').css('display', 'inline');
        }
        if ((answer_array['3'] == 4 || answer_array['3'] == 2) && getLanguage() == 'ma') {
            $('.bookNowInCartMrPoojs').css('display', 'inline');
            $('.bookNowInCartMr').css('display', 'none');
            $('.bookNowInCartEn').css('display', 'none');
        } else if ((answer_array['3'] == 1 || answer_array['3'] == 5) && getLanguage() == 'ma') {
            $('.bookNowInCartMrPoojs').css('display', 'none');
            $('.bookNowInCartMr').css('display', 'inline');
            $('.bookNowInCartEn').css('display', 'none');
        } else {
            $('.bookNowInCartMrPoojs').css('display', 'none');
            $('.bookNowInCartMr').css('display', 'none');
            $('.bookNowInCartEn').css('display', 'inline');
        }

        var language_id = answer_array['5'];
        console.log("Language : ", language_id);
        if (language_id == '') {
            language_id = 0;
        }
        var old_language_id = localStorage.getItem('old_language_id');
        localStorage.setItem('old_language_id', language_id);

        //alert(old_event_type);
        var current_language = localStorage.getItem('current_language');

        if (current_language != getLanguage() && prevProps.history.location.pathname !== prevProps.location.pathname && current_language != null) {
            //alert(current_language);
            localStorage.removeItem('current_language');
            localStorage.setItem('current_language', getLanguage());
            // this.setState({ flag: 1 });
        }
        if (old_event_type != '' && flag != 1 && (current_product_type != old_event_type || old_language_id != language_id) && old_event_type != null && old_event_type > 0) {
            // this.setState({ flag: 1 });
            //alert('hi');
        } else {
            if (current_product_type == 0 && flag != 1 && (current_product_type != old_event_type || old_language_id != language_id) && old_event_type != null) {
                //alert('hii')
                this.setState({ flag: 1 });
            }
        }


        var current_topic_id = answer_array['4'];
        //alert(current_topic_id);
        var old_topic_id = localStorage.getItem('old_topic_id');
        if (old_topic_id == undefined) {
            old_topic_id = answer_array['4'];
        }
        //alert('current_topic_id'+current_topic_id+'old_topic_id'+old_topic_id);
        localStorage.setItem('old_topic_id', current_topic_id);
        //alert(old_topic_id);
        if (old_topic_id != '' && flag != 1 && current_topic_id != old_topic_id && old_topic_id != null && old_topic_id > 0) {
            this.setState({ flag: 1 });
            //alert('hi');
        } else {
            if (current_topic_id > 0 && flag != 1 && current_topic_id != old_topic_id) {
                this.setState({ flag: 1 });
            }
        }

        var event_type = answer_array['3'];




        var topic_id = answer_array['4'];
        if (topic_id > 0) {
            this.state.guestinfo.topic_id = topic_id;
        } else {
            var topic_id = 0;
        }
        // alert(event_type);
        if (event_type > 0) {
            this.state.guestinfo.event_type_id = event_type;
        } else {
            var event_type = 0;
        }
        //const { flag} = this.state  
        if (getLanguage() == 'ma' && prevProps.history.location.pathname !== prevProps.location.pathname) {
            if (getLanguage() == 'ma') {
                this.state.ResidentialStudyCourses = ResidentialStudyCourses_mr;
                this.state.OneDayWorkshop = OneDayWorkshop_mr;
                this.state.RationalPoojas = RationalPoojas_mr;
                this.state.FreeEvents = FreeEvents_mr;
            } else {
                this.state.ResidentialStudyCourses = ResidentialStudyCourses;
                this.state.OneDayWorkshop = OneDayWorkshop;
                this.state.RationalPoojas = RationalPoojas;
                this.state.FreeEvents = FreeEvents;
            }


            this.state.scheduleEvent = [];
            $('.noScheduleAvailable').css('display', 'block');
            this.setState({ flag: 0 })
            fetch(myConstClass.BASE_URL + 'event/getEventsFrontEnd' + '/' + getLanguage() + '/' + event_type + '/' + topic_id + '/' + language_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ posts: response })
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                    this.state.guestinfo.event_type_name = answer_array['2'];
                    if (this.state.posts.length > 0) {
                        var numRows = this.state.posts[0].arr_events.length;
                        if (numRows > 0) {
                            $('.productNotAvailable').css('display', 'none');
                        } else {
                            $('.productNotAvailable').css('display', 'block');
                        }
                    } else {
                        $('.productNotAvailable').css('display', 'inline');

                    }


                })

            fetch(myConstClass.BASE_URL + 'event/getEventTypeDetails' + '/' + getLanguage() + '/' + answer_array['3'])
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventdetails: response })
                    //const numRows = this.state.scheduleEvent.length;
                    var event_type_id = this.state.eventdetails[0].event_type_id;
                    if (event_type_id == 7) {
                        $('.CurrentEventType').css('display', 'none');

                    } else {
                        $('.CurrentEventType').css('display', 'inline');
                    }

                    this.state.eventdetails.map((infos, ii) => {
                        if (ii == '0') {
                            this.setState({ share_current_image_url: window.location.href });
                            this.setState({ social_description: infos.event_description })

                        }
                    })

                })


            fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ topic: response }))
                .catch(err => console.log(err))

                .catch(err => console.log(err))

            if (event_type == 1) {
                if (getLanguage() == 'ma') {
                    $('#background_1_mr').css('display', 'block');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');

                    $('#background_1').css('display', 'none');
                    $('#background_2').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_5').css('display', 'none');
                    $('#background_6').css('display', 'none');
                } else {

                    $('#background_1_mr').css('display', 'none');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');


                    $('#background_1').css('display', 'block');
                    $('#background_2').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_5').css('display', 'none');
                    $('#background_6').css('display', 'none');
                }


                $('#background_1_m').css('display', 'block');

                $('#background_2_m').css('display', 'none');
                $('#background_3_m').css('display', 'none');
                $('#background_4_m').css('display', 'none');
                $('#background_5_m').css('display', 'none');
                $('#background_6_m').css('display', 'none');

                $('#background_1_m_1').css('display', 'block');

                $('#background_2_m_2').css('display', 'none');
                $('#background_3_m_3').css('display', 'none');
                $('#background_4_m_4').css('display', 'none');
                $('#background_5_m_5').css('display', 'none');
                $('#background_6_m_6').css('display', 'none');
            } else if (event_type == 2) {
                if (getLanguage() == 'ma') {
                    $('#background_1').css('display', 'none');
                    $('#background_2').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_5').css('display', 'none');
                    $('#background_6').css('display', 'none');

                    $('#background_2_mr').css('display', 'block');
                    $('#background_1_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');
                } else {

                    $('#background_1_mr').css('display', 'none');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');


                    $('#background_2').css('display', 'block');
                    $('#background_1').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_5').css('display', 'none');
                    $('#background_6').css('display', 'none');
                }

                $('#background_1_m').css('display', 'none');

                $('#background_2_m').css('display', 'block');
                $('#background_3_m').css('display', 'none');
                $('#background_4_m').css('display', 'none');
                $('#background_5_m').css('display', 'none');
                $('#background_6_m').css('display', 'none');

                $('#background_1_m_1').css('display', 'none');

                $('#background_2_m_2').css('display', 'block');
                $('#background_3_m_3').css('display', 'none');
                $('#background_4_m_4').css('display', 'none');
                $('#background_5_m_5').css('display', 'none');
                $('#background_6_m_6').css('display', 'none');
            } else if (event_type == 3) {
                if (getLanguage() == 'ma') {
                    $('#background_3_mr').css('display', 'block');
                    $('#background_1_mr').css('display', 'none');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');

                    $('#background_1').css('display', 'none');
                    $('#background_2').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_5').css('display', 'none');
                    $('#background_6').css('display', 'none');
                } else {

                    $('#background_1_mr').css('display', 'none');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');

                    $('#background_3').css('display', 'block');
                    $('#background_1').css('display', 'none');
                    $('#background_2').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_5').css('display', 'none');
                    $('#background_6').css('display', 'none');
                }

                $('#background_1_m').css('display', 'none');

                $('#background_2_m').css('display', 'none');
                $('#background_3_m').css('display', 'none');
                $('#background_4_m').css('display', 'block');
                $('#background_5_m').css('display', 'none');
                $('#background_6_m').css('display', 'none');

                $('#background_1_m_1').css('display', 'none');

                $('#background_2_m_2').css('display', 'none');
                $('#background_3_m_3').css('display', 'block');
                $('#background_4_m_4').css('display', 'none');
                $('#background_5_m_5').css('display', 'none');
                $('#background_6_m_6').css('display', 'none');
            } else if (event_type == 4) {
                if (getLanguage() == 'ma') {
                    $('#background_4_mr').css('display', 'block');
                    $('#background_1_mr').css('display', 'none');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');

                    $('#background_1').css('display', 'none');
                    $('#background_2').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_5').css('display', 'none');
                    $('#background_6').css('display', 'none');
                } else {
                    $('#background_4').css('display', 'block');
                    $('#background_1').css('display', 'none');
                    $('#background_2').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_5').css('display', 'none');
                    $('#background_6').css('display', 'none');


                    $('#background_1_mr').css('display', 'none');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');
                }

                $('#background_1_m').css('display', 'none');

                $('#background_2_m').css('display', 'none');
                $('#background_3_m').css('display', 'none');
                $('#background_4_m').css('display', 'block');
                $('#background_5_m').css('display', 'none');
                $('#background_6_m').css('display', 'none');

                $('#background_1_m_1').css('display', 'none');

                $('#background_2_m_2').css('display', 'none');
                $('#background_3_m_3').css('display', 'none');
                $('#background_4_m_4').css('display', 'block');
                $('#background_5_m_5').css('display', 'none');
                $('#background_6_m_6').css('display', 'none');
            } else if (event_type == 5) {
                if (getLanguage() == 'ma') {
                    $('#background_5_mr').css('display', 'block');
                    $('#background_1_mr').css('display', 'none');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');

                    $('#background_1').css('display', 'none');
                    $('#background_2').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_5').css('display', 'none');
                    $('#background_6').css('display', 'none');
                } else {
                    $('#background_5').css('display', 'block');
                    $('#background_1').css('display', 'none');
                    $('#background_2').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_6').css('display', 'none');

                    $('#background_1_mr').css('display', 'none');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');


                }

                $('#background_1_m').css('display', 'none');

                $('#background_2_m').css('display', 'none');
                $('#background_3_m').css('display', 'none');
                $('#background_4_m').css('display', 'none');
                $('#background_5_m').css('display', 'block');
                $('#background_6_m').css('display', 'none');


                $('#background_1_m_1').css('display', 'none');

                $('#background_2_m_2').css('display', 'none');
                $('#background_3_m_3').css('display', 'none');
                $('#background_4_m_4').css('display', 'none');
                $('#background_5_m_5').css('display', 'block');
                $('#background_6_m_6').css('display', 'none');
            } else if (event_type == 6) {
                if (getLanguage() == 'ma') {
                    $('#background_6_mr').css('display', 'block');
                    $('#background_1_mr').css('display', 'none');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');

                    $('#background_1').css('display', 'none');
                    $('#background_2').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_5').css('display', 'none');
                    $('#background_6').css('display', 'none');


                } else {
                    $('#background_6').css('display', 'block');
                    $('#background_1').css('display', 'none');
                    $('#background_2').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_5').css('display', 'none');

                    $('#background_1_mr').css('display', 'none');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');


                }

                $('#background_1_m').css('display', 'none');

                $('#background_2_m').css('display', 'none');
                $('#background_3_m').css('display', 'none');
                $('#background_4_m').css('display', 'none');
                $('#background_5_m').css('display', 'none');
                $('#background_6_m').css('display', 'block');


                $('#background_1_m_1').css('display', 'none');

                $('#background_2_m_2').css('display', 'none');
                $('#background_3_m_3').css('display', 'none');
                $('#background_4_m_4').css('display', 'none');
                $('#background_5_m_5').css('display', 'none');
                $('#background_6_m_6').css('display', 'block');
            }

            fetch(myConstClass.BASE_URL + 'cart/getEventCartDataWithoutGroupWise/' + user_id + '/' + getLanguage() + '/' + event_type)
                .then(response => response.json())
                .then(response => {
                    this.setState({ cartEvent: response })

                    const numRows = this.state.cartEvent.length;
                    localStorage.setItem("cartCount", numRows)
                    if (numRows > 0) {
                        $('.noItemInCart').css('display', 'none');
                    } else {
                        $('.noItemInCart').css('display', 'block');
                        // document.getElementById('goToCartBtn').style.display = 'none';
                        // $('.goToCartBtn').css('display', 'none');

                    }

                })
        }

        if (getLanguage() == 'en' && prevProps.history.location.pathname !== prevProps.location.pathname) {
            if (getLanguage() == 'ma') {
                this.state.ResidentialStudyCourses = ResidentialStudyCourses_mr;
                this.state.OneDayWorkshop = OneDayWorkshop_mr;
                this.state.RationalPoojas = RationalPoojas_mr;
                this.state.FreeEvents = FreeEvents_mr;
            } else {
                this.state.ResidentialStudyCourses = ResidentialStudyCourses;
                this.state.OneDayWorkshop = OneDayWorkshop;
                this.state.RationalPoojas = RationalPoojas;
                this.state.FreeEvents = FreeEvents;
            }


            this.state.scheduleEvent = [];
            $('.noScheduleAvailable').css('display', 'block');
            if (event_type == 1) {
                if (getLanguage() == 'ma') {
                    $('#background_1_mr').css('display', 'block');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');

                    $('#background_1').css('display', 'none');
                    $('#background_2').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_5').css('display', 'none');
                    $('#background_6').css('display', 'none');
                } else {

                    $('#background_1_mr').css('display', 'none');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');


                    $('#background_1').css('display', 'block');
                    $('#background_2').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_5').css('display', 'none');
                    $('#background_6').css('display', 'none');
                }


                $('#background_1_m').css('display', 'block');

                $('#background_2_m').css('display', 'none');
                $('#background_3_m').css('display', 'none');
                $('#background_4_m').css('display', 'none');
                $('#background_5_m').css('display', 'none');
                $('#background_6_m').css('display', 'none');

                $('#background_1_m_1').css('display', 'block');

                $('#background_2_m_2').css('display', 'none');
                $('#background_3_m_3').css('display', 'none');
                $('#background_4_m_4').css('display', 'none');
                $('#background_5_m_5').css('display', 'none');
                $('#background_6_m_6').css('display', 'none');
            } else if (event_type == 2) {
                if (getLanguage() == 'ma') {
                    $('#background_1').css('display', 'none');
                    $('#background_2').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_5').css('display', 'none');
                    $('#background_6').css('display', 'none');

                    $('#background_2_mr').css('display', 'block');
                    $('#background_1_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');
                } else {

                    $('#background_1_mr').css('display', 'none');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');


                    $('#background_2').css('display', 'block');
                    $('#background_1').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_5').css('display', 'none');
                    $('#background_6').css('display', 'none');
                }

                $('#background_1_m').css('display', 'none');

                $('#background_2_m').css('display', 'block');
                $('#background_3_m').css('display', 'none');
                $('#background_4_m').css('display', 'none');
                $('#background_5_m').css('display', 'none');
                $('#background_6_m').css('display', 'none');

                $('#background_1_m_1').css('display', 'none');

                $('#background_2_m_2').css('display', 'block');
                $('#background_3_m_3').css('display', 'none');
                $('#background_4_m_4').css('display', 'none');
                $('#background_5_m_5').css('display', 'none');
                $('#background_6_m_6').css('display', 'none');
            } else if (event_type == 3) {
                if (getLanguage() == 'ma') {
                    $('#background_3_mr').css('display', 'block');
                    $('#background_1_mr').css('display', 'none');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');

                    $('#background_1').css('display', 'none');
                    $('#background_2').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_5').css('display', 'none');
                    $('#background_6').css('display', 'none');
                } else {

                    $('#background_1_mr').css('display', 'none');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');

                    $('#background_3').css('display', 'block');
                    $('#background_1').css('display', 'none');
                    $('#background_2').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_5').css('display', 'none');
                    $('#background_6').css('display', 'none');
                }

                $('#background_1_m').css('display', 'none');

                $('#background_2_m').css('display', 'none');
                $('#background_3_m').css('display', 'none');
                $('#background_4_m').css('display', 'block');
                $('#background_5_m').css('display', 'none');
                $('#background_6_m').css('display', 'none');

                $('#background_1_m_1').css('display', 'none');

                $('#background_2_m_2').css('display', 'none');
                $('#background_3_m_3').css('display', 'block');
                $('#background_4_m_4').css('display', 'none');
                $('#background_5_m_5').css('display', 'none');
                $('#background_6_m_6').css('display', 'none');
            } else if (event_type == 4) {
                if (getLanguage() == 'ma') {
                    $('#background_4_mr').css('display', 'block');
                    $('#background_1_mr').css('display', 'none');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');

                    $('#background_1').css('display', 'none');
                    $('#background_2').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_5').css('display', 'none');
                    $('#background_6').css('display', 'none');
                } else {
                    $('#background_4').css('display', 'block');
                    $('#background_1').css('display', 'none');
                    $('#background_2').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_5').css('display', 'none');
                    $('#background_6').css('display', 'none');


                    $('#background_1_mr').css('display', 'none');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');
                }

                $('#background_1_m').css('display', 'none');

                $('#background_2_m').css('display', 'none');
                $('#background_3_m').css('display', 'none');
                $('#background_4_m').css('display', 'block');
                $('#background_5_m').css('display', 'none');
                $('#background_6_m').css('display', 'none');

                $('#background_1_m_1').css('display', 'none');

                $('#background_2_m_2').css('display', 'none');
                $('#background_3_m_3').css('display', 'none');
                $('#background_4_m_4').css('display', 'block');
                $('#background_5_m_5').css('display', 'none');
                $('#background_6_m_6').css('display', 'none');
            } else if (event_type == 5) {
                if (getLanguage() == 'ma') {
                    $('#background_5_mr').css('display', 'block');
                    $('#background_1_mr').css('display', 'none');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');

                    $('#background_1').css('display', 'none');
                    $('#background_2').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_5').css('display', 'none');
                    $('#background_6').css('display', 'none');
                } else {
                    $('#background_5').css('display', 'block');
                    $('#background_1').css('display', 'none');
                    $('#background_2').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_6').css('display', 'none');

                    $('#background_1_mr').css('display', 'none');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');


                }

                $('#background_1_m').css('display', 'none');

                $('#background_2_m').css('display', 'none');
                $('#background_3_m').css('display', 'none');
                $('#background_4_m').css('display', 'none');
                $('#background_5_m').css('display', 'block');
                $('#background_6_m').css('display', 'none');


                $('#background_1_m_1').css('display', 'none');

                $('#background_2_m_2').css('display', 'none');
                $('#background_3_m_3').css('display', 'none');
                $('#background_4_m_4').css('display', 'none');
                $('#background_5_m_5').css('display', 'block');
                $('#background_6_m_6').css('display', 'none');
            } else if (event_type == 6) {
                if (getLanguage() == 'ma') {
                    $('#background_6_mr').css('display', 'block');
                    $('#background_1_mr').css('display', 'none');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');

                    $('#background_1').css('display', 'none');
                    $('#background_2').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_5').css('display', 'none');
                    $('#background_6').css('display', 'none');


                } else {
                    $('#background_6').css('display', 'block');
                    $('#background_1').css('display', 'none');
                    $('#background_2').css('display', 'none');
                    $('#background_3').css('display', 'none');
                    $('#background_4').css('display', 'none');
                    $('#background_5').css('display', 'none');

                    $('#background_1_mr').css('display', 'none');
                    $('#background_2_mr').css('display', 'none');
                    $('#background_3_mr').css('display', 'none');
                    $('#background_4_mr').css('display', 'none');
                    $('#background_5_mr').css('display', 'none');
                    $('#background_6_mr').css('display', 'none');


                }

                $('#background_1_m').css('display', 'none');

                $('#background_2_m').css('display', 'none');
                $('#background_3_m').css('display', 'none');
                $('#background_4_m').css('display', 'none');
                $('#background_5_m').css('display', 'none');
                $('#background_6_m').css('display', 'block');


                $('#background_1_m_1').css('display', 'none');

                $('#background_2_m_2').css('display', 'none');
                $('#background_3_m_3').css('display', 'none');
                $('#background_4_m_4').css('display', 'none');
                $('#background_5_m_5').css('display', 'none');
                $('#background_6_m_6').css('display', 'block');
            }
            this.setState({ flag: 0 })
            fetch(myConstClass.BASE_URL + 'event/getEventsFrontEnd' + '/' + getLanguage() + '/' + event_type + '/' + topic_id + '/' + language_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ posts: response })
                    //this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
                    this.state.guestinfo.event_type_name = answer_array['2'];
                    if (this.state.posts.length > 0) {
                        var numRows = this.state.posts[0].arr_events.length;
                        if (numRows > 0) {
                            $('.productNotAvailable').css('display', 'none');
                        } else {
                            $('.productNotAvailable').css('display', 'block');
                        }
                    } else {
                        $('.productNotAvailable').css('display', 'inline');
                    }
                })

            fetch(myConstClass.BASE_URL + 'event/getEventTypeDetails' + '/' + getLanguage() + '/' + answer_array['3'])
                .then(response => response.json())
                .then(response => {
                    this.setState({ eventdetails: response })
                    //const numRows = this.state.scheduleEvent.length;
                    var event_type_id = response[0].event_type_id;
                    if (event_type_id == 7) {
                        $('.CurrentEventType').css('display', 'none');

                    } else {
                        $('.CurrentEventType').css('display', 'inline');
                    }
                    this.state.eventdetails.map((infos, ii) => {
                        if (ii == '0') {
                            this.setState({ share_current_image_url: window.location.href });
                            this.setState({ social_description: infos.event_description })

                        }
                    })
                })

            fetch(myConstClass.BASE_URL + 'getAllTopics' + '/' + getLanguage())
                .then(response => response.json())
                .then(response => this.setState({ topic: response }))
                .catch(err => console.log(err))

                .catch(err => console.log(err))


            fetch(myConstClass.BASE_URL + 'cart/getEventCartDataWithoutGroupWise/' + user_id + '/' + getLanguage() + '/' + event_type)
                .then(response => response.json())
                .then(response => {
                    this.setState({ cartEvent: response })

                    const numRows = this.state.cartEvent.length;
                    localStorage.setItem("cartCount", numRows)
                    if (numRows > 0) {
                        $('.noItemInCart').css('display', 'none');
                    } else {
                        $('.noItemInCart').css('display', 'block');
                        // $('.goToCartBtn').css('display', 'none');
                    }

                })
        }
        this.state.guestinfo.event_type_name = answer_array['2'];
    }

    changeQuantity(e, product_id) {
        //this.state.quantityValue = event.target.value;
        // this.setState(
        //     {quantityValue : event.target.value}
        // );
        //alert(product_id);
        // this.setState({quantityValue: e.target.value});
        // document.getElementById("qty"+product_id).value = e;

    }

    showSchedule(e) {

        const currentEventId = e.target.id;

        var btnName = document.getElementById(currentEventId).innerHTML;
        //alert(currentEventId);
        if (btnName == 'SHOW SCHEDULE' || btnName == 'वेळापत्रक दर्शवा') {

            const previousEventId = localStorage.getItem('currentEventId');

            const eventIdArr = currentEventId.split('_');

            const eventId = eventIdArr[1];

            var user_id = localStorage.getItem('user_id');
            if (user_id == null || user_id == undefined) {
                user_id = 0;
            }



            if (document.getElementById(previousEventId) && document.getElementById(previousEventId) !== currentEventId) {
                if (getLanguage() == 'ma') {
                    document.getElementById(previousEventId).innerHTML = "वेळापत्रक दर्शवा"
                } else {
                    document.getElementById(previousEventId).innerHTML = "SHOW SCHEDULE"
                }

            }

            $(".eventrow").removeClass("border-warning ");
            $(".eventrow").removeClass("bg-white");
            $(".eventrow").addClass("border-dark bg-light-light");

            $('.viewScheduleForMobile').css('display', 'none');
            $('.viewScheduleForMobile' + eventIdArr[1] + '_' + eventIdArr[2] + '_' + eventIdArr[3]).css('display', 'inline');

            console.log('.viewScheduleForMobile' + eventIdArr[1] + '_' + eventIdArr[2] + '_' + eventIdArr[3]);

            $("." + previousEventId).removeClass("btn-light");
            $("." + previousEventId).addClass("btn-outline-brown");
            $("." + previousEventId).css("color", 'red');

            $("." + currentEventId).removeClass("btn-outline-brown");
            $("." + currentEventId).addClass("btn-light");
            $("." + currentEventId).css("color", 'black');


            // $(".Row_" + event_id).removeClass("border-dark bg-light-light");
            // $(".Row_" + event_id).addClass("border-warning bg-white");

            localStorage.removeItem('currentEventId');
            localStorage.setItem('currentEventId', currentEventId);


            fetch(myConstClass.BASE_URL + 'event/getAllScheduleEventFrontEnd' + '/' + getLanguage() + '/' + eventIdArr[2] + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({
                        scheduleEvent: response,
                        showScheduleFlag: response.length
                    })
                    const numRows = this.state.scheduleEvent.length;
                    $('.scheduleMainDiv').css('display', 'block');
                    $('.withoutScheduleDiv').css('display', 'none');

                    $('.eventRightMenuMain').css('margin-top', '48px');
                    if (getLanguage() == 'ma') {
                        document.getElementById(currentEventId).innerHTML = "वेळापत्रक लपवा";
                    } else {
                        $(`#${currentEventId}`).html("HIDE SCHEDULE"); //versions newer than 1.6
                        // document.getElementById(currentEventId).innerHTML = ;
                    }
                    // if (getLanguage() == 'ma') {
                    //     document.getElementById(previousEventId).innerHTML = "वेळापत्रक दर्शवा";
                    // } else {
                    //     document.getElementById(previousEventId).innerHTML = "SHOW SCHEDULE";
                    // }

                    if (numRows < 1) {
                        $('.noScheduleAvailable').css('display', 'block');
                        $('.eventRightMenuMain').css('margin-top', '48px')
                    } else {
                        this.setState({
                            guestinfo: {
                                ...this.state.guestinfo,
                                schedule_event_name: response[0].title
                            }
                        })
                        $('.noScheduleAvailable').css('display', 'none');

                    }
                })
                .catch(err => console.log(err))
        } else {
            const previousEventId = localStorage.getItem('currentEventId');
            $(".eventrow").removeClass("border-warning");
            $(".eventrow").removeClass("bg-white");
            $(".eventrow").addClass("border-dark bg-light-light");

            $('.viewScheduleForMobile').css('display', 'none');
            $('.viewScheduleForMobile' + currentEventId).css('display', 'inline');

            $('.scheduleMainDiv').css('display', 'none');
            $('.withoutScheduleDiv').css('display', 'block');
            $(".schedule_" + previousEventId).removeClass("btn-light");
            $(".schedule_" + previousEventId).addClass("btn-outline-brown");
            $(".schedule_" + previousEventId).css("color", 'black');
            //document.getElementById(previousEventId).innerHTML = "SHOW SCHEDULE"
            $(`#${currentEventId}`).html("SHOW SCHEDULE"); //versions newer than 1.6
            this.setState({ scheduleEvent: [] })

        }
    }

    showMoreEvents(e) {
        var show_more_event_type_id = e.target.id;
        var arr_show_more_event_type = show_more_event_type_id.split('_');
        var current_event_type_id = arr_show_more_event_type[1];
        $('.showMoreEventsDiv_' + current_event_type_id).css('display', 'block');
        $('#showMoreEvents_' + current_event_type_id).css('display', 'none');
    }


    deleteFromCart(e) {
        const answer_array = this.props.location.pathname.split('/');
        var event_type = answer_array['3'];

        if (event_type > 0) {
            this.state.guestinfo.event_type_id = event_type;
        } else {
            var event_type = 0;
        }
        var delete_event_schedule_id = e.target.id;
        var arr_event_schedule = delete_event_schedule_id.split('_');
        var event_id = arr_event_schedule[1];
        var schedule_id = arr_event_schedule[2];
        var event_schedule_id = event_id + '_' + schedule_id;


        var user_id = localStorage.getItem('user_id');


        if ((schedule_id > 0) && user_id != '' && user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/removeProductFromCart/' + schedule_id + '/' + user_id)
                .then(response => response.json())
                .then(response => {
                    this.setState({ removeFromCart: response })
                    var message = this.state.removeFromCart.message;
                    var status = this.state.removeFromCart.status;
                    if (status == 'true') {
                        $('.' + event_schedule_id).css('display', 'inline');
                        $('.remove' + schedule_id).css('display', 'none');
                        $('.' + delete_event_schedule_id).css('display', 'none'); //for delete

                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });

                        if (user_id > 0 && user_id != null) {
                            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartcount: response })
                                    var totalcartcount = this.state.cartcount.totalcartcount;
                                    $('.lblCartCount').html(totalcartcount);
                                })


                            fetch(myConstClass.BASE_URL + 'cart/getEventCartDataWithoutGroupWise/' + user_id + '/' + getLanguage() + '/' + event_type)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartEvent: response })
                                    const numRows = this.state.cartEvent.length;
                                    localStorage.setItem("cartCount", numRows)
                                    if (numRows > 0) {
                                        $('.noItemInCart').css('display', 'none');
                                    } else {
                                        $('.noItemInCart').css('display', 'block');
                                        // $('.goToCartBtn').css('display', 'none');
                                    }
                                })
                        }


                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err))


        } else {
            //toastr.warning('Product quantity must be greater than 0')
            if (user_id > 0) {
                toast('Error occure.', { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                var message = "Please login to our website.";
                localStorage.removeItem('error');
                localStorage.setItem('error', message);
                this.props.history.push('/signin')
                //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

            }

        }
        //toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!')
        //this.props.offline();    
    }

    notifyMe(e) {
        var event_schedule_id = e.target.id;
        var arr_event_schedule = event_schedule_id.split('_');
        var schedule_id = arr_event_schedule[1];
        var title = $('.notifyScheduletitle_' + schedule_id).val();
        var eventTitle = $('#notifyEventtitle_' + schedule_id).val();
        $('#eventTitle').html(eventTitle);
        // alert(schedule_id);
        $('#notifyTitle').html(title);
        $('#notify_schedule_id').val(schedule_id);
        $('#notifyModel').modal('show');
    }
    addToCart(e) {

        const answer_array = this.props.location.pathname.split('/');
        var event_type = answer_array['3'];

        if (event_type > 0) {
            this.state.guestinfo.event_type_id = event_type;
        } else {
            var event_type = 0;
        }

        var event_schedule_id = e.target.id;
        var arr_event_schedule = event_schedule_id.split('_');
        var event_id = arr_event_schedule[0];
        var schedule_id = arr_event_schedule[1];
        // console.log(e.target.id);
        //alert(event_id);
        var parent = $('#parent' + event_id).val();
        // alert(parent);
        if (parent > 0) {

        } else {
            var parent = $('#parentm' + event_id).val();
        }

        var child = $('#child' + event_id).val();
        if (child > 0) {

        } else {
            var child = $('#childm' + event_id).val();
        }
        //alert(parent);

        var user_id = localStorage.getItem('user_id');
        //console.log(product_quantity);

        if ((parent > 0 || child > 0) && user_id != '' && user_id > 0) {
            fetch(myConstClass.BASE_URL + 'cart/addEventToCart/' + schedule_id + '/' + user_id + '/' + parent + '/' + child)
                .then(response => response.json())
                .then(response => {
                    this.setState({ cart: response })
                    var message = this.state.cart.message;
                    var status = this.state.cart.status;
                    if (status == 'true') {

                        $('.' + event_schedule_id).css('display', 'none');
                        $('.remove' + schedule_id).css('display', 'contents');
                        $('.delete_' + event_schedule_id).css('display', 'contents');

                        toast('' + message, { containerId: 'B', type: toast.TYPE.SUCCESS });
                        if (user_id > 0 && user_id != null) {
                            fetch(myConstClass.BASE_URL + 'cart/getTotalCartCount/' + user_id)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartcount: response })
                                    var totalcartcount = this.state.cartcount.totalcartcount;
                                    $('.lblCartCount').html(totalcartcount);
                                })


                            fetch(myConstClass.BASE_URL + 'cart/getEventCartDataWithoutGroupWise/' + user_id + '/' + getLanguage() + '/' + event_type)
                                .then(response => response.json())
                                .then(response => {
                                    this.setState({ cartEvent: response })
                                    const numRows = this.state.cartEvent.length;
                                    localStorage.setItem("cartCount", numRows)
                                    if (numRows > 0) {
                                        $('.noItemInCart').css('display', 'none');
                                        $('.goToCartBtn').css('display', 'block');
                                    } else {
                                        $('.noItemInCart').css('display', 'block');
                                        // $('.goToCartBtn').css('display', 'none');
                                    }
                                })

                        }
                    } else {
                        toast('' + message, { containerId: 'B', type: toast.TYPE.ERROR });
                    }
                    //console.log(status);
                })
                .catch(err => console.log(err))


        } else {
            //toastr.warning('Product quantity must be greater than 0')
            if (user_id > 0) {
                if (getLanguage() == 'ma') {
                    var message = "कृपया सहभागींची संख्या निवडा.";
                } else {
                    var message = "Please select the number of participants.";
                }
                toast(message, { containerId: 'B', type: toast.TYPE.ERROR });
            } else {
                var message = "Please login to our website.";
                localStorage.removeItem('error');
                localStorage.setItem('error', message);
                this.props.history.push('/signin')
                //toast('Please login to our website.', {containerId: 'B',type: toast.TYPE.ERROR}); 

            }

        }
        //toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!')
        //this.props.offline();    
    }

    mobileApplyFilters = () => {
        console.log("Mobile : ", $('#topic_id_m').val(), $('#language_id_m').val());

        this.applyFilters($('#topic_id_m').val(), $('#language_id_m').val());
    }

    desktopApplyFilters = () => {
        console.log("Desktop : ", $('#topic_id').val(), $('#language_id').val());

        this.applyFilters($('#topic_id').val(), $('#language_id').val());
    }

    applyFilters(topic_id, language_id) {
        $('.scheduleMainDiv').css('display', 'none');
        $('.withoutScheduleDiv').css('display', 'block');

        $(".scheduleButton").removeClass("btn-light");
        $(".scheduleButton").addClass("btn-outline-brown");
        $(".scheduleButton").css("color", 'red');

        const answer_array = this.props.location.pathname.split('/');

        if (topic_id == '') {
            topic_id = 0;
        }

        if (language_id == '') {
            language_id = 0;
        }
        var event_type = answer_array['3'];

        if (event_type == 1) {
            $('#background_1').css('display', 'block');

            $('#background_2').css('display', 'none');
            $('#background_3').css('display', 'none');
            $('#background_4').css('display', 'none');
            $('#background_5').css('display', 'none');
            $('#background_6').css('display', 'none');
        } else if (event_type == 2) {
            $('#background_2').css('display', 'block');

            $('#background_1').css('display', 'none');
            $('#background_3').css('display', 'none');
            $('#background_4').css('display', 'none');
            $('#background_5').css('display', 'none');
            $('#background_6').css('display', 'none');
        } else if (event_type == 3) {
            $('#background_3').css('display', 'block');

            $('#background_1').css('display', 'none');
            $('#background_2').css('display', 'none');
            $('#background_4').css('display', 'none');
            $('#background_5').css('display', 'none');
            $('#background_6').css('display', 'none');
        } else if (event_type == 4) {
            $('#background_4').css('display', 'block');

            $('#background_1').css('display', 'none');
            $('#background_2').css('display', 'none');
            $('#background_3').css('display', 'none');
            $('#background_5').css('display', 'none');
            $('#background_6').css('display', 'none');
        } else if (event_type == 5) {
            $('#background_5').css('display', 'block');

            $('#background_1').css('display', 'none');
            $('#background_2').css('display', 'none');
            $('#background_3').css('display', 'none');
            $('#background_4').css('display', 'none');
            $('#background_6').css('display', 'none');
        } else if (event_type == 6) {
            $('#background_6').css('display', 'block');

            $('#background_1').css('display', 'none');
            $('#background_2').css('display', 'none');
            $('#background_3').css('display', 'none');
            $('#background_4').css('display', 'none');
            $('#background_5').css('display', 'none');
        }

        if (event_type == 1) {
            var path = 'Residential Study Courses/1' + '/' + topic_id + '/' + language_id;
        } else if (event_type == 2) {
            var path = "1-Day Workshops/2" + '/' + topic_id + '/' + language_id;
        } else if (event_type == 4) {
            var path = "Rational Poojs/4" + '/' + topic_id + '/' + language_id;
        } else if (event_type == 3) {
            var path = "Machine Tests/3" + '/' + topic_id + '/' + language_id;
        } else if (event_type == 5) {
            var path = "Free Events/5" + '/' + topic_id + '/' + language_id;
        } else if (event_type == 6) {
            var path = "Organize a Trip/6" + '/' + topic_id + '/' + language_id;
        } else {
            var path = "Events/0" + '/' + topic_id + '/' + language_id;
        }
        if (event_type == 3) {
            this.props.history.push('/machine-test/' + path);
        } else {
            this.props.history.push('/events/' + path);
        }

        //    const answer_array = this.props.location.pathname.split('/');
        //    var event_type =  $('#event_type_id').val();


        //    //var event_type =  answer_array['3'];
        //      // alert(event_type);
        //      if(event_type > 0){
        //         this.state.guestinfo.event_type_id = event_type; 
        //      }else{
        //         var event_type = 0; 
        //      }

        //    fetch(myConstClass.BASE_URL+'event/getEventsFrontEnd'+'/'+getLanguage()+'/'+event_type)
        //    .then(response => response.json())
        //    .then(response =>{this.setState({posts: response})
        //        this.state.guestinfo.event_type_name =  this.state.posts[0].event_type;
        //        console.log('hi'+this.state.posts[0].event_type);
        //    })

        // this.componentDidMount();
        //console.log(this.state.posts);
        //
        //console.log(this.state.eventinfo);
        //this.state.posts = this.state.eventinfo;
        //this.setState({ posts: this.state.eventinfo });
    }

    renderType = ({ id, title }) => <div className="row">
        <div className="col">
            <div className="col pt-3">
                <h5 className="font-weight-bold">{title}</h5>
            </div>
        </div>
    </div>

    renderGuest = ({ type_id, title, price, quantity }) =>
        <div><div className="row"><div className="col-md-3 mb-3">
            <div className="col border text-center">
                <div className=" py-3">
                    <img src={require("./assets/images/product1.png")} />
                    <div className="my-2">
                        <span className="mr-2">
                            <i className="fas fa-comment-dots orangeColor"></i> <span className="small">234</span>
                        </span>
                        <span>
                            <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">15</span>
                        </span>
                    </div>
                    <p className="font-weight-bold mb-1">{title}</p>
                    <p className="mb-1">Donation Contribution</p>
                    <p className="font-weight-bolder">INR. {price}/-</p>
                    <div className="qty mb-3">
                        <span>Qty-</span>
                        <span className="minus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-minus"></i>
                            </span>
                        </span>
                        <input type="number" className="count" name="qty" value={quantity} />
                        <span className="plus border">
                            <span className="d-flex align-items-center ico-hld">
                                <i className="fas fa-plus"></i>
                            </span>
                        </span>
                    </div>
                    <button className="btn btn-brown btn-sm">ADD TO CART</button>
                </div>
            </div></div></div></div>

    render() {
        const { notifyinfo } = this.state
        const { cartEvent, eventdetails, scheduleEvent, eventTypes, posts, types, languages, topic, guestinfo } = this.state
        const { t } = this.props;
        return (
            <div className="App">
                <div>
                    {/* <button onClick={this.notify}>Notify !</button> */}
                    <ToastContainer />
                </div>
                <Header />

                <div className="container-fluid">


                    <div className={this.props.location.pathname.split("/")[4] !== undefined || localStorage.getItem('eventAllTopic') === 'hide' ? "d-block" : "d-xl-block d-lg-block d-md-block d-none"}>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="row mt-5 m-xl-0 mt-lg-0 mt-md-4 mt-sm-5">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb bg-white mb-0">
                                            <li className="breadcrumb-item">
                                                <Link to="/">{t('home.home')}</Link>
                                            </li>
                                            <li className="breadcrumb-item ">
                                                <a href="javascript:void(0)">{t('home.events')}
                                                </a>
                                            </li>
                                            <li className="breadcrumb-item active"><a href="#">
                                                {eventdetails.map((infos, ii) => {
                                                    return (
                                                        <span className="CurrentEventType">{infos.event_type} </span>
                                                    )
                                                })
                                                }
                                            </a></li>
                                        </ol>
                                    </nav>



                                </div>
                                <div className="d-xl-block d-lg-block d-md-block d-none">
                                    <div className="row">
                                        <div className="col">

                                            <div id="background_1" className="col p-3 text-white mb-4 relatedProductsImage" style={{ backgroundImage: "url(" + backgroundEvent1 + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', display: 'none' }} >
                                                <h1 className="m-0 font-weight-light">
                                                </h1>
                                            </div>
                                            <div id="background_1_mr" className="col p-3 text-white mb-4 relatedProductsImage" style={{ backgroundImage: "url(" + backgroundEvent1_mr + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', display: 'none' }} >
                                                <h1 className="m-0 font-weight-light">
                                                </h1>
                                            </div>


                                            <div id="background_2" className="col p-3 text-white mb-4 relatedProductsImage" style={{ backgroundImage: "url(" + backgroundEvent2 + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', display: 'none' }} >
                                                <h1 className="m-0 font-weight-light">
                                                </h1>
                                            </div>
                                            <div id="background_2_mr" className="col p-3 text-white mb-4 relatedProductsImage" style={{ backgroundImage: "url(" + backgroundEvent2_mr + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', display: 'none' }} >
                                                <h1 className="m-0 font-weight-light">
                                                </h1>
                                            </div>

                                            <div id="background_3" className="col p-3 text-white mb-4 relatedProductsImage" style={{ backgroundImage: "url(" + backgroundEvent3 + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', display: 'none' }} >
                                                <h1 className="m-0 font-weight-light">
                                                </h1>
                                            </div>
                                            <div id="background_3_mr" className="col p-3 text-white mb-4 relatedProductsImage" style={{ backgroundImage: "url(" + backgroundEvent3_mr + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', display: 'none' }} >
                                                <h1 className="m-0 font-weight-light">
                                                </h1>
                                            </div>

                                            <div id="background_4" className="col p-3 text-white mb-4 relatedProductsImage" style={{ backgroundImage: "url(" + backgroundEvent4 + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', display: 'none' }} >
                                                <h1 className="m-0 font-weight-light">
                                                </h1>
                                            </div>
                                            <div id="background_4_mr" className="col p-3 text-white mb-4 relatedProductsImage" style={{ backgroundImage: "url(" + backgroundEvent4_mr + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', display: 'none' }} >
                                                <h1 className="m-0 font-weight-light">
                                                </h1>
                                            </div>
                                            <div id="background_5" className="col p-3 text-white mb-4 relatedProductsImage" style={{ backgroundImage: "url(" + backgroundEvent5 + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', display: 'none' }} >
                                                <h1 className="m-0 font-weight-light">
                                                </h1>
                                            </div>
                                            <div id="background_5_mr" className="col p-3 text-white mb-4 relatedProductsImage" style={{ backgroundImage: "url(" + backgroundEvent5_mr + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', display: 'none' }} >
                                                <h1 className="m-0 font-weight-light">
                                                </h1>
                                            </div>
                                            <div id="background_6" className="col p-3 text-white mb-4 relatedProductsImage" style={{ backgroundImage: "url(" + backgroundEvent6 + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', display: 'none' }} >
                                                <h1 className="m-0 font-weight-light">
                                                </h1>
                                            </div>
                                            <div id="background_6_mr" className="col p-3 text-white mb-4 relatedProductsImage" style={{ backgroundImage: "url(" + backgroundEvent6_mr + ")", minHeight: '80px', minWidth: '100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', display: 'none' }} >
                                                <h1 className="m-0 font-weight-light">
                                                </h1>
                                            </div>

                                        </div>

                                    </div>
                                </div>


                                <div className="d-xl-none d-lg-none d-md-none d-block mb-3">
                                    <img src={this.state.ResidentialStudyCourses} height={77} id="background_1_m" />


                                    <img src={this.state.OneDayWorkshop} height={77} id="background_2_m" />
                                    <img src={this.state.RationalPoojas} height={77} id="background_4_m" />
                                    <img src={this.state.FreeEvents} height={77} id="background_5_m" />

                                </div>
                                <div className="d-xl-block d-lg-block d-md-block d-none">
                                    <div className="row">
                                        {eventdetails.map((infos, ii) => {
                                            return (
                                                <span>
                                                    <div className="col-md-12">
                                                        <p style={{ columnCount: '2' }}>

                                                            {infos.event_description}

                                                        </p>
                                                    </div>
                                                </span>
                                            )
                                        })
                                        }

                                    </div>
                                </div>

                                <Row className="bg-light m-0 py-2">
                                    <Col xl="3" lg="3" md="4" className="align-self-center">
                                        {t('home.Select_filters_to_refine_the_results_below')}:
                                    </Col>

                                    {/* Desktop Design Start */}
                                    <Col xl="4" lg="4" md="4" className="d-xl-flex d-lg-flex d-none">
                                        <div className="form-group">

                                            <label for="">{t('home.Topic')}</label>

                                            <select data-dropup-auto="false" id="topic_id" name="topic_id" className=" form-control form-control-sm">
                                                <option value="">{t('home.All')}</option>
                                                {topic.map((info, ii) => {
                                                    if (guestinfo.topic_id == info.value) {
                                                        return (
                                                            <option className="selectOrange" value={info.value} selected="selected">{info.label}</option>
                                                        )
                                                    } else {
                                                        return (
                                                            <option className="selectOrange" value={info.value}>{info.label}</option>
                                                        )
                                                    }
                                                })
                                                }
                                            </select>
                                        </div>
                                    </Col>
                                    <Col xl="2" lg="2" md="2" className="d-xl-flex d-lg-flex d-none">
                                        <div className="form-group">
                                            <label for="">{t('home.Language')}</label>
                                            <select id="language_id" name="language_id" className="form-control form-control-sm">
                                                <option value="">{t('home.All')}</option>
                                                {languages.map((info, ii) => {
                                                    if (info.value != 4) {
                                                        if (guestinfo.language_id == info.value) {
                                                            return (
                                                                <option value={info.value} selected="selected">{info.label}</option>
                                                            )
                                                        } else {
                                                            return (
                                                                <option value={info.value}>{info.label}</option>
                                                            )
                                                        }
                                                    }
                                                })

                                                }
                                            </select>
                                        </div>
                                    </Col>
                                    <Col xl="2" lg="2" md="2" className="d-xl-flex d-lg-flex  d-none">
                                        <div className="form-group">
                                            <button style={{ marginTop: '28px' }} onClick={this.desktopApplyFilters} className="btn btn-sm btn btn-outline-brown btn-block ">
                                                {t('home.APPLY FILTERS')}

                                            </button>
                                        </div>
                                    </Col>
                                    {/* Desktop Design End */}

                                    {/* Mobile Design Start */}
                                    <Col md="3" sm="8" xs="8" className="d-xl-none d-lg-none d-flex">
                                        <FormGroup>
                                            <Label for="">{t('home.Topic')}</Label>

                                            <Input type="select" data-dropup-auto="false" id="topic_id_m" name="topic_id_m" bsSize="sm" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0">
                                                <option value="">{t('home.All')}</option>
                                                {topic.map((info, ii) => {
                                                    if (guestinfo.topic_id == info.value) {
                                                        return (
                                                            <option className="selectOrange" value={info.value} selected="selected">{info.label}</option>
                                                        )
                                                    } else {
                                                        return (
                                                            <option className="selectOrange" value={info.value}>{info.label}</option>
                                                        )
                                                    }
                                                })
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md="2" sm="4" xs="4" className="d-xl-none d-lg-none d-flex">
                                        <FormGroup>
                                            <Label for="">{t('home.Language')}</Label>
                                            <Input type="select" id="language_id_m" name="language_id_m" bsSize="sm" className="border-left-0 border-right-0 border-top-0 bg-transparent rounded-0">
                                                <option value="">{t('home.All')}</option>
                                                {languages.map((info, ii) => {
                                                    if (info.value != 4) {
                                                        if (guestinfo.language_id == info.value) {
                                                            return (
                                                                <option value={info.value} selected="selected">{info.label}</option>
                                                            )
                                                        } else {
                                                            return (
                                                                <option value={info.value}>{info.label}</option>
                                                            )
                                                        }
                                                    }

                                                })


                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md="3" sm="12" xs="12" className="d-xl-none d-lg-none d-flex align-self-end">
                                        <div className="form-group">
                                            <button onClick={this.mobileApplyFilters} className="btn btn-sm btn btn-outline-brown btn-block ">
                                                {t('home.APPLY FILTERS')}
                                            </button>
                                        </div>
                                    </Col>
                                    {/* Mobile Design End */}
                                </Row>
                            </div>
                            <div className="col-lg-9 mt-3" style={{ maxHeight: '900px', overflowY: 'scroll' }}>
                                {posts.map((info, ii) => {
                                    if (info.arr_events.length > 0) {
                                        if (info.arr_events.length > 4) {
                                            return (
                                                <div >
                                                    <div className="row">
                                                        <div className="col">
                                                            <h4 style={{ fontSize: '1.4rem' }}>
                                                                {info.topic_title}
                                                            </h4>
                                                            <h6 className="color-red my-2 mb-4 mt-3 fontWeight700">
                                                                {t('home.Step 1')}:&nbsp;

                                                                <span className="marathiStep1" style={{ display: 'none' }}> {t('home.Click on Show Schedule of the')} </span>

                                                                <span className="marathiStep2" style={{ display: 'none' }}> {t('home.Click on Show Schedule of the second')} </span>
                                                                {eventdetails.map((infos, ii) => {
                                                                    return (
                                                                        <span>{infos.event_type}</span>

                                                                    )
                                                                })
                                                                }
                                                                <span className="marathiStep1" style={{ display: 'none' }}> {t('home.you intend to attend')} </span>
                                                                <span className="marathiStep2" style={{ display: 'none' }}> {t('home.you intend to attend second')} </span>

                                                            </h6>
                                                        </div>
                                                    </div>

                                                    {info.arr_events.map((cell, i) => {
                                                        if (i < 4) {
                                                            if (cell.first_price > 0 && cell.second_price > 0) {
                                                                return (
                                                                    <div>
                                                                        <div className="row px-3">
                                                                            <div className={'col-12 py-2 mb-2 border border-dark bg-light-light eventrow Row_' + cell.event_id}>
                                                                                <DesktopDesignParentChild
                                                                                    cell={cell}
                                                                                    showSchedule={this.showSchedule}
                                                                                    t={(name) => t(name)}
                                                                                    topicId={info.topic_id}
                                                                                />
                                                                                <MobileDesignParentChild
                                                                                    cell={cell}
                                                                                    showSchedule={this.showSchedule}
                                                                                    t={(name) => t(name)}
                                                                                    topicId={info.topic_id}
                                                                                    topicList={this.state.topic}
                                                                                    pathname={this.props.location.pathname}
                                                                                />
                                                                                {/* check event schedule  */}
                                                                            </div>
                                                                            <div className="col-lg-3 pt-3 eventRightMenuMainMobile ">
                                                                                <div className={'viewScheduleForMobile viewScheduleForMobile' + 'm' + '_' + cell.event_id + '_' + info.topic_id}>
                                                                                    <div className="withoutScheduleDiv">
                                                                                        <div className="row">
                                                                                            <div className="col">
                                                                                                <h6 className="color-red fontWeight700" style={{ marginTop: '-3px', marginBottom: '20px' }}>{t('home.Step 2')}: {t('home.View Schedule & Add to Cart')}</h6>
                                                                                                {/* <p className="color-red">Note: To change quantity remove from cart and add again. </p> */}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2" style={{ maxHeight: '500px', overflowY: 'scroll' }}>


                                                                                            <div className="row d-flex">
                                                                                                <div className="col-12">

                                                                                                    {t('home.Click on Show Schedule of the')}&nbsp;
                                                                                                    {eventdetails.map((infos, ii) => {
                                                                                                        //var current_type_name = infos.event_type.toLowerCase();
                                                                                                        var current_type_name = infos.event_type;
                                                                                                        return (
                                                                                                            <span className="CurrentEventType">{current_type_name} </span>
                                                                                                        )
                                                                                                    })
                                                                                                    }
                                                                                                    {t('home.you intend to attend')}
                                                                                                </div>

                                                                                            </div>


                                                                                        </div>

                                                                                    </div>




                                                                                    <div style={{ display: 'none' }} className="scheduleMainDiv">
                                                                                        <div className="row">
                                                                                            <div className="col">
                                                                                                <h6 className="color-red fontWeight700">{t('home.Step 2')}: {t('home.View Schedule & Add to Cart')}</h6>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-1">

                                                                                            {sortDates(scheduleEvent,2).map((cell, ii) => {
                                                                                                if (ii == 0) {
                                                                                                    return (
                                                                                                        <span>
                                                                                                            <p className="font-weight-bolder robotoFontFamily">
                                                                                                                {console.log("cell11",cell.title)}
                                                                                                                {t('home.Upcoming')}&nbsp;

                                                                                                                {cell.title}&nbsp;
                                                                                                                <span class="for_res_event" style={{ display: 'none' }}> {t('home.for')}</span>&nbsp;
                                                                                                                <span class="for_other_event" style={{ display: 'none' }}>{t('home.for other event')} </span>
                                                                                                            </p>

                                                                                                        </span>
                                                                                                    )
                                                                                                }
                                                                                            })
                                                                                            }
                                                                                            <ListGroup>
                                                                                                {sortDates(scheduleEvent, 2).map((info, i) => {
                                                                                                    if (i == 0) {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <ListGroupItem className="orangeColor mb-0">
                                                                                                                    {extractMonthYear(info?.date && typeof info.date === "string" ? info.date : "")}
                                                                                                                </ListGroupItem>
                                                                                                                {info.arr_schedule.map((cell, ii) => {
                                                                                                                    if (cell.is_full == 1) {
                                                                                                                        return (
                                                                                                                            <ListGroupItem>
                                                                                                                                <Row>
                                                                                                                                    <Col sm="6" xs="6">
                                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>

                                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                                <p>{info.date}, {cell.start_date_format} - {cell.end_date_format},{cell.venue_address}</p>
                                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                                    {cell.contact_details}</p>
                                                                                                                                            </ReactTooltip>
                                                                                                                                        </p>
                                                                                                                                    </Col>
                                                                                                                                    <Col className="text-right">

                                                                                                                                        {t('home.FULL')}

                                                                                                                                    </Col>
                                                                                                                                </Row>
                                                                                                                            </ListGroupItem>
                                                                                                                        )

                                                                                                                    } else if (cell.notify == 1) {
                                                                                                                        return (
                                                                                                                            <ListGroupItem>

                                                                                                                                <Row>
                                                                                                                                    <Col sm="6" xs="6">
                                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                                    {cell.contact_details}</p>
                                                                                                                                            </ReactTooltip>

                                                                                                                                        </p>
                                                                                                                                    </Col>
                                                                                                                                    <Col className="text-right">


                                                                                                                                        <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                                                                        <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + ' to ' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                                                                        <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />


                                                                                                                                    </Col>
                                                                                                                                </Row>
                                                                                                                            </ListGroupItem>
                                                                                                                        )

                                                                                                                    } else {
                                                                                                                        if (cell.available_in_cart == 1) {
                                                                                                                            return (
                                                                                                                                <ListGroupItem>

                                                                                                                                    <Row>
                                                                                                                                        <Col sm="6" xs="6">
                                                                                                                                            <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                                    {cell.city_name}
                                                                                                                                                </a>

                                                                                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                                    {cell.city_name}
                                                                                                                                                </a>
                                                                                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                                    <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                                    <p>{t('home.Contact No')}<br />
                                                                                                                                                        {cell.contact_details}</p>
                                                                                                                                                </ReactTooltip>
                                                                                                                                            </p>
                                                                                                                                        </Col>
                                                                                                                                        <Col sm="6" xs="6" className="text-right">

                                                                                                                                            <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                                                                            <br />
                                                                                                                                            <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                                                                            <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id} >{t('home.Added in cart')}</span>

                                                                                                                                        </Col>
                                                                                                                                    </Row>
                                                                                                                                </ListGroupItem>
                                                                                                                            )
                                                                                                                        } else {
                                                                                                                            return (
                                                                                                                                <ListGroupItem>

                                                                                                                                    <Row>
                                                                                                                                        <Col sm="6" xs="6">
                                                                                                                                            <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                                                                                <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                                    {cell.city_name}
                                                                                                                                                </a>
                                                                                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                                    {cell.city_name}
                                                                                                                                                </a>
                                                                                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                                    <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                                    <p>{t('home.Contact No')}<br />
                                                                                                                                                        {cell.contact_details}</p>
                                                                                                                                                </ReactTooltip>
                                                                                                                                            </p>
                                                                                                                                        </Col>
                                                                                                                                        <Col sm="6" xs="6" className="text-right">
                                                                                                                                            <p className="text-right">
                                                                                                                                                <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                                                                                <br />

                                                                                                                                                <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                                                                                <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                                                                            </p>
                                                                                                                                        </Col>
                                                                                                                                    </Row>
                                                                                                                                </ListGroupItem>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    }//Else complete


                                                                                                                })
                                                                                                                }
                                                                                                            </>
                                                                                                        )
                                                                                                    } else {

                                                                                                        return (
                                                                                                            <>
                                                                                                                <ListGroupItem className="orangeColor mb-0">
                                                                                                                    {extractMonthYear(info?.date && typeof info.date === "string" ? info.date : "")}
                                                                                                                </ListGroupItem>

                                                                                                                {info.arr_schedule.map((cell, ii) => {
                                                                                                                    if (cell.is_full == 1) {
                                                                                                                        return (
                                                                                                                            <ListGroupItem>

                                                                                                                                <Row>
                                                                                                                                    <Col sm="6" xs="6">
                                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                                    {cell.contact_details}</p>
                                                                                                                                            </ReactTooltip>
                                                                                                                                        </p>
                                                                                                                                    </Col>
                                                                                                                                    <Col sm="6" xs="6" className="text-right">
                                                                                                                                        {t('home.FULL')}
                                                                                                                                    </Col>
                                                                                                                                </Row>
                                                                                                                            </ListGroupItem>
                                                                                                                        )

                                                                                                                    } else if (cell.notify == 1) {
                                                                                                                        return (
                                                                                                                            <ListGroupItem>

                                                                                                                                <Row>
                                                                                                                                    <Col sm="6" xs="6">
                                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                                    {cell.contact_details}</p>
                                                                                                                                            </ReactTooltip>

                                                                                                                                        </p>
                                                                                                                                    </Col>
                                                                                                                                    <Col sm="6" xs="6" className="text-right">

                                                                                                                                        <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                                                                        <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + ' to ' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                                                                        <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />

                                                                                                                                    </Col>
                                                                                                                                </Row>
                                                                                                                            </ListGroupItem>
                                                                                                                        )

                                                                                                                    } else {
                                                                                                                        if (cell.available_in_cart == 1) {
                                                                                                                            return (
                                                                                                                                <span>
                                                                                                                                    <hr className="border-dark mx-n2 mt-2" />
                                                                                                                                    <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                                                                        <div className="col-6">
                                                                                                                                            <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                                <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                                    {cell.city_name}
                                                                                                                                                </a>
                                                                                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                                    {cell.city_name}
                                                                                                                                                </a>
                                                                                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                                    <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                                    <p>{t('home.Contact No')}<br />
                                                                                                                                                        {cell.contact_details}</p>
                                                                                                                                                </ReactTooltip>
                                                                                                                                            </p>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-6 pl-0 align-content-center">
                                                                                                                                            <p className="text-right">
                                                                                                                                                <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                                                                                <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                                                                                <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id}>{t('home.Added in cart')}</span>
                                                                                                                                            </p>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </span>
                                                                                                                            )
                                                                                                                        } else {
                                                                                                                            return (
                                                                                                                                <span>
                                                                                                                                    <hr className="border-dark mx-n2 mt-2" />
                                                                                                                                    <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                                                                        <div className="col-6">
                                                                                                                                            <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                                <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                                    {cell.city_name}
                                                                                                                                                </a>
                                                                                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                                    {cell.city_name}
                                                                                                                                                </a>
                                                                                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                                    <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                                    <p>{t('home.Contact No')}<br />
                                                                                                                                                        {cell.contact_details}</p>
                                                                                                                                                </ReactTooltip>
                                                                                                                                            </p>
                                                                                                                                        </div>
                                                                                                                                        <div className="col-6 pl-0 align-content-center">
                                                                                                                                            <p className="text-right">
                                                                                                                                                <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>

                                                                                                                                                <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                                                                                <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                                                                            </p>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </span>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    }//Else complete


                                                                                                                })
                                                                                                                }
                                                                                                            </>
                                                                                                        )
                                                                                                    }



                                                                                                })
                                                                                                }






                                                                                                <hr className="border-dark mx-n2 mt-2" />

                                                                                                <span style={{ marginBottom: '10px', display: 'none' }} name="noScheduleAvailable" id="noScheduleAvailable" className="text-muted noScheduleAvailable">{t('home.No schedule available')}</span>
                                                                                                <br />
                                                                                                {/* <button className="btn btn-brown btn-sm mb-2 btn-block goToCartBtn" onClick={this.goToCart}>{t('home.GO TO CART')}</button> */}
                                                                                            </ListGroup>
                                                                                        </div>

                                                                                    </div>




                                                                                    <h6 class="color-red">
                                                                                        <label class="bookNowInCartMrPoojs">{t('home.in Cart poojs')}&nbsp; </label>
                                                                                        <label class="bookNowInCartMr">{t('home.in Cart')}&nbsp; </label>
                                                                                        {eventdetails.map((infos, ii) => {
                                                                                            return (
                                                                                                <span id="CurrentEventType">{infos.event_type} </span>
                                                                                            )
                                                                                        })
                                                                                        }&nbsp;

                                                                                        <label class="bookNowInCartEn">  {t('home.in Cart')} &nbsp; </label>
                                                                                    </h6>
                                                                                    <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2">
                                                                                        {/* <p className="orangeColor mb-0">MAY 2019</p> */}
                                                                                        <hr className="border-dark mx-n2 mt-2" />
                                                                                        {cartEvent.map((info, i) => {
                                                                                            return (
                                                                                                <div>
                                                                                                    <div className="row d-flex">
                                                                                                        <div className="col-6">
                                                                                                            <p className="font-weight-bold">
                                                                                                                {info.title}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        <div className="col-6 pl-0 text-right">
                                                                                                            <div className="row">
                                                                                                                <div className="text-muted text-left col-10">{info.date}<br /> {info.start_date_format} - {info.end_date_format}<br />
                                                                                                                    <a target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                        {info.city_name}
                                                                                                                    </a>
                                                                                                                    <a data-tip='' data-for={'testcart' + info.schedule_id} target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                        {info.city_name}
                                                                                                                    </a>
                                                                                                                    <ReactTooltip id={'testcart' + info.schedule_id} data-tip={info.venue_address} aria-haspopup='true' role='example'>
                                                                                                                        <p>{info.date}, {info.start_date_format} - {info.end_date_format},{info.venue_address}</p>
                                                                                                                        <p>{t('home.Contact No')}<br />
                                                                                                                            {info.contact_details}</p>
                                                                                                                    </ReactTooltip>
                                                                                                                </div>
                                                                                                                <div className="col-2">
                                                                                                                    {/* <i className={'fas fa-trash-alt delete_'+cell.event_id+'_'+cell.schedule_id}></i> */}
                                                                                                                    <i style={{ cursor: 'pointer' }} className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_cart_' + info.event_id + '_' + info.schedule_id} id={'delete_' + info.event_id + '_' + info.schedule_id} onClick={this.deleteFromCart}></i>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <hr className="border-dark mx-n2 mt-2" />
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                        }

                                                                                        <span style={{ marginBottom: '10px', display: 'none' }} name="noItemInCart" id="noItemInCart" className="text-muted noItemInCart">{t('home.No items in the cart')}</span>


                                                                                        {/* <Link  className="btn btn-brown btn-sm mb-2"  to="/mycart">{t('home.GO TO CART')}</Link> */}
                                                                                        {/* <button className="btn btn-brown btn-sm mb-2 btn-block goToCartBtn" onClick={this.goToCart}>{t('home.GO TO CART')}</button> */}
                                                                                    </div>


                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div className="row px-3">
                                                                        <div className={'col-12 py-2 mb-2 border border-dark bg-light-light eventrow Row_' + cell.event_id}>
                                                                            <DesktopDesignAdult
                                                                                cell={cell}
                                                                                showSchedule={this.showSchedule}
                                                                                t={(name) => t(name)}
                                                                                topicId={info.topic_id}
                                                                            />
                                                                            <MobileDesignAdult
                                                                                cell={cell}
                                                                                showSchedule={this.showSchedule}
                                                                                t={(name) => t(name)}
                                                                                topicId={info.topic_id}
                                                                                topicList={this.state.topic}
                                                                                pathname={this.props.location.pathname}
                                                                            />
                                                                        </div>

                                                                        {/* Event schedule for mobile */}
                                                                        <div className="col-lg-3 pt-3 eventRightMenuMainMobile ">
                                                                            <div className={'viewScheduleForMobile viewScheduleForMobile' + 'm' + '_' + cell.event_id + '_' + info.topic_id}>
                                                                                <div className="withoutScheduleDiv">
                                                                                    <div className="row">
                                                                                        <div className="col">
                                                                                            <h6 className="color-red fontWeight700" style={{ marginTop: '-3px', marginBottom: '20px' }}>{t('home.Step 2')}: {t('home.View Schedule & Add to Cart')}</h6>
                                                                                            {/* <p className="color-red">Note: To change quantity remove from cart and add again. </p> */}
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2" style={{ maxHeight: '500px', overflowY: 'scroll' }}>


                                                                                        <div className="row d-flex">
                                                                                            <div className="col-12">

                                                                                                {t('home.Click on Show Schedule of the')}&nbsp;
                                                                                                {eventdetails.map((infos, ii) => {
                                                                                                    //var current_type_name = infos.event_type.toLowerCase();
                                                                                                    var current_type_name = infos.event_type;
                                                                                                    return (
                                                                                                        <span className="CurrentEventType">{current_type_name} </span>
                                                                                                    )
                                                                                                })
                                                                                                }
                                                                                                {t('home.you intend to attend')}
                                                                                            </div>

                                                                                        </div>


                                                                                    </div>

                                                                                </div>




                                                                                <div style={{ display: 'none' }} className="scheduleMainDiv">
                                                                                    <div className="row">
                                                                                        <div className="col">
                                                                                            <h6 className="color-red fontWeight700">{t('home.Step 2')}: {t('home.View Schedule & Add to Cart')}</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-1">

                                                                                        {sortDates(scheduleEvent, 1).map((cell, ii) => {
                                                                                            console.log("cell12",cell)
                                                                                            if (ii == 0) {
                                                                                                return (
                                                                                                    <span>
                                                                                                        <p className="font-weight-bolder robotoFontFamily">
                                                                                                            {console.log("cell12",cell.title)}
                                                                                                            {t('home.Upcoming')}&nbsp;

                                                                                                            {cell.title}&nbsp;
                                                                                                            <span class="for_res_event" style={{ display: 'none' }}> {t('home.for')}</span>&nbsp;
                                                                                                            <span class="for_other_event" style={{ display: 'none' }}>{t('home.for other event')} </span>


                                                                                                        </p>

                                                                                                    </span>
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                        }
                                                                                        <div style={{ maxHeight: '700px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                                                            {sortDates(scheduleEvent, 2).map((info, i) => {
                                                                                                if (i == 0) {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <ListGroupItem className="orangeColor mb-0">
                                                                                                                {extractMonthYear(info?.date && typeof info.date === "string" ? info.date : "")}
                                                                                                            </ListGroupItem>

                                                                                                            {info.arr_schedule.map((cell, ii) => {
                                                                                                                if (cell.is_full == 1) {
                                                                                                                    return (
                                                                                                                        <ListGroupItem>

                                                                                                                            <Row>
                                                                                                                                <Col sm="6" xs="6">
                                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                            <p>{info.date}, {cell.start_date_format} - {cell.end_date_format},{cell.venue_address}</p>
                                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                                {cell.contact_details}</p>
                                                                                                                                        </ReactTooltip>
                                                                                                                                    </p>
                                                                                                                                </Col>
                                                                                                                                <Col sm="6" xs="6" className="text-right">

                                                                                                                                    {t('home.FULL')}

                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                        </ListGroupItem>
                                                                                                                    )

                                                                                                                } else if (cell.notify == 1) {
                                                                                                                    return (
                                                                                                                        <ListGroupItem>

                                                                                                                            <Row>
                                                                                                                                <Col sm="6" xs="6">
                                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />


                                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                                {cell.contact_details}</p>
                                                                                                                                        </ReactTooltip>

                                                                                                                                    </p>
                                                                                                                                </Col>
                                                                                                                                <Col sm="6" xs="6" className="text-right">


                                                                                                                                    <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                                                                    <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + ' to ' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                                                                    <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />

                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                        </ListGroupItem>
                                                                                                                    )

                                                                                                                } else {
                                                                                                                    if (cell.available_in_cart == 1) {
                                                                                                                        return (
                                                                                                                            <ListGroupItem>

                                                                                                                                <Row>
                                                                                                                                    <Col sm="6" xs="6">
                                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                                    {cell.contact_details}</p>
                                                                                                                                            </ReactTooltip>
                                                                                                                                        </p>
                                                                                                                                    </Col>
                                                                                                                                    <Col sm="6" xs="6" className="text-right">

                                                                                                                                        <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                                                                        <br />
                                                                                                                                        <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                                                                        <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id} >{t('home.Added in cart')}</span>

                                                                                                                                    </Col>
                                                                                                                                </Row>
                                                                                                                            </ListGroupItem>
                                                                                                                        )
                                                                                                                    } else {
                                                                                                                        return (
                                                                                                                            <ListGroupItem>

                                                                                                                                <Row>
                                                                                                                                    <Col sm="6" xs="6">
                                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                                    {cell.contact_details}</p>
                                                                                                                                            </ReactTooltip>
                                                                                                                                        </p>
                                                                                                                                    </Col>
                                                                                                                                    <Col sm="6" xs="6" className="text-right">

                                                                                                                                        <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                                                                        <br />
                                                                                                                                        {/* <button className="btn btn-sm btn-outline-brown">{t('home.ADD TO CART')} </button> */}
                                                                                                                                        <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                                                                        <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>

                                                                                                                                    </Col>
                                                                                                                                </Row>
                                                                                                                            </ListGroupItem>
                                                                                                                        )
                                                                                                                    }
                                                                                                                }//Else complete


                                                                                                            })
                                                                                                            }
                                                                                                        </>
                                                                                                    )
                                                                                                } else {

                                                                                                    return (
                                                                                                        <>
                                                                                                            <ListGroupItem className="orangeColor mb-0">
                                                                                                                {extractMonthYear(info?.date && typeof info.date === "string" ? info.date : "")}
                                                                                                            </ListGroupItem>

                                                                                                            {info.arr_schedule.map((cell, ii) => {
                                                                                                                if (cell.is_full == 1) {
                                                                                                                    return (
                                                                                                                        <ListGroupItem>

                                                                                                                            <Row>
                                                                                                                                <Col sm="6" xs="6">
                                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />


                                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                                {cell.contact_details}</p>
                                                                                                                                        </ReactTooltip>
                                                                                                                                    </p>
                                                                                                                                </Col>
                                                                                                                                <Col sm="6" xs="6" className="text-right">


                                                                                                                                    {t('home.FULL')}

                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                        </ListGroupItem>
                                                                                                                    )

                                                                                                                } else if (cell.notify == 1) {
                                                                                                                    return (
                                                                                                                        <ListGroupItem>

                                                                                                                            <Row>
                                                                                                                                <Col sm="6" xs="6">
                                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                                {cell.contact_details}</p>
                                                                                                                                        </ReactTooltip>

                                                                                                                                    </p>
                                                                                                                                </Col>
                                                                                                                                <Col sm="6" xs="6" className="text-right">


                                                                                                                                    <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                                                                    <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + ' to ' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                                                                    <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />


                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                        </ListGroupItem>
                                                                                                                    )

                                                                                                                } else {
                                                                                                                    if (cell.available_in_cart == 1) {
                                                                                                                        return (
                                                                                                                            <ListGroupItem>

                                                                                                                                <Row>
                                                                                                                                    <Col sm="6" xs="6">
                                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                                    {cell.contact_details}</p>
                                                                                                                                            </ReactTooltip>
                                                                                                                                        </p>
                                                                                                                                    </Col>
                                                                                                                                    <Col sm="6" xs="6" className="text-right">

                                                                                                                                        <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                                                                        <br />
                                                                                                                                        <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                                                                        <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id}>{t('home.Added in cart')}</span>

                                                                                                                                    </Col>
                                                                                                                                </Row>
                                                                                                                            </ListGroupItem>
                                                                                                                        )
                                                                                                                    } else {
                                                                                                                        return (
                                                                                                                            <ListGroupItem>

                                                                                                                                <Row>
                                                                                                                                    <Col sm="6" xs="6">
                                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                                    {cell.contact_details}</p>
                                                                                                                                            </ReactTooltip>
                                                                                                                                        </p>
                                                                                                                                    </Col>
                                                                                                                                    <Col sm="6" xs="6" className="text-right">

                                                                                                                                        <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                                                                        <br />
                                                                                                                                        <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                                                                        <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>

                                                                                                                                    </Col>
                                                                                                                                </Row>
                                                                                                                            </ListGroupItem>
                                                                                                                        )
                                                                                                                    }
                                                                                                                }//Else complete


                                                                                                            })
                                                                                                            }
                                                                                                        </>
                                                                                                    )
                                                                                                }



                                                                                            })
                                                                                            }







                                                                                            <ListGroupItem style={{ marginBottom: '10px', display: 'none' }} name="noScheduleAvailable" id="noScheduleAvailable">
                                                                                                <span className="text-muted">{t('home.No schedule available')}</span>
                                                                                            </ListGroupItem>

                                                                                        </div>
                                                                                    </div>

                                                                                </div>




                                                                                <h6 class="color-red">
                                                                                    <label class="bookNowInCartMrPoojs">{t('home.in Cart poojs')}&nbsp; </label>
                                                                                    <label class="bookNowInCartMr">{t('home.in Cart')}&nbsp; </label>
                                                                                    {eventdetails.map((infos, ii) => {
                                                                                        return (
                                                                                            <span id="CurrentEventType">{infos.event_type} </span>
                                                                                        )
                                                                                    })
                                                                                    }&nbsp;

                                                                                    <label class="bookNowInCartEn">  {t('home.in Cart')} &nbsp; </label>
                                                                                </h6>
                                                                                <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2">
                                                                                    {/* <p className="orangeColor mb-0">MAY 2019</p> */}
                                                                                    <hr className="border-dark mx-n2 mt-2" />
                                                                                    {cartEvent.map((info, i) => {
                                                                                        return (
                                                                                            <div>
                                                                                                <div className="row d-flex">
                                                                                                    <div className="col-6">
                                                                                                        <p className="font-weight-bold">
                                                                                                            {info.title}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    <div className="col-6 pl-0 text-right">
                                                                                                        <div className="row">
                                                                                                            <div className="text-muted text-left col-10">{info.date}<br /> {info.start_date_format} - {info.end_date_format}<br />
                                                                                                                <a target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                    {info.city_name}
                                                                                                                </a>
                                                                                                                <a data-tip='' data-for={'testcart' + info.schedule_id} target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                    {info.city_name}
                                                                                                                </a>
                                                                                                                <ReactTooltip id={'testcart' + info.schedule_id} data-tip={info.venue_address} aria-haspopup='true' role='example'>
                                                                                                                    <p>{info.date}, {info.start_date_format} - {info.end_date_format},{info.venue_address}</p>
                                                                                                                    <p>{t('home.Contact No')}<br />
                                                                                                                        {cell.contact_details}</p>
                                                                                                                </ReactTooltip>
                                                                                                            </div>
                                                                                                            <div className="col-2">
                                                                                                                {/* <i className={'fas fa-trash-alt delete_'+cell.event_id+'_'+cell.schedule_id}></i> */}
                                                                                                                <i style={{ cursor: 'pointer' }} className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_cart_' + info.event_id + '_' + info.schedule_id} id={'delete_' + info.event_id + '_' + info.schedule_id} onClick={this.deleteFromCart}></i>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <hr className="border-dark mx-n2 mt-2" />
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                    }

                                                                                    <span style={{ marginBottom: '10px', display: 'none' }} name="noItemInCart" id="noItemInCart" className="text-muted noItemInCart">{t('home.No items in the cart')}</span>

                                                                                    <button className="btn btn-brown btn-sm mb-2 btn-block goToCartBtn" onClick={this.goToCart}>{t('home.GO TO CART')}</button>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        } else {
                                                            if (cell.first_price > 0 && cell.second_price > 0) {
                                                                return (
                                                                    <div className={'row px-3 showMoreEventsDiv_' + info.topic_id} style={{ display: 'none' }}>
                                                                        <div className={'col-12 py-2 mb-2 border border-dark bg-light-light eventrow Row_' + cell.event_id}>
                                                                            <div className="row">
                                                                                <div className="col col-lg-5">
                                                                                    <h4>
                                                                                        <Link style={{ color: '#212529' }} to={"/event_details/" + cell.event_id}>
                                                                                            {cell.title}
                                                                                        </Link>

                                                                                    </h4>
                                                                                    <p className="mb-0">


                                                                                        {cell.topic.map((info, ii) => {
                                                                                            //if(ii%2 == 0){
                                                                                            if (ii == 0) {
                                                                                                return (
                                                                                                    <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                                                                                        <button type="button" className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">{info.name}</button>
                                                                                                    </Link>

                                                                                                )
                                                                                            } else {
                                                                                                return (
                                                                                                    <Link to={'/topics/' + info.name + '/' + info.topic_id + '/' + info.sub_topic_id}>
                                                                                                        <button style={{ marginLeft: '5px' }} type="button" className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">{info.name}</button>
                                                                                                    </Link>

                                                                                                )
                                                                                            }
                                                                                            //}
                                                                                        }
                                                                                        )}



                                                                                        {/* <button type="button" className="btn btn-outline-dark btn-sm rounded-pill py-0 px-3">Happy Marriage</button> */}
                                                                                    </p>

                                                                                </div>
                                                                                <div className="col col-lg-7">
                                                                                    <div className="row">
                                                                                        <div className="col col-md-2">
                                                                                            <span className="mr-2">
                                                                                                <i className="fas fa-comment-dots orangeColor"></i> <span className="small">{cell.total_review}</span>
                                                                                            </span>
                                                                                            <span>
                                                                                                <i className="fas fa-thumbs-up orangeColor"></i> <span className="small">{cell.total_like}</span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col col-md-2">
                                                                                            <p className="mb-2">{t('home.Duration')}
                                                                                                <br />
                                                                                                {cell.duration} {t('home.Days')}
                                                                                            </p>

                                                                                            <p className="mb-0">

                                                                                                <Link style={{ textDecoration: 'underline' }} to={"/event_details/" + cell.event_id}   >{t('home.Know More')}</Link>

                                                                                                {/* <a href="#">{t('home.Know More')}</a> */}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="col col-md-2">
                                                                                            <label>{cell.first_price_for}</label>
                                                                                            <select className="form-control form-control-sm" name={"parent" + cell.event_id} id={"parent" + cell.event_id}>
                                                                                                <option value="0" selected="selected">00</option>
                                                                                                <option value="1">01</option>
                                                                                                <option value="2">02</option>
                                                                                                <option value="3">03</option>
                                                                                                <option value="4">04</option>
                                                                                                <option value="5">05</option>
                                                                                                <option value="6">06</option>
                                                                                                <option value="7">07</option>
                                                                                                <option value="8">08</option>
                                                                                                <option value="9">09</option>
                                                                                                <option value="10">10</option>
                                                                                            </select>
                                                                                        </div>
                                                                                        <div className="col col-md-2">
                                                                                            <label>{cell.second_price_for}</label>
                                                                                            <select className="form-control form-control-sm" name={"child" + cell.event_id} id={"child" + cell.event_id}>
                                                                                                <option value="0" selected="selected">00</option>
                                                                                                <option value="1">01</option>
                                                                                                <option value="2">02</option>
                                                                                                <option value="3">03</option>
                                                                                                <option value="4">04</option>
                                                                                                <option value="5">05</option>
                                                                                                <option value="6">06</option>
                                                                                                <option value="7">07</option>
                                                                                                <option value="8">08</option>
                                                                                                <option value="9">09</option>
                                                                                                <option value="10">10</option>
                                                                                            </select>
                                                                                        </div>
                                                                                        <div className="col col-md-4 text-right">
                                                                                            <h5>{t('home.INR')} {cell.first_price}/- <small style={{ fontWeight: '700', textTransform: 'lowercase' }}>{t('home.per')} {cell.first_price_for}</small>
                                                                                                <br />
                                                                                                {t('home.INR')} {cell.second_price}/- <small style={{ fontWeight: '700', textTransform: 'lowercase' }}>{t('home.per')} {cell.second_price_for}</small>
                                                                                            </h5>


                                                                                            <button style={{ fontWeight: '700' }} className={'schedule_' + cell.event_id + ' btn btn-sm btn-outline-brown color-red mt-4 scheduleButton'} id={'schedule_' + cell.event_id} onClick={this.showSchedule}>{t('home.SHOW SCHEDULE')}</button>



                                                                                            {/* <button className="btn btn-sm btn-outline-brown color-red mt-4 scheduleButton" name={cell.event_id} id={cell.event_id}  onClick={this.addToCart}>{t('home.ADD TO CART')}</button> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-3 pt-3 eventRightMenuMainMobile ">
                                                                            <div className={'viewScheduleForMobile viewScheduleForMobile' + 'm' + '_' + cell.event_id + '_' + info.topic_id}>
                                                                                <div className="withoutScheduleDiv">
                                                                                    <div className="row">
                                                                                        <div className="col">
                                                                                            <h6 className="color-red fontWeight700" style={{ marginTop: '-3px', marginBottom: '20px' }}>{t('home.Step 2')}: {t('home.View Schedule & Add to Cart')}</h6>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2" style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                                                                                        <div className="row d-flex">
                                                                                            <div className="col-12">

                                                                                                {t('home.Click on Show Schedule of the')}&nbsp;
                                                                                                {eventdetails.map((infos, ii) => {
                                                                                                    //var current_type_name = infos.event_type.toLowerCase();
                                                                                                    var current_type_name = infos.event_type;
                                                                                                    return (
                                                                                                        <span className="CurrentEventType">{current_type_name} </span>
                                                                                                    )
                                                                                                })
                                                                                                }
                                                                                                {t('home.you intend to attend')}
                                                                                            </div>

                                                                                        </div>


                                                                                    </div>

                                                                                </div>




                                                                                <div style={{ display: 'none' }} className="scheduleMainDiv">
                                                                                    <div className="row">
                                                                                        <div className="col">
                                                                                            <h6 className="color-red fontWeight700">{t('home.Step 2')}: {t('home.View Schedule & Add to Cart')}</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-1">

                                                                                        {sortDates(scheduleEvent, 1).map((cell, ii) => {
                                                                                            console.log("cell13",cell);
                                                                                            if (ii == 0) {
                                                                                                return (
                                                                                                    <span>
                                                                                                        <p className="font-weight-bolder robotoFontFamily">
                                                                                                            {console.log("cell13",cell.title)}
                                                                                                            {t('home.Upcoming')}&nbsp;

                                                                                                            {cell.title}&nbsp;
                                                                                                            <span class="for_res_event" style={{ display: 'none' }}> {t('home.for')}</span>&nbsp;
                                                                                                            <span class="for_other_event" style={{ display: 'none' }}>{t('home.for other event')} </span>


                                                                                                        </p>

                                                                                                    </span>
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                        }
                                                                                        <ListGroup>
                                                                                            {sortDates(scheduleEvent, 1).map((info, i) => {
                                                                                                if (i == 0) {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <ListGroupItem className="orangeColor mb-0">
                                                                                                                {extractMonthYear(info?.date && typeof info.date === "string" ? info.date : "")}
                                                                                                            </ListGroupItem>
                                                                                                            {info.arr_schedule.map((cell, ii) => {
                                                                                                                if (cell.is_full == 1) {
                                                                                                                    return (
                                                                                                                        <ListGroupItem>
                                                                                                                            <Row>
                                                                                                                                <Col sm="6" xs="6">
                                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                            <p>{info.date}, {cell.start_date_format} - {cell.end_date_format},{cell.venue_address}</p>
                                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                                {cell.contact_details}</p>
                                                                                                                                        </ReactTooltip>
                                                                                                                                    </p>
                                                                                                                                </Col>
                                                                                                                                <Col className="text-right">

                                                                                                                                    {t('home.FULL')}

                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                        </ListGroupItem>
                                                                                                                    )

                                                                                                                } else if (cell.notify == 1) {
                                                                                                                    return (
                                                                                                                        <ListGroupItem>

                                                                                                                            <Row>
                                                                                                                                <Col sm="6" xs="6">
                                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                                {cell.contact_details}</p>
                                                                                                                                        </ReactTooltip>

                                                                                                                                    </p>
                                                                                                                                </Col>
                                                                                                                                <Col className="text-right">


                                                                                                                                    <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                                                                    <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + ' to ' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                                                                    <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />


                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                        </ListGroupItem>
                                                                                                                    )

                                                                                                                } else {
                                                                                                                    if (cell.available_in_cart == 1) {
                                                                                                                        return (
                                                                                                                            <ListGroupItem>

                                                                                                                                <Row>
                                                                                                                                    <Col sm="6" xs="6">
                                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                                    {cell.contact_details}</p>
                                                                                                                                            </ReactTooltip>
                                                                                                                                        </p>
                                                                                                                                    </Col>
                                                                                                                                    <Col sm="6" xs="6" className="text-right">

                                                                                                                                        <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                                                                        <br />
                                                                                                                                        <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                                                                        <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id} >{t('home.Added in cart')}</span>

                                                                                                                                    </Col>
                                                                                                                                </Row>
                                                                                                                            </ListGroupItem>
                                                                                                                        )
                                                                                                                    } else {
                                                                                                                        return (
                                                                                                                            <ListGroupItem>

                                                                                                                                <Row>
                                                                                                                                    <Col sm="6" xs="6">
                                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                                    {cell.contact_details}</p>
                                                                                                                                            </ReactTooltip>
                                                                                                                                        </p>
                                                                                                                                    </Col>
                                                                                                                                    <Col sm="6" xs="6" className="text-right">
                                                                                                                                        <p className="text-right">
                                                                                                                                            <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                                                                            <br />

                                                                                                                                            <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                                                                            <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                                                                        </p>
                                                                                                                                    </Col>
                                                                                                                                </Row>
                                                                                                                            </ListGroupItem>
                                                                                                                        )
                                                                                                                    }
                                                                                                                }//Else complete


                                                                                                            })
                                                                                                            }
                                                                                                        </>
                                                                                                    )
                                                                                                } else {

                                                                                                    return (
                                                                                                        <>
                                                                                                            <ListGroupItem className="orangeColor mb-0">
                                                                                                                {extractMonthYear(info?.date && typeof info.date === "string" ? info.date : "")}
                                                                                                            </ListGroupItem>

                                                                                                            {info.arr_schedule.map((cell, ii) => {
                                                                                                                if (cell.is_full == 1) {
                                                                                                                    return (
                                                                                                                        <ListGroupItem>

                                                                                                                            <Row>
                                                                                                                                <Col sm="6" xs="6">
                                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                                {cell.contact_details}</p>
                                                                                                                                        </ReactTooltip>
                                                                                                                                    </p>
                                                                                                                                </Col>
                                                                                                                                <Col sm="6" xs="6" className="text-right">
                                                                                                                                    {t('home.FULL')}
                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                        </ListGroupItem>
                                                                                                                    )

                                                                                                                } else if (cell.notify == 1) {
                                                                                                                    return (
                                                                                                                        <ListGroupItem>

                                                                                                                            <Row>
                                                                                                                                <Col sm="6" xs="6">
                                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                                {cell.contact_details}</p>
                                                                                                                                        </ReactTooltip>

                                                                                                                                    </p>
                                                                                                                                </Col>
                                                                                                                                <Col sm="6" xs="6" className="text-right">

                                                                                                                                    <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                                                                    <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + ' to ' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                                                                    <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />

                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                        </ListGroupItem>
                                                                                                                    )

                                                                                                                } else {
                                                                                                                    if (cell.available_in_cart == 1) {
                                                                                                                        return (
                                                                                                                            <span>
                                                                                                                                <hr className="border-dark mx-n2 mt-2" />
                                                                                                                                <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                                                                    <div className="col-6">
                                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                                    {cell.contact_details}</p>
                                                                                                                                            </ReactTooltip>
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                    <div className="col-6 pl-0 align-content-center">
                                                                                                                                        <p className="text-right">
                                                                                                                                            <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                                                                            <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                                                                            <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id}>{t('home.Added in cart')}</span>
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </span>
                                                                                                                        )
                                                                                                                    } else {
                                                                                                                        return (
                                                                                                                            <span>
                                                                                                                                <hr className="border-dark mx-n2 mt-2" />
                                                                                                                                <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                                                                    <div className="col-6">
                                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                                    {cell.contact_details}</p>
                                                                                                                                            </ReactTooltip>
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                    <div className="col-6 pl-0 align-content-center">
                                                                                                                                        <p className="text-right">
                                                                                                                                            <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                                                                            {/* <button className="btn btn-sm btn-outline-brown">{t('home.ADD TO CART')} </button> */}
                                                                                                                                            <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                                                                            <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </span>
                                                                                                                        )
                                                                                                                    }
                                                                                                                }//Else complete


                                                                                                            })
                                                                                                            }
                                                                                                        </>
                                                                                                    )
                                                                                                }



                                                                                            })
                                                                                            }






                                                                                            <hr className="border-dark mx-n2 mt-2" />

                                                                                            <span style={{ marginBottom: '10px', display: 'none' }} name="noScheduleAvailable" id="noScheduleAvailable" className="text-muted noScheduleAvailable">{t('home.No schedule available')}</span>
                                                                                            <br />
                                                                                            <button className="btn btn-brown btn-sm mb-2 btn-block goToCartBtn" onClick={this.goToCart}>{t('home.GO TO CART')}</button>
                                                                                            {/* <Link  className="btn btn-brown btn-sm mb-2"  to="/mycart">{t('home.GO TO CART')}</Link> */}
                                                                                        </ListGroup>
                                                                                    </div>

                                                                                </div>




                                                                                <h6 class="color-red">
                                                                                    <label class="bookNowInCartMrPoojs">{t('home.in Cart poojs')}&nbsp; </label>
                                                                                    <label class="bookNowInCartMr">{t('home.in Cart')}&nbsp; </label>
                                                                                    {eventdetails.map((infos, ii) => {
                                                                                        return (
                                                                                            <span id="CurrentEventType">{infos.event_type} </span>
                                                                                        )
                                                                                    })
                                                                                    }&nbsp;

                                                                                    <label class="bookNowInCartEn">  {t('home.in Cart')} &nbsp; </label>
                                                                                </h6>
                                                                                <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2">
                                                                                    {/* <p className="orangeColor mb-0">MAY 2019</p> */}
                                                                                    <hr className="border-dark mx-n2 mt-2" />
                                                                                    {cartEvent.map((info, i) => {
                                                                                        return (
                                                                                            <div>
                                                                                                <div className="row d-flex">
                                                                                                    <div className="col-6">
                                                                                                        <p className="font-weight-bold">
                                                                                                            {info.title}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    <div className="col-6 pl-0 text-right">
                                                                                                        <div className="row">
                                                                                                            <div className="text-muted text-left col-10">{info.date}<br /> {info.start_date_format} - {info.end_date_format}<br />
                                                                                                                <a target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                    {info.city_name}
                                                                                                                </a>
                                                                                                                <a data-tip='' data-for={'testcart' + info.schedule_id} target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                    {info.city_name}
                                                                                                                </a>
                                                                                                                <ReactTooltip id={'testcart' + info.schedule_id} data-tip={info.venue_address} aria-haspopup='true' role='example'>
                                                                                                                    <p>{info.date}, {info.venue_address}</p>
                                                                                                                    <p>{t('home.Contact No')}<br />
                                                                                                                        {info.contact_details}</p>
                                                                                                                </ReactTooltip>
                                                                                                            </div>
                                                                                                            <div className="col-2">
                                                                                                                {/* <i className={'fas fa-trash-alt delete_'+cell.event_id+'_'+cell.schedule_id}></i> */}
                                                                                                                <i style={{ cursor: 'pointer' }} className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_cart_' + info.event_id + '_' + info.schedule_id} id={'delete_' + info.event_id + '_' + info.schedule_id} onClick={this.deleteFromCart}></i>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <hr className="border-dark mx-n2 mt-2" />
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                    }

                                                                                    <span style={{ marginBottom: '10px', display: 'none' }} name="noItemInCart" id="noItemInCart" className="text-muted noItemInCart">{t('home.No items in the cart')}</span>


                                                                                    {/* <Link  className="btn btn-brown btn-sm mb-2"  to="/mycart">{t('home.GO TO CART')}</Link> */}
                                                                                    <button className="btn btn-brown btn-sm mb-2 btn-block goToCartBtn" onClick={this.goToCart}>{t('home.GO TO CART')}</button>
                                                                                </div>


                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                )
                                                            } else {

                                                                return (
                                                                    <div className={'row px-3 showMoreEventsDiv_' + info.topic_id} style={{ display: 'none' }}>
                                                                        <div className={'col-12 py-2 mb-2 border border-dark bg-light-light eventrow Row_' + cell.event_id}>
                                                                            {/* Desktop Design Start*/}
                                                                            <DesktopDesignAdult
                                                                                cell={cell}
                                                                                showSchedule={this.showSchedule}
                                                                                t={(name) => t(name)}
                                                                                topicId={info.topic_id}
                                                                            />
                                                                            {/* Desktop Design End*/}

                                                                            {/* Mobile Design Start*/}
                                                                            <MobileDesignAdult
                                                                                cell={cell}
                                                                                showSchedule={this.showSchedule}
                                                                                t={(name) => t(name)}
                                                                                topicId={info.topic_id}
                                                                                topics={this.state.topic}
                                                                                pathname={this.props.location.pathname}
                                                                            />
                                                                            {/* Mobile Design End*/}</div>


                                                                        {/* Event schedule for mobile */}
                                                                        <div className="col-lg-3 pt-3 eventRightMenuMainMobile ">
                                                                            <div className={'viewScheduleForMobile viewScheduleForMobile' + 'm' + '_' + cell.event_id + '_' + info.topic_id}>
                                                                                <div className="withoutScheduleDiv">
                                                                                    <div className="row">
                                                                                        <div className="col">
                                                                                            <h6 className="color-red fontWeight700" style={{ marginTop: '-3px', marginBottom: '20px' }}>{t('home.Step 2')}: {t('home.View Schedule & Add to Cart')}</h6>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2" style={{ maxHeight: '500px', overflowY: 'scroll' }}>


                                                                                        <div className="row d-flex">
                                                                                            <div className="col-12">

                                                                                                {t('home.Click on Show Schedule of the')}&nbsp;
                                                                                                {eventdetails.map((infos, ii) => {
                                                                                                    //var current_type_name = infos.event_type.toLowerCase();
                                                                                                    var current_type_name = infos.event_type;
                                                                                                    return (
                                                                                                        <span className="CurrentEventType">{current_type_name} </span>
                                                                                                    )
                                                                                                })
                                                                                                }
                                                                                                {t('home.you intend to attend')}
                                                                                            </div>

                                                                                        </div>


                                                                                    </div>

                                                                                </div>




                                                                                <div style={{ display: 'none' }} className="scheduleMainDiv">
                                                                                    <div className="row">
                                                                                        <div className="col">
                                                                                            <h6 className="color-red fontWeight700">{t('home.Step 2')}: {t('home.View Schedule & Add to Cart')}</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-1">

                                                                                        {sortDates(scheduleEvent, 1).map((cell, ii) => {
                                                                                            console.log("cell14",cell)
                                                                                            if (ii == 0) {
                                                                                                return (
                                                                                                    <span>
                                                                                                        <p className="font-weight-bolder robotoFontFamily">
                                                                                                            {t('home.Upcoming')}&nbsp;

                                                                                                            {cell.title}&nbsp;
                                                                                                            {console.log("cell14", cell.title)}
                                                                                                            <span class="for_res_event" style={{ display: 'none' }}> {t('home.for')}</span>&nbsp;
                                                                                                            <span class="for_other_event" style={{ display: 'none' }}>{t('home.for other event')} </span>


                                                                                                        </p>

                                                                                                    </span>
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                        }
                                                                                        <div style={{ maxHeight: '700px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                                                            {sortDates(scheduleEvent, 1).map((info, i) => {
                                                                                                if (i == 0) {
                                                                                                    return (
                                                                                                        <span>
                                                                                                            {/* <hr className="border-dark mx-n2" /> */}
                                                                                                            <p className="orangeColor mb-0">{extractMonthYear(info?.date && typeof info.date === "string" ? info.date : "")}</p>
                                                                                                            {info.arr_schedule.map((cell, ii) => {
                                                                                                                if (cell.is_full == 1) {
                                                                                                                    return (
                                                                                                                        <span>
                                                                                                                            <hr className="border-dark mx-n2 mt-2" />
                                                                                                                            <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                                                                <div className="col-6">
                                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                                {cell.contact_details}</p>
                                                                                                                                        </ReactTooltip>
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                                <div className="col-6 pl-0 align-content-center">
                                                                                                                                    <p className="text-right">
                                                                                                                                        <p style={{ marginRight: '10px' }}>
                                                                                                                                            {t('home.FULL')}
                                                                                                                                        </p>
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </span>
                                                                                                                    )

                                                                                                                } else if (cell.notify == 1) {
                                                                                                                    return (
                                                                                                                        <span>
                                                                                                                            <hr className="border-dark mx-n2 mt-2" />
                                                                                                                            <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                                                                <div className="col-6">
                                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                                {cell.contact_details}</p>
                                                                                                                                        </ReactTooltip>

                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                                <div className="col-6 pl-0 align-content-center">
                                                                                                                                    <p className="text-right">
                                                                                                                                        <p style={{ marginRight: '10px' }}>
                                                                                                                                            <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                                                                            <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + ' to ' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                                                                            <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                                                                                        </p>
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </span>
                                                                                                                    )

                                                                                                                } else {
                                                                                                                    if (cell.available_in_cart == 1) {
                                                                                                                        return (
                                                                                                                            <span>
                                                                                                                                <hr className="border-dark mx-n2 mt-2" />
                                                                                                                                <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                                                                    <div className="col-6">
                                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                                    {cell.contact_details}</p>
                                                                                                                                            </ReactTooltip>
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                    <div className="col-6 pl-0 align-content-center">
                                                                                                                                        <p className="text-right">
                                                                                                                                            <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                                                                            <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                                                                            <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id} >{t('home.Added in cart')}</span>
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </span>
                                                                                                                        )
                                                                                                                    } else {
                                                                                                                        return (
                                                                                                                            <span>
                                                                                                                                <hr className="border-dark mx-n2 mt-2" />
                                                                                                                                <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                                                                    <div className="col-6">
                                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                                    {cell.contact_details}</p>
                                                                                                                                            </ReactTooltip>
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                    <div className="col-6 pl-0 align-content-center">
                                                                                                                                        <p className="text-right">
                                                                                                                                            <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                                                                            {/* <button className="btn btn-sm btn-outline-brown">{t('home.ADD TO CART')} </button> */}
                                                                                                                                            <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                                                                            <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </span>
                                                                                                                        )
                                                                                                                    }
                                                                                                                }//Else complete


                                                                                                            })
                                                                                                            }
                                                                                                        </span>
                                                                                                    )
                                                                                                } else {

                                                                                                    return (
                                                                                                        <span>
                                                                                                            <hr className="border-dark mx-n2" />
                                                                                                            <p className="orangeColor mb-0">{extractMonthYear(info?.date && typeof info.date === "string" ? info.date : "")}</p>
                                                                                                            {info.arr_schedule.map((cell, ii) => {
                                                                                                                if (cell.is_full == 1) {
                                                                                                                    return (
                                                                                                                        <span>
                                                                                                                            <hr className="border-dark mx-n2 mt-2" />
                                                                                                                            <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                                                                <div className="col-6">
                                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                                {cell.contact_details}</p>
                                                                                                                                        </ReactTooltip>
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                                <div className="col-6 pl-0 align-content-center">
                                                                                                                                    <p className="text-right">
                                                                                                                                        <p style={{ marginRight: '10px' }}>
                                                                                                                                            {t('home.FULL')}
                                                                                                                                        </p>
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </span>
                                                                                                                    )

                                                                                                                } else if (cell.notify == 1) {
                                                                                                                    return (
                                                                                                                        <span>
                                                                                                                            <hr className="border-dark mx-n2 mt-2" />
                                                                                                                            <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                                                                <div className="col-6">
                                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                                {cell.contact_details}</p>
                                                                                                                                        </ReactTooltip>

                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                                <div className="col-6 pl-0 align-content-center">
                                                                                                                                    <p className="text-right">
                                                                                                                                        <p style={{ marginRight: '10px' }}>
                                                                                                                                            <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                                                                            <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + ' to ' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                                                                            <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                                                                                        </p>
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </span>
                                                                                                                    )

                                                                                                                } else {
                                                                                                                    if (cell.available_in_cart == 1) {
                                                                                                                        return (
                                                                                                                            <span>
                                                                                                                                <hr className="border-dark mx-n2 mt-2" />
                                                                                                                                <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                                                                    <div className="col-6">
                                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                                    {cell.contact_details}</p>
                                                                                                                                            </ReactTooltip>
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                    <div className="col-6 pl-0 align-content-center">
                                                                                                                                        <p className="text-right">
                                                                                                                                            <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                                                                            <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                                                                            <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id}>{t('home.Added in cart')}</span>
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </span>
                                                                                                                        )
                                                                                                                    } else {
                                                                                                                        return (
                                                                                                                            <span>
                                                                                                                                <hr className="border-dark mx-n2 mt-2" />
                                                                                                                                <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                                                                    <div className="col-6">
                                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                                {cell.city_name}
                                                                                                                                            </a>
                                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                                    {cell.contact_details}</p>
                                                                                                                                            </ReactTooltip>
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                    <div className="col-6 pl-0 align-content-center">
                                                                                                                                        <p className="text-right">
                                                                                                                                            <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                                                                            {/* <button className="btn btn-sm btn-outline-brown">{t('home.ADD TO CART')} </button> */}
                                                                                                                                            <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                                                                            <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                                                                        </p>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </span>
                                                                                                                        )
                                                                                                                    }
                                                                                                                }//Else complete


                                                                                                            })
                                                                                                            }
                                                                                                        </span>
                                                                                                    )
                                                                                                }



                                                                                            })
                                                                                            }






                                                                                            <hr className="border-dark mx-n2 mt-2" />

                                                                                            <span style={{ marginBottom: '10px', display: 'none' }} name="noScheduleAvailable" id="noScheduleAvailable" className="text-muted noScheduleAvailable">{t('home.No schedule available')}</span>
                                                                                            <br />
                                                                                            <button className="btn btn-brown btn-sm mb-2 btn-block goToCartBtn" onClick={this.goToCart}>{t('home.GO TO CART')}</button>
                                                                                            {/* <Link  className="btn btn-brown btn-sm mb-2"  to="/mycart">{t('home.GO TO CART')}</Link> */}
                                                                                        </div>
                                                                                    </div>

                                                                                </div>




                                                                                <h6 class="color-red">
                                                                                    <label id="bookNowInCartMrPoojs">{t('home.in Cart poojs')}&nbsp; </label>
                                                                                    <label id="bookNowInCartMr">{t('home.in Cart')}&nbsp; </label>
                                                                                    {eventdetails.map((infos, ii) => {
                                                                                        return (
                                                                                            <span id="CurrentEventType">{infos.event_type} </span>
                                                                                        )
                                                                                    })
                                                                                    }&nbsp;

                                                                                    <label id="bookNowInCartEn">  {t('home.in Cart')} &nbsp; </label>
                                                                                </h6>
                                                                                <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2">
                                                                                    {/* <p className="orangeColor mb-0">MAY 2019</p> */}
                                                                                    <hr className="border-dark mx-n2 mt-2" />
                                                                                    {cartEvent.map((info, i) => {
                                                                                        return (
                                                                                            <div>
                                                                                                <div className="row d-flex">
                                                                                                    <div className="col-6">
                                                                                                        <p className="font-weight-bold">
                                                                                                            {info.title}3927
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    <div className="col-6 pl-0 text-right">
                                                                                                        <div className="row">
                                                                                                            <div className="text-muted text-left col-10">{info.date}<br /> {info.start_date_format} - {info.end_date_format}<br />
                                                                                                                <a target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                    {info.city_name}
                                                                                                                </a>
                                                                                                                <a data-tip='' data-for={'testcart' + info.schedule_id} target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                    {info.city_name}
                                                                                                                </a>
                                                                                                                <ReactTooltip id={'testcart' + info.schedule_id} data-tip={info.venue_address} aria-haspopup='true' role='example'>
                                                                                                                    <p>{info.date}, {info.venue_address}</p>
                                                                                                                    <p>{t('home.Contact No')}<br />
                                                                                                                        {info.contact_details}</p>
                                                                                                                </ReactTooltip>
                                                                                                            </div>
                                                                                                            <div className="col-2">
                                                                                                                {/* <i className={'fas fa-trash-alt delete_'+cell.event_id+'_'+cell.schedule_id}></i> */}
                                                                                                                <i style={{ cursor: 'pointer' }} className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_cart_' + info.event_id + '_' + info.schedule_id} id={'delete_' + info.event_id + '_' + info.schedule_id} onClick={this.deleteFromCart}></i>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <hr className="border-dark mx-n2 mt-2" />
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                    }

                                                                                    <span style={{ marginBottom: '10px', display: 'none' }} name="noItemInCart" id="noItemInCart" className="text-muted noItemInCart">{t('home.No items in the cart')}</span>


                                                                                    {/* <Link  className="btn btn-brown btn-sm mb-2"  to="/mycart">{t('home.GO TO CART')}</Link> */}
                                                                                    <button className="btn btn-brown btn-sm mb-2 btn-block goToCartBtn" onClick={this.goToCart}>{t('home.GO TO CART')}</button>
                                                                                </div>


                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                    })}


                                                    <div className="row">
                                                        <div className="w-100 text-right mb-2 mt-3">
                                                            <button id={'showMoreEvents_' + info.topic_id} onClick={this.showMoreEvents} className="btn btn-sm btn-brown" style={{ marginRight: '12px' }}>{t('home.SHOW MORE')}</button>
                                                            <hr className="border-dark" />
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div >
                                                    <div className="row mt-4">
                                                        <div className="col">
                                                            <h4 style={{ fontSize: '1.4rem' }}>
                                                                {info.topic_title}
                                                            </h4>
                                                            <h6 className="color-red my-2 mt-3 mb-4 fontWeight700">
                                                                {t('home.Step 1')}: &nbsp;


                                                                <span className="marathiStep1" style={{ display: 'none' }}> {t('home.Click on Show Schedule of the')} </span>

                                                                <span className="marathiStep2" style={{ display: 'none' }}> {t('home.Click on Show Schedule of the second')} </span>
                                                                {eventdetails.map((infos, ii) => {
                                                                    return (
                                                                        <span>{infos.event_type}</span>

                                                                    )
                                                                })
                                                                }
                                                                <span className="marathiStep1" style={{ display: 'none' }}> {t('home.you intend to attend')} </span>
                                                                <span className="marathiStep2" style={{ display: 'none' }}> {t('home.you intend to attend second')} </span>


                                                            </h6>
                                                        </div>
                                                    </div>

                                                    {info.arr_events.map((cell, i) => {
                                                        if (cell.first_price > 0 && cell.second_price > 0) {
                                                            return (
                                                                <div key={cell.event_id}>
                                                                    <div className="row px-3">
                                                                        <div className={'col-12 py-2 mb-2 border border-dark bg-light-light eventrow Row_' + cell.event_id}>
                                                                            {/* Desktop Design Parent Start  */}
                                                                            <DesktopDesignParentChild
                                                                                cell={cell}
                                                                                showSchedule={this.showSchedule}
                                                                                t={(name) => t(name)}
                                                                                topicId={info.topic_id}
                                                                            />
                                                                            {/* Desktop Design Child End */}

                                                                            {/* Mobile Design Start*/}
                                                                            <MobileDesignParentChild
                                                                                cell={cell}
                                                                                showSchedule={this.showSchedule}
                                                                                t={(name) => t(name)}
                                                                                topicId={info.topic_id}
                                                                                topicList={this.state.topic}
                                                                                pathname={this.props.location.pathname}
                                                                            />

                                                                        </div>




                                                                    </div>
                                                                    <div className="col-lg-3 pt-3 eventRightMenuMainMobile ">
                                                                        <div className={'viewScheduleForMobile viewScheduleForMobile' + 'm' + '_' + cell.event_id + '_' + info.topic_id}>
                                                                            <div className="withoutScheduleDiv">
                                                                                <div className="row">
                                                                                    <div className="col">
                                                                                        <h6 className="color-red fontWeight700" style={{ marginTop: '-3px', marginBottom: '20px' }}>{t('home.Step 2')}: {t('home.View Schedule & Add to Cart')}</h6>
                                                                                        {/* <p className="color-red">Note: To change quantity remove from cart and add again. </p> */}
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2" style={{ maxHeight: '500px', overflowY: 'scroll' }}>


                                                                                    <div className="row d-flex">
                                                                                        <div className="col-12">

                                                                                            {t('home.Click on Show Schedule of the')}&nbsp;
                                                                                            {eventdetails.map((infos, ii) => {
                                                                                                //var current_type_name = infos.event_type.toLowerCase();
                                                                                                var current_type_name = infos.event_type;
                                                                                                return (
                                                                                                    <span className="CurrentEventType">{current_type_name} </span>
                                                                                                )
                                                                                            })
                                                                                            }
                                                                                            {t('home.you intend to attend')}
                                                                                        </div>

                                                                                    </div>


                                                                                </div>

                                                                            </div>




                                                                            <div style={{ display: 'none' }} className="scheduleMainDiv">
                                                                                <div className="row">
                                                                                    <div className="col">
                                                                                        <h6 className="color-red fontWeight700">{t('home.Step 2')}: {t('home.View Schedule & Add to Cart')}</h6>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-1">

                                                                                    {sortDates(scheduleEvent, 1).map((cell, ii) => {
                                                                                        console.log("cell15",cell)
                                                                                        if (ii == 0) {
                                                                                            return (
                                                                                                <span>
                                                                                                    <p className="font-weight-bolder robotoFontFamily">
                                                                                                        {t('home.Upcoming')}&nbsp;

                                                                                                        {cell.title}&nbsp;
                                                                                                        {console.log("cell15",cell.title)}
                                                                                                        <span class="for_res_event" style={{ display: 'none' }}> {t('home.for')}</span>&nbsp;
                                                                                                        <span class="for_other_event" style={{ display: 'none' }}>{t('home.for other event')} </span>


                                                                                                    </p>

                                                                                                </span>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                    }
                                                                                    <div style={{ maxHeight: '700px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                                                        {sortDates(scheduleEvent, 1).map((info, i) => {
                                                                                            if (i == 0) {
                                                                                                return (
                                                                                                    <span>
                                                                                                        {/* <hr className="border-dark mx-n2" /> */}
                                                                                                        <p className="orangeColor mb-0">{extractMonthYear(info?.date && typeof info.date === "string" ? info.date : "")}</p>
                                                                                                        {info.arr_schedule.map((cell, ii) => {
                                                                                                            if (cell.is_full == 1) {
                                                                                                                return (
                                                                                                                    <span>
                                                                                                                        <hr className="border-dark mx-n2 mt-2" />
                                                                                                                        <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                                                            <div className="col-6">
                                                                                                                                <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                                                                    <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                        {cell.city_name}
                                                                                                                                    </a>
                                                                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                        {cell.city_name}
                                                                                                                                    </a>
                                                                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                        <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                        <p>{t('home.Contact No')}<br />
                                                                                                                                            {cell.contact_details}</p>
                                                                                                                                    </ReactTooltip>
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div className="col-6 pl-0 align-content-center">
                                                                                                                                <p className="text-right">
                                                                                                                                    <p style={{ marginRight: '10px' }}>
                                                                                                                                        {t('home.FULL')}
                                                                                                                                    </p>
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </span>
                                                                                                                )

                                                                                                            } else if (cell.notify == 1) {
                                                                                                                return (
                                                                                                                    <span>
                                                                                                                        <hr className="border-dark mx-n2 mt-2" />
                                                                                                                        <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                                                            <div className="col-6">
                                                                                                                                <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                    <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                        {cell.city_name}
                                                                                                                                    </a>
                                                                                                                                    <a data-tip='' data-for={'testcart' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                        {cell.city_name}
                                                                                                                                    </a>
                                                                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                        <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                        <p>{t('home.Contact No')}<br />
                                                                                                                                            {cell.contact_details}</p>
                                                                                                                                    </ReactTooltip>

                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div className="col-6 pl-0 align-content-center">
                                                                                                                                <p className="text-right">
                                                                                                                                    <p style={{ marginRight: '10px' }}>
                                                                                                                                        <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                                                                        <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + ' to ' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                                                                        <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                                                                                    </p>
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </span>
                                                                                                                )

                                                                                                            } else {
                                                                                                                if (cell.available_in_cart == 1) {
                                                                                                                    return (
                                                                                                                        <span>
                                                                                                                            <hr className="border-dark mx-n2 mt-2" />
                                                                                                                            <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                                                                <div className="col-6">
                                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <a data-tip='' data-for={'testcart' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                                {cell.contact_details}</p>
                                                                                                                                        </ReactTooltip>
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                                <div className="col-6 pl-0 align-content-center">
                                                                                                                                    <p className="text-right">
                                                                                                                                        <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                                                                        <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                                                                        <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id} >{t('home.Added in cart')}</span>
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </span>
                                                                                                                    )
                                                                                                                } else {
                                                                                                                    return (
                                                                                                                        <span>
                                                                                                                            <hr className="border-dark mx-n2 mt-2" />
                                                                                                                            <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                                                                <div className="col-6">
                                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                                {cell.contact_details}</p>
                                                                                                                                        </ReactTooltip>
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                                <div className="col-6 pl-0 align-content-center">
                                                                                                                                    <p className="text-right">
                                                                                                                                        <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                                                                        {/* <button className="btn btn-sm btn-outline-brown">{t('home.ADD TO CART')} </button> */}
                                                                                                                                        <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                                                                        <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </span>
                                                                                                                    )
                                                                                                                }
                                                                                                            }//Else complete


                                                                                                        })
                                                                                                        }
                                                                                                    </span>
                                                                                                )
                                                                                            } else {

                                                                                                return (
                                                                                                    <span>
                                                                                                        <hr className="border-dark mx-n2" />
                                                                                                        <p className="orangeColor mb-0">{extractMonthYear(info?.date && typeof info.date === "string" ? info.date : "")}</p>
                                                                                                        {info.arr_schedule.map((cell, ii) => {
                                                                                                            if (cell.is_full == 1) {
                                                                                                                return (
                                                                                                                    <span>
                                                                                                                        <hr className="border-dark mx-n2 mt-2" />
                                                                                                                        <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                                                            <div className="col-6">
                                                                                                                                <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                                                                    <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                        {cell.city_name}
                                                                                                                                    </a>
                                                                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                        {cell.city_name}
                                                                                                                                    </a>
                                                                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                        <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                        <p>{t('home.Contact No')}<br />
                                                                                                                                            {cell.contact_details}</p>
                                                                                                                                    </ReactTooltip>
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div className="col-6 pl-0 align-content-center">
                                                                                                                                <p className="text-right">
                                                                                                                                    <p style={{ marginRight: '10px' }}>
                                                                                                                                        {t('home.FULL')}
                                                                                                                                    </p>
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </span>
                                                                                                                )

                                                                                                            } else if (cell.notify == 1) {
                                                                                                                return (
                                                                                                                    <span>
                                                                                                                        <hr className="border-dark mx-n2 mt-2" />
                                                                                                                        <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                                                            <div className="col-6">
                                                                                                                                <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                                                                    <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                        {cell.city_name}
                                                                                                                                    </a>
                                                                                                                                    <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                        {cell.city_name}
                                                                                                                                    </a>
                                                                                                                                    <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                        <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                        <p>{t('home.Contact No')}<br />
                                                                                                                                            {cell.contact_details}</p>
                                                                                                                                    </ReactTooltip>

                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                            <div className="col-6 pl-0 align-content-center">
                                                                                                                                <p className="text-right">
                                                                                                                                    <p style={{ marginRight: '10px' }}>
                                                                                                                                        <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                                                                        <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + ' to ' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                                                                        <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                                                                                    </p>
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </span>
                                                                                                                )

                                                                                                            } else {
                                                                                                                if (cell.available_in_cart == 1) {
                                                                                                                    return (
                                                                                                                        <span>
                                                                                                                            <hr className="border-dark mx-n2 mt-2" />
                                                                                                                            <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                                                                <div className="col-6">
                                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                                {cell.contact_details}</p>
                                                                                                                                        </ReactTooltip>
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                                <div className="col-6 pl-0 align-content-center">
                                                                                                                                    <p className="text-right">
                                                                                                                                        <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                                                                        <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                                                                        <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id}>{t('home.Added in cart')}</span>
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </span>
                                                                                                                    )
                                                                                                                } else {
                                                                                                                    return (
                                                                                                                        <span>
                                                                                                                            <hr className="border-dark mx-n2 mt-2" />
                                                                                                                            <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                                                                <div className="col-6">
                                                                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                            {cell.city_name}
                                                                                                                                        </a>
                                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                                {cell.contact_details}</p>
                                                                                                                                        </ReactTooltip>
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                                <div className="col-6 pl-0 align-content-center">
                                                                                                                                    <p className="text-right">
                                                                                                                                        <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                                                                        {/* <button className="btn btn-sm btn-outline-brown">{t('home.ADD TO CART')} </button> */}
                                                                                                                                        <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                                                                        <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </span>
                                                                                                                    )
                                                                                                                }
                                                                                                            }//Else complete


                                                                                                        })
                                                                                                        }
                                                                                                    </span>
                                                                                                )
                                                                                            }



                                                                                        })
                                                                                        }






                                                                                        <hr className="border-dark mx-n2 mt-2" />

                                                                                        <span style={{ marginBottom: '10px', display: 'none' }} name="noScheduleAvailable" id="noScheduleAvailable" className="text-muted noScheduleAvailable">{t('home.No schedule available')}</span>
                                                                                        <br />
                                                                                        <button className="btn btn-brown btn-sm mb-2 btn-block goToCartBtn" onClick={this.goToCart}>{t('home.GO TO CART')}</button>
                                                                                        {/* <Link  className="btn btn-brown btn-sm mb-2"  to="/mycart">{t('home.GO TO CART')}</Link> */}
                                                                                    </div>
                                                                                </div>

                                                                            </div>




                                                                            <h6 class="color-red">
                                                                                <label class="bookNowInCartMrPoojs">{t('home.in Cart poojs')}&nbsp; </label>
                                                                                <label class="bookNowInCartMr">{t('home.in Cart')}&nbsp; </label>
                                                                                {eventdetails.map((infos, ii) => {
                                                                                    return (
                                                                                        <span id="CurrentEventType">{infos.event_type} </span>
                                                                                    )
                                                                                })
                                                                                }&nbsp;

                                                                                <label class="bookNowInCartEn">  {t('home.in Cart')} &nbsp; </label>
                                                                            </h6>
                                                                            <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2">
                                                                                {/* <p className="orangeColor mb-0">MAY 2019</p> */}
                                                                                <hr className="border-dark mx-n2 mt-2" />
                                                                                {cartEvent.map((info, i) => {
                                                                                    return (
                                                                                        <div>
                                                                                            <div className="row d-flex">
                                                                                                <div className="col-6">
                                                                                                    <p className="font-weight-bold">
                                                                                                        {info.title}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className="col-6 pl-0 text-right">
                                                                                                    <div className="row">
                                                                                                        <div className="text-muted text-left col-10">{info.date}<br /> {info.start_date_format} - {info.end_date_format}<br />
                                                                                                            {/* <a traget="_blank" href={'https://www.google.com/maps/search/?api=1&query='+info.latitude+','+info.longitude}>
                                        {info.city_name}
                                        </a> */}
                                                                                                            {/* <a target='_blank' href={'https://www.google.com/maps/search/?api=1&query='+info.latitude+','+info.longitude}  style={{textDecoration:'underline'}}>{info.city_name}</a> */}
                                                                                                            {/* <a target='_blank' href={info.url} title={info.venue_address} style={{textDecoration:'underline'}}>{info.city_name}</a> */}
                                                                                                            <a target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                {info.city_name}
                                                                                                            </a>
                                                                                                            <a data-tip='' data-for={'testcart' + info.schedule_id} target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                {info.city_name}
                                                                                                            </a>
                                                                                                            <ReactTooltip id={'testcart' + info.schedule_id} data-tip={info.venue_address} aria-haspopup='true' role='example'>
                                                                                                                <p>{info.date}, {info.venue_address}</p>
                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                    {info.contact_details}</p>
                                                                                                            </ReactTooltip>
                                                                                                        </div>
                                                                                                        <div className="col-2">
                                                                                                            {/* <i className={'fas fa-trash-alt delete_'+cell.event_id+'_'+cell.schedule_id}></i> */}
                                                                                                            <i style={{ cursor: 'pointer' }} className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_cart_' + info.event_id + '_' + info.schedule_id} id={'delete_' + info.event_id + '_' + info.schedule_id} onClick={this.deleteFromCart}></i>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                            <hr className="border-dark mx-n2 mt-2" />
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                                }

                                                                                <span style={{ marginBottom: '10px', display: 'none' }} name="noItemInCart" id="noItemInCart" className="text-muted noItemInCart">{t('home.No items in the cart')}</span>


                                                                                {/* <Link  className="btn btn-brown btn-sm mb-2"  to="/mycart">{t('home.GO TO CART')}</Link> */}
                                                                                <button className="btn btn-brown btn-sm mb-2 btn-block goToCartBtn" onClick={this.goToCart}>{t('home.GO TO CART')}</button>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                    {/* Event schedule */}
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <div key={cell.event_id}>
                                                                    <div className="row px-3">
                                                                        <div className={'col-12 py-2 mb-2 border border-dark bg-light-light eventrow Row_' + cell.event_id}>
                                                                            {/* Desktop Design Start*/}
                                                                            <DesktopDesignAdult
                                                                                cell={cell}
                                                                                showSchedule={this.showSchedule}
                                                                                t={(name) => t(name)}
                                                                                topicId={info.topic_id}
                                                                            />
                                                                            {/* Desktop Design End*/}

                                                                            {/* Mobile Design Start*/}
                                                                            <MobileDesignAdult
                                                                                cell={cell}
                                                                                showSchedule={this.showSchedule}
                                                                                t={(name) => t(name)}
                                                                                topicId={info.topic_id}
                                                                                topics={this.state.topic}
                                                                                pathname={this.props.location.pathname}
                                                                            />
                                                                            {/* Mobile Design End*/}
                                                                        </div>
                                                                    </div>


                                                                    {/* Event schedule for mobile */}
                                                                    <div className="col-lg-3 pt-3 eventRightMenuMainMobile ">
                                                                        <div className={'viewScheduleForMobile viewScheduleForMobile' + 'm' + '_' + cell.event_id + '_' + info.topic_id}>
                                                                            <div className="withoutScheduleDiv">
                                                                                <div className="row">
                                                                                    <div className="col">
                                                                                        <h6 className="color-red fontWeight700" style={{ marginTop: '-3px', marginBottom: '20px' }}>{t('home.Step 2')}: {t('home.View Schedule & Add to Cart')}</h6>
                                                                                        {/* <p className="color-red">Note: To change quantity remove from cart and add again. </p> */}
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2" style={{ maxHeight: '500px', overflowY: 'scroll' }}>


                                                                                    <div className="row d-flex">
                                                                                        <div className="col-12">

                                                                                            {t('home.Click on Show Schedule of the')}&nbsp;
                                                                                            {eventdetails.map((infos, ii) => {
                                                                                                //var current_type_name = infos.event_type.toLowerCase();
                                                                                                var current_type_name = infos.event_type;
                                                                                                return (
                                                                                                    <span className="CurrentEventType">{current_type_name} </span>
                                                                                                )
                                                                                            })
                                                                                            }
                                                                                            {t('home.you intend to attend')}
                                                                                        </div>

                                                                                    </div>


                                                                                </div>

                                                                            </div>




                                                                            <ListGroup style={{ display: 'none' }} className="scheduleMainDiv">
                                                                                <ListGroupItem>
                                                                                    <h6 className="color-red fontWeight700">{t('home.Step 2')}: {t('home.View Schedule & Add to Cart')}</h6>
                                                                                </ListGroupItem>
                                                                                {sortDates(scheduleEvent, 1).map((cell, ii) => {
                                                                                    if (ii == 0) {
                                                                                        return (
                                                                                            <ListGroupItem className="font-weight-bolder robotoFontFamily">

                                                                                                {t('home.Upcoming')}&nbsp;

                                                                                                {cell.title}&nbsp;
                                                                                                <span class="for_res_event" style={{ display: 'none' }}> {t('home.for')}</span>&nbsp;
                                                                                                <span class="for_other_event" style={{ display: 'none' }}>{t('home.for other event')} </span>


                                                                                            </ListGroupItem>
                                                                                        )
                                                                                    }
                                                                                })
                                                                                }

                                                                                {sortDates(scheduleEvent, 1).map((info, i) => {
                                                                                    if (i == 0) {
                                                                                        return (
                                                                                            <>
                                                                                                <ListGroupItem>
                                                                                                    <span className="orangeColor">
                                                                                                        {extractMonthYear(info?.date && typeof info.date === "string" ? info.date : "")}
                                                                                                    </span>
                                                                                                </ListGroupItem>
                                                                                                {info.arr_schedule.map((cell, ii) => {
                                                                                                    if (cell.is_full == 1) {
                                                                                                        return (
                                                                                                            <ListGroupItem>
                                                                                                                <Row>
                                                                                                                    <Col sm="6" xs="6">
                                                                                                                        {cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                {cell.contact_details}</p>
                                                                                                                        </ReactTooltip>

                                                                                                                    </Col>
                                                                                                                    <Col sm="6" xs="6" className="text-right">
                                                                                                                        {t('home.FULL')}
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </ListGroupItem>
                                                                                                        )

                                                                                                    } else if (cell.notify == 1) {
                                                                                                        return (
                                                                                                            <ListGroupItem>

                                                                                                                <Row>
                                                                                                                    <Col sm="6" xs="6">
                                                                                                                        {cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                {cell.contact_details}</p>
                                                                                                                        </ReactTooltip>
                                                                                                                    </Col>
                                                                                                                    <Col sm="6" xs="6" className="text-right">
                                                                                                                        <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                                                        <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + ' to ' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                                                        <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </ListGroupItem>
                                                                                                        )

                                                                                                    } else {
                                                                                                        if (cell.available_in_cart == 1) {
                                                                                                            return (
                                                                                                                <ListGroupItem>
                                                                                                                    <Row>
                                                                                                                        <Col sm="6" xs="6">
                                                                                                                            {cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                {cell.city_name}
                                                                                                                            </a>
                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                {cell.city_name}
                                                                                                                            </a>
                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                    {cell.contact_details}</p>
                                                                                                                            </ReactTooltip>
                                                                                                                        </Col>
                                                                                                                        <Col className="text-right">
                                                                                                                            <span
                                                                                                                                className="mr-3"
                                                                                                                                style={{ cursor: 'pointer' }}>
                                                                                                                                <i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i>
                                                                                                                            </span>
                                                                                                                            <br />
                                                                                                                            <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                                                            <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id} >{t('home.Added in cart')}</span>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                </ListGroupItem>
                                                                                                            )
                                                                                                        } else {
                                                                                                            return (
                                                                                                                <ListGroupItem>
                                                                                                                    <Row>
                                                                                                                        <Col sm="6" xs="6">
                                                                                                                            <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                    {cell.city_name}
                                                                                                                                </a>
                                                                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                    {cell.city_name}
                                                                                                                                </a>
                                                                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                    <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                    <p>{t('home.Contact No')}<br />
                                                                                                                                        {cell.contact_details}</p>
                                                                                                                                </ReactTooltip>
                                                                                                                            </p>
                                                                                                                        </Col>
                                                                                                                        <Col sm="6" xs="6">
                                                                                                                            <p className="text-right">
                                                                                                                                <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                                                                <br />
                                                                                                                                <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                                                                <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                                                            </p>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                </ListGroupItem>
                                                                                                            )
                                                                                                        }
                                                                                                    }//Else complete


                                                                                                })
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    } else {

                                                                                        return (
                                                                                            <>
                                                                                                <ListGroupItem>
                                                                                                    <span className="orangeColor mb-0">
                                                                                                        {extractMonthYear(info?.date && typeof info.date === "string" ? info.date : "")}
                                                                                                    </span>
                                                                                                </ListGroupItem>
                                                                                                {info.arr_schedule.map((cell, ii) => {
                                                                                                    if (cell.is_full == 1) {
                                                                                                        return (
                                                                                                            <ListGroupItem>

                                                                                                                <Row>
                                                                                                                    <Col sm="6" xs="6">
                                                                                                                        {cell.start_date_format} - {cell.end_date_format}
                                                                                                                        <br />


                                                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>
                                                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                            {cell.city_name}
                                                                                                                        </a>

                                                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                                                {cell.contact_details}</p>
                                                                                                                        </ReactTooltip>

                                                                                                                    </Col>
                                                                                                                    <Col className="text-right">
                                                                                                                        {t('home.FULL')}
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </ListGroupItem>
                                                                                                        )

                                                                                                    } else if (cell.notify == 1) {
                                                                                                        return (
                                                                                                            <ListGroupItem>

                                                                                                                <Row>
                                                                                                                    <Col sm="6" xs="6">
                                                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                {cell.city_name}
                                                                                                                            </a>
                                                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                {cell.city_name}
                                                                                                                            </a>
                                                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                                    {cell.contact_details}</p>
                                                                                                                            </ReactTooltip>

                                                                                                                        </p>
                                                                                                                    </Col>
                                                                                                                    <Col sm="6" xs="6" className="text-right">
                                                                                                                        <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                                                        <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + ' to ' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                                                        <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </ListGroupItem>
                                                                                                        )

                                                                                                    } else {
                                                                                                        if (cell.available_in_cart == 1) {
                                                                                                            return (
                                                                                                                <ListGroupItem>
                                                                                                                    <Row>
                                                                                                                        <Col sm="6" xs="6">
                                                                                                                            <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                    {cell.city_name}
                                                                                                                                </a>
                                                                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                    {cell.city_name}
                                                                                                                                </a>
                                                                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                    <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                    <p>{t('home.Contact No')}<br />
                                                                                                                                        {cell.contact_details}</p>
                                                                                                                                </ReactTooltip>
                                                                                                                            </p>
                                                                                                                        </Col>
                                                                                                                        <Col sm="6" xs="6">
                                                                                                                            <p className="text-right">
                                                                                                                                <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                                                                <br />
                                                                                                                                <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                                                                <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id}>{t('home.Added in cart')}</span>
                                                                                                                            </p>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                </ListGroupItem>
                                                                                                            )
                                                                                                        } else {
                                                                                                            return (
                                                                                                                <ListGroupItem>
                                                                                                                    <Row>
                                                                                                                        <Col sm="6" xs="6">
                                                                                                                            <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                                                                <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                                    {cell.city_name}
                                                                                                                                </a>
                                                                                                                                <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                                    {cell.city_name}
                                                                                                                                </a>
                                                                                                                                <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                                                    <p>{info.date}, {cell.venue_address}</p>
                                                                                                                                    <p>{t('home.Contact No')}<br />
                                                                                                                                        {cell.contact_details}</p>
                                                                                                                                </ReactTooltip>
                                                                                                                            </p>
                                                                                                                        </Col>
                                                                                                                        <Col sm="6" xs="6" className="text-right">
                                                                                                                            <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                                                            <br />
                                                                                                                            <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                                                            <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                </ListGroupItem>
                                                                                                            )
                                                                                                        }
                                                                                                    }//Else complete


                                                                                                })
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    }



                                                                                })
                                                                                }







                                                                                <ListGroupItem style={{ display: 'none' }} name="noScheduleAvailable" id="noScheduleAvailable" className="text-muted noScheduleAvailable">
                                                                                    {t('home.No schedule available')}
                                                                                </ListGroupItem>
                                                                            </ListGroup>




                                                                            <h6 class="color-red">
                                                                                <label class="bookNowInCartMrPoojs">{t('home.in Cart poojs')}&nbsp; </label>
                                                                                <label class="bookNowInCartMr">{t('home.in Cart')}&nbsp; </label>
                                                                                {eventdetails.map((infos, ii) => {
                                                                                    return (
                                                                                        <span id="CurrentEventType">{infos.event_type} </span>
                                                                                    )
                                                                                })
                                                                                }&nbsp;

                                                                                <label class="bookNowInCartEn">  {t('home.in Cart')} &nbsp; </label>
                                                                            </h6>
                                                                            <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2">
                                                                                {/* <p className="orangeColor mb-0">MAY 2019</p> */}
                                                                                <hr className="border-dark mx-n2 mt-2" />
                                                                                {cartEvent.map((info, i) => {
                                                                                    return (
                                                                                        <div>
                                                                                            <div className="row d-flex">
                                                                                                <div className="col-6">
                                                                                                    <p className="font-weight-bold">
                                                                                                        {info.title}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className="col-6 pl-0 text-right">
                                                                                                    <div className="row">
                                                                                                        <div className="text-muted text-left col-10">{info.date}<br /> {info.start_date_format} - {info.end_date_format}<br />
                                                                                                            {/* <a traget="_blank" href={'https://www.google.com/maps/search/?api=1&query='+info.latitude+','+info.longitude}>
                                        {info.city_name}
                                        </a> */}
                                                                                                            {/* <a target='_blank' href={'https://www.google.com/maps/search/?api=1&query='+info.latitude+','+info.longitude}  style={{textDecoration:'underline'}}>{info.city_name}</a> */}
                                                                                                            {/* <a target='_blank' href={info.url} title={info.venue_address} style={{textDecoration:'underline'}}>{info.city_name}</a> */}
                                                                                                            <a target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                                {info.city_name}
                                                                                                            </a>
                                                                                                            <a data-tip='' data-for={'testcart' + info.schedule_id} target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                                {info.city_name}
                                                                                                            </a>
                                                                                                            <ReactTooltip id={'testcart' + info.schedule_id} data-tip={info.venue_address} aria-haspopup='true' role='example'>
                                                                                                                <p>{info.date}, {info.venue_address}</p>
                                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                                    {info.contact_details}</p>
                                                                                                            </ReactTooltip>
                                                                                                        </div>
                                                                                                        <div className="col-2">
                                                                                                            {/* <i className={'fas fa-trash-alt delete_'+cell.event_id+'_'+cell.schedule_id}></i> */}
                                                                                                            <i style={{ cursor: 'pointer' }} className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_cart_' + info.event_id + '_' + info.schedule_id} id={'delete_' + info.event_id + '_' + info.schedule_id} onClick={this.deleteFromCart}></i>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                            <hr className="border-dark mx-n2 mt-2" />
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                                }

                                                                                <span style={{ marginBottom: '10px', display: 'none' }} name="noItemInCart" id="noItemInCart" className="text-muted noItemInCart">{t('home.No items in the cart')}</span>

                                                                                <button className="btn btn-brown btn-sm mb-2 btn-block goToCartBtn" onClick={this.goToCart}>{t('home.GO TO CART')}</button>
                                                                            </div>


                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            )
                                                        }
                                                    })}

                                                    <div className="row">
                                                        <div className="w-100 text-right mb-2 mt-3">
                                                            {/* <button id={'showMoreEvents_' + info.topic_id} onClick={this.showMoreEvents} className="btn btn-sm btn-brown" style={{ marginRight: '12px' }}>{t('home.SHOW MORE')}</button> */}
                                                            <hr className="border-dark" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                })}

                                <div className="row">
                                    <p id="productNotAvailable" class="productNotAvailable" style={{ display: 'none', marginLeft: '20px' }}>
                                        {t('home.Event not available')}
                                    </p>
                                </div>

                            </div>

                            <div className="col-lg-3 pt-3 eventRightMenuMainDesktop" id="eventRightMenuMain" style={{ marginTop: '63px' }}>
                                <div className="withoutScheduleDiv">
                                    <div className="row">
                                        <div className="col">
                                            <h6 className="color-red fontWeight700" style={{ marginTop: '-3px', marginBottom: '20px' }}>{t('home.Step 2')}: {t('home.View Schedule & Add to Cart')}</h6>
                                            {/* <p className="color-red">Note: To change quantity remove from cart and add again. </p> */}
                                        </div>
                                    </div>

                                    <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2" style={{ maxHeight: '500px', overflowY: 'scroll' }}>


                                        <div className="row d-flex">
                                            <div className="col-12">

                                                {t('home.Click on Show Schedule of the')}&nbsp;
                                                {eventdetails.map((infos, ii) => {
                                                    //var current_type_name = infos.event_type.toLowerCase();
                                                    var current_type_name = infos.event_type;
                                                    return (
                                                        <span className="CurrentEventType">{current_type_name} </span>
                                                    )
                                                })
                                                }
                                                {t('home.you intend to attend')}
                                            </div>

                                        </div>


                                    </div>

                                </div>




                                <div style={{ display: 'none' }} className="scheduleMainDiv">
                                    <div className="row">
                                        <div className="col">
                                            <h6 className="color-red fontWeight700">{t('home.Step 2')}: {t('home.View Schedule & Add to Cart')}</h6>
                                        </div>
                                    </div>
                                    <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-1">

                                        {sortDates(scheduleEvent, 1).map((cell, ii) => {
                                        
                                            // console.log("cell16",cell);
                                            // console.log("scheduleEvent",scheduleEvent);
                                            if (ii == 0) {
                                                return (
                                                    <span>
                                                        <p className="font-weight-bolder robotoFontFamily">
                                                            {t('home.Upcoming')}&nbsp;

                                                            {cell.title}&nbsp;
                                                            {/* {console.log("cell16",cell.date)} */}
                                                            <span class="for_res_event" style={{ display: 'none' }}> {t('home.for')}</span>&nbsp;
                                                            <span class="for_other_event" style={{ display: 'none' }}>{t('home.for other event')} </span>


                                                        </p>

                                                    </span>
                                                )
                                            }
                                        })
                                        }
                                        <div style={{ maxHeight: '700px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                            {sortDates(scheduleEvent,1).map((info, i) => {
                                                console.log("scheduleEvent",scheduleEvent)
                                                if (i == 0) {
                                                    return (
                                                        <span>
                                                            {/* <hr className="border-dark mx-n2" /> */}
                                                            <p className="orangeColor mb-0">{extractMonthYear(info?.date && typeof info.date === "string" ? info.date : "")}</p>
                                                            {info.arr_schedule.map((cell, ii) => {
                                                                if (cell.is_full == 1) {
                                                                    return (
                                                                        <span>
                                                                            <hr className="border-dark mx-n2 mt-2" />
                                                                            <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                <div className="col-6">
                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                        {/* <a target='_blank' href={'https://www.google.com/maps/search/?api=1&query='+cell.latitude+','+cell.longitude} title={cell.venue_address}>{cell.city_name}</a> */}
                                                                                        {/* <a target='_blank' href={cell.url} title={cell.venue_address}>{cell.city_name}</a> */}
                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                            {cell.city_name}
                                                                                        </a>
                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                            {cell.city_name}
                                                                                        </a>
                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                {cell.contact_details}</p>
                                                                                        </ReactTooltip>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-6 pl-0 align-content-center">
                                                                                    <p className="text-right">
                                                                                        <p style={{ marginRight: '10px' }}>
                                                                                            {t('home.FULL')}
                                                                                        </p>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                    )

                                                                } else if (cell.notify == 1) {
                                                                    return (
                                                                        <span>
                                                                            <hr className="border-dark mx-n2 mt-2" />
                                                                            <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                <div className="col-6">
                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                            {cell.city_name}
                                                                                        </a>
                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                            {cell.city_name}
                                                                                        </a>
                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                {cell.contact_details}</p>
                                                                                        </ReactTooltip>

                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-6 pl-0 align-content-center">
                                                                                    <p className="text-right">
                                                                                        <p style={{ marginRight: '10px' }}>
                                                                                            <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                            <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + ' to ' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                            <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                                        </p>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                    )

                                                                } else {
                                                                    if (cell.available_in_cart == 1) {
                                                                        return (
                                                                            <span>
                                                                                <hr className="border-dark mx-n2 mt-2" />
                                                                                <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                    <div className="col-6">
                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                {cell.city_name}
                                                                                            </a>
                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                {cell.city_name}
                                                                                            </a>
                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                    {cell.contact_details}</p>
                                                                                            </ReactTooltip>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="col-6 pl-0 align-content-center">
                                                                                        <p className="text-right">
                                                                                            <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                            <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                            <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id}>{t('home.Added in cart')}</span>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </span>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <span>
                                                                                <hr className="border-dark mx-n2 mt-2" />
                                                                                <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                    <div className="col-6">
                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                {cell.city_name}
                                                                                            </a>
                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                {cell.city_name}
                                                                                            </a>
                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                    {cell.contact_details}</p>
                                                                                            </ReactTooltip>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="col-6 pl-0 align-content-center">
                                                                                        <p className="text-right">
                                                                                            <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                            {/* <button className="btn btn-sm btn-outline-brown">{t('home.ADD TO CART')} </button> */}
                                                                                            <button className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                            <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </span>
                                                                        )
                                                                    }
                                                                }//Else complete


                                                            })
                                                            }
                                                        </span>
                                                    )
                                                } else {

                                                    return (
                                                        <span>
                                                            <hr className="border-dark mx-n2" />
                                                            <p className="orangeColor mb-0">{extractMonthYear(info?.date && typeof info.date === "string" ? info.date : "")}</p>
                                                            {info.arr_schedule.map((cell, ii) => {
                                                                if (cell.is_full == 1) {
                                                                    return (
                                                                        <span>
                                                                            <hr className="border-dark mx-n2 mt-2" />
                                                                            <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                <div className="col-6">
                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                            {cell.city_name}
                                                                                        </a>
                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                            {cell.city_name}
                                                                                        </a>
                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                {cell.contact_details}</p>
                                                                                        </ReactTooltip>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-6 pl-0 align-content-center">
                                                                                    <p className="text-right">
                                                                                        <p style={{ marginRight: '10px' }}>
                                                                                            {t('home.FULL')}
                                                                                        </p>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                    )

                                                                } else if (cell.notify == 1) {
                                                                    return (
                                                                        <span>
                                                                            <hr className="border-dark mx-n2 mt-2" />
                                                                            <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                <div className="col-6">
                                                                                    <p>{cell.start_date_format} - {cell.end_date_format} <br />

                                                                                        <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                            {cell.city_name}
                                                                                        </a>
                                                                                        <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                            {cell.city_name}
                                                                                        </a>
                                                                                        <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                            <p>{info.date}, {cell.venue_address}</p>
                                                                                            <p>{t('home.Contact No')}<br />
                                                                                                {cell.contact_details}</p>
                                                                                        </ReactTooltip>

                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-6 pl-0 align-content-center">
                                                                                    <p className="text-right">
                                                                                        <p style={{ marginRight: '10px' }}>
                                                                                            <button id={'notifyScheduleid_' + cell.schedule_id} className={'btn btn-sm btn-outline-dark notifyScheduleid_' + cell.schedule_id} style={{ cursor: 'pointer' }} onClick={this.notifyMe}>{t('home.NOTIFY')}</button>
                                                                                            <input type="hidden" id={'notifyScheduletitle_' + cell.schedule_id} className={'notifyScheduletitle_' + cell.schedule_id} name={'notifyScheduletitle_' + cell.schedule_id} value={cell.start_date_format_new + ' to ' + cell.end_date_format_new + '-' + cell.city_name} />
                                                                                            <input type="hidden" id={'notifyEventtitle_' + cell.schedule_id} className={'notifyEventtitle_' + cell.schedule_id} name={'notifyEventtitle_' + cell.schedule_id} value={info.title} />
                                                                                        </p>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                    )

                                                                } else {
                                                                    if (cell.available_in_cart == 1) {
                                                                        return (
                                                                            <span>
                                                                                <hr className="border-dark mx-n2 mt-2" />
                                                                                <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                    <div className="col-6">
                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                {cell.city_name}
                                                                                            </a>
                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                {cell.city_name}
                                                                                            </a>
                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                    {cell.contact_details}</p>
                                                                                            </ReactTooltip>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="col-6 pl-0 align-content-center">
                                                                                        <p className="text-right">
                                                                                            <span className="mr-3" style={{ cursor: 'pointer' }}><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} onClick={this.deleteFromCart}></i></span>
                                                                                            <button style={{ display: 'none' }} className={'btn btn-sm btn-outline-brown ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>
                                                                                            <span name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} className={'text-muted remove' + cell.schedule_id}>{t('home.Added in cart')}</span>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </span>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <span>
                                                                                <hr className="border-dark mx-n2 mt-2" />
                                                                                <div className="row d-flex align-items-center" style={{ marginRight: '12px' }}>
                                                                                    <div className="col-6">
                                                                                        <p>{cell.start_date_format} - {cell.end_date_format} <br />
                                                                                            <a target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                                                {cell.city_name}
                                                                                            </a>
                                                                                            <a data-tip='' data-for={'test' + cell.schedule_id} target='_blank' href={cell.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                                                {cell.city_name}
                                                                                            </a>
                                                                                            <ReactTooltip id={'test' + cell.schedule_id} data-tip={cell.venue_address} aria-haspopup='true' role='example'>
                                                                                                <p>{info.date}, {cell.venue_address}</p>
                                                                                                <p>{t('home.Contact No')}<br />
                                                                                                    {cell.contact_details}</p>
                                                                                            </ReactTooltip>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="col-6 pl-0 align-content-center">
                                                                                        <p className="text-right">
                                                                                            <span style={{ cursor: 'pointer' }} className="mr-3"><i className={'fas fa-trash-alt delete_' + cell.event_id + '_' + cell.schedule_id} name={'delete_' + cell.event_id + '_' + cell.schedule_id} id={'delete_' + cell.event_id + '_' + cell.schedule_id} style={{ display: 'none', padding: '10px' }} onClick={this.deleteFromCart}></i></span>
                                                                                            {/* <button className="btn btn-sm btn-outline-brown">{t('home.ADD TO CART')} </button> */}
                                                                                            <button className={'btn btn-sm btn-outline-brown color-red ' + cell.event_id + '_' + cell.schedule_id} name={cell.event_id + '_' + cell.schedule_id} id={cell.event_id + '_' + cell.schedule_id} onClick={this.addToCart}>{t('home.ADD TO CART')}</button>

                                                                                            <span className={'text-muted remove' + cell.schedule_id} name={'remove' + cell.schedule_id} id={'remove' + cell.schedule_id} style={{ display: 'none' }}>{t('home.Added in cart')}</span>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </span>
                                                                        )
                                                                    }
                                                                }//Else complete


                                                            })
                                                            }
                                                        </span>
                                                    )
                                                }



                                            })
                                            }






                                            <hr className="border-dark mx-n2 mt-2" />

                                            <span style={{ marginBottom: '10px', display: 'none' }} name="noScheduleAvailable" id="noScheduleAvailable" className="text-muted noScheduleAvailable">{t('home.No schedule available')}</span>
                                            <br />
                                            <button className="btn btn-brown btn-sm mb-2 btn-block goToCartBtn" onClick={this.goToCart}>{t('home.GO TO CART')}</button>
                                            {/* <Link  className="btn btn-brown btn-sm mb-2"  to="/mycart">{t('home.GO TO CART')}</Link> */}
                                        </div>
                                    </div>

                                </div>




                                <h6 class="color-red">
                                    <label class="bookNowInCartMrPoojs">{t('home.in Cart poojs')}&nbsp; </label>
                                    <label class="bookNowInCartMr">{t('home.in Cart')}&nbsp; </label>
                                    {eventdetails.map((infos, ii) => {
                                        return (
                                            <span id="CurrentEventType">{infos.event_type} </span>
                                        )
                                    })
                                    }&nbsp;

                                    <label class="bookNowInCartEn">  {t('home.in Cart')} &nbsp; </label>
                                </h6>
                                <div className="col py-2 border border-warning bg-white box-shadow mb-3 mt-2">
                                    {/* <p className="orangeColor mb-0">MAY 2019</p> */}
                                    <hr className="border-dark mx-n2 mt-2" />
                                    {cartEvent.map((info, i) => {
                                        return (
                                            <div>
                                                <div className="row d-flex">
                                                    <div className="col-6">
                                                        <p className="font-weight-bold">
                                                            {info.title}
                                                        </p>
                                                    </div>
                                                    <div className="col-6 pl-0 text-right">
                                                        <div className="row">
                                                            <div className="text-muted text-left col-10">{info.date}<br /> {info.start_date_format} - {info.end_date_format}<br />
                                                                <a target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-inline d-xl-none d-lg-none d-md-none">
                                                                    {info.city_name}
                                                                </a>
                                                                <a data-tip='' data-for={'testcart' + info.schedule_id} target='_blank' href={info.url} style={{ textDecoration: 'underline' }} className="d-none d-xl-inline d-lg-inline d-md-inline">
                                                                    {info.city_name}
                                                                </a>
                                                                <ReactTooltip id={'testcart' + info.schedule_id} data-tip={info.venue_address} aria-haspopup='true' role='example'>
                                                                    <p>{info.date}, {info.venue_address}</p>
                                                                    <p>{t('home.Contact No')}<br />
                                                                        {info.contact_details}</p>
                                                                </ReactTooltip>
                                                            </div>
                                                            <div className="col-2">
                                                                {/* <i className={'fas fa-trash-alt delete_'+cell.event_id+'_'+cell.schedule_id}></i> */}
                                                                <i style={{ cursor: 'pointer' }} className={'fas fa-trash-alt delete_' + info.event_id + '_' + info.schedule_id} name={'delete_cart_' + info.event_id + '_' + info.schedule_id} id={'delete_' + info.event_id + '_' + info.schedule_id} onClick={this.deleteFromCart}></i>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <hr className="border-dark mx-n2 mt-2" />
                                            </div>
                                        )
                                    })
                                    }

                                    <span style={{ marginBottom: '10px', display: 'none' }} name="noItemInCart" id="noItemInCart" className="text-muted noItemInCart">{t('home.No items in the cart')}</span>


                                    {/* <Link  className="btn btn-brown btn-sm mb-2"  to="/mycart">{t('home.GO TO CART')}</Link> */}
                                    <button className="btn btn-brown btn-sm mb-2 btn-block goToCartBtn" onClick={this.goToCart}>{t('home.GO TO CART')}</button>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className={this.props.location.pathname.split("/")[4] === undefined && (localStorage.getItem('eventAllTopic') === null || localStorage.getItem('eventAllTopic') === 'show') ? "d-xl-none d-lg-none d-md-none d-block" : "d-none"}>
                        <Row className="mt-4">
                            <Col sm="12" xs="12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb bg-white mb-0">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                {t('home.home')}
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item ">
                                            <a href="javascript:void(0)">
                                                {t('home.events')}
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            <a href="#">
                                                {eventdetails.map((infos, ii) => {
                                                    return (
                                                        <span className="CurrentEventType">{infos.event_type} </span>
                                                    )
                                                })
                                                }
                                            </a>
                                        </li>
                                    </ol>
                                </nav>

                            </Col>
                            <Col sm="12" xs="12" className="mt-1">
                                <img src={this.state.ResidentialStudyCourses} height={77} id="background_1_m_1" />
                                <img src={this.state.OneDayWorkshop} height={77} id="background_2_m_2" />
                                <img src={this.state.RationalPoojas} height={77} id="background_4_m_4" />
                                <img src={this.state.FreeEvents} height={77} id="background_5_m_5" />
                            </Col>
                            <Col sm="12" xs="12" className="my-3">
                                {eventdetails.map((infos, ii) => {
                                    return (
                                        <span>
                                            {infos.event_description}
                                        </span>
                                    )
                                })
                                }
                            </Col>
                        </Row>

                        <Row className="px-1">
                            <Col sm="12" xs="12" className="bg-light py-2 border mb-2">
                                <Link className="w-100" onClick={() => localStorage.setItem('eventAllTopic', 'hide')} to={`${this.props.location.pathname}`}>
                                    <div className="float-left text-dark">
                                        {t('home.All Topic')}
                                    </div>
                                    <div className="float-right text-dark">
                                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                                    </div>
                                </Link>
                            </Col>
                            {
                                this.state.topic.map((topic) =>
                                    <Col sm="12" xs="12" className="bg-light py-2 border mb-2" key={topic.value}>
                                        <Link className="w-100" to={`${this.props.location.pathname}/${topic.value}`}>
                                            <div className="float-left text-dark">
                                                {topic.label}
                                            </div>
                                            <div className="float-right text-dark">
                                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                                            </div>
                                        </Link>
                                    </Col>
                                )
                            }

                        </Row>
                    </div>


                </div>
                <Footer share_image_url={this.state.share_image_url} description={guestinfo.social_description} share_current_image_url={this.state.share_current_image_url} />

                <div className="modal fade z-index--99999" id="notifyModel" tabindex="-1" role="dialog" aria-labelledby="notifyModel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content bg-light-light" style={{ overflow: 'auto' }}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{t('home.Event')}-&nbsp;<span id="eventTitle" style={{ paddingRight: '38px' }}></span></h5><br />
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {/* {t('home.Notify for')} */}
                                    <span id="notifyTitle"></span></h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <i className="far fa-times-circle"></i>
                                </button>
                            </div>
                            <Form action="" id="pform" onSubmit={this.handleSubmit} method="post" encType="multipart/form-data" className="form-horizontal">
                                <div className="modal-body">

                                    {/* <div className="row">
                        <div className="col">
                            <p className="text-right color-red">* {t('home.Mandatory Fields')}</p>
                        </div>
                    </div> */}
                                    <div className="row">
                                        <div className="form-group col col-md-4">
                                            <label className="color-black font-weight-bolder">{t('home.Name')}<span className="color-red">*</span></label>
                                            <input value={notifyinfo.first_name} onChange={e => { this.setState({ notifyinfo: { ...notifyinfo, first_name: e.target.value } }) }} onBlur={() => this.validator.showMessageFor('first_name')} type="text" id="first_name" name="first_name" className="form-control" placeholder={t('home.Enter First Name')} />


                                            {this.validator.message('first_name', this.state.notifyinfo.first_name, 'required')}

                                        </div>
                                        <div className="form-group col col-md-4">
                                            <label className="color-black font-weight-bolder">{t('home.Last Name')}<span className="color-red">*</span></label>
                                            {/* <input type="text" className="form-control" id="last_name" name="last_name" placeholder={t('home.Enter Last Name')} /> */}
                                            <input value={notifyinfo.last_name} onBlur={() => this.validator.showMessageFor('last_name')} onChange={e => { this.setState({ notifyinfo: { ...notifyinfo, last_name: e.target.value } }) }} type="text" id="last_name" name="last_name" className="form-control" placeholder={t('home.Enter Last Name')} />
                                            {this.validator.message('last_name', this.state.notifyinfo.last_name, 'required')}
                                        </div>


                                    </div>
                                    <div className="row">

                                        <div className="form-group col col-md-4">
                                            <label className="color-black font-weight-bolder">{t('home.Email')} <span className="color-red">*</span></label>
                                            <input value={notifyinfo.email} onChange={e => { this.setState({ notifyinfo: { ...notifyinfo, email: e.target.value } }) }} type="text" className="form-control" id="email" name="email" placeholder={t('home.Email')} />
                                            {this.validator.message('email', this.state.notifyinfo.email, 'required')}
                                        </div>
                                        <input type="hidden" value="" id="notify_schedule_id" name="notify_schedule_id" />
                                    </div>

                                </div>
                                <div className="modal-footer">

                                    <span data-dismiss="modal" aria-label="Close" style={{ cursor: 'pointer' }}>{t('home.Cancel')}</span>
                                    <button type="submit" className="btn btn-outline-brown bg-white btn-sm pl-4 pr-4" style={{ color: 'black' }}>{t('home.Submit')}</button>
                                </div>
                            </Form>
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

function extractMonthYear(dateString) {
    // Check if dateString is not a string
    if (typeof dateString !== "string") {
        console.error("Invalid date string:", dateString);
        return ""; // Return an empty string to avoid errors
    }

    const dateParts = dateString.split(" "); // Split the string by spaces
    return `${dateParts[1]} ${dateParts[2]}`; // Extract month and year
}

function sortDates(arr, type) {
    const monthMap = {
        January: 0, February: 1, March: 2, April: 3, May: 4, June: 5,
        July: 6, August: 7, September: 8, October: 9, November: 10, December: 11
    };

    const parseDate = (dateStr) => {
        if (!dateStr) return null;
        const match = dateStr.match(/^(\d+)(?:st|nd|rd|th) (\w+) (\d{4})$/);
        if (!match) return null;

        const day = parseInt(match[1], 10);
        const month = monthMap[match[2]];
        const year = parseInt(match[3], 10);

        return new Date(year, month, day);
    };

    if (type === 1) {
        // Sort main event array by `date`
        arr.sort((a, b) => (parseDate(a.date) - parseDate(b.date)));

        // Sort each `arr_schedule` inside the events by `start_date`
        arr.forEach(event => {
            if (event.arr_schedule && Array.isArray(event.arr_schedule)) {
                event.arr_schedule.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
            }
        });
    }

    return arr;
}


// function sortDates(arr, type) {
//     const monthMap = {
//         January: 0, February: 1, March: 2, April: 3, May: 4, June: 5,
//         July: 6, August: 7, September: 8, October: 9, November: 10, December: 11
//     };
//     // console.log("start", type, arr)
//     const parseDate = (dateStr) => {
//         // Extract day, month, and year using regex
//         const match = dateStr.match(/^(\d+)(?:st|nd|rd|th) (\w+) (\d{4})$/);
//         if (!match) return null;

//         const day = parseInt(match[1], 10);
//         const month = monthMap[match[2]];
//         const year = parseInt(match[3], 10);

//         return new Date(year, month, day);
//     };
//     if (type === 1)
//         arr.sort((a, b) => parseDate(a?.date) - parseDate(b?.date)); // Sorts in ascending order
//     if (type === 2)
//         arr.sort((a, b) => new Date(a?.start_date) - new Date(b?.start_date));
//     // arr.sort((a, b) => parseDate(a?.start_date) - parseDate(b?.start_date)); // Sorts in ascending order

//     return arr;
// }
Events.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Events);

